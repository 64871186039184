import React from "react";
import loader from "assets/loader1.png";
import "./spinner.css";
export const SpinnerLogo = () => {
  return (
    <div style={{ height: "100vh" }}>
      <div className="rotate">
        <img src={loader} />;
      </div>
    </div>
  );
};
