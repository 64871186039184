import React, { useEffect } from "react";
import CollegesHeadingimg from "../../assets/image/college-img-heading.png";
import CollegesSubHeadingimg from "../../assets/image/college-img-subheading.png";
import CollegesTop from "../../assets/image/college-top.png";
import CollegesMiddle from "../../assets/image/college-middle.png";
import CollegesBottom from "../../assets/image/college-bottom.png";

import CollegesTalentPoolimg from "../../assets/image/college-img-talentpool.png";
import CollegeMaxlifeimg from "../../assets/image/college-maxlife.png";
import Collegesstarimg from "../../assets/image/college-star.png";
import Collegestataimg from "../../assets/image/college-tata.png";
import CollegeAccenture from "../../assets/image/college-accenture.png";
import CollegeAxis from "../../assets/image/college-axisbank.png";
import CollegeCare from "../../assets/image/college-care.png";
import CollegeClearTrip from "../../assets/image/college-cleartrip.png";
import CollegeDigit from "../../assets/image/college-digit.png";
import CollegeHDFC from "../../assets/image/college-hdfc.png";
import CollegeHdfcLife from "../../assets/image/college-hdfclife.png";
import CollegeIcici from "../../assets/image/college-icici.png";
import CollegeInfosys from "../../assets/image/college-infosys.png";
import CollegeMakeMyTrip from "../../assets/image/college-makemytrip.png";
import CollegeMaxLife from "../../assets/image/college-maxlife.png";
import CollegeSBI from "../../assets/image/college-sbi.png";
import CollegeTele from "../../assets/image/college-Teleperformance.png";

import SchoolsHeadingimg2 from "../../assets/image/school-heading-2.png";
import SchoolsHeadingimg3 from "../../assets/image/college-img-heading.png";
import CollegesTataasiaimg from "../../assets/image/college-tataaia.png";
import CollegesHdfcimg from "../../assets/image/college-hdfc.png";
import people from "../../components/assets/icon/contact-icon (1).svg";
import messageing from "../../components/assets/icon/contact-icon (2).svg";
import phoneCall from "../../components/assets/icon/contact-icon (3).svg";
import collabaration from "../../components/assets/icon/collabaration.svg";
import "./Colleges.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FooterPurple from "components/Footer/FooterPurple";
import Marquee from "react-fast-marquee";
import { CollabForm } from "Axios/apis";
import { useState } from "react";

const Colleges = ({ setCourse }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  AOS.init({ duration: 2000 });
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const collegecarddata = [
    {
      heading: "Student Requirements",
      datalist: [
        "Career Planning",
        "Industry/Sector Knowledge",
        "ATS Compliant Resume",
        "Professional Profile",
        "Job",
      ],
    },
    {
      heading: "VIOSA Solution",
      datalist: [
        "Professional Roadmap",
        "Corporate Sector Learning",
        "Softskill Competency Coaching",
        "Automated Resume Builder",
        "Digital Profiling & Professional Networking",
        "Interview Skills Training",
        "Elevator Pitch & Group Discussions",
        "Job Identification & Application",
      ],
    },
    {
      heading: "Corporate Requirements",
      datalist: [
        "Corporate Readiness",
        "Sectoral Know-how",
        "Identified functional competencies",
        "Job based skill understanding",
        "Professional Profiling",
        "Corporate Ethics",
      ],
    },
  ];

  const [collaborationdata, setcollaborationdata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    schoolOrCollege: "",
  });
  const submitcollaborationform = async (e) => {
    e.preventDefault();
    setLoading(true);
    // //console.log(collaborationdata);
    try {
      const res = await CollabForm(collaborationdata);
      setLoading(false);

      // //console.log(res);
      setcollaborationdata({
        name: "",
        email: "",
        phone: "",
        message: "",
        schoolOrCollege: "",
      });
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      setLoading(false);
      // //console.log(error);
    }
  };
  const handleChangecollaboration = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setcollaborationdata({
      ...collaborationdata,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid bg-white colleges-main-container">
          <section className="row colleges-section-container">
            <div className="col-8 w-100-768 d-flex flex-column justify-content-center p-0">
              <h2 className="colleges-subheading text-start colleges-text-purple">
                For Colleges
              </h2>
              <h1 className="colleges-mainheading text-start">
                Corporate Mentorship Program
              </h1>
              <p className="colleges-paragraph  ">
                Empowering your students to kickstart their professional career.
                VIOSA assists the placement division in mentoring corporate
                readiness students. We focus on developing sectoral
                competencies, mentoring them to build their professional
                profiles & guiding them to achieve their aspirational placement
                through our corporate linkages.
              </p>
            </div>
            <div className="col-4 d-flex justify-content-center w-100-768 p-0 ">
              <img
                data-aos="zoom-in"
                className="colleges-heading-img"
                src={CollegesTop}
                alt=""
              />
            </div>
          </section>
          <section className="row  md:flex-row-reverse colleges-section-container">
            <div className="col-8 w-100-768 d-flex flex-column justify-content-center">
              <p className="colleges-paragraph">
                Corporate Mentorship is a collaborative program with colleges
                assisting the placement division in coaching students. We focus
                on developing sectoral competencies, mentoring them to build
                their professional profiles & guiding them to achieve their
                aspirational placement through our corporate linkages.
              </p>
            </div>
            <div className="col-4 w-100-768 p-0 d-flex justify-content-center ">
              <img
                className="colleges-heading-img"
                src={CollegesMiddle}
                alt=""
                data-aos="zoom-in"
              />
            </div>
          </section>
          <section
            className=" row colleges-section-container d-flex flex-column"
            style={{ background: "#fefbf3" }}
          >
            <h1 className="colleges-mainheading">
              Our <span className="colleges-text-purple">Corporate </span>
              Linkages
            </h1>
            <div className="colleges-secondary-container d-flex justify-content-evenly">
              <Marquee speed={60} gradient={false}>
                <img
                  className="colleges-corporate-img"
                  src={CollegesHdfcimg}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={Collegestataimg}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={Collegesstarimg}
                  alt=""
                />
                <img
                  style={{ width: "170px" }}
                  className="colleges-corporate-img"
                  src={CollegeMaxlifeimg}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegesTataasiaimg}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeCare}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeHdfcLife}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeAxis}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeIcici}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeSBI}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeInfosys}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeMakeMyTrip}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeAccenture}
                  alt=""
                />
                <img
                  className="colleges-corporate-img"
                  src={CollegeTele}
                  alt=""
                />
              </Marquee>
            </div>
            <h1 className="colleges-mainheading">
              Bridge the Gap between
              <span className="colleges-text-purple">
                {" "}
                Students & Corporates{" "}
              </span>
            </h1>
            <div className="colleges-card-container">
              {collegecarddata.map((card) => {
                return (
                  <div className="colleges-card ">
                    <h3 className="colleges-subheading">{card.heading}</h3>
                    {card.datalist.map((subheading) => {
                      return (
                        <p className="colleges-paragraph size-1-1 text-center">
                          {subheading}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <h2 className="colleges-subheading">
              Explore Courses for College Students
            </h2>
            <div className="row d-flex justify-content-center ">
              <div
                className="col-md-4 col-sm-4 col-lg-3 col-xxl-3 w-100-768"
                onClick={() => {
                  setCourse("Undergraduate/Graduate");
                  navigate("/course");
                }}
              >
                <button className="colleges-btn colleges-btn-org">
                  Explore Courses →
                </button>
              </div>
            </div>
          </section>
          <section className="colleges-talent-outer-container">
            <div className="colleges-talent-inner-container">
              <div className="row py-2 ">
                <h1 className="colleges-mainheading">
                  VIOSA
                  <span className="colleges-text-purple"> Talent Pool </span>
                </h1>
              </div>
              <div className="row py-3 px-35-600">
                <div className="col-8 w-100-768 d-flex flex-column justify-content-center">
                  <p className="colleges-paragraph" style={{ opacity: "0.95" }}>
                    Students registering for this program get added to our
                    talent bank which is accessible to the corporates for their
                    job role specific recruitments.
                  </p>
                  <ul className="colleges-unorderedlist">
                    <li> Placement across various sectors </li>
                    <li>Free placement assistance</li>
                    <li>Opportunity to showcase your skills & competencies</li>
                    <li>Corporate seminars</li>
                    <li>Summer Internships</li>
                  </ul>
                </div>
                <div className="col-4 w-100-768 p-0 ">
                  <img
                    className="colleges-heading-img width-100 mx-auto"
                    src={CollegesBottom}
                    alt=""
                    data-aos="zoom-in"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="schools-contact-container">
            <div>
              <div className="flex justify-center">
                <div className="row py-2 ">
                  <h1 className="colleges-mainheading">
                    Collaboration
                    <span className="colleges-text-purple"> Enquiry </span>
                  </h1>
                </div>
              </div>
              <form onSubmit={(e) => submitcollaborationform(e)}>
                <div className="flex justify-center">
                  <div>
                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6">
                        <img className="mx-4 for-opacity" src={people} alt="" />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="text"
                          name="name"
                          required
                          title="Please enter your name"
                          value={collaborationdata.name}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border  hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="email"
                          name="email"
                          required
                          title="Please enter your email"
                          value={collaborationdata.email}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your email"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={phoneCall}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="tel"
                          name="phone"
                          title="Please enter your valid phone number"
                          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                          required
                          value={collaborationdata.phone}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your phone"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={collabaration}
                          alt=""
                        />

                        <select
                          style={{ background: "#FFF7E7", opacity: "0.5" }}
                          className="pl-2 input-field"
                          required
                          name="schoolOrCollege"
                          onChange={handleChangecollaboration}
                        >
                          <option disabled selected>
                            Choose Collaboration
                          </option>
                          <option value="School">School</option>
                          <option value="College">College</option>
                        </select>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <input
                          style={{ background: "#FFF7E7", marginLeft: "76px" }}
                          className="pl-2 input-field"
                          type="text"
                          name="message"
                          required
                          title="Please enter your message"
                          value={collaborationdata.message}
                          onChange={handleChangecollaboration}
                          placeholder="Your Message"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 d-flex align-center justify-content-center">
                  <button
                    for="my-modal-1"
                    disabled={loading}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {loading ? "Sending" : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};
export default Colleges;
