import * as React from "react";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
import "./TimelineComp.css";
import { Stepper, Step } from 'react-form-stepper';

const TimelineComp = ({ data }) => {
  console.log(data)

  return (
    <>
      <Stepper className="stepper-container" >
        {data.map((label, index) => (
          <Step className="stepper-step-style" label={label} />
        ))}
        {/* <Step label="Children Step 2" />
        <Step label="Children Step 3" /> */}
      </Stepper>

      {/* <Stepper size="small" alternativeLabel >
        {data?.map((label, index) => (
          <Step key={label} StepIconComponent={index + 1}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
    </>
  );
};
export default TimelineComp;
