import React, { useState } from "react";
import password1 from "./../../assets/icon/password.svg";
import homeLogo from "./../../assets/icon/imageHome.png";
import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { resetPasswordApi } from "../../../Axios/apis";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ChangePassword from "../ChangePassword/ChangePassword";
import FooterPurple from "components/Footer/FooterPurple";

const ResetPassword = ({ setRegister }) => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [confirmPassword, setComfirmPassword] = useState(false);
  const [enterPassword, setEnterPassword] = useState(false);
  const [surePassword, setSurePassword] = useState(false);
  const [changepasswordform, setchangepasswordform] = useState(true);

  const handlePasswordShowHide = () => {
    if (!enterPassword) {
      setEnterPassword(true);
    } else {
      setEnterPassword(false);
    }
  };

  const handleConfirmPasswordShowHide = () => {
    if (!surePassword) {
      setSurePassword(true);
    } else {
      setSurePassword(false);
    }
  };
  let isDev =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://viosa.vercel.app";
  const onBlurValidation = (e) => {
    switch (e.target.name) {
      case "password":
        if (
          !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
            form.password
          )
        ) {
          setPasswordError(true);
          return;
        } else {
          setPasswordError(false);
        }
        break;
      case "confirmPassword":
        if (form.password !== form.confirmPassword) {
          setComfirmPassword(true);
          setPasswordError(false);
          return;
        } else {
          setComfirmPassword(false);
        }
    }
  };
  const [confirmPasswordError, setComfirmPasswordError] = useState(false);
  // const closeRef = useRef(null);
  const formHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const id = useParams();

  const passwordReset = () => {
    const passwordValid =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    // //console.log(passwordValid.test(password));
    if (!passwordValid.test(password)) {
      setPasswordError(true);
      return;
    }
    if (password !== confirmPassword) {
      setComfirmPasswordError(true);
      setPasswordError(false);
      return;
    }
    setShowMessage(false);
    setPasswordError(false);
    setComfirmPasswordError(false);
    resetPassword();
  };

  const resetPassword = async (e) => {
    setIsLoading(true);
    let formData = { password: password };
    try {
      const response = await resetPasswordApi(formData, id.id);
      // //console.log(response.data);
      if (response.data.result.status) {
        setMessage("Password reset successfully");
        toast.success("Password Reset Successfully");
        // alert("Password Reset Successfully");
        setIsLoading(false);
        setInterval(() => {
          navigate("/");
        }, 2000);
      }
    } catch (e) {
      setInterval(() => {
        setShowMessage(false);
      }, 3000);
      setIsLoading(false);

      setShowMessage(true);
      setMessage("Something Went Wrong");
      // //console.log(e);
    }
  };

  return (
    <>
      <div style={{ height: "75vh" }}>
        <ChangePassword />;
      </div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default ResetPassword;
