import React, { useState } from "react";
import PureModal from "react-pure-modal";
import Button from "react-bootstrap/Button";

const FillDetailsModal = () => {
  const [filldetailsmodal, setfilldetailsmodal] = useState(false);
  return (
    <>
      <PureModal
        isOpen={filldetailsmodal}
        header="Alert"
        closeButton="X"
        width={400}
        closeButtonPosition="header"
        onClose={() => {
          setfilldetailsmodal(false);
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          {/* <div className="mt-2 mb-4"><img src={} style={{"height":"200px"}}></img>
            </div> */}
          <div className="my-2">
            <h1 style={{ fontSize: "20px", color: "#eb6a3f" }}>
              Please Fill Up all The Details
            </h1>
          </div>
          <div className="my-5">
            <Button
              onClick={() => {
                setfilldetailsmodal(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </PureModal>
    </>
  );
};

export default FillDetailsModal;
