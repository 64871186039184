import React, { useEffect } from "react";
import { useState } from "react";
import "./TeamMemberCard.css";
const TeamMemberCard = ({
  name,
  position,
  linkedin,
  bio,
  profileimg,
  teamMember,
}) => {
  const [showmore, setshowmore] = useState(false);

  return (
    <div className="teammembercard-container">
      <div className="d-flex justify-content-center teamcard-profileimg-container">
        <img className="teammembercard-profileimg" src={profileimg} />
      </div>
      <div
        className="head-sub-height"
        style={{ height: teamMember === "Our Mentors" ? "60px" : "120px" }}
      >
        <h1 className="teammembercard-heading">{name}</h1>
        <h1
          className={`${
            position.toLowerCase() === "mentor"
              ? "teammembercard-subheading-full"
              : "teammembercard-pargraph-full"
          }`}
        >
          {position}
        </h1>
      </div>

      {showmore ? (
        <p className="teammembercard-pargraph-full">{bio}</p>
      ) : (
        bio.length !== 0 && (
          <p className="teammembercard-pargraph-full">
            {bio.length > 450 ? bio.slice(0, 450) + "..." : bio}
          </p>
        )
      )}

      {bio.length > 400 ? (
        showmore ? (
          <div className="text-end btn-end">
            <button
              className="join-session-btn"
              onClick={() => {
                setshowmore(false);
              }}
            >
              Read Less
            </button>
          </div>
        ) : (
          <div className="text-end btn-end">
            <button
              className="join-session-btn"
              onClick={() => {
                setshowmore(true);
              }}
            >
              Read More
            </button>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};
export default TeamMemberCard;
