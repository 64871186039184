import React, { useEffect, useState } from "react";
import {
  getAllCourse,
  getChapter,
  getProgressById,
  updateCourse,
} from "../../../Axios/apis";
import "./courseHeader.css";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { createRoot } from "react-dom/client";
import parse from "html-react-parser";
import { AccordnationItem } from "../AccordnationItem";
import PDFViewer from "pdf-viewer-reactjs";
// import { Accordion } from "./CourseItem";
import ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import { AccordionLiveSession } from "../LiveSessionItem";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import { SingleCourse } from "redux/coures/courseAction";
import LiveSession from "../../../icons/live-session.png";
import { UserCounselling } from "./CareerAssessment";

export const CourseHeader = (courseID) => {
  const dispatch = useDispatch();
  const [lastTime, setLastTime] = useState(null);
  const [progressCircle1, setProgressCircle] = useState([]);
  const [loadspinner, setloading] = useState(false);
  const { courseLink, course, singleCourse, courseBody } = useSelector(
    (state) => state.course
  );
  const [played, setPlayed] = useState({
    played: 0,
    totalVideoDuration: 0,
  });

  const [playprogress, setPlayProgress] = useState(0);
  // //console.log(playprogress)

  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetch = async () => {
      if (played.played > 5) {
        const currentPlayingTime = Math.round(played.played);
        const totalVideoDuration = Math.round(
          played.totalVideoDuration - played.totalVideoDuration * 0.1
        );
        const isCompleted = currentPlayingTime >= totalVideoDuration;
        if (Math.round(played.played) % 5 === 0 || isCompleted) {
          const response = await updateCourse(
            {
              course_id: courseLink.course_id,
              chapter_id: courseLink.chapter_id,
              courseCompleted: isCompleted,
              lastTime: Math.round(played.played),
            },
            token
          );
        } else {
          return;
        }
      }
    };
    fetch();
  }, [played.played]);

  useEffect(() => {
    const fetchProgress = async () => {
      const resProgress = await getProgressById(
        {
          course_id: courseLink.course_id,
        },
        token
      );
      // //console.log(resProgress.data.result);
      setPlayProgress(resProgress.data.result);
      setProgressCircle(
        resProgress?.data?.result?.completedChaptersIds === 0
          ? []
          : resProgress?.data?.result?.completedChaptersIds
      );
      // setCourseProgress(resProgress.data.result)
    };
    fetchProgress();
  }, []);
  //console.log(progressCircle1);
  const progressCirlce =
    (playprogress.completedChapters / playprogress.totalChapters) * 100 || 0.0;
  // //console.log(progressCirlce)

  const lastPlayTime = async () => {
    const body = {
      chapter_id: courseLink.chapter_id,
    };
    const chapterDetails = await getChapter(body, token);
    setLastTime(Number(chapterDetails.data?.result[0]?.lastPlayedVideo || 0));
  };

  useEffect(() => {
    lastPlayTime();
  }, [courseLink.course_id, courseLink.chapter_id]);

  // //console.log(lastPlayTime);

  const [active, setActive] = useState(false);
  const [progress, setProgress] = useState(0);

  const totalChapter =
    singleCourse?.courseID &&
    singleCourse?.section?.reduce((total, section) => {
      return total + section.chapter.length;
    }, 0);

  const count1 =
    singleCourse?.courseID &&
    singleCourse.section.map(
      (item) =>
        item.chapter.filter((value) => value.isCompleted === true).length
    );

  const sum =
    singleCourse?.courseID &&
    count1?.reduce((partialSum, a) => partialSum + a, 0);
  useEffect(() => {
    const updatedProgress = (sum / totalChapter) * 100;
    setProgress(updatedProgress);
  }, [courseLink.link, courseLink.link2, SingleCourse]);

  const check = React.useRef(null);
  // //console.log(courseLink.link2);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="wrapper course-header">
        <div id="main">
          <header className="container-fluid curriculumnav">
            <nav className="navbar navbar-expand navbar-dark">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item leftnav-btnwrap">
                  <button type="button" onClick={() => setActive(!active)}>
                    {!active ? (
                      <i className="fa-solid fa-bars text-dark"></i>
                    ) : (
                      <i className="fa-solid fa-times text-dark"></i>
                    )}
                  </button>
                </li>
              </ul>
              <div className="d-flex justify-content-around align-items-center mb-3 sectionprogress">
                <div className="wrap-circles">
                  <div
                    className="circle per-25"
                    style={{
                      backgroundImage: `conic-gradient(#B5838D ${progressCirlce}%, #FFCDB2 0)`,
                    }}
                  >
                    <div className="inner">{progressCirlce.toFixed(2)}</div>
                    {/* <div className="inner">{playprogress ? playprogress.toFixed(2) : "0.00"}</div> */}
                  </div>
                </div>
              </div>
              <ul className="navbar-nav ml-auto" style={{ display: "flex" }}>
                <Link
                  to={`/profile/dashboard/${courseLink.course_id}/curriculum`}
                  className="white-100"
                >
                  <li className="nav-item ml-2">
                    <button
                      type="button"
                      className="nav-link topnav-btn nextlecture"
                    >
                      EXIT
                    </button>
                  </li>
                </Link>
              </ul>
            </nav>
          </header>
          <div className={`leftnav-container ${active ? "openactive" : ""}`}>
            <div className="close-btn mt-3">
              <h1 className="mx-3">{singleCourse?.name}</h1>
              <button type="button" onClick={() => setActive(!active)}>
                <i className="fa-solid fa-times text-dark"></i>
              </button>
            </div>
            {/* <div className="leftnav-head">
            <h5 className="coursename shadow">{singleCourse?.name}</h5>
          </div> */}
            <div className="leftnav-contwrap ps">
              {/* <ul className="list-unstyled"> */}
              <Accordion
                className="d-flex flex-column mt-5"
                defaultActiveKey="0"
              >
                {singleCourse?.section?.map((item) => (
                  <AccordnationItem
                    progressCircle={progressCircle1}
                    {...{
                      item,
                      courseID: singleCourse.courseID,
                    }}
                  />
                ))}
                {singleCourse?.liveSession?.map((live) => (
                  <AccordionLiveSession
                    {...{
                      live,
                    }}
                  />
                ))}
              </Accordion>
              {/* </ul> */}
              <div className="ps__rail-x">
                <div
                  className="ps__thumb-x"
                  tabindex="0"
                  // style="left: 0px; width: 0px;"
                ></div>
              </div>
              <div
                className="ps__rail-y"
                // style="top: 0px; right: 0px;"
              >
                <div
                  className="ps__thumb-y"
                  tabindex="0"
                  // style="top: 0px; height: 0px;"
                ></div>
              </div>
            </div>
          </div>
          {courseLink.link4 > 0 ? (
            <div
              style={{ marginTop: 100 }}
            >{`course will available after ${courseLink.link4} days`}</div>
          ) : (
            <div className="videodelivery">
              {courseLink?.link?.length > 0 ? (
                <div id="bunnyprovideocontainer">
                  <ReactPlayer
                    ref={check}
                    playing={true}
                    onStart={() => check.current.seekTo(lastTime)}
                    onProgress={(progress) => {
                      setPlayed({
                        ...played,
                        played: progress.playedSeconds,
                        totalVideoDuration: progress.loadedSeconds,
                      });
                    }}
                    key={courseLink.link}
                    url={courseLink?.link}
                    width="100%"
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    height="100%"
                    controls
                    controlsList="nodownload"
                  />
                </div>
              ) : (
                courseLink?.link2 && (
                  <embed
                    type="application/pdf"
                    src={`${courseLink?.link2}#toolbar=0`}
                    style={{ height: "97vh", width: "100%" }}
                  ></embed>
                )
              )}
            </div>
          )}
          {courseLink.livelink && (
            // <div className="">
            <div className="card-1 h-100 border-0 d-flex justify-content-center align-items-center flex-column">
              <img
                src={LiveSession}
                style={{
                  zIndex: 10,
                  width: "50%",
                  height: "100%",
                }}
                alt="Zoom"
              />
              <button className="btn btn-primary white-100 mb-3">
                <a
                  className="white-100"
                  href={courseLink.livelink}
                  target="_blank"
                  style={{ zIndex: 100 }}
                >
                  Join Now
                </a>
              </button>
            </div>
            // </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
