import React, { useEffect, useState } from "react";
import Roadmapheading1 from "../../assets/image/roadmap-heading-1.png";
import IMG1 from "../../assets/image/8-10-with.jpg";
import IMG2 from "../../assets/image/8-10-without.jpg";
import IMG3 from "../../assets/image/11-12-without.jpg";
import IMG4 from "../../assets/image/11-12-with.jpg";
import file from "../../components/assets/icon/person.svg";
import people from "../../components/assets/icon/college.svg";
import messageing from "../../components/assets/icon/message.svg";
import phoneCall from "../../components/assets/icon/call.svg";
import why from "assets/why.png";
import aiImage from "assets/Artboard 1.png";
import FiveD from "../../components/assets/icon/5D.png";
import ACCURACY from "../../components/assets/icon/ACCURACY.png";
import REPORT from "../../components/assets/icon/REPORT-1.png";
import COUNSELLING from "../../components/assets/icon/COUNSELLING.png";
import PREDICTOR from "../../components/assets/icon/PREDICTOR.png";
import EXAM_CALANDER from "../../components/assets/icon/EXAM_CALANDER.png";
import LANGUAGE from "../../components/assets/icon/LANGUAGE.png";
import library2 from "../../components/assets/icon/library2.png";
// import LargeVideo from "../../components/assets/icon/large-video.png";
import class8 from "assets/8.png";
import class11 from "assets/11.png";
import PureModal from "react-pure-modal";

// import SmallVideo from "../../components/assets/icon/small-video.png";

import FrequentlyAsked from "../../components/Home/FrequentlyAsked";
import "./Roadmap.css";
import { Featurecard } from "../../components/Featurecard/Featurecard";
import TimelineComp from "../../components/TimelineComp/TimelineComp";
import { motion } from "framer-motion";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AddToCart,
  CareerCounselorForm,
  FetchSingleCourse,
  getAllCourse,
  getAllUserCourses,
} from "Axios/apis";
import SignUp from "components/Authentication/SignUp/SignUp";
import { AddtoCart, userRoadMapCourse } from "redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import axios from "axios";
import Cartmodal from "components/Cartmodal/Cartmodal";
import { Spin } from "components/Loader/Spin";
import CareerRoadMapCard from "./CareerRoadMapCard";

const CareerRoadMap = ({
  setBlur,
  register1,
  setRegister,
  login,
  setLogin,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [largeVideo, setVideo] = useState();
  const [call, setCall] = useState(false);
  console.log(call);
  const [accessCode, setAccessCode] = useState("");
  const location = useLocation();
  const [counselordata, setcounselordata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    course: "",
  });
  const [spin, setSpin] = useState(false);
  const handleChangecounselor = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setcounselordata({ ...counselordata, [e.target.name]: e.target.value });
  };
  const [loading1, setLoading1] = useState(false);
  const loginToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [courseData, setCourseData] = useState();
  const [courseDataAll, setCourseDataAll] = useState([]);
  const [status, setStatus] = useState(false);
  const [isPurchased, setIsPurchased] = useState();
  const [paymentsuccess, setPaymentSuccess] = useState(false);
  const [courseid, setCourseid] = useState("");
  const [bgimage, setBgImage] = useState();
  const [loadingcart, setloadingcart] = useState(false);
  let [searchParams] = useSearchParams();

  const [singleCourse, setSingleCourse] = useState([]);
  const [revealPrice, setRevealPrice] = useState("");
  const { cart } = useSelector((state) => state.user);
  const isCourseExists = cart?.find((item) => item.name === singleCourse?.name);
  const addToCart = async () => {
    if (!loginToken) {
      setRegister(true);
    } else {
      setloadingcart(true);
      try {
        const cartitem = {
          cartBody: {
            courseID: params.id,
            name: singleCourse?.name,
            thumbnail: singleCourse?.thumbnail,
            price: singleCourse?.price,
            course_id: singleCourse?.course_id,
          },
          userId: localStorage.getItem("userId"),
        };
        if (
          !cartitem.cartBody.course_id ||
          !cartitem.cartBody.courseID ||
          !cartitem.cartBody.name ||
          !cartitem.cartBody.price ||
          !cartitem.cartBody.thumbnail
        ) {
          alert("Failed to Add to Cart, please try again");
          setloadingcart(false);
          return;
        }
        const res = await AddToCart(cartitem);
        dispatch(AddtoCart(res?.data?.result?.cart));
        setloadingcart(false);
      } catch (error) {
        // //console.log(error);
        // toast.warning("Failed to Add to Cart, please try again");
        setloadingcart(false);

        alert("Failed to Add to Cart, please try again");
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllCourse();
      const modifyData = data?.data?.courses.map((item) => {
        if (item.isCounselling) {
          return {
            ...item,
            withoutPrice: 1499,
            withPrice: 2499,
            withoutName: item.name + " without Counselling",
            idWithout: item.course_id,
            idWith: data?.data?.courses.find(
              (item1) => item1.order === item.order - 1
            ).course_id,
          };
        }
      });
      const filterData = modifyData.filter((item) => item);
      setCourseDataAll(filterData);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetchCourse = async () => {
      const userid = localStorage.getItem("userId");

      const response = await FetchSingleCourse(params.id, userid);
      if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 8th, 9th, 10th")
      ) {
        setBgImage(IMG2);
      } else if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 11th & 12th")
      ) {
        setBgImage(IMG3);
      } else if (params.id === "6343bf2ae33839d145810ec6") {
        setBgImage(IMG1);
      } else if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 11th & 12th with counseling")
      ) {
        setBgImage(IMG4);
      }
      setRevealPrice(response?.data?.courses?.price);
      setSingleCourse(response?.data?.courses);
      if (response?.data?.courses?.name?.includes("8th")) {
        setVideo(class8);
      } else {
        setVideo(class11);
      }

      setCourseData({
        isCounselling: response?.data?.courses?.isCounselling,
        name: response?.data?.courses?.name,
        videoLink1: response?.data?.courses?.videoLink1,
        videoLink2: response?.data?.courses?.videoLink2,
        desc:
          response?.data?.courses?.description || response?.data?.courses?.name,
      });

      // dispatch(SingleCourse(response?.data?.courses));
    };
    const setSpecificCourses = () => {
      setCourseid(params.id);
      fetchCourse();
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSpecificCourses();
  }, [params.id]);

  useEffect(() => {
    setLoading1(true);
    const fetchCourse = async () => {
      setloading(true);
      try {
        const result = await axios.get("https://ipapi.co/json");
        if (loginToken) {
          const response2 = await getAllUserCourses(loginToken);
          setIsPurchased(
            response2.data.courses.filter((item) => item.courseID === params.id)
              .length > 0
          );
        }

        setloading(false);
        setLoading1(false);
      } catch {
        setIsPurchased(false);
        setloading(false);
        setLoading1(false);
      }
    };
    fetchCourse();
  }, [loginToken, params.id, location.search]);

  const isLogin = searchParams.get("loggedIn");

  //console.log("purchaged", isPurchased);

  const featurecarddata = [
    {
      icon: FiveD,
      heading: "5-Dimensional Career Analysis",
      subheading:
        "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
    },
    {
      icon: ACCURACY,
      heading: "98.6 % Accuracy",
      subheading:
        "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
    },
    {
      icon: REPORT,
      heading: "24+ Page Detailed Career Plan Report",
      subheading:
        "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
    },
    {
      icon: library2,
      heading: "Career Library with 150+ Career Paths & 3000+ Occupations",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: COUNSELLING,
      heading: "Personalised Career Counseling by Certified Counsellors",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: PREDICTOR,
      heading: "College Admission Predictor",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: EXAM_CALANDER,
      heading: "Competitive Exam Calendar",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: LANGUAGE,
      heading: "Career Assessment & Counseling in the Language of your Choice",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
  ];

  const timelinedata = [
    "Start Career Assessment",
    "Get a 24+ Page Report",
    "Analyse Your Career Paths",
    "Get Personalised Career Counseling",
  ];

  const faq = [
    // {
    //   headQuestion: "What is five dimensional career assessment?",
    //   paraAnswer: `Our career assessment explores the 5-Dimensions of the candidate. It
    //   includes Personality analysis, Career Interest analysis, Career
    //   motivators analysis, learning style analysis and Skills & Abilities
    //   analysis.`,
    // },
    // {
    //   headQuestion:
    //     "What is the difference between Free Suitability and Comprehensive Analysis?",
    //   paraAnswer: `Both are 5-Dimensional career assessment. In Free Suitability,
    //   candidate can get analysis of one career path of their choice with
    //   free 3 pages basic report. In Comprehensive plan, career analysis will
    //   be done across 160+ career paths and 2000+ Occupations to find out the
    //   best career path for you. Comprehensive 28+ pages career report with
    //   execution plan will help you to achieve career goals.`,
    // },
    // {
    //   headQuestion:
    //     "Once Comprehensive report is activated, can I see analysis other career paths as well?",
    //   paraAnswer: `Yes, very much. This is the biggest advantage every student is having.
    //   Once assessment is done student can generate unlimited Comprehensive
    //   28+ pages Comprehensive reports of different career paths. It is
    //   important to know you most preferred career paths but equally it is
    //   important to know about career paths that you should avoid or develop.`,
    // },
    // {
    //   headQuestion:
    //     "Do I need to give assessment once again for Comprehensive analysis?",
    //   paraAnswer: `No, you do not need to give assessment again. Once assessment is done,
    //   you can upgrade any time. System will automatically analyze all career
    //   paths and generate Comprehensive report. Our powerful Artificial
    //   intelligence engine will unlock all the career path analysis.`,
    // },
    {
      headQuestion:
        "How is the Career Guidance and Counselling session conducted?",
      paraAnswer: `1. Select the applicable Career guidance course and click on ‘Buy Now’.
2. Sign up and proceed to complete the payment.
3. Click on ‘career assessment’ and start the assessment.
4. On completing the assessment your report will be activated.
5. You will then have a 30-minute 1:1 counselling session with our Career Counsellor at a
scheduled date.`,
    },
    {
      headQuestion: "What is a psychometric assessment?",
      paraAnswer: `A psychometric assessment is a tool used to evaluate various aspects of an individual's
personality, motivators, learning styles, skills, and abilities. These assessments provide
valuable insights to help students and job seekers make informed decisions about their
academic and professional futures`,
    },
    {
      headQuestion: "When is the right time to do Career Counselling?",
      paraAnswer: `Career counselling is beneficial at any stage of life. Whether you’re a student deciding your
subject selection, college stream or career path, or a professional seeking a career change,
VIOSA’s career assessment and career counsellors help provide personalised guidance to
support your goals and aspirations.`,
    },
    {
      headQuestion: "What does the VIOSA Career Guidance program include?",
      paraAnswer: `You will receive the following on enrolling for the VIOSA CareerPathAI - Career Guidance and
Counselling program -
1. An online psychometric career assessment
2. 24+ pages detailed report
3. 30 min 1:1 career counselling session
4. Access to career library & dashboard with real-time data on -
a. 10,000+ Indian colleges and 1.5 Lac+ courses nationwide.
b. 8,000+ top international universities in 22+ countries.
c. Admissions, access to college info, fees, ranking, benefits and more.
d. 1400+ entrance test details for various degrees.
e. 3000+ career options with comprehensive information.`,
    },
    {
      headQuestion:
        "What is the difference between a Psychometric Assessment and Aptitude test?",
      paraAnswer: `A psychometric assessment is a tool used to evaluate various aspects of an individual's
personality, motivators, learning styles, skills, and abilities. It provides insights into an
individual's overall profile and helps identify suitable career paths or educational choices.
An aptitude test focuses mainly on assessing a person's cognitive abilities, such as numerical,
verbal, and abstract reasoning, as well as specific skills relevant to certain occupations or fields of study. Aptitude tests are often used to determine an individual's potential to perform
specific tasks or succeed in particular roles.`,
    },
  ];

  const submitcounselorform = async (e) => {
    e.preventDefault();
    // //console.log(counselordata);
    if (
      counselordata.name === "" ||
      counselordata.email === "" ||
      counselordata.phone === "" ||
      counselordata.message === "" ||
      counselordata.course === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (counselordata.phone.toString().length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }
    setloading(true);
    try {
      const res = await CareerCounselorForm(counselordata);
      // //console.log(res);
      setloading(false);
      setCall(false);
      setcounselordata({
        name: "",
        email: "",
        phone: "",
        message: "",
        schoolOrCollege: "",
      });
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      // //console.log(error);
      setloading(false);
    }
  };

  const handleBuy = async (courseId, name, image, price) => {
    if (loginToken) {
      if (isCourseExists?.courseID === courseId) {
        navigate("/profile/cart");
      } else {
        const cartitem = {
          cartBody: {
            courseID: courseId,
            name: name,
            thumbnail: image,
            price: price,
            course_id: courseId,
          },
          userId: localStorage.getItem("userId"),
        };
        const res = await AddToCart(cartitem);
        dispatch(AddtoCart(res?.data?.result?.cart));
        setSpin(false);
        if (res?.data?.result?.cart) {
          localStorage.removeItem("course_id");
          localStorage.removeItem("courseName");
          localStorage.removeItem("courseImage");
          localStorage.removeItem("coursePrice");
          setLoading1(false);
          window.location.replace("/profile/cart");
          window.history.pushState({}, "", "/ai-tools/career-roadmap");
        }
      }
    } else {
      setRegister(true);
      setSpin(false);
      localStorage.setItem("courseId", courseId);
      localStorage.setItem("courseName", name);
      localStorage.setItem("courseImage", image);
      localStorage.setItem("coursePrice", price);
      localStorage.setItem("course_id", courseId);

      dispatch(
        userRoadMapCourse({
          courseId: courseId,
          navigateTo: `/ai-tools/career-roadmap?couselling=false`,
        })
      );
    }
  };

  useEffect(() => {
    if (isLogin === "true") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setLoading1(true);
      const name = localStorage.getItem("courseName");
      const image = localStorage.getItem("courseImage");
      const price = localStorage.getItem("coursePrice");
      const course_id = localStorage.getItem("course_id");
      if (loginToken) {
        if (name && image && price && course_id) {
          setTimeout(() => {
            handleBuy(course_id, name, image, price);
          }, 1500);
        }
      }
    }
  }, [isLogin, loginToken, singleCourse, isCourseExists]);
  return (
    <>
      {loading1 ? (
        <Spinner />
      ) : (
        <>
          {status && (
            <Cartmodal
              accessCode={accessCode}
              status={status}
              paymentsuccess={paymentsuccess}
              setStatus={setStatus}
            />
          )}
          {loading1 && <Spin />}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SignUp
              register1={register1}
              setRegister={setRegister}
              setLogin={setLogin}
              login={login}
              setBlur={setBlur}
            />
            <PureModal
              isOpen={call}
              closeButton="X"
              width={500}
              closeButtonPosition="header"
              onClose={() => {
                setCall(false);
              }}
            >
              <form onSubmit={(e) => submitcounselorform(e)}>
                <div className="flex justify-center">
                  <div>
                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6">
                        <img className="mx-4 for-opacity" src={people} alt="" />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="text"
                          name="name"
                          required
                          onChange={handleChangecounselor}
                          value={counselordata?.name}
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border  hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="email"
                          name="email"
                          required
                          onChange={handleChangecounselor}
                          value={counselordata?.email}
                          placeholder="Enter your email"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={phoneCall}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="tel"
                          required
                          placeholder="Enter your phone"
                          name="phone"
                          pattern="[6-9]{1}[0-9]{9}"
                          title="Please enter a valid phone number"
                          onChange={handleChangecounselor}
                          value={counselordata?.phone}
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 input-main-wraper">
                        <img className="mx-3 for-opacity" src={file} alt="" />

                        <select
                          required
                          style={{
                            background: "#FFF7E7",
                            opacity: "0.5",
                            margin: "0 !important",
                          }}
                          className="pl-2 input-field"
                          name="course"
                          onChange={handleChangecounselor}
                        >
                          <option disabled selected>
                            Currently doing?
                          </option>
                          <option value="school">School</option>
                          <option value="college">College</option>
                          <option value="Professionals">Professionals</option>
                        </select>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <input
                          required
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field text-center"
                          type="text"
                          placeholder="Your Message"
                          name="message"
                          onChange={handleChangecounselor}
                          value={counselordata?.message}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 d-flex align-center justify-content-center">
                  <button
                    type="submit"
                    for="my-modal-1"
                    disabled={loading}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
            </PureModal>
            <div className="container-fluid ox-hidden">
              <div className="bg-white container-fluid roadmap-main-container">
                <section>
                  <div className="row roadmap-heading-bg">
                    <div className="roadmap-professional-mainheader-bg ">
                      <div className="w-100 ">
                        <img className="" src={aiImage} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row size-30">
                    <h1
                      style={{
                        color: "#521986",
                        fontWeight: "700",
                        fontSize: "40px",
                        lineHeight: "50px",
                      }}
                      className="pt-1 text-center course-one-line-head roadmap-paragraph"
                    >
                      {courseData?.isCounselling
                        ? "Unlock Your Potential with AI-powered Career Guidance and Counselling"
                        : "Unlock Your Potential with AI-powered Career Guidance"}
                    </h1>
                  </div>
                  {/* <div className=" row  size-30" onClick={() => setCall(true)}>
                    <h1
                      style={{
                        color: "#521986",
                        fontWeight: "700",
                        fontSize: "40px",
                        lineHeight: "50px",
                        cursor: "pointer",
                      }}
                      className="py-2 text-center roadmap-paragraph roadmap-para-res"
                    >
                      Want more information?&nbsp;
                      <span style={{ cursor: "pointer", color: "#FFAE22" }}>
                        Speak to our Career advisors!
                      </span>
                    </h1>
                  </div> */}

                  {/* <div className="roadmap-professional-mainheader-bg"></div> */}
                  {/* <div className="p-0 d-flex-768 d-none-big col-12 d-flex justify-content-center ">
                    <img
                      className="roadmap-professional-img"
                      src={Roadmapheading1}
                      alt=""
                    />
                  </div> */}
                </section>

                <section
                  className="py-10 row roadmap-section-container p-set"
                  style={{ marginTop: "30px" }}
                >
                  <div className="col-7 w-100-768 roadmap-card-container">
                    {featurecarddata.map((carddata, idx) => {
                      return (
                        <Featurecard
                          key={idx}
                          heading={carddata.heading}
                          // subheading={carddata.subheading}
                          icon={carddata?.icon}
                        />
                      );
                    })}
                  </div>

                  <div className="col-5 w-100-768">
                    <div
                      className="roadmap-feature-containerss road-login-mid"
                      style={{ height: 300 }}
                    >
                      <video
                        className="roadmap-feature-video"
                        loop
                        controls
                        controlsList="nodownload"
                        poster={why}
                        src={
                          "https://viosa-storage-2.s3.amazonaws.com/School+Enrollment+Video_English_1.mp4"
                        }
                      ></video>
                    </div>
                    <div className="roadmap-feature-containerss road-login-mid">
                      <div className="mt-4 roadmap-contact">
                        <h1
                          style={{
                            color: "#521986",
                            fontWeight: "600",
                            fontSize: "24px",
                            lineHeight: "28px",
                            cursor: "pointer",
                          }}
                          className="py-2 text-center roadmap-paragraph roadmap-para-res"
                        >
                          Want more information?&nbsp;
                          <br />
                          <span
                            style={{
                              color: "#FFAE22",
                              fontSize: "24px",
                              fontSize: "16px",
                            }}
                          >
                            Speak to our Career advisors!
                          </span>
                        </h1>
                        <form onSubmit={(e) => submitcounselorform(e)}>
                          <div className="flex justify-center">
                            <div>
                              <div className="my-2 input-border hover:shadow-2xl">
                                <div className="flex items-center justify-center mb-6">
                                  <img
                                    className="mx-4 for-opacity"
                                    src={people}
                                    alt=""
                                  />
                                  <input
                                    style={{ background: "#FFF7E7" }}
                                    className="pl-2 input-field"
                                    type="text"
                                    name="name"
                                    required
                                    onChange={handleChangecounselor}
                                    value={counselordata?.name}
                                    placeholder="Enter your name"
                                  ></input>
                                </div>
                              </div>

                              <div className="my-2 input-border  hover:shadow-2xl">
                                <div className="flex items-center justify-center mb-6 ">
                                  <img
                                    className="mx-4 for-opacity"
                                    src={messageing}
                                    alt=""
                                  />
                                  <input
                                    style={{ background: "#FFF7E7" }}
                                    className="pl-2 input-field"
                                    type="email"
                                    name="email"
                                    required
                                    onChange={handleChangecounselor}
                                    value={counselordata?.email}
                                    placeholder="Enter your email"
                                  ></input>
                                </div>
                              </div>

                              <div className="my-2 input-border hover:shadow-2xl">
                                <div className="flex items-center justify-center mb-6 ">
                                  <img
                                    className="mx-4 for-opacity"
                                    src={phoneCall}
                                    alt=""
                                  />
                                  <input
                                    style={{ background: "#FFF7E7" }}
                                    className="pl-2 input-field"
                                    type="tel"
                                    required
                                    placeholder="Enter your phone"
                                    name="phone"
                                    pattern="[6-9]{1}[0-9]{9}"
                                    title="Please enter a valid phone number"
                                    onChange={handleChangecounselor}
                                    value={counselordata?.phone}
                                  ></input>
                                </div>
                              </div>

                              <div className="my-2 input-border hover:shadow-2xl">
                                <div className="flex items-center justify-center mb-6 input-main-wraper">
                                  <img
                                    className="mx-3 for-opacity"
                                    src={file}
                                    alt=""
                                  />

                                  <select
                                    required
                                    style={{
                                      background: "#FFF7E7",
                                      opacity: "0.5",
                                      margin: "0 !important",
                                    }}
                                    className="pl-2 input-field"
                                    name="course"
                                    onChange={handleChangecounselor}
                                  >
                                    <option disabled selected>
                                      Currently doing?
                                    </option>
                                    <option value="school">School</option>
                                    <option value="college">College</option>
                                    <option value="Professionals">
                                      Professionals
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="my-2 input-border hover:shadow-2xl">
                                <div className="flex items-center justify-center mb-6 ">
                                  <input
                                    required
                                    style={{ background: "#FFF7E7" }}
                                    className="pl-2 input-field text-center"
                                    type="text"
                                    placeholder="Your Message"
                                    name="message"
                                    onChange={handleChangecounselor}
                                    value={counselordata?.message}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="my-2 d-flex align-center justify-content-center">
                            <button
                              style={{ width: "350px" }}
                              type="submit"
                              width="350px"
                              for="my-modal-1"
                              disabled={loading}
                              className="forHoverContact-button hover:shadow-2xl"
                            >
                              {loading ? "Sending..." : "Send"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <div className="col-12 w-100-768">
                      <div className="d-flex justify-center price-box-res">
                        <div className="mt-3 box d-flex gap-y-4">
                          <div></div>
                          {isPurchased ? (
                            <>
                              <button
                                className="cursor-pointer btn-primary-1"
                                onClick={() => navigate("/profile/dashboard")}
                              >
                                Go to Dashboard
                              </button>
                              Already Purchased
                            </>
                          ) : (
                            <>
                              {revealPrice !== "Contact Us" ? (
                                loading1 ? (
                                  <p className="text-center">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </p>
                                ) : (
                                  <div className="d-flex align-items-end">
                                    <h1
                                      style={{
                                        display: "inline",
                                        fontSize: "34px",
                                        color: "#521986",
                                        fontWeight: "700",
                                        opacity: 1,
                                      }}
                                      className="one-time-pay"
                                    >
                                      {"₹" + Math.round(revealPrice)}
                                    </h1>
                                    <span
                                      style={{
                                        colour: "gray",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                        color: "#FF0000",
                                      }}
                                    >
                                      <i className="fa-solid fa-plus"></i> GST (18%)
                                    </span>
                                  </div>
                                )
                              ) : (
                                <h1
                                  style={{ display: "inline" }}
                                  className="one-time-pay"
                                >
                                  {revealPrice}
                                </h1>
                              )}
                              <button
                                className="cursor-pointer btn-primary-1"
                                // onClick={() => buyNow()}
                                // onClick={() => setCouponInput(true)}
                                // onClick={() => handleLogin()}
                                disabled={spin}
                                onClick={() => handleBuy()}
                              >
                                {spin ? (
                                  <p className="text-center fw-bold">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </p>
                                ) : (
                                  "Buy Now"
                                )}
                              </button>
                            </>
                          )}
                          {loadingcart ? (
                            <p className="text-center">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </p>
                          ) : isCourseExists?.name ? (
                            <h4 style={{ fontSize: "15px" }}>Added to Cart</h4>
                          ) : (
                            <h4
                              onClick={() => {
                                addToCart();
                              }}
                              style={{ fontSize: "15px" }}
                              className="cursor-pointer"
                            >
                              {isPurchased ? null : "Add to Cart"}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </section>

                <section className="py-10 row roadmap-section-container align-items-center">
                  <div className="col-7 p-0 w-100-768 d-flex flex-column ">
                    <p className="roadmap-paragraph">
                      Discover your ideal career path with VIOSA's AI-powered
                      career assessment and career counselling services
                    </p>
                  </div>
                </section>

                <section className="py-10 roadmap-section-container size-24 size-40">
                  <h1
                    style={{
                      textAlign: "left",
                      fontWeight: "700",
                      color: "rgb(82, 25, 134)",
                    }}
                    className="ml-15-600px"
                  >
                    Confused about what career path to follow?
                  </h1>
                  <p className="roadmap-paragraph mt-2">
                    Our AI-powered psychometric career assessment,
                    ‘CareerPathAI’ helps you understand your unique Career
                    Personality, Career Interests, Career Motivators, Learning
                    Styles and Skills & Abilities. Whether you're exploring
                    subject selection, stream selection, or mapping your student
                    career roadmap, our certified Career counsellors ensure that
                    you make informed decisions for a successful future.{" "}
                    <br></br>Trust VIOSA for expert career guidance and embark
                    on the path to fulfil your professional aspirations.
                  </p>
                </section>
                <section className="py-5 row roadmap-section-container">
                  <TimelineComp data={timelinedata} />
                </section>
                {/* <section className="py-5 row roadmap-section-container">
                  <div
                    className="col-12 roadmap-feature-containerss "
                    style={{ height: "450px" }}
                  >
                    <video
                      poster={largeVideo}
                      className="roadmap-feature-video"
                      loop
                      controls
                    >
                      <source src={courseData?.videoLink2} />
                    </video>
                  </div>
                </section> */}
                {/* <section className="cell-background">
                  <div className="flex items-center justify-between mx-16">
                    <div>
                      <p className="ai-heading">
                        Why is{' '}
                        <span style={{ color: 'rgba(255, 163, 3, 1)' }}> AI </span>{' '}
                        Important
                      </p>
                    </div>
                    <div>
                      <img className="ai-image" src={RoadmapWhyus} alt="" />
                    </div>
                  </div>
                  <p className="flex justify-center Frequently-heading">
                    Frequently Asked{' '}
                    <span style={{ color: 'rgba(255, 163, 3, 1)', marginLeft: '10px' }}>
                      {' '}
                      Questions{' '}
                    </span>{' '}
                  </p>
                </section> */}
                <section className="py-10 row roadmap-section-container">
                  <div className="row gx-1 fl-column">
                    {courseDataAll?.map((item, index) => (
                      <CareerRoadMapCard data={item} handleBuy={handleBuy} />
                    ))}
                  </div>
                </section>
                <section className="py-10 row roadmap-section-container">
                  <FrequentlyAsked faq={faq}></FrequentlyAsked>
                </section>
              </div>
            </div>
          </motion.div>
          {/* <FooterPurple /> */}
        </>
      )}
    </>
  );
};

export default CareerRoadMap;
