// import React from "react";
// import "./spinner.css";
import loader from "assets/loader1.png";
// const Spinner = () => {
//   return (
//     // <div className="spinner-area">
//     // <div className="lds-roller">
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     //   <div></div>
//     // </div>
//     // </div>
//     <div style={{ height: "100vh" }}>
//       <div className="rotate">
//         <img src={loader} />
//       </div>
//     </div>
//   );
// };

// export default Spinner;
import "./spinner.css";
const Spinner = () => {
  return (
    <div className="preloader">
      <p>V</p>
      <p style={{ color: "#FEA302" }}>I</p>
      <p>
        <img height={50} width={50} src={loader} />
      </p>
      <p>S</p>
      <p>A</p>
    </div>
  );
};
export default Spinner;
