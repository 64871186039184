import React, { useCallback, useEffect, useRef, useState } from "react";
import google from "./../../assets/icon/google-login.svg";
import homeLogo from "./../../assets/icon/imageHome.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignInByGoogle, SignUpApi } from "../../../Axios/apis";
import { userDashBoard, UserLogin } from "../../../redux/user/userAction";
import OTPInput from "otp-input-react";
import { auth, googleAuthProvider } from "../../../firebase";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Show } from "components/Show";
// import { getAuth } from "../../../firebase";
import "./signup.css";
import Modal from "components/Modal/Modal";
import Login from "../Login/Login";

const SignUp = ({
  setLogin,
  login,
  setRegister,
  setBlur,
  className,
  register1,
}) => {
  let isDev =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000||http://localhost:3001"
      : "https://viosa.vercel.app";

  const closeRef = useRef(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const [confirmPassword, setComfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpError, setOtpError] = useState("");
  const [viewNumber, setViewNumber] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [enterPassword, setEnterPassword] = useState(false);
  const [surePassword, setSurePassword] = useState(false);
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [mobileNoError, setMobileNoError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [mobileNoDisabled, setMobileNoDisabled] = useState(false);
  const [mobileNoVerifed, setMobileNoVerified] = useState(false);
  const [mobileNo, setMobileNo] = useState(null);
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const dispatch = useDispatch();
  const { userRequest, userRoadMapCourse } = useSelector((state) => state.user);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState(false);

  // //console.log(userRequest);

  const formHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsActive(true);
  }, [className]);

  const onBlurValidation = (e) => {
    switch (e.target.name) {
      case "name":
        if (form.name.length) {
          var regName = /^[a-zA-Z ]*$/;
          if (!form.name.match(regName)) {
            setNameError("Please enter valid name");
            return;
          } else {
            setNameError("");
          }
        } else {
          setNameError("Please enter valid name");
        }
        break;
      case "email":
        if (!/\S+@\S+\.\S+/.test(form.email)) {
          setShowMessage(true);
        } else {
          setShowMessage(false);
        }
        break;
      case "password":
        if (form.password.length < 5) {
          setPasswordError(true);
          return;
        } else {
          setPasswordError(false);
        }
        break;
      case "confirmPassword":
        if (form.password !== form.confirmPassword) {
          setComfirmPassword(true);
          setPasswordError(false);
          return;
        } else {
          setComfirmPassword(false);
        }
        break;
      default:
        return null;
    }
  };

  const signUpHandler = async () => {
    setIsLoading(true);
    setLoading(true);
    setShowMessage(false);
    let formData = {
      email: form.email,
      password: form.password,
      name: form.name,
      phoneNumber: "+" + phoneNumber,
      confirm_password: form.confirmPassword,
    };
    try {
      const response = await SignUpApi(formData);
      setIsLoading(false);
      isDev = "";
      setLoading(false);
      dispatch(
        UserLogin({
          name: response?.data?.result?.name,
          email: response?.data?.result?.email,
          date: response?.data?.result?.createdAt,
          courseList: response?.data?.result?.course,
          loggedIn: true,
        })
      );
      setRegister(false);
      localStorage.setItem("email", response?.data?.data.result?.email);
      localStorage.setItem("phone", response?.data?.data.result?.phone);
      localStorage.setItem("userName", response?.data?.data.result?.name);
      localStorage.setItem("role", response?.data?.data.result?.role);
      localStorage.setItem("token", response?.data?.data.token);
      localStorage.setItem(
        "InterviewProRole",
        response?.data?.result?.hireProRole
      );
      localStorage.setItem("userId", response?.data?.data.result?._id);
      dispatch(userDashBoard("profile"));
      if (userRoadMapCourse?.courseId) {
        window.location.replace(
          userRoadMapCourse?.navigateTo + "&" + "loggedIn=true"
        );
      } else if (userRequest) {
        navigate(`/profile/catalogue/${userRequest}`);
      } else {
        navigate(`/profile/catalogue`);
      }
    } catch (error) {
      setInterval(() => {}, 3000);
      setIsLoading(false);
      setLoading(false);
      setErrorMessage("Email/Phone Already Registered");
    }
  };

  const onSubmit = () => {
    nameError.length === 0 &&
      !confirmPassword &&
      !passwordError &&
      // requestOTP();
      signUpHandler();
  };

  const modalHide = () => {
    setLogin(false);
    setRegister(false);
    setViewOtpForm(false);
    closeRef.current.click();
    setIsActive(false);
    isDev = "";
    setBlur(false);
  };

  const handleGetBackToLogin = () => {
    setLogin(true);
    setBlur(true);
    closeRef.current.click();
  };

  const googleLoginApiHandler = async (firebaseID, username) => {
    const firebaseId = {
      firebaseId: firebaseID,
    };
    const response = await SignInByGoogle(firebaseId);
    localStorage.setItem("token", response?.data?.token);
    if (response?.data?.token) {
      if (userRoadMapCourse?.courseId) {
        window.location.replace(
          userRoadMapCourse?.navigateTo + "&" + "loggedIn=true"
        );
      } else if (userRequest) {
        navigate(`/profile/catalogue/${userRequest}`);
      } else {
        navigate(`/profile/catalogue`);
      }
      localStorage.setItem("userName", username);
      localStorage.setItem("email", response?.data?.result?.email);
      localStorage.setItem("role", response?.data?.user?.role);
      localStorage.setItem(
        "InterviewProRole",
        response?.data?.result?.hireProRole
      );
      localStorage.setItem("userId", response?.data?.result?._id);
      setLogin(false);
      setRegister(false);
      dispatch(
        UserLogin({
          name: username,
          loggedIn: true,
          courseList: response?.data?.course || [],
        })
      );
      dispatch(userDashBoard("profile"));
    }
  };

  const googleLogin = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // //console.log(credential);
        const user = result.user;
        // //console.log(user);
        const token = user.stsTokenManager.accessToken;

        googleLoginApiHandler(token, user.displayName);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handlePasswordShowHide = () => {
    if (!enterPassword) {
      setEnterPassword(true);
    } else {
      setEnterPassword(false);
    }
  };

  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (viewOtpForm) {
      timer > 0 && setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback, viewOtpForm]);

  const resetTimer = function () {
    // //console.log("hello");
    if (timer === 0 || timer === -1) {
      setTimer(60);
    }
  };

  const requestOTP = (e) => {
    // //console.log("resend otp");
    if (phoneNumber.length < 11)
      setMobileNoError("Please enter a valid mobile number.");
    if (phoneNumber.length > 11) {
      setIsLoading(true);
      // setMobileNoDisabled(true);
      setMobileNoError("");
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, "+" + phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // //console.log("resend otp");
          // //console.log(confirmationResult);
          window.confirmationResult = confirmationResult;
          setViewOtpForm(true);
          setIsLoading(false);
        })
        .catch((error) => {
          // resetTimer();
          setError(error.message);
          setErrorMessage(
            error.message == "Firebase: Error (auth/too-many-requests)." &&
              "Too many requests. Try again later. or Change your phone number"
          );
          setViewOtpForm(true);
          setIsLoading(false);
        });
    }
  };
  const handleChange = (code) => {
    setCode(code);
    // //console.log(code);
    if (code.length === 6) {
      setLoading(true);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(code)
        .then((result) => {
          setIsDisabled(false);
          setViewOtpForm(false);
          signUpHandler();
          setCode("");
          setLoading(false);
          setErrorMessage("");
          setMobileNoVerified(true);
          setMobileNoError("");
        })
        .catch((error) => {
          setIsDisabled(false);
          // //console.log(error);
          setOtpError(
            (error.message === "Firebase: Error (auth/code-expired)." &&
              "Code Expired Please Resend OTP") ||
              (error.message ===
                "Firebase: Error (auth/invalid-verification-code)." &&
                "Invalid OTP") ||
              (error.message === "Firebase: Error (auth/too-many-requests)." &&
                "Too many requests. Try again later. or Change your phone number") ||
              (error.message ===
                "Firebase: Error (auth/missing-verification-code)." &&
                "Please Enter OTP")
          );
          setLoading(false);
          setMobileNoDisabled(false);
        });
    }
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: requestOTP,
      },
      auth
    );
  }, []);
  const handleConfirmPasswordShowHide = () => {
    if (!surePassword) {
      setSurePassword(true);
    } else {
      setSurePassword(false);
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <div className="signup-modal">
        <Modal
          onClose={() => {
            setRegister(false);
          }}
          show={register1}
        >
          <div>
            <Show condition={!viewOtpForm}>
              <div className="relative mb-2 pt-3">
                <label
                  onClick={() => {
                    setViewOtpForm(false);
                    setRegister(false);
                  }}
                  setBlur={setBlur}
                  for="my-modal-4"
                  className="absolute  btn btn-sm btn-circle right-2 top-2 hover:text-white "
                  ref={closeRef}
                >
                  ✕
                </label>
                <div>
                  <div>
                    <img
                      className="block mx-auto mb-1 w-28"
                      src={homeLogo}
                      alt=""
                    />
                    <p className="flex justify-center mb-2 login-heading-sign-up">
                      Create your account
                    </p>
                  </div>
                  <div>
                    <div className="">
                      <div className="input-border hover:shadow-2xl">
                        <div className="flex item-center">
                          <i className="fa-solid fa-user mx-4 for-opacity text-2xl mb-2"></i>
                          {/* <img className="mx-4 for-opacity" src={messageing} alt="" /> */}
                          <input
                            style={{ background: "#FFF7E7" }}
                            className="pl-2 input-field"
                            onChange={(e) => formHandler(e)}
                            onBlur={(e) => onBlurValidation(e)}
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                          ></input>
                        </div>
                      </div>
                      <div className="px-3 mx-5">
                        <span className="error-message w-100 error-message-name">
                          {nameError && nameError}
                        </span>
                      </div>

                      <div className="mt-2 input-border-phone  input-phone hover:shadow-2xl ">
                        <div className="flex  justify-center mb-2">
                          <ReactPhoneInput
                            country="in"
                            style={{
                              background: "#FFF7E7",
                              borderRadius: "12px",
                            }}
                            enableSearch
                            name="phoneNumber"
                            value={phoneNumber || ""}
                            disabled={mobileNoDisabled}
                            onChange={(e) => setPhoneNumber(e)}
                            className={` phone-input ${
                              mobileNoError.length !== 0
                                ? "error-outline-1"
                                : "border-white-2"
                            }`}
                          />
                        </div>
                      </div>

                      <div className="input-border hover:shadow-2xl mt-2">
                        <div className="flex ">
                          <i className="fa-solid fa-envelope mx-4 for-opacity text-2xl mb-2"></i>
                          <input
                            style={{ background: "#FFF7E7" }}
                            className="pl-2 input-field"
                            onChange={(e) => formHandler(e)}
                            onBlur={(e) => onBlurValidation(e)}
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                          ></input>
                        </div>
                      </div>
                      <div className="px-3 mx-5">
                        <span className="error-message w-100 error-message-email1 ">
                          {showMessage && "Please enter valid email address"}
                        </span>
                      </div>
                      <div className="mt-2 input-border hover:shadow-2xl">
                        <div className="flex">
                          <svg
                            style={{ marginTop: 7 }}
                            className="fa-regular fa-lock mx-3 for-opacity text-2xl mb-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                          </svg>
                          {/* <img className="mx-4 for-opacity" src={password1} alt="" /> */}
                          <input
                            style={{ background: "#FFF7E7", paddingLeft: 24 }}
                            onChange={(e) => formHandler(e)}
                            className="pl-2 input-field"
                            onBlur={(e) => onBlurValidation(e)}
                            autoComplete="off"
                            type={!enterPassword ? "password" : "text"}
                            name="password"
                            placeholder="Enter the Password"
                          ></input>
                          {!enterPassword ? (
                            <i
                              style={{ marginTop: 5 }}
                              onClick={handlePasswordShowHide}
                              className="fa-solid fa-eye text-xl ml-12"
                            ></i>
                          ) : (
                            <i
                              style={{ marginTop: 5 }}
                              onClick={handlePasswordShowHide}
                              className="fa-solid text-xl ml-12 fa-eye-slash"
                            ></i>
                          )}
                        </div>
                      </div>
                      <div className="px-3 mx-5">
                        <span className="flex  error-message w-100 error-message-password">
                          {passwordError &&
                            "Password must contain at least 6 characters"}
                        </span>
                      </div>
                      <div className={`mt-2 input-border hover:shadow-2xl`}>
                        <div className="flex  mb-2">
                          {/* <img className="mx-4 for-opacity" src={password1} alt="" /> */}
                          <svg
                            style={{ marginTop: 7 }}
                            className="fa-regular fa-lock mx-3 for-opacity text-2xl mb-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                          </svg>
                          <input
                            style={{ background: "#FFF7E7", paddingLeft: 24 }}
                            onChange={(e) => formHandler(e)}
                            className="pl-2 input-field"
                            autoComplete="off"
                            type={!surePassword ? "password" : "text"}
                            onBlur={(e) => onBlurValidation(e)}
                            name="confirmPassword"
                            placeholder="Confirm your password"
                          ></input>
                          {!surePassword ? (
                            <i
                              style={{ marginTop: 5 }}
                              onClick={handleConfirmPasswordShowHide}
                              className="fa-solid fa-eye text-xl ml-12"
                            ></i>
                          ) : (
                            <i
                              style={{ marginTop: 5 }}
                              onClick={handleConfirmPasswordShowHide}
                              className="fa-solid text-xl ml-12 fa-eye-slash"
                            ></i>
                          )}
                        </div>
                      </div>
                      <div>
                        <span className="error-message w-100 error-message-password1 px-3 mx-5">
                          {confirmPassword && "Password does not match"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <div className="mt-1">
                    <button
                      for="my-modal-1"
                      onClick={onSubmit}
                      className="forHoverContact-button hover:shadow-2xl"
                    >
                      {!isLoading ? (
                        <div className="flex items-center justify-center">
                          <p className="mt-[2px] text-xl">Signup</p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <p className="text-xl">Signing up...</p>
                          <div
                            className="spinner-border text-light ml-4"
                            role="status"
                          ></div>
                        </div>
                      )}
                      {isLoading ? "Sign up ..." : ""}
                    </button>
                  </div>
                </div>

                <div>
                  <span
                    className="error-message w-100 error-message-cred fw-bolder"
                    style={{ fontSize: "14px" }}
                  >
                    {errorMessage && errorMessage}
                  </span>
                </div>
                <div className="divider">OR</div>

                <div className="flex items-center justify-center">
                  <div
                    className="flex items-center alternative-login"
                    onClick={googleLogin}
                  >
                    <img className="w-6 mx-2" src={google} alt="" />
                  </div>
                </div>

                <p className="flex justify-center mt-2 text-xl already-margin">
                  Already have an account{" "}
                  <label
                    onClick={handleGetBackToLogin}
                    for="my-modal-4"
                    className="modal-button ml-2"
                    style={{ color: "#fea302", cursor: "pointer" }}
                  >
                    {" "}
                    Log in{" "}
                  </label>{" "}
                </p>

                {/* <p style={{ fontSize: "12px" }} className="flex justify-center">
                        *By joining and using VIOSA Learning platform, you agree to our{" "}
                        <span
                            className="ml-2"
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                        >
                            Terms and Policies
                        </span>
                    </p> */}
              </div>
            </Show>
          </div>
        </Modal>
        <Modal
          onClose={() => {
            setViewOtpForm(false);
          }}
          show={viewOtpForm}
        >
          <Show condition={viewOtpForm}>
            {loading && <div id="cover-spin"></div>}
            <div id="recaptcha-container"></div>
            <div className="relative pt-2">
              <label
                onClick={modalHide}
                for="my-modal-6"
                className="absolute btn-clode-modal  right-2 top-2 "
                ref={closeRef}
              >
                ✕
              </label>
              <div>
                <div>
                  <img className="block mx-auto mb-2" src={homeLogo} alt="" />
                  <p className="flex justify-center mb-2 login-heading">
                    Verify Your Account
                  </p>
                </div>
                <Show condition={viewNumber}>
                  <ReactPhoneInput
                    country="in"
                    style={{ background: "#FFF7E7" }}
                    enableSearch
                    value={phoneNumber || ""}
                    name="phoneNumber"
                    onChange={(e) => setPhoneNumber(e)}
                    className={`phone-input  ${
                      mobileNoError.length !== 0
                        ? "error-outline-1"
                        : "border-white-2"
                    }`}
                  />
                  <div className="my-3 lg:ml-10 md:ml-10">
                    <button
                      for="my-modal-1"
                      onClick={() => {
                        requestOTP();
                        setViewNumber(false);
                      }}
                      className="forHoverContact-button hover:shadow-2xl"
                    >
                      {isLoading ? "Sending ... " : "Send OTP"}
                    </button>
                  </div>
                </Show>
                <Show condition={!viewNumber}>
                  <p className="text-center">
                    We have sent to <strong>+{phoneNumber}</strong> an OTP to
                    verify your account.
                    <a
                      onClick={() => setViewNumber(true)}
                      className="primary-color primary-color-hover"
                    >
                      Change Number ?
                    </a>
                  </p>
                  <div className="ml-10">
                    <div className="">
                      <div className="input-margin">
                        <div className="flex items-center justify-center mb-2 flex-column">
                          {/* <img className="mx-4 for-opacity" src={messageing} alt="" /> */}

                          <OTPInput
                            className="otp-form"
                            value={code}
                            onChange={handleChange}
                            autoComplete={false}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                          />
                          <div>
                            <div className="d-flex my-2">
                              <p>
                                Resend OTP{" "}
                                {timer === 0 || timer === -1
                                  ? ""
                                  : `(${timer})`}
                              </p>
                              <a
                                className="text-warning link mx-2"
                                onClick={() => {
                                  resetTimer();
                                  requestOTP();
                                }}
                              >
                                {(timer === 0 || timer === -1) && "Resend"}
                              </a>
                            </div>
                          </div>
                        </div>
                        {<small className="text-danger">{otpError}</small>}
                      </div>
                      <div>
                        <span className="error-message w-100 error-message-name">
                          {nameError && nameError}
                        </span>
                      </div>
                    </div>
                  </div>
                </Show>
              </div>
            </div>
          </Show>
        </Modal>
      </div>
      <Login setLogin={setLogin} setBlur={setBlur} />
    </>
  );
};

export default SignUp;
