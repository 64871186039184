import React, { useEffect, useState } from "react";
import "./Footer.css";
import linkedin from "./../assets/icon/purple-footer-icon (1).png";
import facebook from "./../assets/icon/purple-footer-icon (2).png";
import twiter from "./../assets/icon/purple-footer-icon (3).png";
// import instagram from "./../assets/icon/instagram-fill.svg";
import telegram from "./../assets/icon/purple-footer-icon (4).png";
import GooglePlay from "./../assets/icon/purple-footer-icon-5.png";
import IOSAPP from "./../assets/icon/purple-footer-icon-6.png";
import call from "./../assets/icon/choose-svg-icon (1).svg";
import location1 from "./../assets/icon/choose-svg-icon (2).svg";
import text from "./../assets/icon/choose-svg-icon (3).svg";
import whatsapp from "./../assets/icon/choose-svg-icon (4).svg";
import { motion } from "framer-motion";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { getAllCourse } from "Axios/apis";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import instagram from "./../assets/icon/Instagram.png";
import youtube from "./../assets/icon/Youtube.png";

const FooterPurple = ({ course, setCourse }) => {
  const navigate = useNavigate();
  const [coursedata, setcoursedata] = useState([]);
  const [activeLink, setActiveLink] = useState({});
  const [activefooter, setactivefooter] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveLink({});
    }
    if (
      location.pathname ===
      "/course/roadmap/career-guidance-for-8th-9th-10th-without-counselling"
    ) {
      setActiveLink({ a: "active" });
    }
    if (
      location.pathname ===
      "/course/roadmap/career-guidance-for-8th-9th-10th-without-counselling"
    ) {
      setActiveLink({ a: "active" });
      return;
    }
    if (
      location.pathname ===
      "/course/roadmap/career-guidance-and-career-counselling-for-11th-12th-without-counselling"
    ) {
      setActiveLink({ b: "active" });
      return;
    }
    if (location.pathname === "/privacy-policy") {
      setActiveLink({});
    }
    if (location.pathname === "/colleges") {
      setActiveLink({});
    }
    if (location.pathname === "/course") {
      setActiveLink({});
    }
    if (location.pathname.includes("/course/roadmap/")) {
      setActiveLink({});
    }
    if (location.pathname === "/school") {
      setActiveLink({});
    }
    if (location.pathname === "/blogs") {
      setActiveLink({});
    }
    // if (location.pathname === "/course/6343c15fe33839d145810f31") {
    if (location.pathname === "/course/interview-pro") {
      setActiveLink({ g: "active" });
    }
    if (location.pathname === "/course/elevator-pitch-group-discussions") {
      setActiveLink({ f: "active" });
    }
    if (
      location.pathname === "/course/digital-profiling-professional-networking"
    ) {
      setActiveLink({ h: "active" });
    }
    if (location.pathname === "/course/job-identification-application") {
      setActiveLink({ i: "active" });
    }
    if (location.pathname === "/course/functional-skill-competency") {
      setActiveLink({ d: "active" });
    }
    if (location.pathname === "/course/corporate-sector-learning") {
      setActiveLink({ c: "active" });
    }
    if (location.pathname === "/aboutus") {
      setActiveLink({});
    }
    if (location.pathname === "/contact") {
      setActiveLink({});
    }
  }, [location.pathname]);
  useEffect(() => {
    const getalldata = async () => {
      try {
        const data = await getAllCourse();
        setcoursedata(data?.data?.courses);
      } catch (error) {
        console.error(error);
      }
    };
    getalldata();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div id="footer-1">
        <div className="footer-container">
          <Row className="footer-con-1">
            <Col lg={3}>
              <h3 className="footer-heading-purple">Career Road Map</h3>
              <h4 className="footer-subheading">AI-Powered</h4>
              <ul>
                <li>
                  <a className={activeLink?.a}>
                    <span
                      className={`footer-link`}
                      onClick={() => {
                        // let coursenew = coursedata.filter(
                        //   (data) =>
                        //     data.name.toLowerCase().includes("career map for 8th, 9th & 10th with counseling")
                        // );
                        // navigate(`/roadmap/${coursenew[0].course_id}`);
                        navigate(
                          `/course/roadmap/career-map-for-8th-9th-10th-with-counselling`
                        );
                        setActiveLink({ a: "active" });
                      }}
                    >
                      For 8-10th Std Students
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.b}>
                    <span
                      className={`footer-link`}
                      onClick={() => {
                        // let coursenew = coursedata.filter(
                        //   (data) => data.name.toLowerCase().includes("career map for 11th & 12th with counseling")
                        // );
                        navigate(
                          `/course/roadmap/career-map-for-11th-12th-with-counselling`
                        );
                        setCourse("8-12 Student");
                        // navigate("/course");
                        setActiveLink({ b: "active" });
                      }}
                    >
                      For 11-12th Std Students
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4}>
              <h3 className="footer-heading-purple">Collaboration</h3>
              <ul>
                <li>
                  <NavLink to="/school">
                    <span className="footer-link">Schools</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/colleges">
                    <span className="footer-link">Colleges</span>
                  </NavLink>
                </li>

                {/* <li >
                  <span className="footer-link">
                    Institutions
                  </span>
                </li> */}
                {/* <li>
                  <NavLink to="/ComingSoon/corporates">
                    <span className="footer-link">Corporates</span>
                  </NavLink>
                </li> */}
              </ul>
            </Col>
            <Col lg={3}>
              <h3 className="footer-heading-purple">Company</h3>
              <ul>
                <li>
                  <NavLink to="/aboutus">
                    <span className="footer-link">About Us</span>
                  </NavLink>
                </li>
                {/* <li>
                  <span className="footer-link">Our Team</span>
                </li> */}
                <li>
                  <NavLink to="/contact">
                    <span className="footer-link">Contact Us</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">
                    <span className="footer-link">Privacy Policy</span>
                  </NavLink>
                </li>
              </ul>
            </Col>
            {/* <Col lg={2}>
              <h3 className="footer-heading-purple">Services</h3>
              <ul>
                <li>
                  <NavLink to="/ComingSoon/Counseling">
                    <span className="footer-link">Counseling</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ComingSoon/CorporateHiring">
                    <span className="footer-link">Corporate Hiring</span>
                  </NavLink>
                </li>
              </ul>
            </Col> */}
            <Col lg={2}>
              <ul>
                <li>
                  <div className="footer-social-icon">
                    <a
                      className="link-hover icons"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/viosa.learning"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon facebook"
                        src={facebook}
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="link-hover icons"
                      href="https://www.linkedin.com/company/viosa-learning/"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon linkedin"
                        src={linkedin}
                        alt=""
                      />
                    </a>
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      className="link-hover icons"
                      href="#"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon linkedin"
                        src={telegram}
                        alt=""
                      />
                    </a> */}
                    <a
                      className="link-hover icons"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/viosa_learning"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon twitter"
                        src={twiter}
                        alt=""
                      />
                    </a>
                    {/* <a
                      target="_blank"
                      href="https://www.instagram.com/viosa_learning/"
                    >
                      <img
                        style={{ width: "30px", height: "30px" }}
                        className="mr-3 social-icon"
                        src={instagram}
                        alt=""
                      />
                    </a> */}
                  </div>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.viosa.app&pcampaignid=web_share">
                    <div className="google-play-img">
                      <img
                        src={GooglePlay}
                        style={{ borderRadius: "5px" }}
                        alt=""
                      />
                    </div>
                  </a>
                  <a href="https://apps.apple.com/in/app/viosa/id6451482835">
                    <div className="ios-app-img">
                      <img
                        src={IOSAPP}
                        style={{ borderRadius: "5px" }}
                        alt=""
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="footer-con-2">
            <Col lg={3}>
              <h3 className="footer-heading-purple">Courses</h3>
              <ul>
                <li>
                  <a className={activeLink?.c}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        navigate(`/course/corporate-sector-learning`);
                        setActiveLink({ c: "active" });
                      }}
                    >
                      Corporate Sector Learning
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.d}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        navigate(`/course/functional-skill-competency`);
                        setActiveLink({ d: "active" });
                      }}
                    >
                      Softskill Competency Coaching
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.e}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        navigate(`/course/resumatic-ai`);
                        setActiveLink({ e: "active" });
                      }}
                    >
                      Automated Resume Builder
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.f}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        setActiveLink({ f: "active" });
                        navigate(`/course/elevator-pitch-group-discussions`);
                      }}
                    >
                      Elevator Pitch & Group Discussions
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.g}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        setActiveLink({ g: "active" });
                        // navigate(`/course/6343c15fe33839d145810f31`);
                        navigate(`/course/interview-pro`);
                      }}
                    >
                      Interview Skills Training
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.h}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        setActiveLink({ h: "active" });
                        navigate(
                          `/course/digital-profiling-professional-networking`
                        );
                      }}
                    >
                      Digital Profiling & Professional Networking
                    </span>
                  </a>
                </li>
                {/* <li>
                  <a className={activeLink?.i}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        setActiveLink({ i: "active" });
                        navigate(`/course/job-identification-application`);
                      }}
                    >
                      Job Identification & Application
                    </span>
                  </a>
                </li> */}
                {/* <li>
                  <span className="footer-link">
                    Job Specififc Courses
                  </span>
                </li> */}
              </ul>
            </Col>
            <Col lg={4}>
              <h3 className="footer-heading-purple">Professional Roadmap</h3>
              <h4 className="footer-subheading">AI-Powered</h4>
              <ul>
                <li>
                  <a className={activeLink?.j}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        let coursenew = coursedata.filter((data) =>
                          data.name
                            .toLowerCase()
                            .includes(
                              "professional roadmap for graduates and under-graduates with counselling"
                            )
                        );
                        // navigate(`/course/6343c02de33839d145810eeb`);
                        navigate(
                          `/course/professional-roadmap-for-graduates-under-graduates-with-counselling`
                        );
                        setActiveLink({ j: "active" });
                        setCourse("Undergraduate/Graduate");
                      }}
                    >
                      For Undergrads/Graduates
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.k}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        navigate(
                          `/course/professional-roadmap-for-post-graduates-with-counselling`
                        );
                        setCourse("Post Graduate");
                        setActiveLink({ k: "active" });
                      }}
                    >
                      For Post Graduates
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.l}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        navigate(
                          `/course/professional-roadmap-for-engineers-with-counselling`
                        );
                        setCourse("Undergraduate/Graduate");
                        setActiveLink({ l: "active" });
                      }}
                    >
                      For Engineers
                    </span>
                  </a>
                </li>
                <li>
                  <a className={activeLink?.m}>
                    <span
                      className="footer-link"
                      onClick={() => {
                        // let coursenew = coursedata.filter(
                        //   (data) => data.name.toLowerCase().includes("professional roadmap for working professionals with counselling")
                        // );
                        navigate(
                          `/course/professional-roadmap-for-working-professionals-with-counselling`
                        );
                        setCourse("Working Professional");
                        setActiveLink({ m: "active" });
                      }}
                    >
                      For Professionals
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3}>
              <h3 className="footer-heading-purple">Reach Us At</h3>
              <ul>
                <li>
                  <a
                    href="tel:022-4190-2766"
                    className="flex items-center footer-link-reach mb-3"
                  >
                    <div>
                      <img
                        className="footer-reach-icon"
                        src={location1}
                        alt=""
                      />
                    </div>
                    <span className="ml-4 footer-link-text">022-4190-2766</span>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/918655803488"
                    className="flex items-center footer-link-reach mb-3"
                  >
                    <div className="footer-img">
                      <img className="footer-reach-icon" src={text} alt="" />
                    </div>
                    <span className="ml-4 footer-link-text">
                      +91-8655803488
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:mail@viosa.in"
                    className="flex items-center footer-link-reach mb-3"
                  >
                    <img className="footer-reach-icon" src={whatsapp} alt="" />
                    <span className="ml-4 footer-link-text">mail@viosa.in</span>
                  </a>
                </li>
                <li>
                  <a
                    className="flex items-center footer-link-reach"
                    href="https://goo.gl/maps/2YoHrrX9Nv4qy9ak8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img classname="footer-reach-icon" src={call} alt="" />
                    <span className="ml-4 address footer-link-text">
                      Corporate Office : 104, Abhishek Premises, Off New Link
                      Rd, Veera Desai Industrial Estate, Andheri West, Mumbai,
                      Maharashtra 400053
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={2}></Col>
            {/* <Col lg={2}>
              <ul>
                <li>
                  <div className="footer-social-icon">
                    <a
                      className="link-hover icons"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/viosa.learning"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon facebook"
                        src={facebook}
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="link-hover icons"
                      href="https://www.linkedin.com/company/viosa-learning/"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon linkedin"
                        src={linkedin}
                        alt=""
                      />
                    </a>
                    <a
                      className="link-hover icons"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/viosa_learning"
                    >
                      <img
                        style={{ width: "35px", height: "35px" }}
                        className="mr-3 social-icon twitter"
                        src={twiter}
                        alt=""
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <Link to="/comingSoon/mobileApp">
                    <div className="google-play-img">
                      <img
                        src={GooglePlay}
                        style={{ borderRadius: "5px" }}
                        alt=""
                      />
                    </div>
                  </Link>
                  <Link to="/comingSoon/mobileApp">
                    <div className="ios-app-img">
                      <img
                        src={IOSAPP}
                        style={{ borderRadius: "5px" }}
                        alt=""
                      />
                    </div>
                  </Link>
                </li>
              </ul>
            </Col> */}
          </Row>
        </div>

        <footer
          className="footer-class-purple p-10  d-none d-flex-1024"
          style={{ paddingLeft: "50px" }}
        >
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">
                Professional Roadmap
              </span>
              <h4 className="footer-subheading white-text">AI-Powered</h4>
              <div>
                <a className={activeLink?.j}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      let coursenew = coursedata.filter((data) =>
                        data.name
                          .toLowerCase()
                          .includes(
                            "professional roadmap for graduates and under-graduates with counselling"
                          )
                      );
                      navigate(
                        `/course/professional-roadmap-for-graduates-under-graduates-with-counselling`
                      );
                      // navigate(`/course/6343c02de33839d145810eeb`);
                      setActiveLink({ j: "active" });
                      setCourse("Undergraduate/Graduate");
                    }}
                  >
                    For Undergraduates/Graduates
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.k}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      // navigate(`/course/6343c079e33839d145810efb`);
                      navigate(
                        `/course/professional-roadmap-for-post-graduates-with-counselling`
                      );
                      setCourse("Post Graduate");
                      setActiveLink({ k: "active" });
                    }}
                  >
                    For Post Graduates
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.l}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      navigate(
                        `/course/professional-roadmap-for-engineers-with-counselling`
                      );
                      setCourse("Undergraduate/Graduate");
                      setActiveLink({ l: "active" });
                    }}
                  >
                    For Engineers
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.m}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      // let coursenew = coursedata.filter(
                      //   (data) => data.name.toLowerCase().includes("professional roadmap for working professionals with counselling")
                      // );
                      navigate(
                        `/course/professional-roadmap-for-working-professionals-with-counselling`
                      );
                      setCourse("Working Professional");
                      setActiveLink({ m: "active" });
                    }}
                  >
                    For Professionals
                  </span>
                </a>
              </div>

              {/* <a className="link link-hover white-text">Jobs</a>
                    <a className="link link-hover white-text">Press kit</a> */}
            </div>
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">Courses</span>
              <div>
                <a className={activeLink?.c}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      navigate(`/course/corporate-sector-learning`);
                      setActiveLink({ c: "active" });
                    }}
                  >
                    Corporate Sector Learning
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.d}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      navigate(`/course/functional-skill-competency`);
                      setActiveLink({ d: "active" });
                    }}
                  >
                    Softskill Competency Coaching
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.e}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      navigate(`/course/resumatic-ai`);
                      setActiveLink({ e: "active" });
                    }}
                  >
                    Automated Resume Builder
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.f}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      setActiveLink({ f: "active" });
                      navigate(`/course/elevator-pitch-group-discussions`);
                    }}
                  >
                    Elevator Pitch & Group Discussions
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.g}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      setActiveLink({ g: "active" });
                      // navigate(`/course/6343c15fe33839d145810f31`);
                      navigate(`/course/interview-pro`);
                    }}
                  >
                    Interview Skills Training
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.h}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      setActiveLink({ h: "active" });
                      navigate(
                        `/course/digital-profiling-professional-networking`
                      );
                    }}
                  >
                    Digital Profiling & Professional Networking
                  </span>
                </a>
              </div>
              {/* <div>
                <a className={activeLink?.i}>
                  <span
                    className="footer-link"
                    onClick={() => {
                      setActiveLink({ i: "active" });
                      navigate(`/course/job-identification-application`);
                    }}
                  >
                    Job Identification & Application
                  </span>
                </a>
              </div> */}
              {/* <a className="link link-hover white-text">Privacy policy</a>
                    <a className="link link-hover white-text">Cookie policy</a> */}
            </div>
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">
                Career Road Map
              </span>
              <h4 className="footer-subheading white-text">AI-Powered</h4>
              {/* <NavLink style={{color:"white"}} to="/roadmap" className="link link-hover white-text">AI-Powered</NavLink> */}
              <div>
                <a className={activeLink?.a}>
                  <span
                    className={`footer-link`}
                    onClick={() => {
                      // let coursenew = coursedata.filter(
                      //   (data) =>
                      //     data.name ==
                      //     "Career Map for 8th, 9th & 10th with Counseling"
                      // );
                      navigate(
                        `/course/roadmap/career-guidance-for-8th-9th-10th-without-counselling`
                      );
                      setActiveLink({ a: "active" });
                    }}
                  >
                    For 8-10th STD Students
                  </span>
                </a>
              </div>
              <div>
                <a className={activeLink?.b}>
                  <span
                    className={`footer-link`}
                    onClick={() => {
                      // let coursenew = coursedata.filter(
                      //   (data) => data.name.toLowerCase().includes("career map for 11th & 12th with counseling")
                      // );
                      navigate(
                        `/course/roadmap/career-guidance-and-career-counselling-for-11th-12th-without-counselling`
                      );
                      setCourse("8-12 Student");
                      // navigate("/course");
                      setActiveLink({ b: "active" });
                    }}
                  >
                    For 11-12th STD Students
                  </span>
                </a>
              </div>
              {/* <a className="link link-hover white-text">Marketing</a>
                    <a className="link link-hover white-text">Advertisement</a> */}
            </div>
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">
                Collaboration
              </span>
              <div>
                <NavLink to="/school">
                  <span className="footer-link">Schools</span>
                </NavLink>
              </div>
              <div>
                <NavLink to="/colleges">
                  <span className="footer-link">Colleges</span>
                </NavLink>
              </div>
              {/* <div>
                <NavLink to="/ComingSoon/corporates">
                  <span className="footer-link">Corporates</span>
                </NavLink>
              </div> */}
            </div>
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">Company</span>
              <div>
                <NavLink to="/aboutus">
                  <span className="footer-link">About Us</span>
                </NavLink>
              </div>
              <div>
                <NavLink to="/contact">
                  <span className="footer-link">Contact Us</span>
                </NavLink>
              </div>
              <div>
                <NavLink to="/privacy-policy">
                  <span className="footer-link">Privacy Policy</span>
                </NavLink>
              </div>
            </div>
            <div className="footer-box-res mt-4">
              <span className=" footer-heading-purple white-text">
                Reach Us At
              </span>
              <div className="flex mb-6">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="link-hover icons"
                  href="https://www.linkedin.com/company/viosa-learning/"
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    className="mr-3 social-icon linkedin"
                    src={facebook}
                    alt=""
                  />
                </a>
                <a
                  rel="noreferrer"
                  className="link-hover icons"
                  target="_blank"
                  href="https://www.facebook.com/viosa.learning"
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    className="mr-3 social-icon facebook"
                    src={linkedin}
                    alt=""
                  />
                </a>
                <a
                  rel="noreferrer"
                  className="link-hover icons"
                  target="_blank"
                  href="https://twitter.com/viosa_learning"
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    className="mr-3 social-icon twitter"
                    src={twiter}
                    alt=""
                  />
                </a>
                {/* <a
                  rel="noreferrer"
                  className="link-hover icons"
                  target="_blank"
                  href="https://www.instagram.com/viosa_learning/"
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    className="mr-3 social-icon twitter"
                    src={instagram}
                    alt=""
                  />
                </a> */}
                {/* <img style={{"width":"40px" ,height:"40px"}} className='mr-2 social-icon' src={telegram} alt="" /> */}
              </div>
              <a
                href="tel:022-4190-2766"
                className="link link-hover white-text flex items-center mb-3"
              >
                <img src={location1} alt="" />

                <span className="ml-4">022-4190-2766</span>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/918655803488"
                className="link link-hover white-text flex items-center mb-3"
              >
                <img src={text} alt="" />

                <span className="ml-4">+91-8655803488</span>
              </a>
              <a
                href="mailto:mail@viosa.in"
                className="link link-hover white-text flex items-center mb-3"
              >
                <img src={whatsapp} alt="" />
                <span className="ml-4">mail@viosa.in</span>
              </a>
              <a
                className="link link-hover white-text flex items-center "
                href="https://goo.gl/maps/2YoHrrX9Nv4qy9ak8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={call} alt="" />
                <span className="ml-4 address">
                  104, Abhishek Premises,
                  <br /> Off New Link Rd,
                  <br /> Veera Desai Industrial Estate,
                  <br /> Andheri West, Mumbai,
                  <br /> Maharashtra 400053
                </span>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </motion.div>
  );
};

export default FooterPurple;
