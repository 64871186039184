import React from "react";
import careerHome from "./../assets/image/undraw_the_search_s0xf 1.png";
import chooseMain from "./../assets/image/undraw_preferences_popup_re_4qk0 1.png";
import choose1 from "./../assets/icon/choose-icon (1).svg";
import choose2 from "./../assets/icon/choose-icon (2).svg";
import choose3 from "./../assets/icon/choose-icon (3).svg";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const RightCounsellor = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <div className="flex items-center justify-between mx-12 mt-6">
            <div className="px-8 counsellor-dropdown">
              <h1 className="mt-4 mb-8 counsellor-heading">
                Find the right counsellor for you
              </h1>
              <p className="mb-4 counsellor-para">
                What type of session are you looking for?
              </p>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select my-4"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select my-4"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select my-4"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <button className="w-full my-4 choose-bookName">Book Now</button>
            </div>
            <div className="counsellor-image">
              <img src={careerHome} alt="" />
            </div>
          </div>

          <div className="mt-12 mb-8">
            <h1
              style={{
                fontSize: "45px",
                fontFamily: "Poppins",
                color: "rgba(82, 25, 134, 1)",
              }}
              className="flex justify-center"
            >
              Why choose{" "}
              <span style={{ color: "#fea302", marginLeft: "10px" }}> Us</span>{" "}
            </h1>
            <div className="flex items-center justify-between mx-12">
              <div>
                <div className="flex">
                  <img
                    className="mr-4 choose-icon-background"
                    src={choose1}
                    alt=""
                  />
                  <div>
                    <h1 className="choose-heading">
                      Best Courses in multiple Sectors
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
                <div className="flex my-12">
                  <img
                    className="mr-4 choose-icon-different-background"
                    src={choose2}
                    alt=""
                  />
                  <div>
                    <h1 className="choose-heading">Great Opportunities</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <img
                    className="mr-4 choose-icon-background"
                    src={choose3}
                    alt=""
                  />
                  <div>
                    <h1 className="choose-heading">Industry Experts</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <img src={chooseMain} alt="" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default RightCounsellor;
