import SignUp from "../Authentication/SignUp/SignUp";
import React, { useState } from "react";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useEffect, useRef } from "react";
import vdo from "assets/vdo1.mp4";
import image1 from "../../assets/1.png";
import image3 from "../../assets/3.png";
import image6 from "../../assets/6.png";
import image7 from "../../assets/7.png";

const Investing = ({
  setLogin,
  setBlur,
  login,
  register1,
  setRegister,
  setCourse,
}) => {
  AOS.init({ duration: 2000 });

  const [headstring, setHeadString] = useState([
    "Plan",
    "Shape",
    "Kickstart",
    "Fastrack",
  ]);

  const [student, setStudent] = useState(false);
  const [under, setUnder] = useState(false);
  const [post, setPost] = useState(false);
  const [proff, setProff] = useState(false);
  const [opacity, setopacity] = useState("op-0");
  const [text, settext] = useState("init");
  let count = 0;

  const activeButton = () => {
    if (count < headstring.length) {
      setopacity("op-100");
      if (count === 0) {
        // setopacity(true)
        settext("Plan");
        setStudent(true);
        setUnder(false);
        setPost(false);
        setProff(false);
      } else if (count === 1) {
        setUnder(true);
        settext("Shape");
        setStudent(false);
        setPost(false);
        setProff(false);
      } else if (count === 2) {
        setPost(true);
        settext("Kickstart");
        setStudent(false);
        setUnder(false);
        setProff(false);
      } else if (count === 3) {
        setProff(true);
        settext("Fastrack");
        setStudent(false);
        setUnder(false);
        setPost(false);
      }
      count !== 3 && setTimeout(() => setopacity("op-0"), 2800);
      count++;
    } else {
      count = 0;
      activeButton();
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    // if (headstring.length !== count) {
    setInterval(activeButton, 3000);
    // } else {
    // count = 0
    // setInterval(activeButton, 3000)
    // }
  }, []);

  const handleStudent = () => {
    setCourse("8-12 Student");
  };

  const handleUnderGr = () => {
    setCourse("Undergraduate/Graduate");
  };

  const handleGr = () => {
    setCourse("Post Graduate");
  };

  const handleProfessional = () => {
    setCourse("Working Professional");
  };

  return (
    <>
      <div>
        <div
          className="flex justify-space-around height-investing"
          style={{ backgroundColor: "#fff", justifyContent: "space-around" }}
        >
          <div
            style={{ maxWidth: "1680px", width: "100%" }}
            className="hero-content  flex-col lg:flex-row-reverse padd-40"
          >
            {/*
            < */}
            {/* <video
              autoPlay
              muted
              loop
              playsInline
              height={500}
              id="myVideo"
              data-aos="fade-top"
              data-aos-duration="1000"
            >
              <source src="https://viosa-storage-2.s3.amazonaws.com/2023-09-28T13%3A33%3A07.866Zvdo1.mp4" />
            </video> */}
            <div className="banner-button" style={{ flex: "1" }}>
              <div>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleStudent}
                  to="/course"
                  // to="/course/roadmap/career-map-for-8th-9th-10th-with-counselling"
                >
                  {/* <button
                    className={`${
                      (student && "button-hover") ||
                      (text === "init" && "button-hover")
                    }`}
                  >
                    Class 8th to 12th
                  </button> */}
                  <img
                    src={image1}
                    alt="Class 8th to 12th"
                    className="homeimg"
                  />
                </NavLink>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleUnderGr}
                  to="/course"
                >
                  {/* <button className={`${under && "button-hover"}`}>
                    Undergrads/Graduates
                  </button> */}
                  <img
                    src={image3}
                    alt="UnderGrad/Graduates"
                    className="homeimg"
                  />
                </NavLink>
              </div>
              <div>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleGr}
                  to="/course"
                >
                  {/* <button className={`${post && "button-hover"}`}>
                    Post Graduates
                  </button> */}
                  <img src={image7} alt="Professional" className="homeimg" />
                </NavLink>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleProfessional}
                  to="/course"
                >
                  {/* <button className={`${proff && "button-hover"}`}>
                    Professionals
                  </button> */}
                  <img src={image6} alt="Post Graduates" className="homeimg" />
                </NavLink>
              </div>
            </div>

            <div className="lg:mr-4" style={{ flex: "1" }}>
              <h1 className="new-invest-heading">
                {/* <span ref={textRef} style={{ color: "#fea302" }}></span> */}
                <div style={{ display: "inline-block" }}>
                  <span
                    className={`span-hover ${text === "init" ? "" : opacity}`}
                    style={{ color: "#fea302" }}
                  >
                    {text === "init" ? "Plan" : text}
                  </span>
                </div>{" "}
                Your Career
              </h1>
              <p className=" investing-para text-start">
                Connecting Skills to Careers 
              </p>
              {/* <div className="banner-button">
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleStudent}
                  to="/course"
                  // to="/course/roadmap/career-map-for-8th-9th-10th-with-counselling"
                >
                  <button
                    className={`${
                      (student && "button-hover") ||
                      (text === "init" && "button-hover")
                    }`}
                  >
                    Class 8th to 12th
                  </button>
                </NavLink>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleUnderGr}
                  to="/course"
                >
                  <button className={`${under && "button-hover"}`}>
                    Undergrads/Graduates
                  </button>
                </NavLink>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleGr}
                  to="/course"
                >
                  <button className={`${post && "button-hover"}`}>
                    Post Graduates
                  </button>
                </NavLink>
                <NavLink
                  style={{ color: "black" }}
                  onClick={handleProfessional}
                  to="/course"
                >
                  <button className={`${proff && "button-hover"}`}>
                    Professionals
                  </button>
                </NavLink>
              </div> */}
            </div>
          </div>
        </div>
        <SignUp
          register1={register1}
          setRegister={setRegister}
          setLogin={setLogin}
          login={login}
          setBlur={setBlur}
        />
      </div>
    </>
  );
};

export default Investing;
