import React, { useEffect, useRef, useState } from "react";
import { ChapterItem } from "./ChapterItem";
import inactiveimg from "../../icons/inactive.svg";
import activeimg from "../../icons/active.svg";
import "./course.css";
import { useDispatch, useSelector } from "react-redux";
import prettyName from "pretty-name";
import { courseLink } from "redux/coures/courseAction";
import { useNavigate } from "react-router-dom";

export const AccordionLiveSession = ({ live }) => {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (
    <div className="accordion__section-courseplay">
      <div
        className={`accordion-main ${active ? "accordion-active" : ""}`}
        onClick={toggleAccordion}
      >
        <p className="accordion__title">{live?.topic || "Live Session"}</p>
        <span className={`arrow ${active ? "arrow-active" : ""}`}>
          <img className="w-2" src={inactiveimg} alt="" />
        </span>
      </div>
      <div className={active ? "accordion-content-container" : ""}>
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className="accordion__content"
        >
          {live?.join_url && (
            <div className="accordion-heading cursor-pointer">
              <div
                onClick={() => {
                  dispatch(
                    courseLink({
                      livelink: live?.join_url,
                    })
                  );
                  navigate("/course/play");
                }}
                className="accordion__text"
                dangerouslySetInnerHTML={{
                  __html:
                    live.join_url &&
                    `Live Session :${prettyName(live.name || "Live Session")}`,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
