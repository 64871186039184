import React, { useEffect, useState } from "react";
import "./EditProfileModal.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PersonalDetailForm from "./EditProfileForms/PersonalDetailForm";
import EducationForm from "./EditProfileForms/EducationForm";
import WorkExForm from "./EditProfileForms/WorkExForm";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import { ToastContainer } from "react-toastify";
import ChangePassword from "./EditProfileForms/ChangePassword";

function EditProfileModal(props) {
  const [active, setactive] = useState(props.openModal);
  const [modalClose, setModalClose] = useState(false);
  const [modalCloseClass, setModalCloseClass] = useState("");
  let isDev =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000||http://localhost:3001"
      : "https://viosa.vercel.app";

  useEffect(() => {
    if (modalClose) {
      setTimeout(() => {
        setModalClose(false);
        setModalCloseClass("modal-close");
      }, 1000);
    }
  }, [modalClose]);

  return (
    <>
      <ToastContainer />
      <PureModal
        header="Edit Profile"
        isOpen={props.editModalOpen}
        closeButton="X"
        width={650}
        closeButtonPosition="header"
        onClose={() => {
          props.setEditModalOpen(false);
          return true;
        }}
      >
        <div>
          <div className="editprofile-modal-container d-flex flex-column justify-content-between ">
            <div>
              <Breadcrumbs className="breadcum-container">
                <button
                  className={`edit-profile-breadcum ${
                    active === "Personal Details"
                      ? "edit-profile-breadcum-active"
                      : ""
                  }`}
                  onClick={() => setactive("Personal Details")}
                >
                  Personal Details
                </button>
                <button
                  className={`edit-profile-breadcum ${
                    active === "Education" ? "edit-profile-breadcum-active" : ""
                  }`}
                  onClick={() => setactive("Education")}
                >
                  Education
                </button>
                <button
                  className={`edit-profile-breadcum ${
                    active === "Work Ex" ? "edit-profile-breadcum-active" : ""
                  }`}
                  onClick={() => setactive("Work Ex")}
                >
                  Work Ex
                </button>
                <button
                  className={`edit-profile-breadcum ${
                    active === "change-pwd"
                      ? "edit-profile-breadcum-active"
                      : ""
                  }`}
                  onClick={() => setactive("change-pwd")}
                >
                  Change Password
                </button>
              </Breadcrumbs>
              {active === "Personal Details" && (
                <PersonalDetailForm
                  userDetails={props.userDetails}
                  setEditModalOpen={props.setEditModalOpen}
                  detailsupdated={props.detailsupdated}
                  setdetailsupdated={props.setdetailsupdated}
                />
              )}
              {active === "Education" && (
                <EducationForm
                  userDetails={props.userDetails}
                  setEditModalOpen={props.setEditModalOpen}
                  detailsupdated={props.detailsupdated}
                  setdetailsupdated={props.setdetailsupdated}
                />
              )}
              {active === "Work Ex" && (
                <WorkExForm
                  userDetails={props.userDetails}
                  setEditModalOpen={props.setEditModalOpen}
                  detailsupdated={props.detailsupdated}
                  setdetailsupdated={props.setdetailsupdated}
                />
              )}
              {active === "change-pwd" && (
                <ChangePassword
                  userDetails={props.userDetails}
                  setEditModalOpen={props.setEditModalOpen}
                  detailsupdated={props.detailsupdated}
                  setdetailsupdated={props.setdetailsupdated}
                />
              )}
            </div>
          </div>
        </div>
      </PureModal>
    </>
  );
}

export default EditProfileModal;
