export const consellingData = [
  {
    name: "Career Analysis for 8th, 9th & 10th Class",
    description:
      "It will help you to find out most suitable career path and subjects",
    id: 1,
    icon: "fas fa-route",
    courseIds: ["6343bf2ae33839d145810ec6", "6343bbfee33839d145810ea0"],
  },
  {
    name: "Career Analysis for 11th & 12th Class",
    description:
      "It will help you to find out most suitable career path and career road map with detailed execution plan",
    id: 2,
    icon: "fas fa-compass",
    courseIds: ["6343bfe5e33839d145810ede", "6343bda5e33839d145810eba"],
  },
  {
    name: "Career Analysis for Graduates",
    description:
      "It will help you to find out Most Suitable Career path and Career Road map with detailed execution plan",
    id: 5,
    icon: "fas fa-school",
    courseIds: [
      "6377550b5487bee9bd93580f",
      "6343c02de33839d145810eeb",
      "637755b15487bee9bd93584d",
      "6343c079e33839d145810efb",
    ],
  },
  {
    name: "Career Analysis for Professionals",
    description:
      "Early and Mid career counselling for professionals with detailed execution plan",
    id: 6,
    icon: "fas fa-school",
    courseIds: ["6343c1e6e33839d145810f52", "634cec0f9734e0fe03a03224"],
  },
  {
    name: "Engineering Branch Selector",
    description:
      "It will help you to select most suitable engineering branch before engineering college admission",
    id: 7,
    icon: "fas fa-school",
    courseIds: ["634fbdd75321de9b7048f588", "634fbdd65321de9b7048f586"],
  },
];
