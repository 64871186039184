import React, { useRef, useState } from "react";
import { ChapterItem } from "./ChapterItem";
import "./item.css";
import inactiveimg from "../../icons/inactive.svg";

export const AccordnationItem = ({ item, courseID, progressCircle }) => {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  function toggleAccordion() {
    if (item?.sectionName.toLowerCase().includes("access tool")) {
      setActive(!active);
      setHeight(active ? "0px" : `57px`);
      // //console.log("access")
    } else {
      setActive(!active);
      setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }
    // setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }
  const section_id = item._id;

  return (
    <div className="accordion__section-courseplay ">
      <div
        className={`accordion-main ${
          active ? "accordion-active-courseplay" : ""
        }`}
        eventKey={item._id}
        onClick={toggleAccordion}
      >
        <p className="accordion__title">{item.sectionName || item.name}</p>
        <span
          className={`arrow-courseplay ${
            active ? "arrow-active-courseplay" : ""
          }`}
        >
          <img className="w-2" src={inactiveimg} alt="" />
        </span>
      </div>
      <div className={active ? "accordion-content-container-courseplay" : ""}>
        {item?.chapter?.map((chapter) => (
          <ChapterItem
            {...{
              chapter,
              content,
              section_id,
              height,
              courseID,
              progressCircle,
            }}
          />
        ))}
      </div>
    </div>
  );
};
