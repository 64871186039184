export const getAllCourse = (courseId) => {
  return {
    type: "GET_COURSE",
    payload: courseId,
  };
};
export const courseId = (courseId) => {
  return {
    type: "GET_COURSE_ID",
    payload: courseId,
  };
};
export const courseLink = (links) => {
  return {
    type: "GET_COURSE_LINKS",
    payload: links,
  };
};
export const loading = (boolean) => {
  return {
    type: "LOADING",
    payload: boolean,
  };
};
export const SingleCourse = (data) => {
  return {
    type: "SINGLE_COURSE_DATA",
    payload: data,
  };
};
export const courseDetails = (data) => {
  return {
    type: "COURSE_BODY",
    payload: data,
  };
};
export const seachAction = (data) => {
  return {
    type: "SEARCH_COURSE",
    payload: data,
  };
};
export const SingleCourse2 = (data) => {
  return {
    type: "SINGLE_COURSE_DATA_2",
    payload: data,
  };
};
