import React, { useEffect, useState } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Link, useLocation } from "react-router-dom";
// import avatar from "../components/assets/icon/avatarsvg.svg";
import avatar from "../components/assets/icon/profile-avatar3.png";
import CartIcon from "../icons/carticon.png";
import search from "../components/assets/icon/Ellipse 2 (2).svg";
import { useDispatch, useSelector } from "react-redux";
import { seachAction } from "redux/coures/courseAction";

const HeaderTop = (props) => {
  const { cart } = useSelector((state) => state.user);
  const location = useLocation();
  const role = localStorage.getItem("role");

  const [searchvalue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(seachAction(e.target.value));
  };

  useEffect(() => {
    if (location.pathname !== "/profile/catalogue") {
      document.getElementById("search-bar-1").style.display = "none";
    }
  }, [location.pathname]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center mr-4">
          <div
            className="d-flex-1025 d-none-big me-2"
            onClick={() => {
              props.togglesidebar();
            }}
          >
            <ReorderIcon />
          </div>
          <strong className="dashboard-nav-head" style={{ color: "#FFA303" }}>
            {props.name}
          </strong>
        </div>
        <div className="flex items-center justify-center mr-4">
          <div className="search-container">
            <div
              className="flex justify-content-between search-bar display-none"
              id="search-bar-1"
            >
              <input onChange={handleChange} type="text" placeholder="Search" />
              <img src={search} alt="" />
            </div>
          </div>
          {role !== "college" && (
            <>
              {cart?.length > 0 && (
                <Link to="/profile/cart" style={{ position: "relative" }}>
                  <img
                    className="cart-image-1 mx-4"
                    src={CartIcon}
                    style={{ width: "28px" }}
                    alt=""
                  />
                  <span className="cart-count">{cart.length}</span>
                </Link>
              )}
              <div className="divider lg:divider-horizontal"></div>
            </>
          )}

          {role !== "college" ? (
            <>
              <Link to="/profile/user">
                <img
                  style={{ height: "40px" }}
                  className="mr-[23px] user-img"
                  src={localStorage.getItem("userimageavatar") || avatar}
                  alt=""
                />
              </Link>
              <Link to="/profile/user" className="d-none-500">
                <p
                  style={{ color: "#FFA303" }}
                  className="user-name d-none-500"
                >
                  {localStorage.getItem("userName") || "Anonymous"}
                </p>
              </Link>
            </>
          ) : (
            <>
              <Link>
                <img
                  style={{ height: "40px" }}
                  className="mr-[23px] user-img"
                  src={
                    localStorage.getItem("profileImg") ||
                    localStorage.getItem("userimageavatar") ||
                    avatar
                  }
                  alt=""
                />
              </Link>
              <Link className="d-none-500">
                <p
                  style={{ color: "#FFA303" }}
                  className="user-name d-none-500"
                >
                  {localStorage.getItem("userName") || "Anonymous"}
                </p>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
