import React, { useState, useEffect } from "react";
import "./AssignmentSubmit.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { submitAssessmentResults, getAssessmentResults } from "Axios/apis";
import axios from "axios"; // Ensure Axios is available

const AssignmentSubmit = () => {
  // const navigate = useNavigate();
  // function handleClick() {
  //   navigate("/assignment/start");
  // }
  const location = useLocation();
  const { userAnswers, questionList } = location.state || {};
  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  console.log("User Answers:", userAnswers);
  console.log("Question List:", questionList);
  const [results, setResults] = useState({
    correctAnswers: 0,
    incorrectAnswers: 0,
  });
  const [detailedResults, setDetailedResults] = useState([]);
  const handleGoBack = () => {
    // Go back to the previous page twice
    navigate(-2); // You can also use navigate(-1) to go back once
  };
  // const calculateResults = () => {
  //   let correctAnswers = 0;
  //   let incorrectAnswers = 0;
  //   if (Array.isArray(userAnswers)) {
  //     // Check if userAnswers is an array
  //     userAnswers.forEach((answer) => {
  //       const question = questionList.find((q) => q._id === answer.questionId);
  //       console.log("question", question);
  //       console.log("questionOption", question.correctAnswer);
  //       console.log("selectOption", answer.selectedOption);
  //       console.log("answer", answer);
  //       if (question && question.correctAnswer === answer.selectedOption) {
  //         // Ensure question exists before accessing correctOption
  //         correctAnswers += 1;
  //       } else {
  //         incorrectAnswers += 1;
  //       }
  //     });
  //   } else {
  //     console.log("User answers are not available or are not an array.");
  //   }
  //   return { correctAnswers, incorrectAnswers };
  // };

  // const results = calculateResults();
  // console.log("Calculated Results:", results);

  useEffect(() => {
    const calculateAndSubmitResults = async () => {
      if (
        !userAnswers ||
        !questionList ||
        !userAnswers.length ||
        !questionList.length
      ) {
        console.warn("No user answers or question list available.");
        return;
      }
      let correctAnswers = 0;
      let incorrectAnswers = 0;

      const results = userAnswers.map((answer) => {
        const question = questionList.find((q) => q._id === answer.questionId);
        const isCorrect =
          question && question.correctAnswer === answer.selectedOption;
        return {
          question: question.question,
          correctAnswer: question.correctAnswer,
          selectedOption: answer.selectedOption,
          isCorrect,
        };
      });

      setDetailedResults(results);
      userAnswers.forEach((answer) => {
        const question = questionList.find((q) => q._id === answer.questionId);
        if (question && question.correctAnswer === answer.selectedOption) {
          correctAnswers++;
        } else {
          incorrectAnswers++;
        }
      });

      setResults({ correctAnswers, incorrectAnswers });
      console.log("Calculated Results:", results);

      // Assuming you have `userId` and `chapterId` available
      const userId = localStorage.getItem("userId");
      const chapterId = localStorage.getItem("currentChapterId");
      const sectionId = localStorage.getItem("currentSectionId");
      const data = {
        userId: userId,
        chapterId: chapterId,
        sectionId: sectionId,
        answers: userAnswers.map((answer) => ({
          questionId: answer.questionId,
          selectedOption: answer.selectedOption,
          question: answer.question.question,
          isCorrect:
            questionList.find((q) => q._id === answer.questionId)
              .correctAnswer === answer.selectedOption,
        })),
        score: correctAnswers,
        maxScore: questionList.length,
        passingScore: Math.floor(questionList.length * 0.6), // Example: 60%
        numberOfAttempts: 1,
      };

      try {
        const response = await submitAssessmentResults(data);
        console.log("Results submitted successfully:", response.data);
      } catch (error) {
        console.error("Error submitting results:", error.response);
      }
    };

    if (userAnswers.length && questionList.length) {
      calculateAndSubmitResults();
    }
  }, [userAnswers, questionList, navigate]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main-container">
        <div className="outer-container">
          <div className="container ">
            <div className="center-layout-container">
              <div className="preview-txt">
                <p>Result</p>
              </div>
              <div className="inner-container container-fluid">
                <div className="inner-container-heading">Assessment</div>
                <div className="inner-container-subheading">
                  {" "}
                  Thank you for submitting your answers.
                </div>
                <div className="result-summary">
                  <p>Correct Answers: {results.correctAnswers}</p>
                  <p>Incorrect Answers: {results.incorrectAnswers}</p>
                </div>
                <div className="result-summary">
                  {detailedResults.map((result, index) => (
                    <div
                      key={index}
                      className={`question-result ${
                        result.isCorrect ? "correct" : "incorrect"
                      }`}
                    >
                      <p className="question-text">
                        Question: {result.question}
                      </p>
                      <div className="details">
                        <p>Correct Answer: {result.correctAnswer}</p>
                        <p>Your Answer: {result.selectedOption}</p>
                        <p>
                          Status: {result.isCorrect ? "Correct" : "Incorrect"}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <Link to="/course/${courseId}">Go to Course</Link> */}
              <button className="submit-btn" onClick={handleGoBack}>
                Go to Course
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AssignmentSubmit;
