import React from "react";

const PremiumCourseSlider = ({ course }) => {
  const pstyle = {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: " hidden",
    "text-overflow": "ellipsis",
  };
  return (
    <div className="card course-card text-white mb-0 mx-2">
      <img
        className="spotlight-image"
        style={{ borderRadius: "20px" }}
        src={
          course.thumbnail ||
          "https://s35691.pcdn.co/wp-content/uploads/2021/04/university-students-doing-group-study-picture-id624695614.jpg"
        }
        alt=""
      />
      <div className="container-fluid px-4 py-2 for-set-bottom">
        <div className="row ">
          <div className="col-6 ">
            <div className="d-flex flex-column ">
              <h2 className="card-title px-3">
                {course.name || "Career Assessment AI-Powered"}
              </h2>
              <p className="text-small-p px-3" style={pstyle}>
                {course.description ||
                  "Career Assessment is a 5-Dimensional Career Analysis for school/college students, courses graduates, post-graduates..."}
                .
              </p>
            </div>
          </div>
          <div className=" col-4 card-actions">
            <div className="d-flex flex-column "></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumCourseSlider;
