import React, { useEffect } from "react";
import "./AboutUs.css";
import AboutUsHeading from "../../assets/image/about-us-heading-img.png";
import AboutUsMission from "../../assets/image/aboutus-mission-img-removebg-preview.png";
import TeamMemberGroup from "components/TeamMemberGroup/TeamMemberGroup";
import AOS from "aos";
import "aos/dist/aos.css";
import AshishSardesai from "./TeamMembers/Photos/Ashish sardesai.png";
import SaquibKazi from "./TeamMembers/Photos/Mr. Saquib Kazi.png";
import KalpeshKotecha from "./TeamMembers/Dr. Kalpesh Kotecha.png";
import AakashKotian from "./TeamMembers/Mr. Aakash Kotian.jpg";
import AakashSardesai from "./TeamMembers/Photos/Mr. aakash Sardesai.png";
import AbhayJoshi from "./TeamMembers/Photos/Mr. Abhay Joshi.png";
import AnujDaftary from "./TeamMembers/Mr. Anuj Daftary.png";
import AnujParekh from "./TeamMembers/Mr. Anuj Parekh.png";
import DabalThapa from "./TeamMembers/Photos/Mr. Dabal Thapa.png";
import DennisMathew from "./TeamMembers/Mr. Dennis Mathew.png";
import PritamDas from "./TeamMembers/Mr. Pritam Das.png";
import SanjayRam from "./TeamMembers/Mr. Sanjay Ram.png";
import amit from "assets/amit.png.jfif";
import ShantanuRay from "./TeamMembers/Mr. Shantanu Ray.png";
import ShashankSardesai from "./TeamMembers/Mr. Shashank Sardesai.png";
import TejasChandran from "./TeamMembers/Photos/Mr. Tejas.png";
import MaushmiPaulChaudhary from "./TeamMembers/Photos/Ms. Maushmi Paul Chaudhary.png";
import PritiKabra from "./TeamMembers/Photos/Ms. Priti Kabra.png";
import RiddhiChhaya from "./TeamMembers/Photos/Ms. Riddhi Chhaya.png";
import NashleyDsouza from "./TeamMembers/Photos/Mr. Nashley Dsouza.png";
import JuvanAranha from "./TeamMembers/Photos/Ms. Juvan Aranha.png";
import VikashGupta from "./TeamMembers/Mr. Vikash Gupta.jpg";
import VenkataRaman from "./TeamMembers/Mr Venkata Raman J.jpg";
import RK from "./TeamMembers/rk.png";
import AmitTank from "./TeamMembers/Mr. Amit Tank.jpg";
import AbhishekSingh from "./TeamMembers/AbhishekSingh.png";
import TraptiGupta from "./TeamMembers/Ms. Trapti Gupta.jpg";
import RusselKane from "./TeamMembers/Photos/Mr. Russel Kane.png";
import HardikZaveri from "./TeamMembers/Mr. Hardik Zaveri.jpg";
import IvyGrowth from "./TeamMembers/Mr. Ivy growth.jpg";
import SarlaJagannathan from "./TeamMembers/Ms. Sarla Jagannathan.jpg";
import NarendraGupta from "./TeamMembers/Mr. Narendra Gupta.jpg";
import ManoshiNath from "./TeamMembers/Ms. Manoshi Nath.jpg";
import SandhyaVenkat from "./TeamMembers/Ms. Sandhya Venkat.jpg";
import Sayali from "./TeamMembers/Photos/Ms. Sayali.png";
import Prashant from "./TeamMembers/Photos/Mr. Prashant.png";
import Lalit from "./TeamMembers/Photos/Mr. Lalit.png";
import CynitaPinto from "./TeamMembers/Photos/Ms. Cynita.png";
import Ajay from "./TeamMembers/Photos/Ajay.png";
import HarshWal from "../../assets/harsh.jpg";
import MadhuWal from "../../assets/madhu.jpg";

import PraveenKishorpuria from "./TeamMembers/Mr. Praveen Kishorpuria.jpg";
import PriyaImg from "../../assets/priyal.jpg";
import ShivamImg from "../../assets/shivam.jpg";
import Dummy from "./TeamMembers/Dummy.png";

import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";
import { height } from "@mui/system";

const AboutUs = () => {
  const coreteam = [
    // {
    //   name: "Riddhi Chhaya",
    //   image: RiddhiChhaya,
    //   position: "Assistant Vice President- Project",
    //   linkedin: "https://www.linkedin.com/in/riddhi-chhaya-4aab34188/",
    //   bio: "",
    //   // bio: "Riddhi Chhaya is graduate from Sydenham College of commerce and economics, Mumbai. Currently working as Assistant Vice President- Projects, at Viosa, an Edtech Startup Her personal projects and achievement include, book blogging and reviewing, content writing as a freelancer, graphic design and digital marketing as a freelancer, and co-authoring the WORLD'S LARGEST REVERSE ANTHOLOGY. Enthusiastic to learn and acquire various skills and experiences as she grows, is the only reason that keeps her motivated to aim higher.",
    // },
    {
      name: "Ajay Shah",
      image: Ajay,
      position: "Full Stack Software Engineer",
      linkedin: "https://www.linkedin.com/in/ajayshah213",
      bio: "",
    },
    {
      name: "Priti Kabra",
      image: PritiKabra,
      position: "Vice President- National Head- School Collaboration",
      linkedin: "",
      bio: "",
      // bio: "A blend of many cultures, brought up in Africa, Ghana and in Asia, India,  I have  travelled globally and have had the opportunity to interact with many nationalities, which has made me versatile. Out of my passion for self-help work, i have made various videos on YouTube to help people help themselves and written my first self-help book called 'The Life Learnings'. With a series of turn around of events, i from a sales person in the Marriott Juhu, soon I was awarded on the stage of the Marriott White Field Banglore by WEFT foundation as Emerging Author, Wellness Trainer and Coach  of the Year, at the WEFT Conference and Awards 2022, for my work in the area of Wellness and Mental Heath Awareness.",
    },
    {
      name: "Maushmi Paulchoudhury",
      image: MaushmiPaulChaudhary,
      position: "Assistant Vice President- Counseling",
      linkedin: "",
      bio: "",
      // bio: "A very goal-oriented by nature aimed to take up various task from execution to counselling to college tie-up. She has already done her Diploma in Psychology and MBBS. With her background she fits with her role of Strategic Alliance. A person who is ready to take up any task that comes her way.",
    },
    // {
    //   name: "Tejas Chandran",
    //   image: TejasChandran,
    //   position: "Sr. Manager- Accounts",
    //   linkedin: "https://www.linkedin.com/in/chandrantejas",
    //   bio: "",
    //   // bio: "3 Years of experience in Accounts and Finance Domain.",
    // },
    {
      name: "Priyal Nagrecha",
      image: PriyaImg,
      position: "Sr. Manager - School Collaborations",
      linkedin: "",
      bio: "",
      // bio: "comes with 19 years of experience in various corporate sectors. He is an MBA from Dnyaneshwar University,Pune - 2002  batch. Abhay Joshi started his career as a  sales representative with Lokmat Group of Newspapers ,Aurangabad  and in the last 19 years he has had the opportunity to establish different distribution Verticals with ICICI Prudential, Bajaj Allianz, Tata AIA, EDELWEISS under the Mentorship and Guidance of Mr.Ashish Sardesai ",
    },
    {
      name: "Shivam Mishra",
      image: ShivamImg,
      position: "Manager - Accounts & Finance.",
      linkedin: "",
      bio: "",
      // bio: "comes with 19 years of experience in various corporate sectors. He is an MBA from Dnyaneshwar University,Pune - 2002  batch. Abhay Joshi started his career as a  sales representative with Lokmat Group of Newspapers ,Aurangabad  and in the last 19 years he has had the opportunity to establish different distribution Verticals with ICICI Prudential, Bajaj Allianz, Tata AIA, EDELWEISS under the Mentorship and Guidance of Mr.Ashish Sardesai ",
    },
    {
      name: "Abhay Joshi",
      image: AbhayJoshi,
      position: "National -Head & Vice-President -Viosa Learning",
      linkedin: "",
      bio: "",
      // bio: "comes with 19 years of experience in various corporate sectors. He is an MBA from Dnyaneshwar University,Pune - 2002  batch. Abhay Joshi started his career as a  sales representative with Lokmat Group of Newspapers ,Aurangabad  and in the last 19 years he has had the opportunity to establish different distribution Verticals with ICICI Prudential, Bajaj Allianz, Tata AIA, EDELWEISS under the Mentorship and Guidance of Mr.Ashish Sardesai ",
    },
    {
      name: "Cynita Pinto",
      image: CynitaPinto,
      position: "Head of Human Resources",
      linkedin: "",
      bio: "",
      // bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    },
    {
      name: "Dabal Thapa",
      image: DabalThapa,
      position: "Vice President- Operations",
      linkedin: "Member",
      bio: "",
      // bio: "12+ experience in customer service and sales,  High altitude mountaineer as well.A go getter by nature amidst the rough surroundings of nature, he lived in the mountains for years out of the passion for a serene lifestyle and has developed his skills being a mountaineer always been result oriented, constantly checking in with the goal to determine how close or how far away we are and what it will take to make it happen is now his forte,",
    },
    // {
    //   name: "Pritam Das",
    //   image: PritamDas,
    //   position: "Sr. Manager- Video & Technology",
    //   linkedin: "",
    //   bio: "",
    //   // bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
    // {
    //   name: "Nashley Dsouza",
    //   image: NashleyDsouza,
    //   position: "Graphic Designer Intern",
    //   linkedin: "",
    //   bio: "",
    // },
    {
      name: "Juvan Aranha",
      image: JuvanAranha,
      position: "Social Media Marketing Manager",
      linkedin: "",
      bio: "",
    },
    // {
    //   name: "Prashant",
    //   image: Prashant,
    //   position: "Pune Regional Head- School Collaboration",
    //   linkedin: "",
    //   bio: "",
    // },
    // {
    //   name: "Lalit Patil",
    //   image: Lalit,
    //   position: "Regional Manager",
    //   linkedin: "",
    //   bio: "",
    //   // bio: "19 years of experience across different corporate companies.Started my career with Life Insurance Corporation of India and in my long span of experience I have worked with  MNC's like Birla Sun Life, Exide Life Insurance, Bajaj Allianz, Aviva Life Insurance, Reliance Nippon as Territory Manager with a team of 50+ sales representatives"
    // },
    // {
    //   name: "Aakash Sardesai",
    //   image: AakashSardesai,
    //   position: "Technology Lead",
    //   linkedin: "",
    //   bio: "",
    //   // bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
    // {
    //   name: "Abhishek Singh",
    //   image: AbhishekSingh,
    //   position: "VP Strategic alliance",
    //   linkedin: "",
    //   bio: "",
    //   // bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
  ];
  const mentors = [
    {
      name: "Maj Gen RK Raina",
      image: RK,
      position: "Mentor",
      linkedin: "",
      bio: `Former MD of Army Welfare Education Society (AWES). Spearheaded five programmes of ‘Shresth aur Shaksham Bharat’ including Digitalisation,  Inclusive Education, Gifted Program, Teachers’ Training and Vidyanjali Project`,
    },
    {
      name: "Praveen Sharma",
      image: PraveenKishorpuria,
      position: "Mentor",
      linkedin: "",
      bio: `Managing Director Accenture (Middle East) Startup advisor | Investor`,
    },
    {
      name: "Venkat Raman J",
      image: VenkataRaman,
      position: "Mentor",
      linkedin: "",
      bio: "Investment Banker | Managing Partner Seed VC",
    },
    {
      name: "Amit Tak",
      image: amit,
      position: "Mentor",
      linkedin: "",
      bio: "B.E. | MBA |Seasoned Banker | Founder | Angel Investor",
    },
    {
      name: "Anuj Parekh",
      image: AnujParekh,
      position: "Mentor",
      linkedin: "",
      bio: "Co-founder | CEO Healthysure",
    },
    {
      name: "Anuj Daftary",
      image: AnujDaftary,
      position: "Mentor",
      linkedin: "",
      bio: "Founder- Daftary & Associate | Early stage Angel Investor",
    },
    {
      name: "Dennis Mathew",
      image: DennisMathew,
      position: "Mentor",
      linkedin: "",
      bio: "CTO Knowlens",
    },
    {
      name: "Mr Harsh Wal",
      image: HarshWal,
      position: "Mentor",
      linkedin: "",
      bio: "Ex- Founding Principal - DPS STS School | Independent Consultant",
    },
    {
      name: "Mrs Madhu Wal",
      image: MadhuWal,
      position: "Mentor",
      linkedin: "",
      bio: "Ex-Principal - DPS STS School | Independent Consultant | Author",
    },

    {
      name: "Russell Kane",
      image: RusselKane,
      position: "Mentor",
      linkedin: "",
      bio: "Hon' Director Bombay Education Society",
    },
    // {
    //   name: "Shantanu Ray",
    //   image: ShantanuRay,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
    // {
    //   name: "Sanjay Ram",
    //   image: SanjayRam,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
    // {
    //   name: "Shashank Sardesai",
    //   image: ShashankSardesai,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "VIOSA is the EdTech industry's first unified technology platform that coaches students in acquiring sector-based competencies.",
    // },
    // {
    //   name: "Narendra Kumar Devichandra Gupta",
    //   image: NarendraGupta,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Science Graduate with post graduation in Packaging Technology and Materials Managment. A Supply chain Professional with more than 25 years of experience in various Indian and MNCs. Currently working as Regional Head of Procurement for India, Asia and ASEANZ with UPL Limited, a 6bn USD Crop Care company"
    // },
    // {
    //   name: "Amit Tak",
    //   image: AmitTank,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Mr Amit Tak is a banker/founder/ Angel Investor. He comes from the BFSI sector with very rich knowledge in banking & investment. He’s worked with some of the biggest banks in india, such as HDFC, Axis, ICICI. Currently, he’s the Founder & CEO of PRATIRAKSHA 4EVER INSURANCE BROKERS PVT LTD, AADHYANSH ENTERPRISES LLP & SENTIER SUCCES PVT LTD With his rich experience and knowledge, he is also guiding HEALTHYSURE & VIOSA towards their success journey!"
    // },
    // {
    //   name: "Hardik Kaushik Jhaveri",
    //   image: HardikZaveri,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Founder - WoodPecker Wealth Pvt Ltd I have been a part of  the financial industry since the last 2 Decades. Started off as an advisor and when I quit I was heading their 3 key Distributors. Currently Managing an AUM of 90 Crs and counting"
    // },
    // {
    //   name: "Aditi Ashish Mitra",
    //   image: "",
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Performing Artist - Indian Classical – Odissi Erstwhile 11+ years. In Information Technology       Professional Experiences Heads The Aratrika Performing Arts Institute Middleware Specialist at Nomura Services & Xorient Mumbai."
    // },
    // {
    //   name: "Vikas Sarwankumar Gupta",
    //   image: VikashGupta,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Mr. Vikas gupta is a Managing director of janani industries, janani food tek & Trustee of JJIS Education Trust, Medicap Education Trust"
    // },
    // {
    //   name: "Manoshi Nath",
    //   image: ManoshiNath,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Manoshi Nath is an Indian costume designer, who works jointly with Rushi Sharma in Bollywood films. Their team has earned Filmfare Award for Best Costume Design two times: 2009 and 2013."
    // },
    // {
    //   name: "KV Prabhakaran",
    //   image: "",
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Mr. K V Prabhakaran is a banker with 30+ years of experience"
    // },
    // {
    //   name: "Sarla",
    //   image: SarlaJagannathan,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Mrs. Sarala Jagannathan is a Prof of English with two and a half decades of teaching experience. She was incharge of career guidance cum placement cell at the institutions, Was a resource person for the NIE prog, 'The Hindu'. Have been freelancing as a soft skills trainer since 2011. Mentoring teachers of govt schools in TN for the past few months."
    // },
    // {
    //   name: "Ivy Growth Associates Pvt Ltd",
    //   image: IvyGrowth,
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "IVY Growth Associates is a MicroVC & Angel Network having 300+ Angels in the community aiming to create an impact in global startup ecosystem with over 40+ investments across sectors like Edtech, EV, Agritech, SAAS, D2C, FinTech etc. & stages from Pre Seed to Series A funding rounds."
    // },
    // {
    //   name: "Dipang Kamdar",
    //   image: "",
    //   position: "Mentor",
    //   linkedin: "",
    //   bio: "Mr. Dipang Kamdar 30 yrs export and import consultant, businessman - African textiles"
    // },
  ];
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  AOS.init({ duration: 2000 });
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="bg-white container-fluid aboutus-main-container">
          <section
            className="py-10 row aboutus-section-container mt-40-md"
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
          >
            <div className="col d-flex flex-column justify-content-center">
              <h1 className="aboutus-mainheading text-start">
                About <span className="aboutus-text-purple">Us</span>
              </h1>
              <p className="aboutus-paragraph">
                VIOSA is the EdTech industry's first unified technology platform
                that coaches students in acquiring sector-based competencies,
                help them build their professional profiles, & guides them to
                achieve their aspirational employment.
              </p>
              <p className="aboutus-paragraph">
                VIOSA is the outcome of various student interactions across
                colleges in India. During these exchanges, most student queries
                focused on understanding the right approach to identifying
                suitable corporate sectors and required readiness for the
                desired jobs.
              </p>
              <p className="aboutus-paragraph">
                Graduates and freshers needed professional guidance through
                multi-lingual coaching on various job-related competencies to
                begin their meaningful professional journey.
              </p>
            </div>
            <div className="p-0 col d-flex justify-content-center align-items-center ">
              <img
                data-aos="zoom-in-right"
                className="aboutus-heading-img"
                src={AboutUsHeading}
                alt=""
              />
            </div>
          </section>
          <section className="py-10 row aboutus-section-container col-reverse">
            <div className="p-0 mt-20-md col d-flex justify-content-center ">
              <img
                data-aos="zoom-in-left"
                className="aboutus-heading-img"
                src={AboutUsMission}
                alt=""
              />
            </div>
            <div
              className="col d-flex flex-column justify-content-center"
              style={{ marginRight: "40px" }}
            >
              <h1 className="aboutus-mainheading text-start ">
                Our <span className="aboutus-text-purple">Mission</span>
              </h1>
              <p className="aboutus-paragraph">
                At VIOSA, our Mission is to provide you with simplified access
                to Job-ready skills and coach you to begin your dream
                professional career! We are a group of industry experts who have
                identified various problems graduates and Job seekers face in
                finding a meaningful professional career.
              </p>
              <p className="aboutus-paragraph">
                {" "}
                Our coaching programs and the technology platform assists you at
                every step of determining a suitable corporate sector and
                applying for the right job in the city of your choice.
              </p>
              <p className="aboutus-paragraph">
                We have identified four sectors that are the fastest growing
                globally and provide ample opportunities to freshers and
                experienced graduates. Our courses are customized to help you
                begin your professional career!
              </p>
            </div>
          </section>
          <section className="py-10 aboutus-section-container ">
            <h1 className="aboutus-mainheading ">
              Our <span className="aboutus-text-purple">Team</span>
            </h1>
            <h2 className="aboutus-subheading">
              Know more about the professionals behind VIOSA.
            </h2>
            <div className="teacher-background">
              <div className=" row">
                <h1 className="text-center teamgroup-teamname">Our Founders</h1>
                <div className="mt-2">
                  <div
                    className="d-flex col-column"
                    style={{ position: "relative" }}
                  >
                    {/* <ArrowButton slider={slider1} />
                <ArrowNext slider={slider1} /> */}
                    <div
                      className="teammembercard-container width-head-card w-auto-res"
                      style={{ width: "450px", height: "300px" }}
                    >
                      <div className="d-flex justify-content-center teamcard-profileimg-container">
                        <img
                          className="teammembercard-profileimg"
                          src={AshishSardesai}
                        />
                      </div>
                      <h1 className="teammembercard-heading">
                        Ashish R Sardesai
                      </h1>
                      <h1 className="teammembercard-subheading">
                        Founder | CEO
                      </h1>
                      <p className="teammembercard-pargraph-full">
                        27+ years of professional experience across <br />a wide
                        spectrum of functions <br />
                        Associate Director and Country Head, <br />
                        Edelweiss Tokyo Life Insurance. <br />
                        Vice President and National Head, <br />
                        TATA AIA Life Insurance Company.
                      </p>
                    </div>
                    <div
                      className="teammembercard-container width-head-card w-auto-res"
                      style={{ width: "450px", height: "300px" }}
                    >
                      <div className="d-flex justify-content-center teamcard-profileimg-container">
                        <img
                          className="teammembercard-profileimg"
                          src={SaquibKazi}
                        />
                      </div>
                      <h1 className="teammembercard-heading">
                        {" "}
                        M Saquib M A Kazi
                      </h1>
                      <h1 className="teammembercard-subheading">
                        Director - Learning & Operations{" "}
                      </h1>
                      <p className="teammembercard-pargraph-full">
                        21+ years of experience in Professional Coaching,
                        <br />
                        Business Development & ITES <br />
                        International Coaching Federation Associate Certified
                        Coach <br />
                        IT & Business Analyst at JP Morgan Chase, Nomura
                        Services, Lehman Brothers, Network International
                        [Dubai].
                        {/* 20+ years of Corporate, Business Development &
                        Entrepreneurship Experience withWork Experience in MNCs
                        like Lehman Brothers, Nomura Services, JP Morgan,
                        Network International (Dubai). International Coaching
                        Federation - Associate Certified Coach. */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <TeamMemberGroup data={coreteam} teamname="Our Core Team" />
              <TeamMemberGroup data={mentors} teamname="Our Mentors" />

              {/* to be added */}
              {/* <TeamMemberGroup teamname="Our Industry Experts" />
            <TeamMemberGroup teamname="Our Counselor and Coaches" /> */}
            </div>
          </section>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default AboutUs;
