import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Profile from "./components/Profile";
import { useEffect, useState } from "react";
import { CourseHeader } from "./components/Section/CoursePlay/CourseHeader";
import Home from "./components/Home/Home";
import Header from "./components/Navbar/Header";
import AboutUs from "Pages/AboutUs/AboutUs";
import StudentForm from "Pages/Form/Form";
import Collabaration from "./components/Collabaration/Collabaration";
import Contact from "./components/Contact/Contact";
import StudentCourse from "./components/Course/StudentCourse";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "./components/Authentication/Login/ResetPassword";
import Protected from "components/Protected/Protected";
import Assignment from "components/Assignment/Assignment";
import AssignmentQuiz from "components/Assignment/AssignmentQuiz";
import AssignmentSubmit from "components/Assignment/AssignmentSubmit";
import Roadmap from "Pages/Roadmap/Roadmap";
import Interview from "components/Home/Interview";
import Resume from "components/Home/Resume";
import Job from "components/Home/Job";
import CertifiedCareer from "components/Collabaration/CertifiedCareer";
import RightCounsellor from "components/Collabaration/RightCounsellor";
import RightCoach from "components/Collabaration/RightCoach";
import FooterPurple from "components/Footer/FooterPurple";
import { Certificate } from "components/Certificate/Certificate";
import Colleges from "Pages/Colleges/Colleges";
import Schools from "Pages/Schools/Schools";
import Blogs from "Pages/Blogs/Blogs";
import CourseNotLogin from "components/CourseNotLogin";
import { AnimatePresence } from "framer-motion";
import { Loader } from "components/Loader/Loader";
import {
  userDashBoard,
  userRequest,
  userRoadMapCourse,
} from "redux/user/userAction";
import CounselingBooking from "components/Collabaration/CounsellingBooking";
import { PrivacyPolicy } from "PrivacyPolicy";
import { ComingSoon } from "Pages/ComingSoon";
import { SpinnerLogo } from "components/Spinner/SpinnerLogo";
import Spinner from "components/Spinner/Spinner";
import CareerRoadMap from "Pages/Roadmap/CareerRoadmap";
import CollegeDashboard from "Pages/CollegeDashboard/CollegeDashboard";

const HeaderPreview = () => {
  const location = useLocation();
  useEffect(() => {
    const alreadyHeader = document.getElementById("header-1");
    if (location.pathname.includes("/profi") && alreadyHeader) {
      alreadyHeader.style.display = "none";
    } else {
      alreadyHeader.style.display = "block";
    }
    if (location.pathname.includes("/play") && alreadyHeader) {
      alreadyHeader.style.display = "none";
    }
  }, [location.pathname]);
};

const FooterPreview = () => {
  const location = useLocation();
  useEffect(() => {
    const alreadyHeader = document.getElementById("footer-1");
    if (location.pathname.includes("/profi") && alreadyHeader) {
      alreadyHeader.style.display = "none";
    } else {
      alreadyHeader.style.display = "block";
    }
    if (location.pathname.includes("/play") && alreadyHeader) {
      alreadyHeader.style.display = "none";
    }
  }, [location.pathname]);
};

const RedirectHome = () => {
  const loginToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const isProfile =
      location.pathname.includes("/profile") ||
      location.pathname.includes("/pro") ||
      location.pathname.includes("/play");
    if (!loginToken && isProfile) {
      navigate("/");
    }
  }, []);
};

const App = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { backHome } = useSelector((state) => state.user);
  const isLoggedIn = userData?.loggedIn;
  const [login, setLogin] = useState(false);
  const [course, setCourse] = useState("");
  const [blur, setBlur] = useState(false);
  const [courseid, setCourseid] = useState("");
  const [register1, setRegister] = useState(false);
  const loginToken = localStorage.getItem("token");
  // const navigate = useNavigate()
  useEffect(() => {
    dispatch(userRequest(""));
    dispatch(
      userRoadMapCourse({
        courseId: "",
        navigateTo: "",
      })
    );

    dispatch(userDashBoard("home"));
  }, []);
  return (
    <BrowserRouter>
      <HeaderPreview />
      <RedirectHome />
      <AnimatePresence exitBeforeEnter>
        {/* {
          name !== "/profile" && (
            <Header
              setCourse={setCourse}
              setBlur={setBlur}
              register1={register1}
              login={login}
              setLogin={setLogin}
              setRegister={setRegister}
              className={`${blur ? "all-blur" : ""}`}
            />

          )
        } */}
        <Header
          setCourse={setCourse}
          setBlur={setBlur}
          register1={register1}
          login={login}
          setLogin={setLogin}
          setRegister={setRegister}
          className={`${blur ? "all-blur" : ""}`}
        />
        <Routes>
          <Route
            path="/school"
            element={<Schools setCourse={setCourse} />}
            exact
          />
          {/* <Route path="/test" element={<Spinner />} exact /> */}
          <Route path="/ComingSoon/Counseling" element={<ComingSoon />} exact />
          <Route path="/comingSoon/mobileApp" element={<ComingSoon />} exact />
          <Route path="/ComingSoon/corporates" element={<ComingSoon />} exact />
          <Route
            path="/ComingSoon/CorporateHiring"
            element={<ComingSoon />}
            exact
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
          <Route path="/blogs" element={<Blogs />} exact />
          <Route
            path="/colleges"
            element={<Colleges setCourse={setCourse} />}
            exact
          />
          <Route
            path="/course/:id"
            element={
              <CourseNotLogin
                setBlur={setBlur}
                register1={register1}
                login={login}
                setCourseid={setCourseid}
                setLogin={setLogin}
                setRegister={setRegister}
              />
            }
            exact
          />
          <Route path="/aboutus" element={<AboutUs />} exact />
          <Route path="/form" element={<StudentForm />} exact />
          <Route path="/assignment" element={<Assignment />} />
          <Route path="/assignment/start" element={<AssignmentQuiz />} />
          <Route path="/assignment/submit" element={<AssignmentSubmit />} />

          <Route
            path="/"
            element={
              <Home
                setCourse={setCourse}
                login={login}
                setLogin={setLogin}
                setBlur={setBlur}
                register1={register1}
                setRegister={setRegister}
                className={`${blur ? "all-blur" : ""}`}
              />
            }
          />
          {/* <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/profile/dashboard" />
              ) : (
                <Home
                  setCourse={setCourse}
                  login={login}
                  setLogin={setLogin}
                  setBlur={setBlur}
                  register1={register1}
                  setRegister={setRegister}
                  className={`${blur ? "all-blur" : ""}`}
                />
              )
            }
          /> */}

          <Route
            path="course/roadmap/:id"
            element={
              <Roadmap
                setBlur={setBlur}
                register1={register1}
                login={login}
                setCourseid={setCourseid}
                setLogin={setLogin}
                setRegister={setRegister}
              />
            }
          />
          <Route
            path="ai-tools/career-roadmap"
            element={
              <CareerRoadMap
                setBlur={setBlur}
                register1={register1}
                login={login}
                setCourseid={setCourseid}
                setLogin={setLogin}
                setRegister={setRegister}
              />
            }
          />
          <Route path="/interview" element={<Interview />} />
          <Route
            path="/roadmap/:id/:booking"
            element={
              <CounselingBooking
                setBlur={setBlur}
                register1={register1}
                login={login}
                setCourseid={setCourseid}
                setLogin={setLogin}
                setRegister={setRegister}
              />
            }
          />
          <Route path="/resume" element={<Resume />} />
          <Route path="/job" element={<Job />} />
          <Route path="/career" element={<CertifiedCareer />} />
          <Route path="/counsellor" element={<RightCounsellor />} />
          <Route path="/coach" element={<RightCoach />} />

          <Route path="/auth/resetPassword/:id" element={<ResetPassword />} />
          <Route path="/home" element={<Home />} />
          <Route element={<Protected />}>
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/course/play" element={<CourseHeader />} />
          </Route>
          <Route
            path="/course"
            element={<StudentCourse course={course} setCourse={setCourse} />}
          ></Route>
          {/* <Route path="/about" element={<AboutUs></AboutUs>}></Route> */}
          <Route path="/collabaration" element={<Collabaration />}></Route>
          <Route path="/certificate" element={<Certificate />}></Route>

          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <FooterPreview />
        <FooterPurple course={course} setCourse={setCourse} />
        {/* {!isLoggedIn && <FooterPurple />} */}
      </AnimatePresence>
    </BrowserRouter>
  );
};

export default App;
