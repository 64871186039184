import { pca } from "Axios/apis";

export const UserLogin = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};
export const AddtoCart = (data) => {
  return {
    type: "ADD_TO_CART",
    payload: data,
  };
};
export const Logout = (data) => {
  localStorage.clear();
  return {
    type: "LOGOUT",
    payload: data,
  };
};
export const userRequest = (data) => {
  return {
    type: "USER_REQUEST",
    payload: data,
  };
};
export const userRoadMapCourse = (data) => {
  return {
    type: "ROADMAP_COURSE",
    payload: data,
  };
};

export const userDashBoard = (data) => {
  return {
    type: "USER_DASHBOARD",
    payload: data,
  };
};

export const addStatus = async (course, setIframeData, data) => {
  // const stringToNumber = (str) => {
  //   let num = 0;
  //   for (let i = 0; i < str.length; i++) {
  //     num += str.charCodeAt(i);
  //   }
  //   return num;
  // };
  const allDATA = {
    channel_id: 1645,
    cd: course?.crm[0]?.cd,
    name: "Test",
    age: 1645,
    person_age: 21,
    userID: course?.userID,
    courseID: course?.crm[0]?.courseID,
    current_stage:
      Number(data.current_stage) === 0 ? 1 : Number(data.current_stage),
    access_code: course?.access_code,
    location: "India",
  };
  const d = await pca(
    { details: allDATA },
    localStorage.getItem("token"),
    course?._id
  );
  setIframeData(d?.data?.result?.data?.redirect_link);
  return {
    type: "USER_STATUS",
    payload: course?.status,
  };
};

// export const UPDATE_ATTEMPTS = "UPDATE_ATTEMPTS";

export const updateAttempts = (attempts) => ({
  type: "UPDATE_ATTEMPTS",
  payload: attempts,
});
