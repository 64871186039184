// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blogs-main-container {\n  max-width: 1680px;\n}\n.blogs-section-container {\n  padding: 30px 40px;\n}\n/* .heading-container {\n  background: #ffe4b4;\n} */\n.blogs-mainheading {\n  font-family: \"Inter\", sans-serif;\n\n  font-weight: 700;\n  font-size: 3.5rem;\n  color: #521986;\n}\n.blogs-subheading {\n  font-family: \"Inter\", sans-serif;\n\n  font-style: normal;\n  font-weight: 500;\n  font-size: 2.5rem;\n  color: #fea302;\n}\n/*  */\n\n.blogs-heading-img {\n  max-height: 350px;\n  width: auto;\n}\n\n.blogs-paragraph {\n  font-weight: 600;\n  font-size: 2.5rem;\n  color: #fea302;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/Blogs/Blogs.css"],"names":[],"mappings":"AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;;GAEG;AACH;EACE,gCAAgC;;EAEhC,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,gCAAgC;;EAEhC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA,KAAK;;AAEL;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap\");\n.blogs-main-container {\n  max-width: 1680px;\n}\n.blogs-section-container {\n  padding: 30px 40px;\n}\n/* .heading-container {\n  background: #ffe4b4;\n} */\n.blogs-mainheading {\n  font-family: \"Inter\", sans-serif;\n\n  font-weight: 700;\n  font-size: 3.5rem;\n  color: #521986;\n}\n.blogs-subheading {\n  font-family: \"Inter\", sans-serif;\n\n  font-style: normal;\n  font-weight: 500;\n  font-size: 2.5rem;\n  color: #fea302;\n}\n/*  */\n\n.blogs-heading-img {\n  max-height: 350px;\n  width: auto;\n}\n\n.blogs-paragraph {\n  font-weight: 600;\n  font-size: 2.5rem;\n  color: #fea302;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
