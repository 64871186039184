import React from "react";
import talent from "./../assets/image/talent.png";

const ViosaTalent = () => {
  return (
    <div className="mt-12 mb-12">
      <h1 className="flex justify-center mt-16 mb-8 bridge">
        <span style={{ color: "#fea302" }} className="mr-2">
          {" "}
          VIOSA{" "}
        </span>{" "}
        <span style={{ color: "rgba(82, 25, 134, 1)" }}>Talent Pool</span>
      </h1>
      <div className="flex justify-center">
        <div className="flex justify-center">
          <div className="hero-content flex-col lg:flex-row-reverse">
            <img src={talent} alt="" />
            <div>
              <div>
                <div>
                  <p className="text-xl">
                    Students registering for this program get added to our
                    talent bank which is accessible to the corporates for their
                    job role specific recruitments.
                  </p>

                  <ul className="mt-12 text-xl">
                    <li>Placement across various sectors</li>
                    <li>Free placement assistance</li>
                    <li>Opportunity to showcase your skills & competencies</li>
                    <li>Corporate seminars</li>
                    <li>Summer Internships</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViosaTalent;
