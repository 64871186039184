import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AddWork } from "../../../Axios/apis";
import { toast } from "react-toastify";

function WorkExForm({
  setEditModalOpen,
  detailsupdated,
  setdetailsupdated,
  userDetails,
}) {
  const [fromdate, setfromdate] = useState("");
  const [tilldate, settilldate] = useState("");
  const [job, setJob] = useState("");
  const [location, setLocation] = useState("");
  const [Organization, setOrganization] = useState("");

  const [formValues, setFormValues] = useState(
    userDetails?.result[0]?.workExperience
  );

  const handleChange = (i, e) => {
    // //console.log(e.target.date)
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleStartingDate = (i, date) => {
    let newFormValues = [...formValues];
    // //console.log(date)
    newFormValues[i].startingYear = formatDate(new Date(date.$d));
    setFormValues(newFormValues);
  };
  const handleEndingDate = (i, date) => {
    let newFormValues = [...formValues];
    newFormValues[i].endingYear = formatDate(new Date(date.$d));
    setFormValues(newFormValues);
  };

  const addFormFields = (e) => {
    e.preventDefault();
    setFormValues([
      ...formValues,
      {
        company: "",
        designation: "",
        location: "",
        endingYear: "",
        startingYear: "",
      },
    ]);
  };

  const removeField = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  }

  const submit = async (e) => {
    e.preventDefault();
    const formData = {
      userId: localStorage.getItem("userId"),
      workExpBody: formValues,
    };
    // const formData = {
    //   userId: localStorage.getItem("userId"),
    //   workExpBody: {
    //     designation: job,
    //     startingYear: fromdate,
    //     endingYear: tilldate,
    //     location: location,
    //     company: Organization,
    //   },
    // };
    // //console.log(formData);
    try {
      const data = await AddWork(formData);
      // //console.log(data);
      setdetailsupdated(!detailsupdated);
    } catch (error) {
      // //console.log(error);
      toast.warning("Failed to add work experiance");
    }
    setEditModalOpen(false);
  };

  return (
    <form>
      {formValues.map((e, i) => (
        <>
          <div onClick={() => removeField(i)} className="cancel-icon">
            <i class="fa-solid fa-xmark"></i>
          </div>
          <TextField
            name="designation"
            className="edit-profile-text-field"
            label="Job Profile"
            variant="standard"
            value={e.designation}
            onChange={(e) => handleChange(i, e)}
          />
          <TextField
            name="location"
            className="edit-profile-text-field"
            label="Location"
            variant="standard"
            value={e.location}
            onChange={(e) => handleChange(i, e)}
          />
          <TextField
            name="company"
            className="edit-profile-text-field"
            label="Organization Name"
            variant="standard"
            value={e.company}
            onChange={(e) => handleChange(i, e)}
          />

          <div className="d-flex flex-row justify-content-between">
            <div className="col-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="startingYear"
                  label="From"
                  value={e.startingYear}
                  className="edit-profile-date-field"
                  onChange={(date) => handleStartingDate(i, date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-5">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="endingYear"
                  className="edit-profile-date-field"
                  label="Till Date"
                  value={e.endingYear}
                  onChange={(date) => handleEndingDate(i, date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <input className="form-check-input mr-2" type="radio" />
            Present
          </div>
        </>
      ))}

      <div className="d-flex flex-wrap flex-row justify-content-around mt-5">
        <button
          onClick={addFormFields}
          className="edit-profile-btn-addMore"
        >
          + Add More
        </button>
        <button
          className="edit-profile-btn edit-profile-btn-white"
          // onClick={submit}
        >
          Discard
        </button>
        <button
          className="edit-profile-btn edit-profile-btn-orange"
          onClick={submit}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default WorkExForm;
