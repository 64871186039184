import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip({ students, setstudents, studentData, setSelectedStudent }) {
    const theme = useTheme();


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const newarray =
            value?.map(name => {
                const student = studentData?.find(student => student?.name === name);
                return student ? student._id : null; // Return null if name not found
            }).filter(id => id !== null);
        // value.map((item,index)=> )
        console.log(newarray)
        setSelectedStudent(newarray)
        setstudents(
            value
            // typeof value === 'string' ? value.split(',') : value,
        );
    };

    const filterStudent = (e) => {

    }
    return (
        <FormControl sx={{ m: 3, width: 500 }}>
            <InputLabel id="demo-multiple-chip-label">Select Students</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={students}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {/* <div className='px-3 w-100'>
                    <TextField id="outlined-basic" onChange={filterStudent} type="search" fullWidth className='w-100' label="Search Student" variant="outlined" />
                </div> */}
                {studentData?.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item?.name}
                    // style={getStyles(students, theme)}
                    >
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}