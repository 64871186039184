import "./certificate.css";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

export const Certificate = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="my-5 d-flex justify-content-center">
          <table className="cert">
            <tr>
              <td align="center" className="crt_logo">
                <img
                  src="https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg"
                  alt="logo"
                />
              </td>
            </tr>
            <tr>
              <td align="center">
                <h1 className="crt_title">Certificate Of Completion</h1>
                <h2>This Certificate is awarded to</h2>
                <h1 className="colorGreen crt_user">Gyasuddin Ansari</h1>
                <h3 className="afterName">For his/her completion of Node Js</h3>
                <h3>Awarded on 27 Aug 2022 </h3>
              </td>
            </tr>
            <tr>
              <td align="center">
                <img
                  src="https://camo.githubusercontent.com/805e05b94844e39d7edd518f492c8599c71835b3/687474703a2f2f692e696d6775722e636f6d2f646e5873344e442e706e67"
                  className="certSign"
                  alt="sign"
                />
                <h3>Anubhav Mittal </h3>
                <h3>Learning Lead</h3>
                <h3>Department Name</h3>
              </td>
            </tr>
          </table>
        </div>
      </motion.div>
      <FooterPurple />
    </>
  );
};
