import React, { useEffect, useState } from "react";
import Cartmodal from "./Cartmodal/Cartmodal";
import arrow from "../icons/arrow.png";
import clock from "../icons/clock.svg";
import star from "../icons/star.svg";
import stardark from "../icons/stardark.svg";
import check from "../icons/check.png";
import carrer1 from "../icons/carrer (1).png";
import road from "assets/road.png";
import carrer2 from "../icons/carrer (2).png";
import PureModal from "react-pure-modal";
import LargeVideo from "components/assets/icon/large-video.png";
import carrer3 from "../icons/carrer (3).png";
import carrer4 from "../icons/carrer (4).png";
import HeaderTop from "./HeaderTop";
import largeImage from "components/assets/icon/large-video.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  AddToCart,
  ApplyCoupon,
  getAllCourse,
  getAllCourseWithPrice,
  getAllUserCourses,
  getCourseDetailsbyId,
} from "Axios/apis";
import { AddtoCart, UserLogin } from "redux/user/userAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import prettyName from "pretty-name";
import ReactPlayer from "react-player";
import { Spinner } from "react-bootstrap";
import Spinner1 from "./Spinner/Spinner";

export const CourseBuy = (props) => {
  const [couponloading, setcouponloading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const { userData } = useSelector((state) => state.user);
  const [status, setStatus] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [paymentsuccess, setPaymentSuccess] = useState(false);
  const [purchage, setPurchage] = useState(false);
  const [couponInput, setCouponInput] = useState(false);
  const [couponNumber, setCoupon] = useState("");
  const lastCourse = courses.filter((item) => item?.course_id == params.id);
  const [revealPrice, setRevealPrice] = useState("");
  const [addedtocart, setaddedtocart] = useState("");
  const [loadingcart, setloadingcart] = useState(false);
  const userID = localStorage.getItem("userId");
  const [loading, setloading] = useState(true);
  const token = localStorage.getItem("token");
  const [ipdetails, setipdetails] = useState("");
  const { cart } = useSelector((state) => state.user);
  const isExitsCart = cart.find((item) => item?.courseID == params.id);
  const navigate = useNavigate();
  const [nextCourseId, setNextCourseId] = useState({
    courseid: "",
    courseName: "",
    coursePrice: "",
  });
  useEffect(() => {
    setIsLoading(true);
    const fetchCourse = async () => {
      try {
        setloading(true);
        const response = await getAllUserCourses(token);
        // //console.log(response.data.courses);
        setIsLoading(false);
        dispatch(
          UserLogin({
            courseList: response?.data?.courses,
            loggedIn: true,
          })
        );
        setloading(false);
      } catch (err) {
        setIsLoading(false);
        // //console.log(err);
      }
      setloading(false);
    };
    fetchCourse();
  }, [purchage]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const buyNow = async (courseid, courseName, coursePrice) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      // toast.warning("Razorpay SDK failed to load. Are you online?");
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const paymentData = {
      amount: Number(revealPrice),
      currency: "INR",
    };
    axios
      .post("https://api.viosa.in/user/payment/buy_course_request", paymentData)
      // .post(
      //   "https://api-live.viosa.in/user/payment/buy_course_request",
      //   paymentData
      // )
      .then((response) => {
        const { amount, id: order_id, currency } = response.data.result;
        const options = {
          key: "rzp_live_nsCl8adCCyvN7S", // Enter the Key ID generated from the Dashboard
          amount,
          secret: "69lDy6zhJV9KdRhb9RfnXsft",
          currency: currency,
          name: "Viosa Learning.",
          description: "Test Transaction",
          image:
            "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg",
          order_id: order_id,
          handler: async function (response) {
            const data = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              courseId: lastCourse[0].course_id,
              userId: localStorage.getItem("userId"),
              name: localStorage.getItem("userName"),
              email: localStorage.getItem("email"),
              amount: Number(revealPrice),
              currency: "INR",
              courseName: lastCourse[0].name,
            };
            axios
              // .post("https://api.viosa.in/user/payment/buy_course_verify", data)
              .post("https://api.viosa.in/user/payment/buy_course_verify", data)
              .then((res) => {
                if (res.data.error === null) {
                  const response = getAllUserCourses(token);
                  // //console.log(response);
                  dispatch(
                    UserLogin({
                      courseList: response?.data?.courses,
                      loggedIn: true,
                    })
                  );
                  setPurchage(true);
                  setStatus(true);
                  setPaymentSuccess(true);
                } else {
                  setStatus(true);
                  setPaymentSuccess(false);
                }
              });
          },
          prefill: {
            name: "Viosa Pvt. Ltd",
            email: localStorage.getItem("email") || "example@gmail.com",
            contact: "9999999999",
          },
          theme: {
            color: "#F37254",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      });
  };
  const cousellingbuy = async (courseid, courseName, coursePrice) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      // toast.warning("Razorpay SDK failed to load. Are you online?");
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const paymentData = {
      amount: coursePrice || Number(revealPrice),
      currency: "INR",
    };
    axios
      // .post("https://api.viosa.in/user/payment/buy_course_request", paymentData)
      .post("https://api.viosa.in/user/payment/buy_course_request", paymentData)
      .then((response) => {
        const { amount, id: order_id, currency } = response.data.result;
        const options = {
          key: "rzp_live_nsCl8adCCyvN7S", // Enter the Key ID generated from the Dashboard
          amount,
          secret: "69lDy6zhJV9KdRhb9RfnXsft",
          currency: currency,
          name: "Viosa Learning.",
          description: "Test Transaction",
          image:
            "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg",
          order_id: order_id,
          handler: async function (response) {
            const data = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              courseId: courseid || lastCourse[0].course_id,
              userId: localStorage.getItem("userId"),
              name: localStorage.getItem("userName"),
              email: localStorage.getItem("email"),
              amount: coursePrice || Number(revealPrice),
              currency: "INR",
              courseName: courseName || lastCourse[0].name,
            };
            axios
              // .post("https://api.viosa.in/user/payment/buy_course_verify", data)
              .post("https://api.viosa.in/user/payment/buy_course_verify", data)
              .then((res) => {
                if (res.data.error === null) {
                  const response = getAllUserCourses(token);
                  // //console.log(response);
                  dispatch(
                    UserLogin({
                      courseList: response?.data?.courses,
                      loggedIn: true,
                    })
                  );
                  setPurchage(true);
                  setStatus(true);
                  setPaymentSuccess(true);
                } else {
                  setStatus(true);
                  setPaymentSuccess(false);
                }
              });
          },
          prefill: {
            name: "Viosa Pvt. Ltd",
            email: localStorage.getItem("email") || "example@gmail.com",
            contact: "9999999999",
          },
          theme: {
            color: "#F37254",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      });
  };

  const applyCoupon = async () => {
    const formData = {
      couponCode: couponNumber,
      courseId: lastCourse[0].course_id,
    };
    try {
      const coupon = await ApplyCoupon(formData);
      setRevealPrice(coupon.data.result);
    } catch (error) {
      // //console.log(error);

      alert("Failed to apply coupon , please try again Or Coupon Limit Over");
    }
    setcouponloading(false);
  };

  const AddthisToCart = async () => {
    try {
      const cartitem = {
        cartBody: {
          courseID: lastCourse[0]?.course_id,
          name: lastCourse[0]?.name,
          thumbnail: lastCourse[0]?.thumbnail,
          price: revealPrice,
        },
        userId: userID,
      };
      const res = await AddToCart(cartitem);
      dispatch(AddtoCart(res?.data?.result?.cart));
      setaddedtocart(lastCourse[0]?.course_id);
    } catch (error) {
      // //console.log(error);
      // toast.warning("Failed to Add to Cart, please try again");

      alert("Failed to Add to Cart, please try again");
    }
    setloadingcart(false);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // //console.log(token);
    // ;
    const fetchCourse = async () => {
      const response = await getAllUserCourses(token);
      // //console.log(response.data.courses);
      dispatch(
        UserLogin({
          courseList: response?.data?.courses,
          loggedIn: true,
        })
      );
    };
    fetchCourse();
  }, [purchage]);
  const isPurchased =
    userData?.courseList?.filter((item) => item?.courseID === params.id) || [];

  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const response = await getAllCourse();
      setCourses(response?.data?.courses);
      const result = await axios.get("https://ipapi.co/json");
      const res = await getCourseDetailsbyId(params.id, token);
      setCouponInput(true);
      setRevealPrice(res?.data?.courses[0]?.price);
      const result1 = await getAllCourseWithPrice({
        id: params.id,
        country: result?.data?.country,
        country_calling_code: result?.data?.country_calling_code,
      });
      const res2 = result1?.data?.courses?.find(
        (item) => item.order === res?.data?.courses[0]?.order + 1
      );
      setNextCourseId({
        courseid: res2?.course_id,
        coursePrice: res2?.price,
        courseName: res2?.name,
      });
      // //console.log(res);
      setloading(false);
    };
    fetchCourse();
  }, []);

  return (
    <div className="containter mx-2">
      <HeaderTop name="Course" togglesidebar={props.togglesidebar} />
      {loading ? (
        <Spinner1 className="m-auto" />
      ) : (
        <div>
          <PureModal
            isOpen={show}
            style={{ borderRadius: "10px" }}
            closeButton="X"
            width={900}
            closeButtonPosition="header"
            onClose={() => {
              setShow(false);
              return true;
            }}
          >
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="mt-2 mb-4">
                <img src={road} className="counseling-popup-img"></img>
              </div>
              <div
                className="d-flex justify-content-around"
                style={{ justifyContent: "space-around", width: "100%" }}
              >
                <button
                  className="btn btn-warning new-button-1 counseling-popup-button "
                  onClick={() => cousellingbuy()}
                  style={{
                    backgroundColor: "#FEA302",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  With Counselling
                </button>
                <button
                  className="btn btn-outline-warning new-button-2 counseling-popup-button "
                  style={{
                    border: "2px solid #FEA302",
                    color: "#000",
                    backgroundColor: "#fff",
                    textTransform: "capitalize",
                  }}
                  onClick={() => buyNow(true, true, true)}
                >
                  Without Counseling
                </button>
              </div>
              <div className="my-2">
                <h1 style={{ fontSize: "35px", color: "#eb6a3f" }}>
                  {/* {props.paymentsuccess ? "All the Best!" : "Try Again"} */}
                </h1>
              </div>
            </div>
          </PureModal>
          <div className="items-center onetp-res justify-between">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div className="col-4 w-100-768 ">
                {status && (
                  <Cartmodal
                    status={status}
                    paymentsuccess={paymentsuccess}
                    setStatus={setStatus}
                  />
                )}
                <div className="d-flex price-box-res justify-end">
                  <div className="mt-3  box d-flex gap-y-4">
                    {/* <h1 className="one-time-pay">One Time Pay Only</h1> */}
                    {/* <p>$1200</p> */}
                    {!isPurchased?.length > 0 ? (
                      <>
                        {
                          <h2 style={{ fontSize: "23px" }}>
                            {revealPrice ? "₹" + revealPrice : ""}
                          </h2>
                        }
                        {!couponInput ? (
                          <>
                            <Spinner animation="border" size="lg" />
                            {/* <button
                              className="cursor-pointer btn-primary-1"
                              // onClick={() => buyNow()}
                              onClick={() => setCouponInput(true)}
                            >
                              View Price
                            </button> */}
                          </>
                        ) : (
                          <>
                            {!revealPrice ? (
                              <input
                                type="text"
                                className="px-3 py-2"
                                onChange={(e) => setCoupon(e.target.value)}
                                placeholder="enter coupon"
                              />
                            ) : (
                              <></>
                            )}
                            <div
                              style={{ fontSize: "15px" }}
                              className={`cursor-pointer ${
                                revealPrice && "d-none"
                              }`}
                              onClick={() => {
                                setcouponloading(true);
                                applyCoupon();
                              }}
                            >
                              {revealPrice ? (
                                <>
                                  {/* <div className="coupon__box ">
                                  <p className="coupon__name">Coupon Applied</p>{" "}
                                  <img alt="..." className="coupon__img" src={check} />
                                </div> */}
                                </>
                              ) : couponloading ? (
                                <div className="apply__coupon cursor-pointer d-flex align-center justify-center">
                                  <p className="apply__text ">Loading...</p>
                                  <div
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                    className="spinner-border text-light ml-2 mt-1"
                                    role="status"
                                  ></div>
                                </div>
                              ) : (
                                <div className="apply__coupon cursor-pointer">
                                  <p className="apply__text cursor-pointer">
                                    Apply Coupon
                                  </p>
                                </div>
                              )}
                            </div>

                            {revealPrice ? (
                              <>
                                <div
                                  className="cursor-pointer apply__coupon"
                                  onClick={() => buyNow()}
                                >
                                  <p className="buy__text"> Buy Now</p>
                                </div>
                                {loadingcart ? (
                                  <Spinner animation="border" size="sm" />
                                ) : isExitsCart?.courseID === params.id ? (
                                  <h4 style={{ fontSize: "15px" }}>
                                    Already in Cart
                                  </h4>
                                ) : (
                                  <h4
                                    onClick={() => {
                                      setloadingcart(true);
                                      AddthisToCart();
                                    }}
                                    style={{ fontSize: "15px" }}
                                    className="cursor-pointer"
                                  >
                                    Add to Cart
                                  </h4>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => navigate("/profile/dashboard")}
                          className="btn-primary-1"
                        >
                          Go to Dashboard
                        </button>
                        Already Purchased
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-8  w-100-768 ">
                <div className="">
                  <div className="flex items-center mb-3">
                    <h1
                      className="mr-2 text-xl update-heading"
                      onClick={() => {
                        setIsOpened(false);
                        setShow(false);
                        setRevealPrice("");
                        setCouponInput(false);
                      }}
                    >
                      <Link
                        style={{ color: "#535353" }}
                        to="/profile/catalogue"
                      >
                        All Courses
                      </Link>
                    </h1>
                    <img
                      className="img-small"
                      src={arrow}
                      height="16"
                      alt="img"
                    />
                    <h1 className="px-1 update-heading">
                      {prettyName(lastCourse[0]?.name)}
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <p className="course-mainheading">
                      {prettyName(lastCourse[0]?.name)}
                    </p>
                  </div>
                  <div>
                    <div className="flex items-center my-2 gap-x-2">
                      <p className="text-xl" style={{ color: "#FFA200" }}>
                        4.0
                      </p>
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={stardark} alt="" />
                    </div>
                    <p className="pb-4 course-paragraph">
                      {lastCourse[0]?.description ||
                        "The difference between User Interface (UI) and User Experience (UX) is that UI refers to the aesthetic elements by which people interact with a product, while UX is about the experience a user has with a product or service"}
                    </p>
                  </div>
                  {lastCourse[0]?.name.toLowerCase().includes("professional") ||
                  lastCourse[0]?.name.toLowerCase().includes("coun") ? null : (
                    <button className="flex items-center justify-center mt-4 minit120-button gap-x-2">
                      <img src={clock} alt="" />

                      <p className="text-white">120 minutes</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 mb-8  react__player">
            <ReactPlayer
              url={lastCourse[0]?.videoLink2}
              width="100%"
              muted
              light={largeImage}
              height="450px"
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              controls
              className="height-res-player"
              // autoplay
              playing={true}
            />
          </div>

          <div>
            <p className="mb-4 course-mainheading">About the Course</p>
            <p className="text-justify course-paragraph lh-30">
              Career Assessment is a 5-Dimensional Career Analysis for
              school/college students, graduates, post-graduate, freshers and
              working professionals. This assessment is conducted in order to
              evaluate an individual’s skills, knowledge, abilities, personality
              traits, attitudes, and job / academic potential & performance.
              Career Assessment is a 5-Dimensional Career Analysis for
              school/college students, graduates, post-graduate, freshers and
              working professionals. This assessment is conducted in order to
              evaluate an individual’s skills, knowledge, abilities, personality
              traits, attitudes, and job / academic potential & performance.
            </p>
          </div>

          <div>
            <p className="mt-4 mb-4 course-mainheading">Course Contents</p>
            <ul>
              <li style={{ opacity: "1" }} className=" course-paragraph">
                Building Blocks Of Career Assessment
              </li>
              <li style={{ opacity: "1" }} className="pt-3 course-paragraph">
                Take The Assessment PLUS 23+ Page Scorecard
              </li>
              <li style={{ opacity: "1" }} className="pt-3 course-paragraph">
                Career Analysis Scorecard Interpretation
              </li>
            </ul>
          </div>

          <div className="mb-12">
            <p className="mt-4 mb-4 course-mainheading r">
              Why is Career Assessment a must?
            </p>
            <div>
              <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 w-100-600">
                <div className=" shadow-xl carrer-box">
                  <figure className=" pt-10">
                    <img src={carrer2} alt="Shoes" className="mx-3" />
                  </figure>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title">
                      5-Dimensional Career Assessment
                    </h2>
                    <p>
                      Our assessment platform helps you to get a 5-dimensional
                      assessment and get an analysis of your complete career
                    </p>
                  </div>
                </div>

                <div className=" shadow-xl carrer-box">
                  <figure className=" pt-10">
                    <img
                      src={carrer3}
                      alt="Shoes"
                      className=" mx-3 rounded-xl"
                    />
                  </figure>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title">
                      AI for higher Accuracy and reliability
                    </h2>
                    <p>
                      Our assessment platform helps you to get a 5-dimensional
                      assessment and get an analysis of your complete career
                    </p>
                  </div>
                </div>

                <div className=" shadow-xl carrer-box">
                  <figure className=" pt-10">
                    <img
                      src={carrer4}
                      alt="Shoes"
                      className=" mx-3 rounded-xl"
                    />
                  </figure>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title">
                      Career Roadmap in just 45 mins
                    </h2>
                    <p>
                      Our assessment platform helps you to get a 5-dimensional
                      assessment and get an analysis of your complete career
                    </p>
                  </div>
                </div>

                <div className=" shadow-xl carrer-box">
                  <figure className=" pt-10">
                    <img
                      src={carrer1}
                      alt="Shoes"
                      className="mx-3 rounded-xl"
                    />
                  </figure>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title">
                      150+ Career paths and 3000+ Occupations
                    </h2>
                    <p>
                      Our assessment platform helps you to get a 5-dimensional
                      assessment and get an analysis of your complete career
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
