import React from "react";
import ForCollage from "./ForCollage";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const Collabaration = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <ForCollage></ForCollage>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default Collabaration;
