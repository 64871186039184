import React, { useRef } from "react";
import Slider from "react-slick";
import SchoolsDimensionimg1 from "../../assets/image/school-dimension-img.png";
import SchoolsDimensionimg2 from "../../assets/image/school-dimension-img.png";
import PersonalityAnay from "../../assets/image/PersonalityAnay-img.png";
import StudentCareer from "../../assets/image/StudentCareer.png";
import CareerMotivation from "../../assets/image/Career-motivation.png";
import LearningStyle from "../../assets/image/Learning-style.png";
import StudentInterest from "../../assets/image/Student-interest.png";
// import courseLeft from "../..//arrow-left.png";
import courseLeft from "../../components/assets/icon/course-left-right (1).svg";
import courseRight from "../../components/assets/icon/course-left-right (2).svg";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

const SchoolSlider = () => {
  const slider1 = useRef(null);

  var setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  return (
    <>
      <div className="flex items-center school-slider-arrow justify-end mb-2 gap-x-6">
        <img
          className="course-left-right"
          onClick={() => slider1.current.slickPrev()}
          src={courseLeft}
          alt=""
        />
        <img
          onClick={() => slider1.current.slickNext()}
          className="course-left-right"
          src={courseRight}
          alt=""
        />
      </div>
      <Slider ref={slider1} {...setting}>
        <div style={{ height: "80%" }} ref={ref}>
          <div className="row mobile-row">
            <div className="col-7 d-flex flex-column justify-content-center width-100">
              <h2 className="schools-mainheading text-align-lf">
                Personality Analysis
              </h2>
              <p className="schools-paragraph">
                By understanding the personality, the assessment helps identify
                the right career path in accordance with the right education
                stream.
              </p>
            </div>
            <div className="col-5 p-0 d-flex justify-content-center width-100">
              <img
                className="schools-heading-img"
                src={PersonalityAnay}
                alt=""
              />
            </div>
          </div>
        </div>
        <div style={{ height: "100%" }} ref={ref1}>
          <div className="row mobile-row py-5">
            <div className="col-5 p-0 d-flex justify-content-center width-100">
              <img className="schools-heading-img" src={StudentCareer} alt="" />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center width-100">
              <h2 className="schools-mainheading text-align-lf">
                Student Career Interest
              </h2>
              <p className="schools-paragraph">
                This helps understand the nature of jobs that interests the
                candidate. Which sectors are more aligned to their personality
                is the outcome of this dimension.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: "100%" }} ref={ref2}>
          <div className="row mobile-row py-5">
            <div className="col-7 d-flex flex-column justify-content-center width-100">
              <h2 className="schools-mainheading text-align-lf">
                Career Motivators Analysis
              </h2>
              <p className="schools-paragraph">
                This helps the candidates understand what motivates them. This
                helps maximize efforts in the right direction.
              </p>
            </div>
            <div className="col-5 p-0 d-flex justify-content-center width-100 ">
              <img
                className="schools-heading-img"
                src={CareerMotivation}
                alt=""
              />
            </div>
          </div>
        </div>
        <div style={{ height: "100%" }} ref={ref3}>
          <div className="row mobile-row py-5">
            <div className="col-5 p-0 d-flex justify-content-center width-100">
              <img className="schools-heading-img" src={LearningStyle} alt="" />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center width-100">
              <h2 className="schools-mainheading text-align-lf">
                Learning Style Analysis
              </h2>
              <p className="schools-paragraph">
                Knowing this helps candidates achieve more in a shorter period
                of time. Knowing this helps the candidates maximize their output
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: "100%" }} ref={ref4}>
          <div className="row mobile-row py-5 width-100">
            <div className="col-7 d-flex flex-column justify-content-center width-100">
              <h2 className="schools-mainheading text-align-lf">
                Skills & Abilities Analysis
              </h2>
              <p className="schools-paragraph">
                This helps the candidates explore and identify different ways to
                reshape their career direction. The skill analysis provides a
                definitive path.
              </p>
            </div>
            <div className="col-5 p-0 d-flex justify-content-center width-100">
              <img
                className="schools-heading-img"
                src={StudentInterest}
                alt=""
              />
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default SchoolSlider;
