const initialState = {
  userData: {},
  courses: [],
  link: "",
  link2: "",
  course: "",
  userRequest: "",
  userRoadMapCourse: {
    courseId: "",
    navigateTo: "",
  },
  cart: [],
  status: null,
  backHome: "home",
  attempts: 0,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.payload,
      };
    case "LOGOUT":
      return {
        userData: {},
        courses: [],
        link: "",
        link2: "",
        course: "",
        userRequest: "",
        userRoadMapCourse: {},
        userData: action.payload,
      };
    case "COURSE_LIST":
      return {
        ...state,
        courses: action.payload,
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cart: action.payload,
      };
    case "USER_REQUEST":
      return {
        ...state,
        userRequest: action.payload,
      };
    case "ROADMAP_COURSE":
      return {
        ...state,
        userRoadMapCourse: action.payload,
      };
    case "USER_DASHBOARD":
      return {
        ...state,
        backHome: action.payload,
      };
    case "USER_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "UPDATE_ATTEMPTS":
      return {
        ...state,
        attempts: action.payload,
      };
    default:
      return state;
  }
};
