import HeaderTop from "components/HeaderTop";
import React, { useEffect, useState } from "react";
import { consellingData } from "./coursellingData";
import {
  GetUserDetails,
  getAllUserCourses,
  pca,
  statusCheck,
} from "Axios/apis";
import { careerAssessmentCD } from "./data";
import Spinner from "components/Spinner/Spinner";

export const CareerAssessment = (props) => {
  const [loading, setLoading] = useState(false);
  const [iframeData, setIframeData] = useState();
  const [selectEnable, setSelectEnable] = useState(false);
  const [dashboardCourse, setDashboardCourse] = useState();

  const [select, setSelect] = useState(0);
  const [data, setData] = useState({
    channel_id: 1645,
    cd: 0,
    name: "",
    current_stage: 1,
    location: "",
    age: 1645,
    person_age: 21,
    access_code: "",
  });
  const stringToNumber = (str) => {
    let num = 0;
    for (let i = 0; i < str.length; i++) {
      num += str.charCodeAt(i);
    }
    return num;
  };
  const [careerAssessmentData, setCareerAssessmentData] = useState([]);
  const formSubmit = async (value) => {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userId");
    const response = await getAllUserCourses(token);
    const user = await GetUserDetails(userID);
    const filteredCourses = [
      ...new Map(response?.data?.courses?.map((m) => [m.courseID, m])).values(),
    ];
    const userCareerAssessmentCourse = careerAssessmentCD.filter((item) =>
      filteredCourses.some((i) => i.courseID === item.id)
    );
    const dashboardCourse = filteredCourses.filter(
      (item) => item.courseID === "637239518381b37e31dbd273"
    );
    if (dashboardCourse.length > 0) {
      setDashboardCourse(true);
      setIframeData(
        "https://pca.viosa.in/student-dashboard/?channel_id=MTY0NQ="
      );
      return;
    }

    setCareerAssessmentData(userCareerAssessmentCourse);
    if (userCareerAssessmentCourse[0]?.optionSelect && selectEnable === false) {
      setSelectEnable(true);
    } else if (!userCareerAssessmentCourse[0]?.optionSelect) {
      try {
        if (userCareerAssessmentCourse?.length === 0) {
          setIframeData("https://pca.viosa.in/");
          return;
        }
        setDashboardCourse(false);
        setLoading(true);
        const allDATA = {
          ...data,
          cd: userCareerAssessmentCourse[0]?.optionSelect
            ? Number(value)
            : userCareerAssessmentCourse[0]?.value,
          name: user?.data?.result[0]?.name,
          location: "India",
          access_code:
            user.data.result[0]?.serialNumber === 0
              ? "abaven_" + stringToNumber(user?.data?.result[0]?._id)
              : "abaven_" + user.data.result[0]?.serialNumber,
        };
        localStorage.setItem("access_code", allDATA.access_code);
        localStorage.setItem("cd", allDATA.cd);
        const d = await pca({ details: allDATA });
        setLoading(false);
        setIframeData(d?.data?.result?.data?.redirect_link);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    formSubmit();
  }, []);

  useEffect(() => {
    setInterval(() => {
      const status = localStorage.getItem("status");
      if (status === 3) return;
      const access_code = localStorage.getItem("access_code");
      const cd = localStorage.getItem("cd");
      const fetch = async () => {
        const statusData = await statusCheck({
          access_code: access_code,
          cd: cd,
        });
        localStorage.setItem("status", statusData?.data?.status.status);
        if (statusData?.data?.status.status === 3) {
          localStorage.removeItem("access_code");
          localStorage.removeItem("cd");
        }
        if (statusData?.data?.status.status === 2) {
          localStorage.removeItem("access_code");
          localStorage.removeItem("cd");
          window.location.reload();
        }
      };
      fetch();
    }, 10000);
    return () => {
      clearInterval();
    };
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userId");
      const user = await GetUserDetails(userID);
      if (careerAssessmentData?.length === 0) {
        setIframeData("https://pca.viosa.in/");
        return;
      }
      const cd = localStorage.getItem("cd2");
      const allDATA = {
        ...data,
        cd: cd || Number(select),
        name: user?.data?.result[0]?.name,
        access_code:
          user.data.result[0]?.serialNumber === 0
            ? "abaven_" + stringToNumber(user?.data?.result[0]?._id)
            : "abaven_" + user.data.result[0]?.serialNumber,

        location: "India",
      };
      localStorage.setItem("cd2", allDATA.cd);
      const d = await pca({ details: allDATA });
      console.log(d?.data?.result?.data);
      setIframeData(d?.data?.result?.data?.redirect_link);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      {props.headerTop && <HeaderTop togglesidebar={props.togglesidebar} />}
      {loading ? (
        <Spinner />
      ) : (
        <div className="">
          <div className="">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center col-12 mt-5">
                {careerAssessmentData[0]?.optionSelect &&
                  selectEnable &&
                  !iframeData && (
                    <>
                      <div className="form-group">
                        <select
                          name="drop_cdi"
                          className="form-control"
                          style={{ border: "2px solid #1F409B" }}
                          required
                          onChange={(e) => setSelect(e.target.value)}
                        >
                          <option selected value>
                            Please select your Current Doing
                          </option>
                          <option value={125}>
                            Career Planning for Graduates (Commerce in 12th) -
                            English
                          </option>
                          <option value={126}>
                            Career Planning for Graduates (Maths in 12th) -
                            English
                          </option>
                          <option value={127}>
                            Career Planning for Graduates (Bio in 12th) -
                            English
                          </option>
                          <option value={128}>
                            Career Planning for Graduates (Arts in 12th) -
                            English{" "}
                          </option>
                          <option value={159}>
                            Career Planning for Graduates ( Commerce in 12th) -
                            Marathi
                          </option>
                          <option value={160}>
                            Career Planning for Graduates (Maths in 12th) -
                            Marathi
                          </option>
                          <option value={161}>
                            Career Planning for Graduates (Bio in 12th)?-
                            Marathi
                          </option>
                          <option value={162}>
                            Career Planning for Graduates (Arts in 12th)?-
                            Marathi
                          </option>
                          <option value={171}>
                            Career Planning for Graduates ( Commerce in 12th) -
                            Tamil
                          </option>
                          <option value={172}>
                            Career Planning for Graduates (Maths in 12th) -
                            Tamil
                          </option>
                          <option value={173}>
                            Career Planning for Graduates (Bio in 12th) - Tamil
                          </option>
                          <option value={174}>
                            Career Planning for Graduates (Arts in 12th) - Tamil
                          </option>
                          <option value={182}>
                            Career Planning for Graduates ( Commerce in 12th) -
                            Gujarati
                          </option>
                          <option value={183}>
                            Career Planning for Graduates (Maths in 12th) -
                            Gujarati
                          </option>
                          <option value={184}>
                            Career Planning for Graduates (Bio in 12th) -
                            Gujarati
                          </option>
                          <option value={185}>
                            Career Planning for Graduates (Arts in 12th) -
                            Gujarati
                          </option>
                          <option value={196}>
                            Career Planning for Graduates ( Commerce in 12th) -
                            Malayalam
                          </option>
                          <option value={197}>
                            Career Planning for Graduates (Maths in 12th) -
                            Malayalam
                          </option>
                          <option value={198}>
                            Career Planning for Graduates (Bio in 12th) -
                            Malayalam
                          </option>
                          <option value={199}>
                            Career Planning for Graduates (Arts in 12th) -
                            Malayalam
                          </option>
                          <option value={208}>
                            Career Planning for Graduates (PCMB in 12th){" "}
                          </option>
                          <option value={233}>
                            Career Planning for Graduates ( Commerce in 12th) -
                            Hindi New
                          </option>
                          <option value={234}>
                            Career Planning for Graduates (Maths in 12th) -
                            Hindi New
                          </option>
                          <option value={235}>
                            Career Planning for Graduates (Bio in 12th) - Hindi
                            New
                          </option>
                          <option value={236}>
                            Career Planning for Graduates (Arts in 12th) - Hindi
                            New
                          </option>
                          <option value={237}>
                            Career Planning for Graduates (PCMB in 12th) - Hindi
                            New
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <div>
                          <button
                            className="btn btn-btn-main w-50 mt-2"
                            onClick={fetch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </>
                  )}
              </div>
              {dashboardCourse === true ? (
                <iframe
                  src={
                    "https://pca.viosa.in/student-dashboard/?channel_id=MTY0NQ="
                  }
                  width="100%"
                  title="Career Assessment"
                  height="600px"
                ></iframe>
              ) : (
                (dashboardCourse === false || iframeData) && (
                  <iframe
                    src={iframeData}
                    width="100%"
                    title="Career Assessment"
                    height="600px"
                  ></iframe>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
