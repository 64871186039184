import React from "react";
import forCollage from "./../assets/image/for-collage.png";
import forCollage2 from "./../assets/image/Group 33807.png";
import ourLickage from "./../assets/image/our-lickage (1).png";
import ourLickage2 from "./../assets/image/our-lickage (2).png";
import ourLickage3 from "./../assets/image/our-lickage (3).png";
import ourLickage4 from "./../assets/image/our-lickage (4).png";
import ourLickage5 from "./../assets/image/our-lickage (5).png";

import idea from "./../assets/image/idea.png";
import ViosaTalent from "./ViosaTalent";
import AnswerPart from "./AnswerPart";

const ForCollage = () => {
  return (
    <div>
      <div className="flex items-center justify-center">
        <div className="lg:ml-48" style={{ width: "50%" }}>
          <p className=" investing-para">For College</p>
          <h1 className="invest-heading Corporate">
            Corporate Mentorship Program
          </h1>
          <div>
            <p className="text-xl">
              Empowering your students to kickstart their professional career
            </p>
          </div>
        </div>

        <div className="jobPoster" style={{ width: "70%" }}>
          <img src={forCollage} alt="" />
        </div>
      </div>

      <div className="flex justify-center">
        <div className="hero-content flex-col lg:flex-row">
          <img src={forCollage2} className="max-w-lg" alt="" />
          <div className="lg:ml-48" style={{ width: "50%" }}>
            <div>
              <p className="text-xl">
                Corporate Mentorship is a{" "}
                <span style={{ color: "#fea302" }} className="">
                  {" "}
                  FREE{" "}
                </span>{" "}
                collaborative program with colleges assisting the placement
                division in coaching students. We focus on developing sectoral
                competencies, mentoring them to build their professional
                profiles & guiding them to achieve their aspirational placement
                through our corporate linkages.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="flex justify-center our-corporate">
          Our{" "}
          <span className="mx-2" style={{ color: "#fea302" }}>
            {" "}
            Corporate{" "}
          </span>{" "}
          Linkages
        </p>
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-5 md:grid-cols-4">
          <div className="flex items-center justify-center">
            <img src={ourLickage2} alt="" />
          </div>

          <div className="flex items-center justify-center">
            <img src={ourLickage5} alt="" />
          </div>

          <div className="flex items-center justify-center">
            <img src={ourLickage4} alt="" />
          </div>

          <div className="flex items-center justify-center">
            <img src={ourLickage3} alt="" />
          </div>

          <div className="flex items-center justify-center">
            <img src={ourLickage} alt="" />
          </div>
        </div>
      </div>

      {/* Bridge the Gap between Students & Corporates */}
      <div className="mt-12 mb-12">
        <p className="flex items-center justify-center bridge">
          Bridge the Gap between{" "}
          <span className="ml-[10px]" style={{ color: "rgba(82, 25, 134, 1)" }}>
            {" "}
            Students & Corporates{" "}
          </span>{" "}
        </p>
        <div className="flex items-center justify-center mx-6">
          <div>
            <div>
              <p className="idea-heading">VIOSA Solution</p>
              <ul>
                <li className="idea-point">Professional Roadmap</li>
                <li className="idea-point">Corporate Learning</li>
                <li className="idea-point">Competence Coaching</li>
                <li className="idea-point">ResuMatic</li>
                <li className="idea-point">VdO Resume</li>
                <li className="idea-point">Interview Pro</li>
                <li className="idea-point">Pro Connect</li>
                <li className="idea-point">Job Eazy</li>
              </ul>
            </div>
          </div>

          <div className="">
            <img className="idea-picture" src={idea} alt="" />
          </div>

          <div>
            <div>
              <div style={{ marginBottom: "38px" }}>
                <h1 className="idea-heading">Career Requirements</h1>
                <ul>
                  <li className="idea-point">Corporate Readyness</li>
                  <li className="idea-point">Sectoral Know-how</li>
                  <li className="idea-point">
                    Identified functional competencies
                  </li>
                  <li className="idea-point">Job based skill understanding</li>
                  <li className="idea-point">Professional Profiling</li>
                  <li className="idea-point">Corporate Ethics</li>
                </ul>
              </div>

              <div>
                <h1 className="idea-heading">Student Requirements</h1>
                <ul>
                  <li className="idea-point">Career Planning</li>
                  <li className="idea-point">Industry/Sector Knowledge</li>
                  <li className="idea-point">ATS Compliant Resume</li>
                  <li className="idea-point">Professional Profile</li>
                  <li className="idea-point">Job</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViosaTalent></ViosaTalent>
      <AnswerPart></AnswerPart>
    </div>
  );
};

export default ForCollage;
