import React from "react";
import Button from "react-bootstrap/Button";
import "./Cartmodal.css";
import PureModal from "react-pure-modal";
import CartSuccess from "../../assets/image/cart-success-img.png";
import CartFailure from "../../assets/image/cart-failure-img.png";
const Cartmodal = (props) => {
  return (
    <>
      {/* <div className="cart-modal-container d-flex flex-column align-items-center justify-content-center">
      <img src={status ? CartSuccess : CartFailure}></img>
      <h2>
        {status ? "Thank You for your Purchase" : "Sorry, something went wrong"}
      </h2>
      <h1>{status ? "All the Best!" : "Try Again"}</h1>
    </div> */}

      <PureModal
        header="Last Order History"
        isOpen={props.status}
        closeButton="X"
        width={500}
        closeButtonPosition="header"
        onClose={() => {
          props.setStatus(false);
          window.location.href = "https://viosa.in/profile/dashboard";
          return true;
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mt-2 mb-4">
            <img
              src={props.paymentsuccess ? CartSuccess : CartFailure}
              style={{ height: "200px" }}
            ></img>
          </div>
          <div>
            <h2 style={{ fontSize: "28px" }}>
              {props.paymentsuccess
                ? "Thank You For Your Purchase"
                : "Sorry, something went wrong"}
            </h2>
          </div>
          <div className="my-2">
            <h1
              style={{ fontSize: "35px", color: "#FEA302", fontWeight: "bold" }}
            >
              {props.paymentsuccess ? "All the Best!" : "Try Again"}
            </h1>
          </div>
          <div className="my-4">
            {props.accessCode && "Access Code :"}
            <span
              style={{
                border: props.accessCode ? "3px dashed #FEA302" : "none",
                fontSize: "18px",
                padding: "3px",
              }}
            >
              {props.accessCode}
            </span>
          </div>
        </div>
      </PureModal>
    </>
  );
};

export default Cartmodal;
