import React, { useEffect, useRef, useState } from "react";
import SchoolsHeadingimg1 from "../../assets/image/school-heading-1.png";
import SchoolsHeadingimg2 from "../../assets/image/school-heading-2.png";
import SchoolsDimensionimg1 from "../../assets/image/school-dimension-img.png";
import SchoolsDimensionimg2 from "../../assets/image/school-dimension-img-2.png";
import { motion } from "framer-motion";
import Slider from "@mui/material/Slider";
import "./Schools.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import FooterPurple from "components/Footer/FooterPurple";
import people from "../../components/assets/icon/contact-icon (1).svg";
import messageing from "../../components/assets/icon/contact-icon (2).svg";
import phoneCall from "../../components/assets/icon/contact-icon (3).svg";
import file from "../../components/assets/icon/contact-icon (4).svg";
import collabaration from "../../components/assets/icon/collabaration.svg";
import { CollabForm } from "Axios/apis";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Carousel from "react-bootstrap/Carousel";
import SchoolSlider from "./SchoolSlider";

const Schools = ({ setCourse }) => {
  AOS.init({ duration: 2000 });
  const navigate = useNavigate();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [scrollTop, setscrollTop] = useState(100);
  const onscroll = () => {
    const winScroll = document.getElementById("scroller").scrollTop;
    const height =
      document.getElementById("scroller").scrollHeight -
      document.getElementById("scroller").clientHeight;
    const scrolled = (winScroll / height) * 100;
    setscrollTop(100 - scrolled);
  };

  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const scrolldown = (ref) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
    ref.current("null");
  };
  const [loading, setLoading] = useState(false);

  const [collaborationdata, setcollaborationdata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    schoolOrCollege: "",
  });
  const submitcollaborationform = async (e) => {
    // //console.log(collaborationdata);
    e.preventDefault();
    setLoading(true);
    try {
      const res = await CollabForm(collaborationdata);
      // //console.log(res);
      setcollaborationdata({
        name: "",
        email: "",
        phone: "",
        message: "",
        schoolOrCollege: "",
      });
      setLoading(false);
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      setLoading(false);
      // //console.log(error);
    }
  };
  const handleChangecollaboration = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setcollaborationdata({
      ...collaborationdata,
      [e.target.name]: e.target.value,
    });
  };

  const carsoal = [1, 2, 3, 4];

  var setting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: "50px",
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid bg-white schools-main-container">
          <section className="row schools-section-container mt-4 mt-0-768">
            <div className="col-8 d-flex w-100-768 flex-column justify-content-center p-0">
              <h1 className="schools-subheading text-start schools-text-purple">
                For Schools
              </h1>
              <h1 className="schools-mainheading text-start">
                AI-Powered Career Planning And Guidance
              </h1>
              <p className="schools-paragraph">
                Mentoring school & junior college students to achieve their
                aspirational careers goals
              </p>
            </div>
            <div className="col-4 w-100-768 p-0 d-flex justify-content-center mt-30-md">
              <img
                data-aos="zoom-in"
                className="schools-heading-img"
                src={SchoolsHeadingimg1}
                alt=""
              />
            </div>
          </section>
          <section className="row md:flex-row-reverse schools-section-container">
            <div className="col-8 w-100-768 d-flex flex-column justify-content-center">
              <p className="schools-paragraph">
                Student mentorship is a collaborative program with schools and
                junior schools assisting the students to select their
                recommended subjects & appropriate career path. The basis of
                these recommendations is a 5-Dimensional AI-Powered assessment
                report (scorecard).
              </p>
            </div>
            <div className="col-4 w-100-768 p-0 d-flex justify-content-center mt-30-md">
              <img
                data-aos="zoom-in"
                className="schools-heading-img"
                src={SchoolsHeadingimg2}
                alt=""
              />
            </div>
          </section>
          <section className="schools-section-container ">
            <div className="row">
              <h1 className="schools-mainheading">
                5-Dimensions
                <span className="schools-text-purple"> of the Report </span>
              </h1>
            </div>

            <SchoolSlider />
          </section>
          {/*
          <section className="schools-talent-outer-container">
            <div className="schools-talent-inner-container">
              <h1 className="schools-mainheading-bottom">
                Collaboration benefits with
                <span className="schools-text-orange"> VIOSA</span>
              </h1>
              <div className="row">
                <div className="col-2  slider-container d-flex justify-content-center">
                  <Slider
                    value={scrollTop}
                    orientation="vertical"
                    track="inverted"
                  />
                </div>
                <div className="col-10 ">
                  <ul
                    className="schools-unorderedlist"
                    id="scroller"
                    onScroll={onscroll}
                  >
                    <li> Free Career Counseling Training (worth 7000/-) </li>
                    <li>
                      Free Access to Career Library - 15+ Career sectors & 140+
                      career paths
                    </li>
                    <li>Free Workshops for students (Any 3) 40 mins each</li>
                    <ul className="list-scroll-school">
                      <li>Goal Setting</li>
                      <li>Managing Exam Stress</li>
                      <li>Time Management</li>
                      <li>Personality Development</li>
                      <li>Exam Memory Techniques</li>
                    </ul>
                  </ul>
                </div>
              </div>
              <p className="schools-paragraph text-center mt-12">
                Explore courses for school and junior schools
              </p>

              <div className="row d-flex justify-content-center me-4">
                <div
                  className="col-3 w-100-768"
                  onClick={() => {
                    setCourse("8-12 Student");
                    navigate("/course");
                  }}
                >
                  <button className="schools-btn schools-btn-org">
                    Explore Courses →
                  </button>
                </div>
              </div>
            </div>
          </section> */}
          <section className="schools-contact-container">
            {/* <div className="row schools-contact-box">
              <div className="col-5 w-100-768 justify-content-center align-items-center">
                <div className="mt-16 mt-0-768">
                  <h2 className="schools-contact-heading">
                    A Career Map to Your Successful Aspirational Career
                  </h2>
                  <h2 className="schools-contact-heading ">Now and Always.</h2>
                </div>
              </div>
              <div className="col-1 d-none-768"></div>
              <div className="col-6 w-100-768 d-flex flex-column ">
                <a
                  href="tel:022-4011-5448"
                  className="d-flex justify-content-center "
                >
                  <button className=" schools-btn schools-btn-white">
                    022-4011-5448
                  </button>
                </a>
                <a
                  href="https://wa.me/919987575606"
                  className="d-flex justify-content-center "
                >
                  <button className=" schools-btn schools-btn-white">
                    +91-9987575606
                  </button>
                </a>
                <a
                  href="mailto:mail@viosa.in"
                  className="d-flex justify-content-center "
                >
                  <button className="schools-btn schools-btn-white">
                    mail@viosa.in
                  </button>
                </a>
              </div>
            </div> */}
            <div>
              <div className="flex justify-center">
                <div className="row py-2">
                  <h1 className="colleges-mainheading">
                    Collaboration
                    <span className="colleges-text-purple"> Enquiry </span>
                  </h1>
                </div>
              </div>
              <form onSubmit={(e) => submitcollaborationform(e)}>
                <div className="flex justify-center">
                  <div>
                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6">
                        <img className="mx-4 for-opacity" src={people} alt="" />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="text"
                          name="name"
                          required
                          value={collaborationdata.name}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border  hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="email"
                          name="email"
                          required
                          value={collaborationdata.email}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your email"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={phoneCall}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="tel"
                          name="phone"
                          required
                          title="Please enter a valid phone number."
                          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                          value={collaborationdata.phone}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your phone"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={collabaration}
                          alt=""
                        />

                        <select
                          style={{ background: "#FFF7E7", opacity: "0.5" }}
                          className="pl-2 input-field"
                          name="schoolOrCollege"
                          required
                          onChange={handleChangecollaboration}
                        >
                          <option disabled selected>
                            Choose Collaboration
                          </option>
                          <option value="School">School</option>
                          <option value="College">College</option>
                        </select>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <input
                          style={{ background: "#FFF7E7", marginLeft: "76px" }}
                          className="pl-2 input-field"
                          type="text"
                          name="message"
                          required
                          value={collaborationdata.message}
                          onChange={handleChangecollaboration}
                          placeholder="Your Message"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 d-flex align-center justify-content-center">
                  <button
                    for="my-modal-1"
                    type="submit"
                    disabled={loading}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {loading ? 'Sending..."' : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </motion.div>

      {/* <FooterPurple /> */}
    </>
  );
};
export default Schools;
