import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./QuizQuestionCard.css";
const QuizQuestionCard = ({
  questiondata,
  selectedOption,
  onAnswer,
  handleNextQuestion,
}) => {
  console.log("questiondata", questiondata);
  return (
    <>
      <div
        className="p-3 questioncard"
        // id="q-1"
        id={`q-${questiondata.id}`}
      >
        <div className="questioncard-header  pb-0 d-flex justify-content-end">
          <span className="pull-right mr-3">[ {questiondata.marks} ]</span>
          <div className="dropdown">
            <button
              className="badge badge-pill  btn-hint dropdown-toggle py-0 px-1 pull-right"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-info-circle"></i> {questiondata.hint}
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-item">{questiondata.hint}</div>
            </div>
          </div>
        </div>

        <div className="card-body questioncard-body  pt-0">
          <div className="questioncard-title mb-0">
            <p className="mb-0">{questiondata.question}</p>
          </div>
          {questiondata.options.map((option, index) => {
            return (
              <div className="row " key={index}>
                <div className="col-12 py-2 qcard-item d-flex align-items-center">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input singleSelectionAnswer"
                      name={`question-${questiondata.id}`} // Unique name per question
                      checked={selectedOption === index} // Ensure the correct option is checked
                      onChange={() => onAnswer(index)} // Call onAnswer when an option is selected
                    />
                    <label className="form-check-label" for="">
                      {option}
                    </label>
                  </div>
                  {option.image && (
                    <img
                      src={
                        option.image ||
                        "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
                      }
                      height="40"
                      width="40"
                      alt="Option"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default QuizQuestionCard;
