import React, { useEffect } from "react";
import { useState } from "react";
import "./Blogs.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";
import PremiumCourseSlider from "components/PremiumCourseSlider";
import blogpageimage from "../../icons/blogpageimage.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { getAllCourse } from "Axios/apis";
import FooterPurple from "components/Footer/FooterPurple";

const Blogs = () => {
  AOS.init({ duration: 2000 });

  const [coursedata, setcoursedata] = useState([]);
  useEffect(() => {
    const getalldata = async () => {
      try {
        const data = await getAllCourse();
        setcoursedata(data?.data?.courses);
      } catch (error) {
        console.error(error);
      }
    };
    getalldata();
  }, []);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid">
          <div className="flex items-center justify-center py-5 they-said-background ">
            <div className="padd-40 blogs-head-res" style={{ width: "60%" }}>
              <p className="mb-2 investing-para">#TopicForTheDay</p>
              <h1 className="invest-heading">VIOSA Blogs</h1>
            </div>

            <div className="jobPoster lg:mr-24" style={{ width: "40%" }}>
              <img data-aos="zoom-in" src={blogpageimage} alt="" />
            </div>
          </div>
        </div>

        <div className="container-fluid  blogs-main-container">
          <section className="row blogs-section-container ">
            <h4 className="blogs-paragraph">Featured</h4>
            <div className="d-flex my-3">
              {coursedata.splice(0, 3).map((course, index) => (
                <PremiumCourseSlider
                  {...{
                    course,
                  }}
                />
              ))}
            </div>
          </section>
          <section className="row blogs-section-container mb-5 ">
            <h4 className="blogs-paragraph">Popular</h4>
            <div className="d-flex  my-3">
              {coursedata.splice(4, 3).map((course, index) => (
                <PremiumCourseSlider
                  {...{
                    course,
                  }}
                />
              ))}
            </div>
          </section>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};
export default Blogs;
