import React, { useState, useEffect } from "react";
import { Loader } from "components/Loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import {
  AddCoursefieldsintouser,
  GetBatchesFromCollegeId,
  batchbyId,
  collegebyId,
  fetchAllColleges,
  checkUserExistenceAPI,
  StudentSignUp,
  UpdateTeam,
  googlesheet,
  verifyCaptchaAPI,
} from "Axios/apis";
import { useLocation } from "react-router-dom"; // Import useLocation hook to access query parameters
import debounce from "lodash.debounce";
import { userDashBoard, UserLogin } from "../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";

function Modal({ isOpen, message, onClose }) {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          padding: "20px",
          background: "#fff",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <p>{message}</p>
        <button
          className="forHoverContact-button hover:shadow-2xl"
          onClick={onClose}
          style={{
            marginTop: "10px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#521986",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function MyForm() {
  const location = useLocation(); // Access location object to get query parameters
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search); // Parse query parameters
  const defaultCollegeId = queryParams.get("collegeid") || ""; // Extract collegeId from query parameters or set default value to empty string
  const [formData, setFormData] = useState({
    college: defaultCollegeId, // Set default college from query parameter
    batch: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    hireProRole: "student",
    className: "",
    rollNo: "",
  });
  const [colleges, setColleges] = useState([]);
  const [batches, setBatches] = useState([]);
  const [error, setError] = useState(""); // State to hold error message
  const [message, setMessage] = useState(""); // State to hold error message
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // State to track captcha verification
  const [disableCollegeDropdown, setDisableCollegeDropdown] = useState(false); // State to control the disabled state of the college dropdown
  const [collegeName, setCollegeName] = useState(""); // State to hold the college name
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Fetch college data when the component mounts
    fetchColleges();
  }, []);
  useEffect(() => {
    if (defaultCollegeId) {
      fetchBatches(defaultCollegeId);
      fetchCollegeName(defaultCollegeId); // Fetch the college name when defaultCollegeId is available
      setDisableCollegeDropdown(true); // Disable college dropdown if defaultCollegeId is provided
    }
  }, [defaultCollegeId]); // Fetch batches whenever defaultCollegeId changes
  const fetchColleges = async () => {
    try {
      // const response = await fetch(
      //   // "https://interview-api.viosa.in/auth/college/all"
      //   // "http://localhost:3200/interview-pro/auth/college/all"
      //   "https://api.viosa.in/interview-pro/auth/college/all"
      // );
      const response = await fetchAllColleges();
      // if (response.ok) {
      if (response.status === 200) {
        // const data = await response.json();
        // Filter colleges based on the status being true
        const filteredColleges = response.data.data.filter(
          (college) => college.status === true
        );
        setColleges(filteredColleges);
        // console.log("College Data", filteredColleges);
        // setColleges(data.data); // Assuming data is an array of colleges
        // console.log("College Data", data.data);
      } else {
        console.error("Failed to fetch colleges");
        // setMessage("Failed to fetch colleges.");
      }
    } catch (error) {
      console.error("Error fetching colleges:", error);
    }
  };

  const fetchCollegeName = async (collegeId) => {
    try {
      const response = await collegebyId(collegeId);
      console.log("College Name", response);
      if (response && response.data) {
        setCollegeName(response.data.data.collegename);
      } else {
        console.error("Failed to fetch college name");
      }
    } catch (error) {
      console.error("Error fetching college name:", error);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(true); // Set captcha verification state to true when user interacts with captcha
  };

  const handleCollegeChange = async (e) => {
    const collegeId = e.target.value; // Assuming value is the college ID
    // console.log("college Id", collegeId);
    try {
      // Update formData state after fetching batches
      setFormData((prevState) => ({
        ...prevState,
        college: collegeId,
        batch: "", // Reset batch when college changes
      }));
      // Fetch batches for the selected college
      const batchesResponse = await fetchBatches(collegeId);
      // console.log("Batches", batchesResponse);
      const batchid = batchesResponse._id;
      // console.log("Batches id", batchid);
    } catch (error) {
      console.error("Error handling college change:", error);
    }
  };

  const fetchBatches = async (collegeId) => {
    try {
      //   const response = await fetch(
      //     `https://interview-api.viosa.in/auth/college/teams/college/${collegeId}`
      //   );
      // console.log("college ID:", collegeId);
      const response = await GetBatchesFromCollegeId(collegeId);
      // console.log("Response:", response); // Log the response to see its structure
      if (response && response.data) {
        // If response is valid and contains data
        setBatches(response.data.data); // Set batches state with the data
        // console.log("Batches", response.data.data); // Log batches data
      } else {
        console.error("Failed to fetch batches");
      }
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  // Debounced function to check user existence
  const checkUserExistence = debounce(async () => {
    const batchid = await batchbyId(formData.batch);

    const collegeid = await collegebyId(formData.college);
    const formData2 = {
      email: formData.email,
      phone: formData.phone,
      batch: batchid.data.data.name,
      className: formData.className,
      firstName: formData.firstName,
      lastName: formData.lastName,
      rollNo: formData.rollNo,
      college: collegeid.data.data.collegename,
    };
    try {
      // const response = await fetch("https://api.viosa.in/api/check-user", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(formData2),
      // });
      const response = await checkUserExistenceAPI(formData2);
      console.log("Response", response);
      // const data = await response.json();
      setMessage(response.data.message);
      {
        response.data.message && setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error checking user:", error);
      setMessage("Network error, please try again later.");
    }
  }, 300); // Debounce for 300ms

  useEffect(() => {
    checkUserExistence();
  }, [
    formData.email,
    formData.phone,
    formData.batch,
    formData.className,
    formData.firstName,
    formData.lastName,
    formData.rollNo,
  ]); // Trigger the check on email or phone change

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isCaptchaVerified) {
      // Check if captcha is verified
      return; // Return early if captcha is not verified
    }
    setIsLoading(true); // Set loading state to true
    // captchaRef.current.reset();
    try {
      // Verify CAPTCHA token
      const captchaResponse = await verifyCaptcha(captchaValue);
      // console.log(captchaResponse);
      if (!captchaResponse.data.success) {
        // Display error message for CAPTCHA verification failure
        setMessage("CAPTCHA verification failed. Please try again.");
        setIsLoading(false); // Set loading state to false
        return;
      }
      const signUpData = {
        collegeid: formData.college,
        email: formData.email,
        firstname: formData.firstName,
        lastname: formData.lastName,
        password: formData.password,
        hireProRole: "student",
        teams: [formData.batch],
        phoneNumber: formData.phone,
      };
      // const response = await fetch(
      //   // "https://interview-api.viosa.in/auth/student/signup",
      //   // "http://localhost:3200/interview-pro/auth/student/signup",
      //   "https://api.viosa.in/interview-pro/auth/student/signup",
      //   // "https://viosa-interview-backend-2.onrender.com/auth/student/signup",
      //   // "http://localhost:4800/auth/student/signup",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       collegeid: formData.college, // Assuming collegeid is the key expected by the API
      //       email: formData.email,
      //       firstname: formData.firstName, // Using firstName from formData
      //       lastname: formData.lastName, // Using lastName from formData
      //       password: formData.password,
      //       hireProRole: "student",
      //       teams: [formData.batch],
      //       phoneNumber: formData.phone,
      //     }),
      //   }
      // );
      const response = await StudentSignUp(signUpData);
      console.log("Response", response);

      // const responseData = await response.json();
      // console.log("Response", responseData);
      setMessage(response.data.message); // Set error message state
      // console.log("Form", formData);
      dispatch(
        UserLogin({
          name: response.data?.data?.student.name,
          email: response.data?.data?.student.email,
          date: response.data?.data?.student.createdAt,
          courseList: response.data?.data?.student.course,
          loggedIn: true,
        })
      );
      localStorage.setItem("email", response.data?.data?.student.email);
      localStorage.setItem("phone", response.data?.data?.student.phoneNumber);
      localStorage.setItem("userName", response.data?.data?.student.name);
      localStorage.setItem("role", response.data?.data?.student.role);
      localStorage.setItem("token", response.data?.token);
      localStorage.setItem(
        "InterviewProRole",
        response.data?.data?.student?.hireProRole
      );
      localStorage.setItem("userId", response.data?.data?.student._id);
      dispatch(userDashBoard("profile"));
      // navigate(`/profile/catalogue`);

      // Assuming signUpData includes userId
      const courseToUserBody = {
        userId: response.data.data.student._id, // This depends on your API response structure
        collegeId: formData.college,
        teamId: formData.batch,
      };
      console.log("coursetouser", courseToUserBody);

      const courseToUserResponse = await AddCoursefieldsintouser(
        courseToUserBody
      );
      console.log("coursetouser", courseToUserResponse);

      // const courseToUserData = await courseToUserResponse.json();
      // if (!courseToUserResponse.ok) {
      //   throw new Error(
      //     courseToUserData.message || "Assigning course to user failed."
      //   );
      // }

      if (response.status === 201) {
        // Handle successful response
        // console.log(responseData);

        const batchid = await batchbyId(formData.batch);
        console.log("batchid", batchid);
        // console.log("batchid", batchid.data.data._id);

        const collegeid = await collegebyId(formData.college);
        console.log("collegeid", collegeid);
        // console.log("college id", collegeid.data.data.collegename);
        // Call updateteam API instead of addteammembers
        // const updateTeamResponse = await fetch(
        //   // "https://interview-api.viosa.in/auth/college/updateteam", // Update the endpoint URL
        //   "https://api.viosa.in/interview-pro/auth/college/updateteam", // Update the endpoint URL
        //   // "http://localhost:3200/interview-pro/auth/college/updateteam", // Update the endpoint URL
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       id: batchid.data.data._id, // Provide the ID of the college team
        //       updateBody: {
        //         newStudents: [responseData.data.student._id], // Assuming the student ID is returned in responseData
        //         // Add other necessary data for the updateteam API
        //       },
        //     }),
        //   }
        // );

        const updateteamdata = {
          id: batchid.data.data._id, // Provide the ID of the college team
          updateBody: {
            newStudents: [response.data.data.student._id], // Assuming the student ID is returned in responseData
          },
        };
        const updateTeamResponse = await UpdateTeam(updateteamdata);
        console.log("Update Team Response", updateteamdata);

        // const updateTeamData = await updateTeamResponse.json();
        // console.log("Team updated:", updateTeamData);

        if (updateTeamResponse.status === 200) {
          // Handle successful update
          // console.log("Team updated:", updateTeamData);
          // Submit data to the Google Sheets API
          // const googleSheetsResponse = await fetch(
          //   // "http://localhost:3200/api/submit-form",
          //   // "https://api.viosa.in/api/submit-form",
          //   "https://api.viosa.in/api/submit-form",
          //   {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({
          //       college: collegeid.data.data.collegename,
          //       batch: batchid.data.data.name,
          //       firstname: formData.firstName, // Using firstName from formData
          //       lastname: formData.lastName, // Using lastName from formData
          //       email: formData.email,
          //       phone: formData.phone,
          //       className: formData.className,
          //       rollNo: formData.rollNo,
          //     }),
          //   }
          // );
          const googleSheetsResponse = await googlesheet({
            college: collegeid.data.data.collegename,
            batch: batchid.data.data.name,
            firstname: formData.firstName, // Using firstName from formData
            lastname: formData.lastName, // Using lastName from formData
            email: formData.email,
            phone: formData.phone,
            className: formData.className,
            rollNo: formData.rollNo,
          });
          console.log("Google Sheets Response", googleSheetsResponse);

          if (googleSheetsResponse.ok) {
            setMessage("Form submitted successfully.");
            // const googleSheetsData = await googleSheetsResponse.json();
            // console.log("Data submitted to Google Sheets:", googleSheetsData);
          } else {
            // const googleSheetsError = await googleSheetsResponse.json();
            setMessage(
              "Error submitting data to Google Sheets: " +
                googleSheetsResponse.data.error
            );
            console.error(
              "Error submitting data to Google Sheets:",
              googleSheetsResponse.data.error
            );
          }
          setIsLoading(false); // Set loading state to false after submission

          // Optionally, you can redirect the user to a success page or show a success message
          // Redirect to https://viosa.in after successful submission
          window.location.href = "https://viosa.in/profile/catalogue";
          // window.location.href = "http://localhost:3000/profile/catalogue";
        } else {
          const updateTeamError = await updateTeamResponse.json();
          setMessage("Error updating team: " + updateTeamError.error);
          console.error("Error updating team:", updateTeamError.error);
          // Optionally, handle error response from updateteam API
        }
      } else {
        const signUpError = await response.json();
        setMessage("Error during signup: " + response.data.message);
        console.error("Error signing up:", signUpError.message);
        setError(signUpError.message); // Set error message state
        // Optionally, handle error response from sign up API
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Error submitting form: User already exists");
    }
    setIsLoading(false); // Set loading state to false in case of error
  };

  const verifyCaptcha = async (token) => {
    try {
      // const response = await axios.post("/verify-captcha", { token });
      // const response = await fetch("https://api.viosa.in/verify-captcha", {
      // const response = await fetch("https://api.viosa.in/verify-captcha", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     token: token,
      //   }),
      // });
      const response = await verifyCaptchaAPI(token);
      console.log(response);
      // const responseData = await response.json(); // Parse response body as JSON
      // console.log(responseData); // Log the parsed response data
      return response;
    } catch (error) {
      throw error;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      className="form"
      style={{
        padding: "20px",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "#000",
      }}
    >
      <h1 style={{ textAlign: "center", fontSize: "2rem", padding: "10px" }}>
        Students Registration Form
      </h1>
      {collegeName && (
        <h2
          style={{ textAlign: "center", fontSize: "1.5rem", padding: "10px" }}
        >
          College Name - {collegeName}
        </h2>
      )}
      {isLoading && <Loader />}
      {!isLoading && (
        <form
          onSubmit={handleSubmit}
          style={{
            width: "23em",
            padding: "2em",
          }}
        >
          <div
            className="form-group"
            style={{ marginBottom: "1em", display: "none" }}
          >
            <label htmlFor="college">College:</label>
            <select
              id="college"
              name="college"
              value={formData.college}
              onChange={handleCollegeChange}
              required
              disabled={disableCollegeDropdown} // Disable college dropdown based on state
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            >
              <option value="">Select College</option>
              {colleges.map((college) => (
                <option key={college._id} value={college._id}>
                  {college.collegename}
                </option>
              ))}
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="batch">Batch:</label>
            <select
              id="batch"
              name="batch"
              value={formData.batch}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            >
              <option value="">Select Batch</option>
              {batches.map((batch) => (
                <option key={batch._id} value={batch._id}>
                  {batch.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div> */}
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="className">Class/Div/Section:</label>
            <input
              type="text"
              id="className"
              name="className"
              value={formData.className}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="rollNo">Roll No:</label>
            <input
              type="text"
              id="rollNo"
              name="rollNo"
              value={formData.rollNo}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>

          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>

          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "1em" }}>
            <label htmlFor="confirm_password">Confirm Password:</label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
            />
          </div>

          {/* Add reCAPTCHA */}
          <ReCAPTCHA
            sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
            onChange={handleCaptchaChange}
            style={{
              margin: "0px 0px 10px 0px",
            }}
          />
          <button
            className="forHoverContact-button hover:shadow-2xl"
            type="submit"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#521986",
              color: "white",
              border: "none",
              cursor: "pointer",
              cursor: !isCaptchaVerified ? "not-allowed" : "pointer", // Disable button if captcha is not verified
            }}
            disabled={!isCaptchaVerified} // Disable button if captcha is not verified
          >
            Submit
          </button>
        </form>
      )}
      {/* Error message display */}
      {message && (
        <p
          style={{
            marginTop: "20px",
            color: message.includes("Error") ? "red" : "green",
          }}
        >
          {message}
        </p>
      )}
      <Modal isOpen={isModalOpen} message={message} onClose={closeModal} />
    </div>
  );
}

export default MyForm;
