import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import { EditProfile } from "../../../Axios/apis";
import ReactPhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

function PersonalDetailForm({
  setEditModalOpen,
  detailsupdated,
  setdetailsupdated,
  userDetails,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailerror, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const value = document?.querySelector("input[type=tel]")?.value || "-";
  const phoneRef = useRef(null);
  const submit = async (e) => {
    e.preventDefault();
    const value = document?.querySelector("input[type=tel]")?.value || "-";
    let mobileNo = value?.split("-").join("").split(" ").join("");
    const formData = {
      userId: localStorage.getItem("userId"),
      name: firstName || userDetails[0]?.result?.name,
      phoneNumber: mobileNo || userDetails[0]?.result?.phoneNumber,
      email: email || userDetails[0]?.result?.email,
    };
    // //console.log(formData);
    if (!emailerror && !nameError) {
      setLoading(true);
      try {
        const data = await EditProfile(formData);
        localStorage.setItem("userName", data?.data?.result?.name);
        // //console.log(data);
        setdetailsupdated(!detailsupdated);
        setLoading(false);
        setEditModalOpen(false);
      } catch (error) {
        // //console.log(error);
        setLoading(false);
        alert("Failed to update profile");
      }
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidName(name) {
    return /^[a-zA-Z ]+$/.test(name);
  }

  function isValidPhone(phone) {
    return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(
      phone
    );
  }

  function validProfile(profile) {
    return /^[a-zA-Z ]+$/.test(profile);
  }

  useEffect(() => {
    if (!isValidEmail(email || userDetails?.result[0]?.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (!isValidName(firstName || userDetails?.result[0]?.name)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, [firstName]);

  useEffect(() => {
    if (!validProfile(jobProfile || userDetails?.result[0]?.designation)) {
      setProfileError(true);
    } else {
      setProfileError(false);
    }
  }, [jobProfile]);

  return (
    <form>
      <div className="d-flex flex-row justify-content-between">
        <div className="col-12">
          <TextField
            error={nameError ? true : false}
            className="edit-profile-text-field mb-2"
            label="Full Name"
            variant="standard"
            helperText={nameError ? "Enter valid name" : ""}
            defaultValue={userDetails?.result[0]?.name}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField
            error={profileError ? true : false}
            className="edit-profile-text-field"
            label="Job Profile"
            variant="standard"
            helperText={profileError ? "Invalid Entry" : ""}
            defaultValue={userDetails?.result[0]?.designation}
            onChange={(e) => {
              setJobProfile(e.target.value);
            }}
          />
        </div>
      </div>
      <TextField
        error={emailerror ? true : false}
        className="edit-profile-text-field"
        label="Email"
        variant="standard"
        disabled
        defaultValue={userDetails?.result[0]?.email}
        helperText={emailerror ? "Invalid Entry" : ""}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <ReactPhoneInput
        country="in"
        ref={phoneRef}
        value={userDetails?.result[0]?.phoneNumber || ""}
        style={{ background: "#FFF7E7" }}
        // defaultValue={userDetails?.result[0]?.phoneNumber}
        enableSearch
        name="phoneNumber"
      />
      <div className="mt-3">
        <button
          onClick={submit}
          disabled={loading}
          style={{marginRight:"0.5rem"}}
          className="edit-profile-btn edit-profile-btn-orange col-lg-12 col-md-12 col-sm-12 "
        >
          {loading ? "Submitting..." : "Submit"}
        </button>

        <button
        className={ loading ? "edit-profile-btn edit-profile-btn-white col-lg-12 col-md-12 col-sm-12" : "edit-profile-btn edit-profile-btn-white col-lg-12 col-md-12 col-sm-12"}
        >
          Discard
        </button>

      </div>
    </form>
  );
}
export default PersonalDetailForm;
