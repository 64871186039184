import React, { useEffect } from "react";
import Investing from "./Investing";
import { motion } from "framer-motion";
import Testomonial from "./Testomonial";

const Home = ({
  setCourse,
  setBlur,
  setLogin,
  blur,
  setRegister,
  register1,
  login,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid">
          <Investing
            setCourse={setCourse}
            setBlur={setBlur}
            register1={register1}
            setRegister={setRegister}
            login={login}
            setLogin={setLogin}
            className={`${blur ? "all-blur" : ""}`}
          ></Investing>
          <Testomonial setRegister={setRegister} setLogin={setLogin} />
        </div>
      </motion.div>
    </>
  );
};

export default Home;
