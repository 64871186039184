import carrer2 from "../icons/carrer (2).png";
import carrer5 from "../icons/career5.png";
import carrer6 from "../icons/career6.png";
import carrer7 from "../icons/career7.png";
import spaceRover from "../components/assets/icon/Space Rover.svg";
import FiveD from "../components/assets/icon/5D.png";
import ACCURACY from "../components/assets/icon/ACCURACY.png";
import REPORT from "../components/assets/icon/REPORT-1.png";
import COUNSELLING from "../components/assets/icon/COUNSELLING.png";
import PREDICTOR from "../components/assets/icon/PREDICTOR.png";
import EXAM_CALANDER from "../components/assets/icon/EXAM_CALANDER.png";
import LANGUAGE from "../components/assets/icon/LANGUAGE.png";
import REVISION from "../components/assets/icon/REVISION.png";
import IndustryIcon from "../components/assets/icon/industry_icon.png";
import VideoResume from "../components/assets/icon/videoresume-1.png";
import Frontend from "../components/assets/icon/frontend.png";
import Backend from "../components/assets/icon/backend.png";
import AIResume from "../components/assets/icon/ai-resume1.png";
import library2 from "../components/assets/icon/library2.png";
import library from "../components/assets/icon/library.png";
import ResumePana from "../components/assets/icon/Resume-pana.png";
import AtsResume from "../components/assets/icon/ats-resume.png";
import DosDont from "../components/assets/icon/dos&dont.png";
import Importance from "../components/assets/icon/importance.png";
import JobIcon from "../components/assets/icon/job-icon.png";
import Network from "../components/assets/icon/network2.png";
import LiveInterview from "../components/assets/icon/live-interview.png";
import InterviewPractice from "../components/assets/icon/interview-practice.png";
import InterviewAnswer from "../components/assets/icon/interview-answer.png";
import BodyLanguage from "../components/assets/icon/body-language.png";
import PeachAnalysis from "../components/assets/icon/peach-analysis.png";
import JobSkill from "../components/assets/icon/job-skill.png";
import IdentifyingOpp from "../components/assets/icon/identifying-opp.png";
import Profile from "../components/assets/icon/profile.png";
import Email from "../components/assets/icon/email-rec.png";
import JobsMore from "../components/assets/icon/jobs-more.png";
import IconSearch from "../components/assets/icon/icon-seach.png";
import MultipleJob from "../components/assets/icon/multiple-job.png";
import EmailTemplates from "../components/assets/icon/email-templates.png";
import ApplicationProcess from "../components/assets/icon/application-process2.jpg";
import TrackSearch from "../components/assets/icon/track-search.png";

const CourseData = [
  {
    name: "Corporate Sector Learning",
    desc: "Learn about various sectors in the corporate world, their job opportunities, pay structures, and how to align them with your aspirational professional career ",
    time: "240 minutes (2 sessions)",
    timeMonth: "",
    text1: "You Will Learn & Gain Expertise on Various Sectors",
    text2: "Identify the Industry & Sector Of Your Interest",
    text3: "Course duration: 240 minutes (2 sessions)",
    featurecarddata: [
      {
        icon: IndustryIcon,
        heading: `Banking Financial Services & Insurance `,
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: FiveD,
        heading: "Learn Sector Based Competencies",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: VideoResume,
        heading: "4 Hours of self-paced learning content",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: REVISION,
        heading: "30 days of revision access",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Know the Sectors",
      "Understand Job Opportunities",
      "Identify Your Sector & Job",
      "Kick Start your Professional Journey",
    ],
    text4: [
      "- Recorded Sessions",
      "- Learn Sector-Based Competencies",
      "- 240 min modules ",
      "- 30 days access for a revision session",
    ],
    text5: [
      {
        head: "",
        para: "Corporate Learning is focused on helping individuals find the right pathway towards a professional career. At VIOSA the individuals receive coaching from industry experts & professional coaches that provide guidance by understanding an individual’s strengths, weaknesses, learning patterns, and career of interest.",
        para2:
          "A brief description of the Growing Corporate Sectors [BFSI, FMCG, ITES, TTH. Healthcare, Media & Entertainment and More] being catered by VIOSA Learning. In addition a glimpse of various career opportunities available in each Sector with frontend and backend job roles.",
        list: [],
      },
    ],
    text6: "How will Corporate Learning help you?",
    text7: [
      {
        iconImg: carrer2,
        head: "Sector Knowledge",
        para: "Learn about sector and respective competencies to achieve your aspirational professional roles",
      },
      {
        iconImg: carrer5,
        head: "Functional Understanding",
        para: "Employees who possess frontend/backend skills tend to be more successful in professional careers at an early stage",
      },
      {
        iconImg: carrer6,
        head: "Industry Trends",
        para: "Learn about latest trends in your choice of industry and government initiative for top 5 growing sectors",
      },
    ],
  },
  {
    name: "Softskill Competency Coaching",
    desc: "Job specific knowledge, skills, attributes, and behaviors that enable you to be successful in your aspirational roles.",
    time: "240 Minutes ( 4 Sessions )",
    timeMonth: "",
    text1: "You will be equipped with role specific competencies",
    text2: "Gain edge in your professional career",
    text3: "Course duration: 240 minutes (2 sessions)",
    featurecarddata: [
      {
        icon: Frontend,
        heading: "Front-End Job Competencies",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: Backend,
        heading: "Back-End Job Competencies",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: VideoResume,
        heading: "4 Hours of Self Paced Learning Content",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: REVISION,
        heading: "30 Days of Revision Access",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Know the Competencies ",
      "Identify Your competencies",
      "Set your Goals",
      "Upskill Yourself",
    ],
    text4: [
      "- Recorded Sessions",
      "- Learn Sector-Based Competencies",
      "- 240 min modules ",
      "- 30 days access for a revision session",
    ],
    text5: [
      {
        head: "",
        para: "",
        para2: "",
        list: [
          `"With competition for jobs on the rise, a person’s continuing employability and career success are increasingly defined by his or her mastery of individual career development and the ability to effectively "manage oneself" (P. Drucker)`,
          "Softskill Competency Coaching is directed towards acquiring required competencies to perform a specific job role as per specified standards under certain conditions. Being able to showcase these competencies is the first step in bringing individuals to a level of expertise that creates a competitive advantage.",
          "A detailed description of the various job roles available in the market. An introduction to frontend and backend jobs. And most importantly the different competencies that are useful for each of these job roles.",
        ],
      },
      {
        head: "",
        para: "",
        para2: "",
        list: [],
      },
    ],
    text6: "What you'll learn?",
    text7: [
      {
        iconImg: carrer2,
        head: "Job-based Competencies",
        para: "Learn job-specific competencies to achieve your aspirational professional roles",
      },
      {
        iconImg: carrer5,
        head: "Frontend & Backend Roles",
        para: "Employees who possess roles based skills tend to be more successful in their professional careers at an early stage",
      },
      {
        iconImg: carrer6,
        head: "Skill Management",
        para: "Master the practice of understanding, developing, and deploying functional skills that the job role requires",
      },
    ],
  },
  {
    name: "Digital Profiling & Professional Networking",
    desc: "Enhance your Digital Profile & Build your Networking Skills",
    time: "120 Minutes (2 Sessions)",
    timeMonth: "30 days Access",
    text1: "Enhance Your Digital Profile & Improve Your Professional Network",
    text2: "",
    text3: "Course duration: 240 minutes (2 sessions)",
    featurecarddata: [
      {
        icon: Profile,
        heading: "LinkedIn Profile Best Practices",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: JobIcon,
        heading: "Instagram and Twitter for Professional Career",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: Network,
        heading: "Effective Networking Skills",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: IdentifyingOpp,
        heading: "Identifying Opportunities through Networking",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Understand Smart Profiling",
      "Learn To Create Your Profile",
      "Learn Networking Skills",
      "Create Your Online Presence",
    ],
    text4: [
      "- Module on Digital Profiling",
      "- Module on Professional Networking",
    ],
    text5: [
      {
        head: "",
        para: "Professionals need to be able to enrich their profiles and network with the right corporate connections. Finding the right connection is essential at every stage and is a vital part of professional success. Digital Profiling & Professional Networking helps you build your smart professional profile and we make it easy for you to understand the skills required for expanding your professional network. This course provides you with an understanding to search for appropriate work or business opportunity.",
        para2: "",
        list: [],
      },
      {
        head: "Key examples include:",
        para: "",
        para2: "",
        list: [
          "Sales leaders use digital profiling to build relationships, identify leads and grow business",
          "Talent acquisition professionals rely on digital profiles to source talent",
          "Marketing professionals use digital profiling to create content and reach decision-makers",
          "Executives use digital profiling to grow the reputation or brand of their company",
          "HR professionals use digital profiling to communicate and improve DE&I",
          "760M people use digital profiling to find meaningful work",
        ],
      },
    ],
    text6: "Why is Digital Profiling & Professional Networking a must?",
    text7: [
      {
        iconImg: carrer2,
        head: "Personal & Corporate Branding",
        para: "Create your digital presence with proven techniques",
      },
      {
        iconImg: carrer5,
        head: "Career Transition, and Networking",
        para: "Learn the art of networking with professionals in your chosen industry",
      },
      {
        iconImg: carrer6,
        head: "Social Media",
        para: "Leverage the power of social media and its global reach",
      },
      {
        iconImg: carrer7,
        head: "Online presence",
        para: "Make yourself reachable to prospect employers",
      },
    ],
  },
  {
    name: "Automated Resume Builder",
    desc: "AI-Powered Automatic Resume Builder",
    time: "120 Minutes (2 Sessions)",
    timeMonth: "2 Months of Access",
    text1: "Intelligent Resume & Cover Letter Builder",
    text2: "",
    text3: "Course duration: 120 minutes (3 sessions) + Resume Builder Access ",
    featurecarddata: [
      {
        icon: AtsResume,
        heading: "Coaching On ATS Resume Writing",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: AIResume,
        heading: "AI-Powered Resume",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: ResumePana,
        heading: "AI-Powered Cover Letter",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: library,
        heading: "AI-Powered Resume Analysis ",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Enter Your Details",
      "Use AI- Writer",
      "Select Template",
      "Download your Resume & Cover Letter",
    ],
    faq: [
      {
        headQuestion: "What is ATS?",
        paraAnswer: `ATS stands for "Applicant Tracking System". It is designed to automate the initial stages of the
hiring process by collecting, sorting, scanning, and ranking resumes by specific keywords,
qualifications, and experience that match the job requirements set by the employer. ATS
systems help recruiters and hiring managers efficiently filter through a large volume of
resumes, allowing them to focus on the most qualified candidates.`,
      },
      {
        headQuestion:
          "Is Resumatic AI suitable for all career levels and industries?",
        paraAnswer: `Yes, AI Resume tool offers a diverse range of customisable templates and resources suitable
for individuals at all career levels and across various industries. Whether you're a fresher or an
experienced professional, our platform is designed to cater to your unique career goals.
`,
      },
      {
        headQuestion:
          "How does Resumatic AI ensure that the generated resumes are ATS-optimised?",
        paraAnswer: `Resumatic AI's intelligent algorithm identifies industry-specific keywords and phrases, ensuring
that generated resumes are ATS-optimised. By incorporating these keywords strategically, our
platform enhances the visibility of resumes, increasing the likelihood of getting past applicant
tracking systems!
`,
      },
      {
        headQuestion:
          "Is my personal information secure when using Resumatic AI?",
        paraAnswer: `Protecting our users' privacy and security is a top priority. VIOSA follows stringent security
protocols to safeguard personal and professional information entered into the platform. We
utilise advanced security measures to ensure the confidentiality of all users' data.`,
      },
      // {
      //   headQuestion: "What are AI Credits?",
      //   paraAnswer: `Resumatic AI Writer is powered by GPT-3 API from OpenAI. This means, with the content you generate, we're "buying" the usage of their API.`,
      // },
      {
        headQuestion: "Where do I use Resumatic AI Writer?",
        paraAnswer: `Resumatic can be used to write and edit an ATS compliant resume and cover letter. ATS compliant software is used by the corporates to filter out the necessary resumes for the respective job roles`,
      },
    ],
    text4: [
      "- Demo to create AI-powered resume",
      "- Coaching on resume skills",
      "- ATS-Compliant resume",
      "- Cover letter",
    ],
    text5: [
      {
        head: "Build a Professional Resume in Minutes with Resumatic AI",
        para: "Bid farewell to the days of spending hours manually formatting and designing your resume. Whether you're a recent graduate, a seasoned professional, or changing careers, ResuMatic AI is here to streamline the resume-building process and help you land your dream job.",
        para2:
          "Our innovative tool combines artificial intelligence with user-friendly features to create standout professional resumes. ResuMatic AI helps you make a resume easily, allowing you to focus on other aspects of your job search. We understand that getting your resume past applicant tracking systems (ATS) is crucial. Our Resume writing tool uses AI algorithms to identify relevant keywords and phrases that recruiters are looking for, increasing the visibility of your resume and maximizing your chances of landing an interview",
        list: [],
      },
      {
        head: "Features of Resumatic AI",
        para: "The outcome for you using an automated resume builder is the creation of well-structures, visually appealing resumes that effectively highlight your qualifications and experiences. This saves you time and effort in crafting resumes from scratch while ensuring your resumes adhere to professional standards. Using an automated resume builder streamlines the resume creation process, enhances your presentation of your skills and achievements, and increases your chances of landing interviews and securing desired opportunities.",
        para2:
          "Whether you're leveling up, transitioning your career, or unexpectedly unemployed, this module will help you craft a resume & cover letter which is job-specific and AI-enabled.",
        list: [
          "AI-powered technology for quick and efficient resume-building",
          "Multiple template options and resources for tailored resumes",
          "ATS optimisation and keyword targeting",
          "Professional resume that will impress potential employers",
        ],
      },
    ],
    text6: "Why is our AI-powered ResuMatic the best choice?",
    text7: [
      {
        iconImg: carrer2,
        head: "ATS Keyword Targeting",
        para: "Instantly improve your chances of being selected for an interview by using the targeted keywords identified by our AI-enabled Technology Platform.",
      },
      {
        iconImg: carrer5,
        head: "Real-Time Content Analysis",
        para: "Our AI-enabled Technology Platform instantly identifies common content errors such as missing bullet points, buzz words, useful content, and more.",
      },
      {
        iconImg: carrer6,
        head: " An Automated Resume Review",
        para: "Helps you understand how well you have created your resume across 23 criteria points, further suggesting appropriate areas of improvement.",
      },
    ],
  },
  {
    name: "Elevator Pitch & Group Discussions",
    desc: "Learn how to create Resume in Video format and make an impactful impression on employers.",
    time: "90 Minutes (2 Sessions)",
    timeMonth: "30 days Access",
    text1: "Enables You To Prepare An Impactful Video Resume",
    text2: "",
    text3: "Course duration: 90 minutes (1 session) ",
    featurecarddata: [
      {
        icon: Importance,
        heading: "Importance of Elevator Pitch",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: AtsResume,
        heading: "Sample Video",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: DosDont,
        heading: "Do’s & Don’ts",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: REVISION,
        heading: "30 Days of Access",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Learn VIDEO Profiling",
      "Practice the Script",
      "Create Your Video Resume",
      "Impress Employers",
    ],
    text4: [
      "- Recorded sessions",
      "- 90 Minutes modules",
      "- Learn how to create your video profiling",
    ],
    text5: [
      {
        head: "",
        para: "Video resumes are becoming a requirement by many companies, and yet it's still an approach that very few job seekers fully embrace.",
        para2: "",
        list: [],
      },
      {
        head: "Explore the untapped possibilities of a elevator pitch.",
        para: "Find out more about the value of video as a resume format and why it’s quickly becoming a new norm. Learn how to plan, film, edit, submit and feature your elevator pitch. Work with Madeline to develop an action plan and commit to it in your job-hunting process.",
        para2: "",
        list: [],
      },
    ],
    text6: "Why is VdO Resume a must?",
    text7: [
      {
        iconImg: carrer2,
        head: "Stand out from the crowd",
        para: "Create a unique impression with our video profile coaching",
      },
      {
        iconImg: carrer5,
        head: "Showcase your creativity",
        para: "Impress your employer with learnt resume creation skills",
      },
      {
        iconImg: carrer6,
        head: "Display your personality",
        para: "Learn to show your confident with the step by step process",
      },
      {
        iconImg: carrer7,
        head: "Demonstrate particular skills",
        para: "Get the opportunities to implement elevator pitch profiling",
      },
    ],
  },
  {
    name: "Interview Skill Training",
    desc: "Ace the Interview, Get the Job",
    time: "120 Minutes (2 Sessions) of Coaching",
    timeMonth: "2 Months Access",
    text1:
      "Get an assessment of how you perform in interviews & check your scores to improve your performance.",
    text2: "",
    text3:
      "Course duration: 120 minutes (2 sessions) + Mock Interview Tool Access",
    text4: [
      "- Demo to take AI-powered interview",
      "- AI-Powered mock interview",
      "- Report on your interview evaluation",
      "- AI-Based technical and soft skills evaluation",
    ],
    faq: [
      {
        headQuestion: "For what roles should I use InterviewPro?",
        paraAnswer:
          "InterviewPro assesses candidates for soft skills such as communication skills, friendliness, and energy levels which are ideal for customer-facing roles. But these are also key skills to thrive in any working environment, so you can choose to interview candidates for all roles.",
      },
      {
        headQuestion:
          "How long does it take for video interviews to be assessed by AI?",
        paraAnswer:
          "Depending on the number of video interviews being assessed, InterviewPro will take about 15-30 minutes to complete the assessment and reflect the results on the dashboard.",
      },
      {
        headQuestion: "What skills do you measure?",
        paraAnswer:
          "We stack rank candidates based on experience levels, academic qualifications, and skills through the resume. We also use a questionnaire to measure skills like teamwork, leadership, initiative, etc. through a Skills analysis; as well as professionalism, sociability, and communication skills from the video interview.",
      },
      {
        headQuestion: " How many interviews can be taken?",
        paraAnswer: "The candidate can take multiple interviews.",
      },
    ],
    featurecarddata: [
      {
        icon: FiveD,
        heading: "Interview Skill Training",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: JobIcon,
        heading: "Mock Interview Practice for various Job Profiles",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: LiveInterview,
        heading: "Live Interview Performance Report",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: InterviewPractice,
        heading: "Unlimited Interview Practice",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: InterviewAnswer,
        heading: "Suggested Answers",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: BodyLanguage,
        heading: "Facial & Body Language Analysis",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: PeachAnalysis,
        heading: "Sentiment and Pitch Analysis",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: JobSkill,
        heading: "Job Functional Skill Mapping",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Select Job Profile",
      "Test Your Job Skills",
      "Practice your Mock Interview",
      "Get a Detailed Analysis of Your Interview",
    ],
    text5: [
      {
        head: "Benefits of InterviewPro",
        para: "Interview skills training involves equipping you with the necessary knowledge and skills to excel in job interviews. Through this training, you learn techniques for effective interview preparation, including researching the company and practicing common interview questions. You gain insight into non-verbal communication, such as body language and professional appearance, to create a positive impression. Additionally, you develop strong communication skills, including active listening and effective verbal responses, to articulate your qualifications and demonstrate your fit for the role.",
        // para: "Whether you're leveling up, transitioning your career, or unexpectedly unemployed, this module will help you boost up your interview skills while sharing feedback and areas of improvement.",
        para2: "",
        list: [],
      },
      {
        head: "Why Choose InterviewPro",
        para: "The outcome for you in interview skills training is increased confidence, improved interview performance, and higher chances of securing job offers. This proficiency empowers you to effectively showcase your skills, experiences, and potential, enabling you to make a positive impact during interviews and successfully transition into your desired careers.",
        // para: "Whether you're leveling up, transitioning your career, or unexpectedly unemployed, this module will help you boost up your interview skills while sharing feedback and areas of improvement.",
        para2: "",
        list: [],
      },
    ],
    text6: "Why is Interview Pro a must?",
    text7: [
      {
        iconImg: carrer2,
        head: "Real time interview question practice",
        para: "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        iconImg: carrer5,
        head: "AI for higher Accuracy and reliability",
        para: "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        iconImg: carrer6,
        head: "Question Bank",
        para: "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
    ],
  },
  {
    name: "Job Identification & Application",
    desc: "One Click For Multiple Job Applications",
    time: "120 Minutes (2 Sessions)",

    text1: "Save time searching endlessly on various job sites.",
    text2:
      "Get your personalised job recommendations & apply automatically. Track your applications with a personalised dashboard.",
    text3: "Course duration: 120 minutes ",
    text4: [
      "- Demo to create automated job loop",
      "- Coaching on job application skills",
      "- Automate your emails",
      "- Automate your applications",
    ],
    featurecarddata: [
      {
        icon: JobIcon,
        heading: "Auto Apply to Jobs",
        subheading:
          "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
      },
      {
        icon: Email,
        heading: "Auto Email to Recruiters",
        subheading:
          "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
      },
      {
        icon: JobsMore,
        heading: "Jobs from Shine, Naukri, Indeed, LinkedIn and few more",
        subheading:
          "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
      },
      {
        icon: IconSearch,
        heading: "Customize your Search and get Results!",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: MultipleJob,
        heading: "Multiple job Role Search",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: EmailTemplates,
        heading: "Email Templates",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: ApplicationProcess,
        heading: "Track Your Application Process",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
      {
        icon: TrackSearch,
        heading: "Track Metrics and Improve your Job Search",
        subheading:
          "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
      },
    ],
    timelinedata: [
      "Know the Sectors ",
      "Understand Job Opportunities ",
      "Identify Your Sector & Job",
      "Kick Start your Professional Journey",
    ],
    text5: [
      {
        head: "Save time searching endlessly on various job sites.",
        para: "Get your personalised job recommendations & apply automatically. Track your applications with a personalised dashboard.",
        para2: "",
        list: [],
      },
    ],
    text6: "",
    text7: [
      {
        iconImg: carrer2,
        head: "All jobs in one place",
        para: "Instantly improve your chances of being selected for an interview by using the targeted keywords identified by our AI-enabled Technology Platform.",
      },
      {
        iconImg: carrer5,
        head: "Automatically apply to jobs",
        para: " Our AI-enabled Technology Platform instantly identifies common content errors such as missing bullet points, buzz words, useful content, and more.",
      },
      {
        iconImg: carrer6,
        head: " Find statistics and get improved",
        para: "Our AI technology collects your statistics such as how many companies opened your email, how many companies replied, and which of your CVs got more attention.",
      },
    ],
    faq: [
      {
        headQuestion: "What is a Loop?",
        paraAnswer: `The first thing that you will learn in our platform is that we call a "Loop" a single job search.

        The reason why we call it a Loop, is because it works automatically on repeated cycles.

        Which means that we collect jobs automatically every 2 hours for your specified job title and location.We call this process "Looping" and the single job search that we perform a single "Loop".

        A Loop starts when we search a platform and ends when we apply to all the positions that match your profile.

        If the "automated email and application" selections are enabled in your Loop(as you can see in the image below) we will repeat the fully automated process of searching for relevant opportunities and applying on your behalf(either sending emails to companies or applying directly to the job application forms)`,
      },
      {
        headQuestion: "Is there any limit on the number of applications?",
        paraAnswer:
          "There is no limitation in the number of emails that you can create or the number of emails you can send or applications that you will submit.",
      },
      {
        headQuestion: "What does unlimited application mean?",
        paraAnswer: `And here is where most people get confused. Job Eazy every day collects jobs from multiple platform with a single goal. To find the ones that match your profile before doing any other action.

        So let's say that we collect 2000 jobs with the job title that you provide (i.e "Software Engineer") and we find that only 150 out of the 2000 are a good match with your profile. This means that in this case we will only start working with the 150. In an ideal world where your CV is perfect and Unlimited jobs could exist, then Job eazy would be able to send millions of emails.

        But for sure that's not the reality. Every day there is an upper limit of jobs posted by companies and there is also a limit on the number of jobs that we believe are related (using our machine learning models) to your profile.`,
      },
      {
        headQuestion: "What is a variable in a email template?",
        paraAnswer: `Variables ( for example {{COMPANY_NAME}} ) are ways to dynamicaly insert information about a specific job in your email template. In this way the email is personalised for each specific job posting. In the current versions the available variables are:
        {{ COMPANY_NAME }}
        {{ JOB_TITLE }}
        {{ JOB_LOCATION }}
        {{ JOB_URL }}
        {{ USER_FIRSTNAME }}
        {{ USER_LASTNAME }}
        You can use those variables in any place inside your email.
         `,
      },
      {
        headQuestion: "How can I see my daily results?",
        paraAnswer: `To see your daily job applications and email sent, you can either go in your overview tab or check each loop’s (each job search) results.
        Every loop contains information regarding the job postings matched with your profile, the emails that you sent and additional details that help you further improve your CV.`,
      },
    ],
  },
];

export default CourseData;
