import React, { useState } from "react";
import selected from "./../assets/icon/Polygon 7.svg";
import notSelected from "./../assets/icon/Polygon 6.svg";
import curve from "./../assets/icon/Rectangle 154.svg";
import MyAsked from "./MyAsked";

const FrequentlyAsked = ({ faq }) => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);
  const handleQuestion1 = () => {
    if (!question1) {
      setQuestion1(true);
    } else {
      setQuestion1(false);
    }
  };
  const handleQuestion2 = () => {
    if (!question2) {
      setQuestion2(true);
    } else {
      setQuestion2(false);
    }
  };
  const handleQuestion3 = () => {
    if (!question3) {
      setQuestion3(true);
    } else {
      setQuestion3(false);
    }
  };
  const handleQuestion4 = () => {
    if (!question4) {
      setQuestion4(true);
    } else {
      setQuestion4(false);
    }
  };
  const handleQuestion5 = () => {
    if (!question5) {
      setQuestion5(true);
    } else {
      setQuestion5(false);
    }
  };

  const arr = [
    {
      id: 1,
      headQuestion: " What is five dimensional career assessment?",
      paraanser:
        "Our career assessment explores the 5-Dimensions of the candidate.",
    },
    {
      id: 2,
      headQuestion: " What is five dimensional career assessment?",
      paraanser:
        "Our career assessment explores the 5-Dimensions of the candidate.",
    },
  ];

  return (
    <>
      <div className="mb-12">
        <h2 className="fad-head mb-4">FAQ's</h2>
        {faq?.map((data) => {
          return <MyAsked {...data} />;
        })}
        {/* <div className="w-full my-2 question-background">
          <div onClick={handleQuestion2} className="flex items-center h-16 ques-res">
            {question2 && <img className="h-16" src={curve} alt="" />}
            {question2 ? (
              <img className="w-4 mr-4 ml-4" src={notSelected} alt="" />
            ) : (
              <img className="w-4 mr-4" src={selected} alt="" />
            )}
            <h1 className="question">
              What is five dimensional career assessment?
            </h1>
          </div>
          <p className={`${question2 ? "blocked" : "hide"}`}>
            Our career assessment explores the 5-Dimensions of the candidate. It
            includes Personality analysis, Career Interest analysis, Career
            motivators analysis, learning style analysis and Skills & Abilities
            analysis.
          </p>
        </div>
        <div className="w-full question-background">
          <div onClick={handleQuestion3} className="flex items-center h-16 ques-res">
            {question3 && <img className="h-16" src={curve} alt="" />}
            {question3 ? (
              <img className="w-4 mr-4 ml-4" src={notSelected} alt="" />
            ) : (
              <img className="w-4 mr-4" src={selected} alt="" />
            )}
            <h1 className="question">
              What is the difference between Free Suitability and Comprehensive
              Analysis?
            </h1>
          </div>
          <p className={`${question3 ? "blocked" : "hide"}`}>
            Both are 5-Dimensional career assessment. In Free Suitability,
            candidate can get analysis of one career path of their choice with
            free 3 pages basic report. In Comprehensive plan, career analysis will
            be done across 160+ career paths and 2000+ Occupations to find out the
            best career path for you. Comprehensive 28+ pages career report with
            execution plan will help you to achieve career goals.
          </p>
        </div>
        <div className="w-full my-2 question-background">
          <div onClick={handleQuestion4} className="flex items-center h-16 ques-res">
            {question4 && <img className="h-16" src={curve} alt="" />}
            {question4 ? (
              <img className="w-4 mr-4 ml-4" src={notSelected} alt="" />
            ) : (
              <img className="w-4 mr-4 " src={selected} alt="" />
            )}
            <h1 className="question">
              Once Comprehensive report is activated, can I see analysis other
              career paths as well?
            </h1>
          </div>
          <p className={`${question4 ? "blocked" : "hide"}`}>
            Yes, very much. This is the biggest advantage every student is having.
            Once assessment is done student can generate unlimited Comprehensive
            28+ pages Comprehensive reports of different career paths. It is
            important to know you most preferred career paths but equally it is
            important to know about career paths that you should avoid or develop.
          </p>
        </div>
        <div className="w-full question-background">
          <div onClick={handleQuestion5} className="flex items-center h-16 ques-res">
            {question5 && <img className="h-16" src={curve} alt="" />}
            {question5 ? (
              <img className="w-4 mr-4 ml-4" src={notSelected} alt="" />
            ) : (
              <img className="w-4 mr-4" src={selected} alt="" />
            )}
            <h1 className="question">
              Do I need to give assessment once again for Comprehensive analysis?
            </h1>
          </div>
          <p className={`${question5 ? "blocked" : "hide"}`}>
            No, you do not need to give assessment again. Once assessment is done,
            you can upgrade any time. System will automatically analyze all career
            paths and generate Comprehensive report. Our powerful Artificial
            intelligence engine will unlock all the career path analysis.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default FrequentlyAsked;
