import catalogueicon from "../../icons/catalogue-icon.svg";
import dashboardicon from "../../icons/sidebar-logo (2).svg";
import achievementsicon from "../../icons/achivements.svg";
import myCourses from "../../icons/myCourses.svg";
import live from "../../icons/live.svg";

export const StudentProfile = [
  {
    id: 2,
    name: "All Courses",
    link: "catalogue",
    icon: catalogueicon,
  },
  {
    id: 1,
    name: "My Courses",
    link: "dashboard",
    icon: myCourses,
  },
  {
    id: 3,
    name: "Career Assessment",
    link: "career-assessment-test",
    icon: live,
  },
  // {
  //   id: 3,
  //   name: "Live Sessions",
  //   link: "webinars",
  //   icon: live,
  // },
  {
    id: 4,
    name: "Achievements",
    link: "achievements",
    icon: achievementsicon,
  },
];

export const collegeProfile = [
  {
    id: 1,
    name: "Dashboard",
    link: "college",
    icon: catalogueicon,
  },
];

export const professionProfile = [
  {
    id: 1,
    name: "Dashboard",
    link: "catalogue",
    icon: catalogueicon,
  },
  {
    id: 2,
    name: "Jobs",
    link: "jobs",
    icon: dashboardicon,
  },
  {
    id: 3,
    name: "Inbox",
    link: "inbox",
    icon: achievementsicon,
  },
];
export const corporateProfile = [
  {
    id: 1,
    name: "Dashboard",
    link: "catalogue",
    icon: catalogueicon,
  },
  {
    id: 2,
    name: "Students",
    link: "jobs",
    icon: dashboardicon,
  },
  {
    id: 3,
    name: "Inbox",
    link: "inbox",
    icon: catalogueicon,
  },
];
