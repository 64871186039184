import FooterPurple from "components/Footer/FooterPurple";
import React, { useEffect } from "react";

export const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div class="is-box-centered container-xxl px-5">
        <div
          class="is-container is-builder container-fluid is-content-1200"
          id="contentareaaLQs9"
        >
          <div class="row" data-type="staticsnippet" data-num="8">
            <div class="col-md-12 pb-3">
              <h1 class="size-48 is-title1-48 is-title-bold">
                Terms and Conditions
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b className="fw-bold">Privacy Policy</b>
              </p>
              <p class="size-14 pb-5">
                We value your trust. In order to honor that trust, VIOSA adheres
                to ethical standards in gathering, using, and safeguarding any
                information you provide. Abaven EduTech Private Limited
                (operating under the brand name VIOSA), is a leading edtech
                company, incorporated in India, for imparting learning through
                Webinars and subscription based learning modules. This privacy
                policy governs your use of the application “VIOSA” (Mobile
                Application), www.VIOSA.in ('Website') and the other associated
                applications, products, websites and services managed by the
                Company. Please read this privacy policy ('Policy') carefully
                before using the Application, Website, our services and
                products, along with the Terms of Use ('ToU') provided on the
                Application and the Website. Your use of the Website,
                Application, or services in connection with the Application,
                Website or products ('Services'), or registrations with us
                through any modes or usage of any products including through SD
                cards, tablets or other storage/transmitting device shall
                signify your acceptance of this Policy and your agreement to be
                legally bound by the same. If you do not agree with the terms of
                this Policy, do not use the Website, Application our products or
                avail any of our Services.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>User Provided Information</b>
              </p>
              <p class="size-14 pb-5">
                The Application/Website/Services/products obtains the
                information you provide when you download and register for the
                Application or Services or products. When you register with us,
                you generally provide (a) your name, age, email address,
                location, phone number, password and your professional courses
                learning interests; and any other information provided while
                registration or usage of website/Application (b)
                transaction-related information, such as when you make
                purchases, respond to any offers, or download or use
                applications from us; (c) information you provide us when you
                contact us for help; (d) information you enter into our system
                when using the Application/Services/products, such as while
                asking doubts, participating in discussions and taking tests as
                and when applicable. The said information collected from the
                users could be categorized as “Personal Information”, “Sensitive
                Personal Information” and “Associated Information”. Personal
                Information, Sensitive Personal Information and Associated
                Information (each as individually defined under this Information
                Technology (Reasonable security practices and procedures and
                sensitive personal data or information) Rules, 2011 (the “Data
                Protection Rules”)) shall collectively be referred to as
                'Information' in this Policy. We may use the Information to
                contact you from time to time, to provide you with the Services,
                important information, required notices and marketing
                promotions. We will ask you when we need more information that
                personally identifies you (personal information) or allows us to
                contact you. We will not differentiate between who is using the
                device to access the Application, Website or Services or
                products, so long as the log in/access credentials match with
                yours. In order to make the best use of the
                Application/Website/Services/products and enable your
                Information to be captured accurately on the
                Application/Website/Services/products, it is essential that you
                have logged in using your own credentials. We will, at all
                times, provide the option to you to not provide the Personal
                Information or Sensitive Personal Information, which we seek
                from you. Further, you shall, at any time while using the
                Application/Services/products, also have an option to withdraw
                your consent given earlier to us to use such Personal
                Information or Sensitive Personal Information. Such withdrawal
                of the consent is required to be sent in writing to us at the
                contact details provided in this Policy below. In such event,
                however, the Company fully reserves the right not to allow
                further usage of the Application or provide any
                Services/products thereunder to you.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Automatically Collected Information</b>
              </p>
              <p class="size-14 pb-5">
                In addition, the Application/products/Services may collect
                certain information automatically, including, but not limited
                to, the type of mobile device you use, your mobile devices
                unique device ID, the IP address of your mobile device, your
                mobile operating system, the type of mobile Internet browsers
                you use, and information about the way you use the
                Application/Services/products. As is true of most Mobile
                applications, we also collect other relevant information as per
                the permissions that you provide. We use an outside credit card
                processing company to bill you for goods and services. These
                companies do not retain, share, store or use personally
                identifiable information for any other purpose.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Use of your Personal Information</b>
              </p>
              <p class="size-14 pb-5">
                We use the collected Information to analyze trends, to conduct
                research, to administer the Application/Services and products,
                to learn about each user's learning patterns and movements
                around the Application/Services and products and to gather
                demographic information and usage behavior about our user base
                as a whole. Aggregated and individual, anonymized and
                non-anonymized data may periodically be transmitted to external
                service providers to help us improve the Application, products
                and our Services. We will share your information with third
                parties only in the ways that are described below in this
                Policy. We may use the individual data and behavior patterns
                combined with personal information to provide you with
                personalised content, and better your learning objectives. Third
                parties provide certain services which we may use to analyze the
                data and information to personalize, drive insights and help us
                better your experience or reach out to you with more value-added
                applications, products, information and services. However, these
                third-party companies do not have any independent right to share
                this information. We do not sell, trade or share your
                Information to any third party (except subsidiaries/affiliates /
                franchisees of the Company for related offerings) unless, we
                have been expressly authorized by you either in writing or
                electronically to do so. We may at times provide aggregate
                statistics about our customers, traffic patterns, and related
                site information to reputable third parties, however this
                information when disclosed will be in an aggregate form and does
                not contain any of your Personally Identifiable Information.
                VIOSA will occasionally send email notices, messages or contact
                you to communicate about our existing as well as add on new
                services, products and benefits, as they are considered an
                essential part of the Services/products you have chosen. We may
                disclose Information:
              </p>
              <ul>
                <li class="mb-3 size-14">
                  as required by law, such as to comply with a subpoena, or
                  similar legal process;
                </li>
                <li class="mb-3 size-14">
                  to enforce applicable ToU, including investigation of
                  potential violations thereof;
                </li>
                <li class="mb-3 size-14">
                  when we believe in good faith that disclosure is necessary to
                  protect our rights, protect your safety or the safety of
                  others, investigate fraud, address security or technical
                  issues or respond to a government request;
                </li>
                <li class="mb-3 size-14">
                  with our trusted services providers who work on our behalf, do
                  not have an independent use of the information we disclose to
                  them, and have agreed to adhere to the rules set forth in this
                  Policy;
                </li>
                <li class="mb-3 size-14">
                  to protect against imminent harm to the rights, property or
                  safety of the Application/Website/ Abaven EduTech Private
                  Limited or its users or the public as required or permitted by
                  law;
                </li>
                <li class="mb-3 size-14">
                  with third party service providers in order to personalize the
                  Application/Website/Services/products for a better user
                  experience and to perform behavioral analysis;
                </li>
                <li class="mb-3 size-14">
                  Any portion of the Information containing personal data
                  relating to minors provided by you shall be deemed to be given
                  with the consent of the minor's legal guardian. Such consent
                  is deemed to be provided by your registration with us
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pb-3">
              <p>
                <b>Access to your Personal Information</b>
              </p>
              <p class="size-14">
                We will provide you with the means to ensure that your Personal
                Information is correct and current. If you have filled out a
                user profile, we will provide an obvious way for you to access
                and change your profile from our
                Application/Services/Website/products. We adopt reasonable
                security measures to protect your password from being exposed or
                disclosed to anyone.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Cookies</b>
              </p>
              <p class="size-14 pb-3">
                We send cookies (small files containing a string of characters)
                to your computer, thereby uniquely identifying your browser.
                Cookies are used to track your preferences, help you login
                faster, and aggregated to determine user trends. This data is
                used to improve our offerings, such as providing more content in
                areas of greater interest to a majority of users. Most browsers
                are initially set up to accept cookies, but you can reset your
                browser to refuse all cookies or to indicate when a cookie is
                being sent. Some of our features and services may not function
                properly if your cookies are disabled.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Links</b>
              </p>
              <p class="size-14 pb-3">
                We may present links in a format that enables us to keep track
                of whether these links have been followed. We use this
                information to improve our customized content. Clicking on links
                may take you to sites outside our domain. We are not responsible
                for the privacy practices of other web sites. We encourage our
                users to be aware when they leave our site to read the privacy
                statements of each and every web site that collects personally
                identifiable information. This Privacy Policy applies solely to
                information collected by our Website.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Alerts</b>
              </p>
              <p class="size-14 pb-3">
                We may alert you by email or phone (through sms/call) to inform
                you about new service offerings of the Company and its
                subsidiaries/affiliates or other information which we feel might
                be useful for you, through the Company or its
                subsidiaries/affiliates
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Public Forums</b>
              </p>
              <p class="size-14 pb-3">
                When you use certain features on our website like the discussion
                forums and you post or share your personal information such as
                comments, messages, files, photos, will be available to all
                users, and will be in the public domain. All such sharing of
                information is done at your own risk. Please keep in mind that
                if you disclose personal information in your profile or when
                posting on our forums this information may become publicly
                available.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Security</b>
              </p>
              <p class="size-14 pb-3">
                We are concerned about safeguarding the confidentiality of your
                Information. We provide physical, electronic, and procedural
                safeguards to protect Information we process and maintain. For
                example, we limit access to this Information to authorized
                employees only who need to know that information in order to
                operate, develop or improve our
                Application/Services/products/Website. Please be aware that,
                although we endeavor to provide reasonable security for
                information we process and maintain, no security system can
                prevent all potential security breaches.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>How Long Do We Retain User Data?</b>
              </p>
              <p class="size-14 pb-3">
                Currently, we plan to retain user data while an account is
                active and for at least three years afterward. We may alter this
                practice according to legal and business requirements. For
                example, we may lengthen the retention period for some data if
                needed to comply with law or voluntary codes of conduct. Unless
                otherwise prohibited, we may shorten the retention period for
                some types of data if needed to free up storage space.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Log information</b>
              </p>
              <p class="size-14 pb-3">
                When you access our Website, our servers automatically record
                information that your browser sends whenever you visit a
                website. These server logs may include information such as your
                web request, internet protocol address, browser type, browser
                language, the date and time of your request and one or more
                cookies that may uniquely identify your browser.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>User communications</b>
              </p>
              <p class="size-14 pb-3">
                When you send an email or other communication to us, we may
                retain those communications in order to process your inquiries,
                respond to your requests and improve our Services.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Changes to this Statement</b>
              </p>
              <p class="size-14 pb-3">
                As the Company evolves, our privacy policy will need to evolve
                as well to cover new situations. You are advised to review this
                Policy regularly for any changes, as continued use is deemed
                approval of all changes.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Your Consent</b>
              </p>
              <p class="size-14 pb-3">
                We believe that, every user of our
                Application/Services/products/Website must be in a position to
                provide an informed consent prior to providing any Information
                required for the use of the
                Application/Services/products/Website. By registering with us,
                you are expressly consenting to our collection, processing,
                storing, disclosing and handling of your information as set
                forth in this Policy now and as amended by us. Processing, your
                information in any way, including, but not limited to,
                collecting, storing, deleting, using, combining, sharing,
                transferring and disclosing information, all of which activities
                will take place in India. If you reside outside India your
                information will be transferred, processed and stored in
                accordance with the applicable data protection laws of India.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>
                <b>Terms &amp; Conditions</b>
              </p>
              <p class="size-14 pb-3">
                These Terms &amp; Conditions (“Terms”) of (a) use of our website
                VIOSA.in (“Website”), our applications (“Application”) or any
                products or services in connection with the Application/,&nbsp;
                Website/products (“Services”)&nbsp; or (b) any modes of
                registrations or usage of products, including through SD cards,
                tablets or other storage/transmitting device&nbsp; are between
                Abaven EduTech Private Limited Private Limited
                (“Company/We/Us/Our”) and its users (“User/You/Your”).
              </p>
              <p class="size-14 pb-3">
                These Terms constitute an electronic record in accordance with
                the provisions of the Information Technology Act, 2000 and the
                Information Technology (Intermediaries guidelines) Rules, 2011
                thereunder, as amended from time to time.
              </p>
              <p class="size-14 pb-3">
                Please read the Terms and the privacy policy of the Company
                (“Privacy Policy”) with respect to registration with us, the use
                of the Application, Website, Services and products carefully
                before using the Application, Website, Services or products. In
                the event of any discrepancy between the Terms and any other
                policies with respect to the Application or Website or Services
                or products, the provisions of the Terms shall prevail.
              </p>
              <p class="size-14 pb-3">
                Your use/access/browsing of the Application or Website or the
                Services or products or registration (with or without
                payment/with or without subscription) through any means shall
                signify Your acceptance of the Terms and Your agreement to be
                legally bound by the same. If you do not agree with the Terms or
                the Privacy Policy, please do not use the Application or Website
                or avail the Services or products. Any access to our
                Services/Application/products through registrations/subscription
                is non transferable.
              </p>
            </div>
          </div>
          <div class="row mx-5">
            <div class="col-md-12">
              <ol>
                <li class="mb-3 size-14 pb-3">
                  Except as mentioned below, all information, content, material,
                  trademarks, services marks, trade names, and trade secrets
                  including but not limited to the software, text, images,
                  graphics, video, script and audio, contained in the
                  Application, Website, Services and products are proprietary
                  property of the Company (“Proprietary Information”). No
                  Proprietary Information may be copied, downloaded, reproduced,
                  modified, republished, uploaded, posted, transmitted or
                  distributed in any way without obtaining prior written
                  permission from the Company and nothing on this Application or
                  Website or Services shall be or products deemed to confer a
                  license of or any other right, interest or title to or in any
                  of the intellectual property rights belonging to the Company,
                  to the User. You may own the medium on which the information,
                  content or materials resides, but the Company shall at all
                  times retain full and complete title to the information,
                  content or materials and all intellectual property rights
                  inserted by the Company on such medium. Certain contents on
                  the Website may belong to third parties. Such contents have
                  been reproduced after taking prior consent from said party and
                  all rights relating to such content will remain with such
                  third party. Further, you recognize and acknowledge that the
                  ownership of all trademarks, copyright, logos, service marks
                  and other intellectual property owned by any third party shall
                  continue to vest with such party and You are not permitted to
                  use the same without the consent of the respective third
                  party.
                </li>
                <li class="mb-3 size-14 pb-3">
                  Your use of our products, Website, Application and Services is
                  solely for Your personal and non-commercial use. Any use of
                  the Application, Website, Services or products or their
                  contents other than for personal purposes is prohibited. Your
                  personal and non-commercial use of this Application, Website,
                  products and / or our Services shall be subjected to the
                  following restrictions:
                  <ol type="i">
                    <li class="mb-3 size-14 pb-3">
                      You may not decompile, reverse engineer, or disassemble
                      the contents of the Application and / or our Website
                      and/or Services/ products or modify, copy, distribute,
                      transmit, display, perform, reproduce, publish, license,
                      create derivative works from, transfer, or sell any
                      information or software obtained from the Application and
                      / or our Website and/or Services/products, or remove any
                      copyright, trademark registration, or other proprietary
                      notices from the contents of the Application and / or and
                      / or our Website and/or Services/products.
                    </li>
                    <li class="mb-3 size-14 pb-3">
                      You will not (a) use this Application and / or our Website
                      and/or any of our product/s or Service/s for commercial
                      purposes of any kind, or (b) advertise or sell the
                      Application or any products, Services or domain names or
                      otherwise (whether or not for profit), or solicit others
                      (including, without limitation, solicitations for
                      contributions or donations) or use any public forum for
                      commercial purposes of any kind, or (c) use the
                      Application and / or Website/our products and Services in
                      any way that is unlawful, or harms the Company or any
                      other person or entity as determined by the Company.
                    </li>
                    <li class="mb-3 size-14 pb-3">
                      No User shall be permitted to perform any of the following
                      prohibited activities while availing our Services:
                      <ol type="a">
                        <li class="mb-3 size-14">
                          Making available any content that is misleading,
                          unlawful, harmful, threatening, abusive, tortious,
                          defamatory, libelous, vulgar, obscene,
                          child-pornographic, lewd, lascivious, profane,
                          invasive of another's privacy, hateful, or racially,
                          ethnically or otherwise objectionable;
                        </li>
                        <li class="mb-3 size-14">
                          Stalking, intimidating and/or harassing another and/or
                          inciting other to commit violence;
                        </li>
                        <li class="mb-3 size-14">
                          Transmitting material that encourages anyone to commit
                          a criminal offence, that results in civil liability or
                          otherwise breaches any relevant laws, regulations or
                          code of practice;
                        </li>
                        <li class="mb-3 size-14">
                          Interfering with any other person's use or enjoyment
                          of the Application/Website/Services;
                        </li>
                        <li class="mb-3 size-14">
                          Making, transmitting or storing electronic copies of
                          materials protected by copyright without the
                          permission of the owner, committing any act that
                          amounts to the infringement of intellectual property
                          or making available any material that infringes any
                          intellectual property rights or other proprietary
                          rights of anyone else;
                        </li>
                        <li class="mb-3 size-14">
                          Make available any content or material that You do not
                          have a right to make available under any law or
                          contractual or fiduciary relationship, unless You own
                          or control the rights thereto or have received all
                          necessary consents for such use of the content;
                        </li>
                        <li class="mb-3 size-14">
                          Impersonate any person or entity, or falsely state or
                          otherwise misrepresent Your affiliation with a person
                          or entity;
                        </li>
                        <li class="mb-3 size-14">
                          Post, transmit or make available any material that
                          contains viruses, Trojan horses, worms, spyware, time
                          bombs, cancelbots, or other computer programming
                          routines, code, files or such other programs that may
                          harm the Application/services, interests or rights of
                          other users or limit the functionality of any computer
                          software, hardware or telecommunications, or that may
                          harvest or collect any data or personal information
                          about other Users without their consent;
                        </li>
                        <li class="mb-3 size-14">
                          Access or use the
                          Application/Website/Services/products in any manner
                          that could damage, disable, overburden or impair any
                          of the Application's/Website's servers or the networks
                          connected to any of the servers on which the
                          Application/Website is hosted;
                        </li>
                        <li class="mb-3 size-14">
                          Intentionally or unintentionally interfere with or
                          disrupt the services or violate any applicable laws
                          related to the access to or use of the
                          Application/Website/Services/products, violate any
                          requirements, procedures, policies or regulations of
                          networks connected to the
                          Application/Website/Services/products, or engage in
                          any activity prohibited by these Terms;
                        </li>
                        <li class="mb-3 size-14">
                          Disrupt or interfere with the security of, or
                          otherwise cause harm to, the
                          Application/Website/Services/products, materials,
                          systems resources, or gain unauthorized access to user
                          accounts, passwords, servers or networks connected to
                          or accessible through the
                          Application/Website/Services/products or any
                          affiliated or linked sites;
                        </li>
                        <li class="mb-3 size-14">
                          Interfere with, or inhibit any user from using and
                          enjoying access to the Application/Website/
                          Services/products, or other affiliated sites, or
                          engage in disruptive attacks such as denial of service
                          attack on the Application/Website/Services/products;
                        </li>
                        <li class="mb-3 size-14">
                          Use deep-links, page-scrape, robot, spider or other
                          automatic device, program, algorithm or methodology,
                          or any similar or equivalent manual process, to
                          increase traffic to the
                          Application/Website/Services/products, to access,
                          acquire, copy or monitor any portion of the
                          Application /Website/Services/products, or in any way
                          reproduce or circumvent the navigational structure or
                          presentation of the Application, or any content, to
                          obtain or attempt to obtain any content, documents or
                          information through any means not specifically made
                          available through the Application/
                          Website/Services/products;
                        </li>
                        <li class="mb-3 size-14">
                          Alter or modify any part of the Services;
                        </li>
                        <li class="mb-3 size-14">
                          Use the Services for purposes that are not permitted
                          by: (i) these Terms; and (ii) any applicable law,
                          regulation or generally accepted practices or
                          guidelines in the relevant jurisdiction; or
                        </li>
                        <li class="mb-3 size-14">
                          Violate any of the terms specified under the Terms for
                          the use of the Application /Website/Services/products.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li class="mb-3 size-14">
                  By submitting content on or through the Services (your
                  “Material”), you grant us a worldwide, non-exclusive,
                  royalty-free license (with the right to sublicense) to use,
                  copy, reproduce, process, adapt, modify, publish, transmit,
                  display and distribute such Material in any and all media or
                  distribution methods (now known or later developed) and to
                  associate your Material with you, except as described below.
                  You agree that others may use Your Material in the same way as
                  any other content available through the Services. Other users
                  of the Services may fork, tweak and repurpose your Material in
                  accordance with these Terms. If you delete your user account,
                  your Material and name may remain available through the
                  Services
                </li>
                <li class="mb-3 size-14">
                  In the preparation of the
                  Application/Website/Services/products and contents therein,
                  every effort has been made to offer the most current, correct,
                  and clearly expressed information possible. Nevertheless,
                  inadvertent errors may occur. In particular, but without
                  limiting anything here, the Company disclaims any
                  responsibility for any errors and accuracy of the information
                  that may be contained in the Application. Any feedback from
                  User is most welcome to make the Application and contents
                  thereof error free and user friendly. Company also reserves
                  the right and discretion to make any changes/corrections or
                  withdraw/add contents at any time without notice. Neither the
                  Company nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or Suitability of the information and materials
                  found or offered on Application/Website/Services/products for
                  any particular purpose. You acknowledge that such information
                  and materials may contain inaccuracies or errors and we
                  expressly exclude liability for any such inaccuracies or
                  errors to the fullest extent permitted by law.
                </li>
                <li class="mb-3 size-14">
                  Our Website provides Users with access to compiled educational
                  information and related sources. Such information is provided
                  on an As Is basis and We assume no liability for the accuracy
                  or completeness or use or non-obsolescence of such
                  information. We shall not be liable to update or ensure
                  continuity of such information contained on the Website. We
                  would not be responsible for any errors, which might appear in
                  such information, which is compiled from third party sources
                  or for any unavailability of such information. From time to
                  time the Website may also include links to other websites.
                  These links are provided for your convenience to provide
                  further information. They do not signify that we endorse the
                  website(s). We have no responsibility for the content of the
                  linked website(s). You may not create a link to the Website
                  from another website or document without the Company's prior
                  written consent.
                </li>
                <li class="mb-3 size-14">
                  The contents of the Application/Services/products are
                  developed on the concepts covered in the structured
                  curriculum/training syllabus prescribed for users of various
                  courses. The usage of the Application/Services/products is not
                  endorsed as a substitution to any curriculum based education
                  provided by the educational institutions but is intended to
                  supplement the same by explaining and presenting the concepts
                  in a manner enabling easy understanding. The basic definitions
                  and contents of the subject matter would remain the same. The
                  Company acknowledges that there are various means of
                  delivering structured curriculum pedagogy and inclusion of
                  methods in the Application/Services/products does not imply
                  endorsement of any particular method nor exclusion imply
                  disapproval. Subscription to the Application or usage of our
                  Services/Website/products does not in any manner guarantee
                  jobs, admission to any educational institutions or passing of
                  any exams or achievement of any specified percentage of marks
                  in any examinations.
                </li>
                <li class="mb-3 size-14">
                  Certain contents in the Application/Services/Website/products)
                  may contain opinions and views. The Company shall not be
                  responsible for such opinions or any claims resulting from
                  them. Further, the Company makes no warranties or
                  representations whatsoever regarding the quality, content,
                  completeness, or adequacy of such information and data.
                </li>
                <li class="mb-3 size-14">
                  Some parts of the Services are interactive, and we encourage
                  contributions by Users, which may or may not be subject to
                  editorial control prior to being posted. The Company accepts
                  no responsibility or liability for any material communicated
                  by third parties in this way. The Company reserves the right
                  at its sole discretion to remove, review, edit or delete any
                  content. Similarly, we will not be responsible or liable for
                  any content uploaded by Users directly on the Website,
                  irrespective of whether We have certified any answer uploaded
                  by the User. We would not be responsible to verify whether
                  such questions/answers or contents placed by any User contain
                  infringing materials or not.
                </li>
                <li class="mb-3 size-14">
                  The Company (including but not limited to its
                  subsidiaries/affiliates) may, based on any form of access to
                  the Application (including free download/trials) or Services
                  or Website or registrations through any source whatsoever,
                  contact the User through sms, email and call, to give
                  information about their offerings and products as well as
                  notifications on various important updates and/or to seek
                  permission for demonstration of its products. The User
                  expressly grants such permission to contact him/her through
                  telephone, SMS, e-mail and holds the Company (including but
                  not limited to its subsidiaries/affiliates) indemnified
                  against any liabilities including financial penalties,
                  damages, expenses in case the User's mobile number is
                  registered with Do not Call (DNC) database. By registering
                  yourself, you agree to make your contact details available to
                  Our employees, associates, subsidiaries, affiliates and
                  partners so that you may be contacted for education
                  information, offerings and promotions through telephone, SMS,
                  email etc.
                </li>
                <li class="mb-3 size-14">
                  While the Company may, based on the User's confirmation,
                  facilitate the demonstration of its products at the location
                  sought by the User, the User acknowledges that he/she has not
                  been induced by any statements or representations of any
                  person with respect to the quality or conditions of the
                  products and that User has relied solely on the
                  investigations, examinations and inspections as the User has
                  chosen to make and that the Company has afforded the User the
                  opportunity for full and complete investigations, examinations
                  and inspections.
                </li>
                <li class="mb-3 size-14">
                  Upon registration through any means whatsoever, the Company
                  may contact You through the registered mobile number or e-mail
                  or any other mobile number or contact number or email provided
                  by You to enable effective provision of Services. The User
                  expressly permits the Company to contact him/her and the
                  aspirants / users utilizing the Services, through the
                  above-mentioned means at any time post registration. .-.
                  Further, the Company shall have the right to monitor the
                  download and usage of the Application/Services/products and
                  the contents thereof by the User/aspirants, to analyze such
                  usage and discuss the same with the User/aspirants to enable
                  effective and efficient usage of the Services. The User
                  expressly permits the Company to clear the doubts of the
                  aspirants / users using the Application/Services/online portal
                  by answering the questions placed before it, providing new
                  courses, informing of the progress, providing feedback,
                  communicating with the aspirants / users and mentoring the
                  aspirants / users through telephone or e-mail on express
                  consent of the legal guardian/parent of the User or through
                  any other forum.
                </li>
                <li class="mb-3 size-14">
                  While the Company has made efforts to train the personnel
                  engaged in the sales and services relating to its products to
                  enable quality control, it makes no warranties or
                  representations whatsoever regarding the quality and
                  competence of such personnel and would not be responsible for
                  any deviant behavior of any such personnel. Any feedback from
                  User relating to the same is most welcome and Company reserves
                  the right and discretion to take any action in this regard.
                </li>
                <li class="mb-3 size-14">
                  Access to certain elements of the Services including doubt
                  clearance, mentoring services etc. may be subject to separate
                  terms, conditions and fair usage policy. The Company reserves
                  the right to determine the criteria for provision of various
                  elements of Services to the different categories of Users
                  based on its policies. Hence, subscription to the
                  Application/products or registrations do not automatically
                  entitle the User to any and all elements of Services provided
                  by the Company and the Company shall be entitled to exercise
                  its discretion while providing access to and determining
                  continuity of certain elements of Services. We reserve the
                  right to extend, cancel, discontinue, prematurely withdraw or
                  modify any of Our Services at Our discretion.
                </li>
                <li class="mb-3 size-14">
                  The Company's products and / or Services, including the
                  Application and content, are compatible only with certain
                  devices/tablets/instruments/hardware. The Company shall not be
                  obligated to provide workable products and / or services for
                  any instruments that are not recognized by the Company or
                  those instruments that may be purchased from any third party
                  which are not compatible with the Company's products and
                  Services. The company reserves the right to upgrade the table/
                  type of compatible devices as required from time to time.
                </li>
                <li class="mb-3 size-14">
                  The Company shall have no responsibility for any loss or
                  damage caused to tablet or any other hardware and / or
                  software and/or instrument, including loss of data or effect
                  on the processing speed, resulting from Your use of our
                  products and Services.
                </li>
                <li class="mb-3 size-14">
                  You have to specify the address to which any shipment has to
                  be made at the time of registration. All the related material
                  / certificates / product(s) shall be delivered directly to the
                  address as specified at the point of registration and You
                  cannot, under any circumstances whatsoever, change the address
                  after the registration is processed. In case of any change in
                  the address, You need to specify the same to us in writing
                  well in advance to the shipping date. Any inconsistencies in
                  name or address will result in non-delivery of the product(s).
                  <br />
                </li>
                <li class="mb-3 size-14">
                  (a) For return of product(s) / Certificates damaged at the
                  time of delivery, the shipping charges shall be borne by the
                  Company. However, for return any of the product(s) for any
                  other reasons, it shall be the responsibility of the User to
                  arrange for the return of such cancelled
                  product(s)/Certificates and the shipping charges shall be
                  borne by such User. (b)We request You not to accept any
                  product package that seems to be tampered with, opened or
                  damaged at the time of delivery. The products / Certificates
                  must be returned in the same condition as delivered by the
                  Company. Any products returned showing signs of any use or
                  damage in any manner shall not be accepted for return. (c)All
                  requests for return of products have to be placed within 15
                  (fifteen) days from the date of delivery. Please note that no
                  refunds shall be claimed or will be entertained post 15
                  (fifteen) days from the date of delivery.
                  <br />
                </li>
                <li class="mb-3 size-14">N/A</li>
                <li class="mb-3 size-14">
                  In order to access the Services and to avail the use of the
                  Application/products, You shall be required to register
                  yourself with the Application/Services/products, and maintain
                  an account with the Application/Services/products. You will be
                  required to furnish certain information and details, including
                  Your name, mobile number, e-mail address, residential address,
                  grade/class of the aspirants / users, school name, College
                  name, payment information (credit/debit card details) if
                  required, and any other information deemed necessary by the
                  Application. With respect to the provision of information, the
                  following may be noted: -<br />
                  <ul style={{ listStyleType: "disc" }}>
                    <li class="mb-3 size-14">
                      It is Your sole responsibility to ensure that the account
                      information provided by You is accurate, complete and
                      latest.
                    </li>
                    <li class="mb-3 size-14">
                      You shall be responsible for maintaining the
                      confidentiality of the account information and for all
                      activities that occur under Your account. You agree to (a)
                      ensure that You successfully log out from Your account at
                      the end of each session; and (b) immediately notify the
                      Company of any unauthorized use of Your account. If there
                      is reason to believe that there is likely to be a breach
                      of security or misuse of Your account, we may request You
                      to change the password or we may suspend Your account
                      without any liability to the Company, for such period of
                      time as we deem appropriate in the circumstances. We shall
                      not be liable for any loss or damage arising from Your
                      failure to comply with this provision.
                    </li>
                    <li class="mb-3 size-14">
                      You acknowledge that Your ability to use Your account is
                      dependent upon external factors such as internet service
                      providers and internet network availability and the
                      Company cannot guarantee accessibility to the Application
                      at all times. In addition to the disclaimers set forth in
                      the Terms, the Company shall not be liable to You for any
                      damages arising from Your inability to log into Your
                      account and access the services of the Application at any
                      time.
                    </li>
                  </ul>
                </li>
                <li class="mb-3 size-14">
                  Persons who are "competent/capable" of contracting within the
                  meaning of the Indian Contract Act, 1872 shall be eligible to
                  register for the Application and all Our products or Services.
                  Persons who are minors, un-discharged insolvents etc. are not
                  eligible to register for Our products or Services. As a minor
                  if You wish to use Our products or Services, such use shall be
                  made available to You by Your legal guardian or parents, who
                  has agreed to these Terms. In the event a minor utilizes the
                  Application/Website/Services, it is assumed that he/she has
                  obtained the consent of the legal guardian or parents and such
                  use is made available by the legal guardian or parents. The
                  Company will not be responsible for any consequence that
                  arises as a result of misuse of any kind of Our Application or
                  any of Our products or Services that may occur by virtue of
                  any person including a minor registering for the
                  Services/products provided. By using the products or Services
                  You warrant that all the data provided by You is accurate and
                  complete and that aspirants / users using the Application has
                  obtained the consent of parent/legal guardian (in case of
                  minors). The Company reserves the right to terminate Your
                  subscription and / or refuse to provide You with access to the
                  products or Services if it is discovered that You are under
                  the age of 18 (eighteen) years and the consent to use the
                  products or Services is not made by Your parent/legal guardian
                  or any information provided by You is inaccurate. You
                  acknowledge that the Company does not have the responsibility
                  to ensure that You conform to the aforesaid eligibility
                  criteria. It shall be Your sole responsibility to ensure that
                  You meet the required qualification. Any persons under the age
                  of 18 (eighteen) should seek the consent of their
                  parents/legal guardians before providing any Information about
                  themselves or their parents and other family members on the
                  Application.
                  <br />
                </li>
                <li class="mb-3 size-14">
                  You agree to defend, indemnify and hold harmless the Company,
                  its officers, directors, employees and agents, from and
                  against any and all claims, damages, obligations, losses,
                  liabilities, costs or debt, and expenses (including but not
                  limited to attorney's fees) arising from: (i) Your use of and
                  access of the Application/Website/Services; (ii) Your
                  violation of any term of these Terms or any other policy of
                  the Company; (iii) Your violation of any third party right,
                  including without limitation, any copyright, property, or
                  privacy right; or (iv) any claim that Your use of the
                  Application/Website/Services has caused damage to a third
                  party. This defense and indemnification obligation will
                  survive these Terms.
                  <br />
                </li>
                <li class="mb-3 size-14">
                  In no event, shall the Company, its officers, directors,
                  employees, partners or agents be liable to You or any third
                  party for any special, incidental, indirect, consequential or
                  punitive damages whatsoever, including those resulting from
                  loss of use, data or profits or any other claim arising out,
                  of or in connection with, Your use of, or access to, the
                  Application.
                </li>
                <li class="mb-3 size-14">
                  In the event of Your breach of these Terms, You agree that the
                  Company will be irreparably harmed and may not have an
                  adequate remedy in money or damages. The Company therefore,
                  shall be entitled in such event to obtain an injunction
                  against such a breach from any court of competent
                  jurisdiction. The Company's right to obtain such relief shall
                  not limit its right to obtain other remedies.
                </li>
                <li class="mb-3 size-14">
                  Any violation by You of the terms of this Clause may result in
                  immediate suspension or termination of Your Accounts apart
                  from any legal remedy that the Company can avail. In such
                  instances, the Company may also disclose Your Account
                  Information if required by any Governmental or legal
                  authority. You understand that the violation of these Terms
                  could also result in civil or criminal liability under
                  applicable laws.
                </li>
                <li class="mb-3 size-14">
                  The Terms shall be governed by and construed in accordance
                  with the laws of India, without regard to conflict of law
                  principles. Further, the Terms shall be subject to the
                  exclusive jurisdiction of the competent courts located in
                  Mumbai ( India ) and You hereby accede to and accept the
                  jurisdiction of such courts.
                </li>
                <li class="mb-3 size-14">
                  The Company has the right to change modify, suspend, or
                  discontinue and/or eliminate any aspect(s), features or
                  functionality of the Application or the Services as it deems
                  fit at any time without notice. Further, the Company has the
                  right to amend these Terms from time to time without prior
                  notice to you. The Company makes no commitment, express or
                  implied, to maintain or continue any aspect of the
                  Application. You agree that the Company shall not be liable to
                  You or any third party for any modification, suspension or
                  discontinuance of the Application/Services. All prices are
                  subject to change without notice.
                </li>
                <li class="mb-3 size-14">
                  DISCLAIMER: THIS WEBSITE, THE APPLICATION AND THE SERVICES ARE
                  PROVIDED ON AN "AS IS" BASIS WITH ALL FAULTS AND WITHOUT ANY
                  WARRANTY OF ANY KIND. THE COMPANY HEREBY DISCLAIMS ALL
                  WARRANTIES AND CONDITIONS WITH REGARD TO THE WEBSITE,
                  APPLICATION/PRODUCTS AND THE SERVICES, INCLUDING WITHOUT
                  LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                  ACCURACY, TIMELINESS. PERFORMANCE, COMPLETENESS, Suitability
                  AND NON-INFRINGEMENT. ADDITIONALLY, THE COMPANY SHALL NOT BE
                  LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                  THE USE OR PERFORMANCE OF THIS SITE, OR THE APPLICATION OR THE
                  SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS
                  WEBSITE/APPLICATION/SERVICES/PRODUCTS IS ENTIRELY AT YOUR OWN
                  RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN
                  RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED BY US MEET
                  YOUR SPECIFIC REQUIREMENTS.
                </li>
                <li class="mb-3 size-14">
                  <p>
                    <b>General Provisions:</b>
                  </p>
                  <ul style={{ listStyleType: "disc" }}>
                    <li class="mb-3 size-14">
                      Notice: All notices served by the Company shall be
                      provided via email to Your account or as a general
                      notification on the Application. Any notice to be provided
                      to the Company should be sent to{" "}
                      <a
                        href="mailto:mail@viosa.in"
                        className="text-email"
                        style={{ color: "#521986!important" }}
                      >
                        mail@viosa.in
                      </a>
                    </li>
                    <li class="mb-3 size-14">
                      Entire Agreement: The Terms, along with the Privacy
                      Policy, and any other guidelines made applicable to the
                      Application from time to time, constitute the entire
                      agreement between the Company and You with respect to Your
                      access to or use of the Application, Website and the
                      Services thereof.
                    </li>
                    <li class="mb-3 size-14">
                      Assignment: You cannot assign or otherwise transfer Your
                      obligations under the Terms, or any right granted
                      hereunder to any third party. The Company's rights under
                      the Terms are freely transferable by the Company to any
                      third parties without the requirement of seeking Your
                      consent.
                    </li>
                    <li class="mb-3 size-14">
                      Severability: If, for any reason, a court of competent
                      jurisdiction finds any provision of the Terms, or portion
                      thereof, to be unenforceable, that provision shall be
                      enforced to the maximum extent permissible so as to give
                      effect to the intent of the parties as reflected by that
                      provision, and the remainder of the Terms shall continue
                      in full force and effect.
                    </li>
                    <li class="mb-3 size-14">
                      Waiver: Any failure by the Company to enforce or exercise
                      any provision of the Terms, or any related right, shall
                      not constitute a waiver by the Company of that provision
                      or right.
                    </li>
                    <li class="mb-3 size-14">
                      Relationship: You acknowledge that Your participation on
                      the Application, does not make You an employee or agency
                      or partnership or joint venture or franchise of the
                      Company.
                    </li>
                    <li class="mb-3 size-14">
                      The Company provides these Terms so that You are aware of
                      the terms that apply to your use of the
                      Website/Application and Services. You acknowledge that,
                      the Company has given You a reasonable opportunity to
                      review these Terms and that You have agreed to them.
                    </li>
                  </ul>
                </li>
                <li class="mb-3 size-14">
                  <p>
                    <b>Feedback:</b>
                  </p>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      Any feedback You provide with respect to the Application
                      shall be deemed to be non-confidential. The Application
                      shall be free to use such information on an unrestricted
                      basis. Further, by submitting the feedback, You represent
                      and warrant that (i) Your feedback does not contain
                      confidential or proprietary information of You or of third
                      parties; (ii) the Company is not under any obligation of
                      confidentiality, express or implied, with respect to the
                      feedback; (iii) the Application may have something similar
                      to the feedback already under consideration or in
                      development; and (iv) You are not entitled to any
                      compensation or reimbursement of any kind from the Company
                      for the feedback under any circumstances, unless
                      specified.
                    </li>
                    <li>
                      Under no circumstances shall the Company be held
                      responsible in any manner for any content provided by
                      other users even such content is offensive, hurtful or
                      offensive. Please exercise caution while accessing the
                      Application.
                    </li>
                  </ul>
                </li>
                <li class="mb-3 size-14">
                  <p>
                    <b>Customer Care:</b>
                  </p>
                  <p>
                    We make all best endeavors to provide You with a pleasant
                    experience. In the unlikely event that You face any issues,
                    please contact us at{" "}
                    <a
                      href="mailto:mail@viosa.in"
                      className="text-email"
                      title="mail@viosa.in"
                      style={{ color: "#521986!important" }}
                    >
                      mail@viosa.in
                    </a>
                  </p>
                </li>
                <li class="mb-3 size-14">
                  <p>
                    <b>Refunds:</b>
                  </p>
                  <p>
                    Any refunds that are to be processed shall be processed in
                    accordance with VIOSA’s refund policy.
                  </p>
                </li>
                <li class="mb-3 size-14">
                  <p>
                    <b>Force Majeure:</b>
                  </p>
                  <p>
                    VIOSA shall not be liable for failure to perform, or the
                    delay in performance of, any of its obligations if, and to
                    the extent that, such failure or delay is caused by events
                    substantially beyond its control, including but not limited
                    to acts of God, acts of the public enemy or governmental
                    body in its sovereign or contractual capacity, war,
                    terrorism, floods, fire, strikes, epidemics, civil unrest or
                    riots, power outage, and/or unusually severe weather.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
