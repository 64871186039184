import React, { useEffect } from "react";
import ContactTop from "./ContactTop";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="container-fluid">
          <ContactTop></ContactTop>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default Contact;
