import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AddWork, ChangePasswordApi } from "../../../Axios/apis";
import { toast } from "react-toastify";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ChangePassword({
  setEditModalOpen,
  detailsupdated,
  setdetailsupdated,
  userDetails,
}) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  // //console.log(form);
  const submit = async (e) => {
    e.preventDefault();
    if (form.password !== form.confirmPassword) {
      setLoading(false);
      alert("Password and confirm password should be same");
      return;
    }
    if (!form.password || !form.confirmPassword) {
      alert("Please fill all the fields");
      setLoading(false);
      return;
    }
    setLoading(true);
    const formData = {
      password: form.password,
    };

    const token = localStorage.getItem("token");
    try {
      const data = await ChangePasswordApi(formData, token);
      // //console.log(data);
      setLoading(false);
      setdetailsupdated(!detailsupdated);
    } catch (error) {
      // //console.log(error);
      setLoading(false);
      toast.warning("Failed to add work experiance");
    }
    setEditModalOpen(false);
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);

  return (
    <>
      <form>
        <TextField
          name="password"
          className="edit-profile-text-field"
          label="Password"
          error={
            form.password.length
              ? !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
                  form.password
                )
                ? true
                : false
              : null
          }
          variant="standard"
          helperText={
            form.password.length
              ? /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
                  form.password
                ) === true
                ? ""
                : "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character"
              : null
          }
          pattern=""
          InputLabelProps={{ shrink: form.password ? true : false }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onHandleChange(e)}
        />
        <TextField
          name="confirmPassword"
          className="edit-profile-text-field"
          label="Confirm Password"
          error={
            form.confirmPassword.length > 5
              ? form.password !== form.confirmPassword
                ? true
                : false
              : null
          }
          helperText={
            form.confirmPassword.length > 5
              ? form.password !== form.confirmPassword
                ? "Password does not match"
                : ""
              : null
          }
          variant="standard"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          type={showPassword1 ? "text" : "password"}
          InputLabelProps={{ shrink: form.confirmPassword ? true : false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => onHandleChange(e)}
        />
        <div className="d-flex justify-content-center mt-3">
          <button
            className="edit-profile-btn edit-profile-btn-orange"
            onClick={submit}
            disabled={form.confirmPassword !== form.password}
          >
            {loading ? "Submiting..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
}

export default ChangePassword;
