import React, { useEffect, useState } from "react";
import homeLogo from "./../assets/icon/Vector (3).svg";
import downArrow from "./../assets/icon/down-arrow.svg";
import "./Navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Login from "./../Authentication/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { Logout, userDashBoard, userRequest } from "redux/user/userAction";
import ReorderIcon from "@mui/icons-material/Reorder";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Accordion } from "react-bootstrap";
import avatar from "../../components/assets/icon/avatarsvg.svg";
import profileAvatar from "../../components/assets/icon/profile-avatar3.png";
import Menu from "@mui/material/Menu";
import CartIcon from "icons/carticon.png";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";

const Header = ({
  setCourse,
  setBlur,
  register1,
  setRegister,
  login,
  setLogin,
}) => {
  const navigate = useNavigate();
  const handleEight = () => {
    setCourse("8-12 Student");
  };
  const { cart } = useSelector((state) => state.user);
  const role = localStorage.getItem("role");

  const handleUnderGr = () => {
    setCourse("Undergraduate/Graduate");
  };
  const handleGr = () => {
    setCourse("Post Graduate");
  };
  const handleProfessional = () => {
    setCourse("Working Professional");
  };
  const dispatch = useDispatch();
  const handleLogin = () => {
    setShow(false);
    setLogin(true);
    setBlur(true);
    dispatch(userRequest(""));
  };
  const [show, setShow] = useState(false);
  const [option, setOption] = useState(false);

  const { userData } = useSelector((state) => state.user);
  const isLoggedIn = userData?.loggedIn;

  const handleClose = () => {
    setShow(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(
      Logout({
        isLoggedIn: false,
      })
    );
    navigate("/");
  };

  const handleDashBoard = () => {
    if (role === "college") {
      navigate("/profile/college");
    } else {
      dispatch(userDashBoard("profile"));
      navigate("/profile/catalogue");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const [isHome, setIsHome] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("/profile")) {
      setIsHome(true);
      setAnchorEl(null);
    } else {
      setIsHome(false);
    }
  }, [location.pathname]);

  return (
    <>
      <Login
        setLogin={setLogin}
        setBlur={setBlur}
        register1={register1}
        setRegister={setRegister}
      />
      <div className="" id="header-1">
        <div className="navbar padd-40 sticky top-0 z-50 navbar-position h-[80px] newBackground">
          <div className="flex-1 logo-res">
            <img
              className=""
              style={{ cursor: "pointer" }}
              src={homeLogo}
              alt=""
              onClick={() => {
                navigate("/");
              }}
            />
          </div>

          <div className="d-none-1024">
            <ul className="menu menu-horizontal p-0">
              <div>
                <NavLink
                  className="ml-5 text-black text-sige for-hover text-underline-none"
                  tabIndex="0"
                  to="/"
                >
                  Home
                </NavLink>
              </div>
              <li
                style={{ marginLeft: "1.5rem" }}
                className=" text-black text-sige for-hover"
                tabIndex="0"
              >
                AI Tools
                <img className=" header-dropdown" src={downArrow} alt="" />
                <ul className="course-ul  forZindex mb-6 course-topic pt-2">
                  <NavLink
                    onClick={handleEight}
                    className={`my-2  different-color pl-2 text-underline-none`}
                    to="/ai-tools/career-roadmap"
                  >
                    Career Guidance/Counselling
                  </NavLink>
                  <NavLink
                    onClick={handleUnderGr}
                    className={`my-2  different-color pl-2`}
                    to="/course/resumatic-ai"
                  >
                    Resume Builder
                  </NavLink>

                  <NavLink
                    onClick={handleGr}
                    className={`my-2  different-color pl-2`}
                    // to="/course/6343c15fe33839d145810f31"
                    to="/course/interview-pro"
                  >
                    Mock Interview
                  </NavLink>

                  {/* <NavLink
                    onClick={handleProfessional}
                    className={`my-2  different-color pl-2`}
                    to="/course/job-identification-application"
                  >
                    JobEazy
                  </NavLink> */}
                </ul>
              </li>
              <li className=" text-black text-sige for-hover" tabIndex="0">
                Job Ready Courses
                <img className=" header-dropdown" src={downArrow} alt="" />
                <ul className="course-ul  forZindex mb-6 course-topic pt-2">
                  <NavLink
                    onClick={handleEight}
                    className={`my-2  different-color pl-2 text-underline-none`}
                    to="/course/corporate-sector-learning"
                  >
                    Corporate Sector Learning
                  </NavLink>
                  <NavLink
                    onClick={handleUnderGr}
                    className={`my-2  different-color pl-2`}
                    to="/course/functional-skill-competency"
                  >
                    Softskill Competency Coaching
                  </NavLink>

                  <NavLink
                    onClick={handleGr}
                    className={`my-2  different-color pl-2`}
                    to="/course/elevator-pitch-group-discussions"
                  >
                    Elevator Pitch & Group Discussions
                  </NavLink>

                  <NavLink
                    onClick={handleProfessional}
                    className={`my-2  different-color pl-2`}
                    to="/course/digital-profiling-professional-networking"
                  >
                    Digital Profiling & Professional Networking
                  </NavLink>
                </ul>
              </li>

              <li className="text-black text-sige for-hover" tabIndex="0">
                Collaborations
                <img className=" header-dropdown" src={downArrow} alt="" />
                <ul className="collab-ul forZindex mb-6 course-topic pt-2">
                  <NavLink
                    className={`my-2  different-color pl-2`}
                    to="/school"
                  >
                    Schools
                  </NavLink>
                  <NavLink
                    className={`my-2  different-color pl-2`}
                    to="/colleges"
                  >
                    Colleges
                  </NavLink>
                </ul>
              </li>

              <div style={{ marginRight: "15px" }}>
                <NavLink
                  className="text-black text-sige for-hover text-underline-none"
                  tabIndex="0"
                  to="/aboutus"
                >
                  About Us
                </NavLink>
              </div>

              <div style={{ marginRight: "15px" }}>
                <NavLink
                  className="text-black text-sige for-hover text-underline-none"
                  tabIndex="0"
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </div>

              {isLoggedIn && isHome ? (
                <>
                  <li
                    className="login-li text-black text-sige for-hover"
                    id="login-li"
                    tabIndex="0"
                  >
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="linkT NavICon"
                    >
                      <img
                        style={{ height: "30px" }}
                        className="mr-[23px] user-img"
                        src={
                          localStorage.getItem("userimageavatar") ||
                          profileAvatar
                        }
                        alt=""
                      />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleProfileClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={() => handleDashBoard()}>
                        Dashboard
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                    </Menu>
                    {/* <ul className='login-ul forZindex mb-6 login-option pt-2'>
                      <span onClick={() => handleDashBoard()} className={`my-2  different-color pl-2`}>DashBoard</span>
                      <NavLink onClick={handleLogout} className={`my-2  different-color pl-2`}>Log Out</NavLink>
                    </ul> */}
                  </li>
                  {cart?.length > 0 && (
                    <li
                      className="login-li text-black text-sige"
                      id="login-li"
                      backgroundColor="transparent"
                      tabIndex="0"
                      onClick={() => navigate("/profile/cart")}
                    >
                      <img
                        style={{ height: "23px" }}
                        className="mr-[23px] cursor-pointer"
                        src={CartIcon}
                        alt=""
                      />
                      <span className="cart-count-1">{cart?.length}</span>
                    </li>
                  )}
                </>
              ) : (
                <label
                  onClick={() => handleLogin()}
                  htmlFor="my-modal-4"
                  className="modal-button"
                >
                  <li className=" text-black text-sige login-button">
                    Login
                    {/* <a className="login-button">
                    {name ? name : "Login"}
                    {name && (
                      <BiLogOutCircle
                        onClick={() => handleSignOut()}
                        size={24}
                        color="#EB6A3F"
                      />
                    )}
                  </a> */}
                  </li>
                </label>
              )}

              {/* <label
                onClick={() => handleLogin()}
                htmlFor="my-modal-4"
                className="modal-button"
              >
                <li className=" text-black text-sige login-button">
                  Login
                </li>
              </label> */}
            </ul>
          </div>
          <div className="mr-4 d-flex-1025 d-none-big">
            <div
              className="  me-2"
              onClick={() => {
                setShow(true);
              }}
            >
              <ReorderIcon />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mt-3">
          <div className="sidebar-toggle">
            <div className="header"></div>
            <div className="">
              <div
                className="pb-2"
                onClick={() => {
                  setShow(false);
                }}
              >
                <NavLink
                  className="ml-5 text-black text-sige for-hover"
                  tabIndex="0"
                  to="/"
                  onClick={() => {
                    setShow(false);
                  }}
                  style={{ fontSize: "1rem" }}
                >
                  Home
                </NavLink>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="text-sige">
                    AI Tools
                  </Accordion.Header>
                  <Accordion.Body
                    onClick={() => {
                      setShow(false);
                    }}
                    className="d-flex flex-column"
                  >
                    <NavLink
                      onClick={handleEight}
                      className={`my-2  different-color pl-2`}
                      to="/ai-tools/career-roadmap"
                    >
                      Career Guidance/Counselling
                    </NavLink>
                    <NavLink
                      onClick={handleUnderGr}
                      className={`my-2  different-color pl-2`}
                      to="/course/resumatic-ai"
                    >
                      Resume Builder
                    </NavLink>

                    <NavLink
                      onClick={handleGr}
                      className={`my-2  different-color pl-2`}
                      // to="/course/6343c15fe33839d145810f31"
                      to="/course/interview-pro"
                    >
                      Mock Interview
                    </NavLink>

                    <NavLink
                      onClick={handleProfessional}
                      className={`my-2  different-color pl-2`}
                      to="/course/job-identification-application"
                    >
                      JobEazy
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="text-sige">
                    Job Ready Courses
                  </Accordion.Header>
                  <Accordion.Body
                    onClick={() => {
                      setShow(false);
                    }}
                    className="d-flex flex-column"
                  >
                    <NavLink
                      onClick={handleEight}
                      className={`my-2  different-color pl-2`}
                      to="/course/corporate-sector-learning"
                    >
                      Corporate Sector Learning
                    </NavLink>
                    <NavLink
                      onClick={handleUnderGr}
                      className={`my-2  different-color pl-2`}
                      to="/course/functional-skill-competency"
                    >
                      Softskill Competency Coaching
                    </NavLink>

                    <NavLink
                      onClick={handleGr}
                      className={`my-2  different-color pl-2`}
                      to="/course/elevator-pitch-group-discussions"
                    >
                      Elevator Pitch & Group Discussions
                    </NavLink>

                    <NavLink
                      onClick={handleProfessional}
                      className={`my-2  different-color pl-2`}
                      to="/course/digital-profiling-professional-networking"
                    >
                      Digital Profiling & Professional Networking
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="text-sige">
                    Collaborations
                  </Accordion.Header>
                  <Accordion.Body
                    onClick={() => {
                      setShow(false);
                    }}
                    className="d-flex flex-column"
                  >
                    <NavLink
                      className={`my-2  different-color pl-2`}
                      to="/school"
                    >
                      School
                    </NavLink>
                    <NavLink
                      className={`my-2  different-color pl-2`}
                      to="/colleges"
                    >
                      Colleges
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item>
                <div
                  style={{ margin: "10px 0px" }}
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <NavLink
                    className=" my-2 ml-5 text-black accordion-header text-sige for-hover"
                    tabIndex="0"
                    to="/aboutus"
                    onClick={() => {
                      setShow(false);
                    }}
                    style={{ fontSize: "1rem" }}
                  >
                    About Us
                  </NavLink>
                </div>

                <div
                  style={{ margin: "20px 0px" }}
                  className="pb-2"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <NavLink
                    className=" my-2 ml-5 text-black accordion-header text-sige for-hover"
                    tabIndex="0"
                    to="/contact"
                    onClick={() => {
                      setShow(false);
                    }}
                    style={{ fontSize: "1rem" }}
                  >
                    Contact Us
                  </NavLink>
                </div>

                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header>About Us</Accordion.Header>
                  <Accordion.Body
                    onClick={() => {
                      setShow(false);
                    }}
                    className="d-flex flex-column"
                  >
                    <NavLink
                      onClick={handleEight}
                      className={`my-2  different-color pl-2`}
                      to="/aboutus"
                    >
                      {" "}
                      About Us
                    </NavLink>

                    <NavLink
                      onClick={handleUnderGr}
                      className={`my-2  different-color pl-2`}
                      to="/contact"
                    >
                      Contact Us
                    </NavLink>

                    <NavLink
                      onClick={handleGr}
                      className={`my-2  different-color pl-2`}
                      to="/blogs"
                    >
                      Blog
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
              {isLoggedIn ? (
                <>
                  <div className="login-btn-canvas">
                    <label
                      onClick={() => {
                        if (role === "college") {
                          navigate("/profile/college");
                        } else {
                          navigate("/profile/dashboard");
                        }
                        setShow(false);
                      }}
                      className="modal-button"
                    >
                      <h5 className=" text-black text-sige login-button">
                        Dashboard
                      </h5>
                    </label>
                  </div>
                  <div className="login-btn-canvas">
                    <label
                      onClick={() => {
                        navigate("/profile/cart");
                        setShow(false);
                      }}
                      className="modal-button"
                    >
                      <h5 className=" text-black text-sige login-button">
                        Cart {cart?.length > 0 ? `(${cart?.length})` : null}
                      </h5>
                    </label>
                  </div>
                </>
              ) : (
                <div className="login-btn-canvas">
                  <label
                    onClick={() => handleLogin()}
                    htmlFor="my-modal-4"
                    className="modal-button"
                  >
                    <h5 className=" text-black text-sige login-button">
                      Login
                    </h5>
                  </label>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
