import axios from "axios";
import {
  FetchSingleCourse,
  getAllUserCourses,
  getCourseDetailsbyId,
} from "Axios/apis";
import SignUp from "components/Authentication/SignUp/SignUp";
import Cartmodal from "components/Cartmodal/Cartmodal";
import FooterPurple from "components/Footer/FooterPurple";
import Spinner from "components/Spinner/Spinner";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userRoadMapCourse } from "redux/user/userAction";
import rightMark from "./../assets/icon/right-mark.svg";

const CounselingBooking = ({
  setBlur,
  register1,
  setRegister,
  login,
  setLogin,
}) => {
  const [revealPrice, setRevealPrice] = useState("");
  const loginToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [singleCourse, setSingleCourse] = useState([]);
  const [status, setStatus] = useState(false);
  const userCourse = useSelector((state) => state.user);
  const [paymentsuccess, setPaymentSuccess] = useState(false);
  const [location, setLocation] = useState("");
  const loc = useLocation();
  const [loading, setloading] = useState(true);
  const [purchage, setPurchage] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const [isPurchased, setIsPurchased] = useState(false);
  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const response = await FetchSingleCourse(params.id);
      if (loginToken) {
        const response2 = await getAllUserCourses(loginToken);
        setIsPurchased(
          response2.data.courses.filter((item) => item.courseID === params.id)
            .length > 0
        );
      }
      setloading(false);
      setSingleCourse(response?.data?.courses);
    };
    fetchCourse();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [params.id, loginToken]);

  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const result = await axios.get("https://ipapi.co/json");
      setLocation(
        result?.data?.city +
          "," +
          result?.data?.region +
          "," +
          result?.data?.country
      );
      const res = await getCourseDetailsbyId({
        id: params.id,
        country: result?.data?.country,
        country_calling_code: result?.data?.country_calling_code,
      });
      setRevealPrice(res?.data?.courses[0]?.price);
      // //console.log(res);
      setloading(false);
    };
    fetchCourse();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const buyNow = async () => {
    if (loginToken) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        // toast.warning("Razorpay SDK failed to load. Are you online?");
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const paymentData = {
        amount: Number(revealPrice),
        currency: "INR",
      };
      axios
        .post(
          // "https://api.viosa.in/user/payment/buy_course_request",
          "https://api.viosa.in/user/payment/buy_course_request",
          paymentData
        )
        .then((response) => {
          const { amount, id: order_id, currency } = response.data.result;
          const options = {
            key: "rzp_live_nsCl8adCCyvN7S", // Enter the Key ID generated from the Dashboard
            amount,
            secret: "69lDy6zhJV9KdRhb9RfnXsft",
            currency: currency,
            name: "Viosa Learning.",
            description: "Test Transaction",
            image:
              "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg",
            order_id: order_id,
            handler: async function (response) {
              const data = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                courseId: params.id,
                userId: localStorage.getItem("userId"),
                name: localStorage.getItem("userName"),
                email: localStorage.getItem("email"),
                amount: Number(revealPrice),
                currency: "INR",
                courseName: singleCourse.name,
              };
              axios
                .post(
                  // "https://api.viosa.in/user/payment/buy_course_verify",
                  "https://api.viosa.in/user/payment/buy_course_verify",
                  data
                )
                .then((res) => {
                  if (res.data.error === null) {
                    setPurchage(true);
                    setStatus(true);
                    setPaymentSuccess(true);
                    setIsPurchased(true);
                  } else {
                    setStatus(true);
                    setPaymentSuccess(false);
                  }
                });
            },
            prefill: {
              name: "Viosa Pvt. Ltd",
              email: localStorage.getItem("email") || "example@gmail.com",
              contact: "9999999999",
            },
            theme: {
              color: "#F37254",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        });
    } else {
      setRegister(true);
      dispatch(
        userRoadMapCourse({
          courseId: params.id,
          navigateTo: `roadmap/${params.id}/booking`,
        })
      );
    }
  };
  useEffect(() => {
    if (loc.search.split("=")[1] === "true" && !isPurchased) {
      buyNow();
    }
  }, [loc.search, isPurchased]);

  return (
    <>
      <SignUp
        register1={register1}
        setRegister={setRegister}
        setLogin={setLogin}
        login={login}
        setBlur={setBlur}
      />
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {status && (
            <Cartmodal
              status={status}
              paymentsuccess={paymentsuccess}
              setStatus={setStatus}
            />
          )}
          <h1 className="flex justify-center counselling-booking">
            Counseling Booking
          </h1>
          <div className="flex justify-between mx-24 my-12 booking">
            <div className="h-full line-divider">
              <div className="flex items-center mb-10">
                <img src={rightMark} alt="" />
                <h1 className="ml-4 booking-confirmed">Booking Confirmed</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity">Type of Counseling</h1>
                <h1 className="deep-font">{singleCourse?.name}</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Mode of Counseling
                </h1>
                <h1 className="deep-font">Online</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">Location</h1>
                <h1 className="deep-font">{location}</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Language Preference
                </h1>
                <h1 className="deep-font">English/Hindi</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Date & Time
                </h1>
                <h1 className="deep-font">{new Date().toDateString()}</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Duration of the session
                </h1>
                <h1 className="deep-font">2 Hours</h1>
              </div>
              {/* <p className="flex justify-center change">Change</p> */}
            </div>

            <div>
              <div className="flex items-center mb-10">
                <h1 className="payment-smry">Payment Summary</h1>
              </div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Counseling Fee
                </h1>
                <h1 className="deep-font">Rs. {revealPrice}</h1>
              </div>

              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Total Amount
                </h1>
                <h1 className="deep-font">Rs. {Number(revealPrice)}</h1>
              </div>
              <div className="divider"></div>
              <div className="flex justify-between gap-x-36">
                <h1 className="mb-8 new-opacity different-padding">
                  Amout to Pay
                </h1>
                <h1 className="deep-font">Rs. {Number(revealPrice)}</h1>
              </div>
              {isPurchased ? (
                <button
                  className="flex justify-center pay"
                  onClick={() => navigate("/profile/dashboard")}
                >
                  Go To Dashboard
                </button>
              ) : (
                <button
                  className="flex justify-center pay"
                  onClick={() => buyNow()}
                >
                  {loginToken ? "Pay Now" : "Login to Pay"}
                </button>
              )}
            </div>
          </div>
          <FooterPurple></FooterPurple>
        </div>
      )}
    </>
  );
};

export default CounselingBooking;
