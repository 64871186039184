import HeaderTop from "components/HeaderTop";
import Spinner from "components/Spinner/Spinner";
import prettyName from "pretty-name";
import { useEffect, useState } from "react";
import { Accordion } from "components/Section/CourseItem";
import arrow from "icons/arrow.png";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getProgressById,
  getAllUserCourses2,
  FetchSingleCourse,
  getAllUserCourses,
  getProgress,
  GetUserDetails,
  GetStudentByBatchId,
} from "Axios/apis";
import { useSelector } from "react-redux";
import { AccordionLiveSession } from "components/Section/LiveSessionItem";

export const Curricullam = () => {
  const [myCourse, setMyCourse] = useState({});
  const [singleCourse, setSingleCourse] = useState({});
  const [progressCircle, setProgressCircle] = useState([]);
  const userId = localStorage.getItem("userId");
  const [loadspinner, setloading] = useState(true);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { loader } = useSelector((state) => state.course);
  useEffect(() => {
    if (id) {
      setLoading(true);
      console.log("id", id);
      const fetchCourse = async () => {
        // try {
        // const response = await getAllUserCourses2(token);
        try {
          // Fetch user details from local storage
          const storedUser = await GetUserDetails(userId);
          if (!storedUser) {
            console.error("No user details found in local storage");
            setloading(false);
            return;
          }
          const user = storedUser.data.result;
          console.log("User details fetched from local storage:", user);
          // const userResponse = await GetUserDetails(user._id); // Assuming you have a function to get user details
          // console.log("User details fetched:", userResponse);

          const teams = user?.teams || [];
          console.log("User teams:", teams);

          // Fetch batches (teams) details and extract courses
          console.log("Fetching batches details...");
          const batchesPromises = teams.map((team) =>
            GetStudentByBatchId(team._id)
          ); // Assuming you have a function to get batch details
          const batchesResponses = await Promise.all(batchesPromises);
          console.log("Batches details fetched:", batchesResponses);

          const coursesFromBatches =
            batchesResponses.flatMap(
              (response) => response?.data?.data?.course
            ) || [];
          console.log("Courses extracted from batches:", coursesFromBatches);

          const filteredCourses = [
            ...new Map(
              coursesFromBatches.map((courseID) => [courseID, { courseID }])
            ).values(),
          ];
          console.log("Filtered courses:", filteredCourses);

          // Fetch full course details using courseID
          console.log("Fetching full course details...");
          const courseDetailsPromises = filteredCourses.map(
            (course) => FetchSingleCourse(course.courseID, userId) // Assuming you have a function to get course details
          );
          const courseDetailsResponses = await Promise.all(
            courseDetailsPromises
          );
          console.log("Full course details fetched:", courseDetailsResponses);

          // Extract the course data from the responses
          const fullCourseDetails = courseDetailsResponses.map(
            (response) => response.data.courses
          );
          console.log("Extracted full course details:", fullCourseDetails);
          // Filter the course details based on the given ID
          const selectedCourse = fullCourseDetails.find(
            (course) => course._id === id
          );
          // Update the state with the filtered course details
          // setMyCourse(fullCourseDetails);
          setMyCourse(selectedCourse);

          console.log("myCourse", fullCourseDetails);
          console.log("selectedCourse", selectedCourse);
          // setMyCourse(fullCourseDetails);
          setLoading(false);
          // setMyCourse(
          //   fullCourseDetails?.filter((item) => item.data.courses._id === id)
          // );
          // console.log("item", response);
          // setSingleCourse(
          //   fullCourseDetails?.find(
          //     (item) => item.data?.courses._id === id || item.courseID === id
          //   )
          // );
          console.log("singleCourse", singleCourse);
        } catch (error) {
          setLoading(false);
          alert(error?.response?.data?.message);
        }
      };
      fetchCourse();
    }
    const fetchProgress = async () => {
      const resProgress = await getProgressById(
        {
          course_id: id,
        },
        token
      );
      // //console.log(resProgress.data.result)
      setProgressCircle(
        resProgress?.data?.result?.completedChaptersIds === 0
          ? []
          : resProgress?.data?.result?.completedChaptersIds
      );
    };
    fetchProgress();
  }, [id]);

  console.log("course", myCourse);
  return (
    <>
      <HeaderTop name="My Course" />
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center my-3">
            <h1 className="px-1 heading-primary" onClick={() => {}}>
              <Link style={{ color: "#535353" }} to="/profile/dashboard">
                All Courses
              </Link>
            </h1>
            <img className="img-small" src={arrow} height="16" />
            <h1
              className="px-1 heading-primary"
              onClick={() => {
                navigate(`/profile/dashboard/${id}`);
              }}
            >
              My Courses
            </h1>
            <img className="img-small" src={arrow} height="16" />
            <h1
              className="px-1 heading-primary"
              style={{ color: "rgb(255 163 3)" }}
            >
              {prettyName(myCourse?.name)}
            </h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="flex gap-x-4">
          <h1
            style={{ color: "#521986 !important" }}
            className="course-mainheading text-start "
          >
            {prettyName(myCourse?.name)}
          </h1>
        </div>
        <div className="mt-8 col-12 col-md-10 col-sm-6">
          {loader || loading ? (
            <Spinner />
          ) : (
            myCourse?.section?.map((item) => (
              <Accordion
                progressCircle={progressCircle}
                courseID={id}
                className="w-100"
                {...{
                  singleCourse,
                  item,
                }}
              />
            ))
          )}
          {myCourse?.liveSession?.map((live) => (
            <AccordionLiveSession
              className="w-100"
              {...{
                live,
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
