import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  AddStudentToBatch,
  GetStudentByBatchId,
  GetStudentByCollegeId,
  RemoveStudentFromBatch,
  getCollegeResumeApi,
} from "Axios/apis";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Button from "@mui/material/Button";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import MultipleSelectChip from "./ReactMultiselect";
import { Delete } from "@mui/icons-material";

function Batches({ data }) {
  const [students, setstudents] = React.useState([]);
  const [studentData, setstudentData] = useState();
  const [batchStudents, setbatchStudents] = useState([]);
  const param = useParams();
  const token = localStorage.getItem("token");
  const [trailResumes, setTrailResumes] = useState([]); // Added this line
  const [loadingStudent, setloadingStudent] = useState(false);

  const fetchStudentsByCollegeId = async () => {
    try {
      setloadingStudent(true);
      const res = await GetStudentByCollegeId();
      setstudentData(res.data.data);
      setloadingStudent(false);
    } catch (error) {
      setloadingStudent(false);
      console.log(error);
    }
  };
  const fetchingBatchStudent = async () => {
    try {
      setloadingStudent(true);
      const res2 = await GetStudentByBatchId(param.id);
      setbatchStudents(res2.data.data.students);
      setloadingStudent(false);
    } catch (error) {
      setloadingStudent(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStudentsByCollegeId();
    fetchingBatchStudent();
  }, []);

  const viewResumesHandler = async (student) => {
    console.log("View Resumes Button Clicked!");
    console.log("Student Data:", student);
    console.log("Student ID:", student._id);
    console.log("Token:", token);

    try {
      const resumesResponse = await axios.post(
        //   "https://resumatic-api.viosa.in/admin/studentResumes",
        "https://api.viosa.in/resumatic/admin/studentResumes",
        {
          userId: student._id, // Assuming user ID is present in userData
        }
      );
      console.log("API Response3", resumesResponse);
      console.log("API Response4", resumesResponse.data.data.trailResume);

      // Open the dialog
      setSelectedStudent(student);
      setTrailResumes(resumesResponse.data.data.trailResume); // Set trail resumes in state
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching user resumes:", error);
    }
  };
  const [selectedStudent, setSelectedStudent] = useState(null); // Added state for selected student
  const [openModal, setOpenModal] = useState(false);
  const closeViewResumesModal = () => {
    setSelectedStudent(null);
    setOpenModal(false);
  };
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }
  //   const ResumeData = resumesResponse.data.data.trailResume;
  const CoverData = useSelector((item) => item.cover);
  const [loading, setLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handlePdfModalClose = () => setShowPdfModal(false);
  const handlePdfModalShow = () => setShowPdfModal(true);

  const downloadResume = async (resumeId, student, item) => {
    try {
      setLoading(true);
      const response = await axios.post(
        // "https://resumatic-api.viosa.in/admin/studentResumes",
        "https://api.viosa.in/resumatic/admin/studentResumes",
        {
          userId: student[0].user, // Assuming user ID is present in userData
        }
      );
      const resumeData = response.data.data.trailResume.find(
        (resume) => resume._id === resumeId
      );
      // const resumeData = response.data.data.trailResume[0]; // Adjust this based on your API response structure
      const templateId = response.data.data.trailResume[0].template;

      // Use the resume data to generate and download the PDF
      const pdfResponse = await fetch(
        // "https://resumatic-api.viosa.in/pdf/generate-pdf",
        "https://api.viosa.in/resumatic/pdf/generate-pdf",
        // "http://localhost:3200/resumatic/pdf/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ResumeData: resumeData,
            Template: templateId,
            doctype: "resume",
            CoverData: CoverData,
          }),
        }
      );

      //       if (pdfResponse.ok) {
      //         // Handle the PDF response (e.g., open in a new window or provide a download link)
      //         const pdfBlob = await pdfResponse.blob();
      //         const pdfUrl = URL.createObjectURL(pdfBlob);

      //         // Example: Open PDF in a new window
      //         window.open(pdfUrl, "_blank");
      //       } else {
      //         // Handle error
      //         const errorMessage = await pdfResponse.text();
      //         console.error("Failed to generate PDF,", errorMessage);
      //       }
      //     } catch (error) {
      //       console.error("Error during PDF generation:", error);
      //     } finally {
      //       setLoading(false);
      //     }
      //   };
      if (pdfResponse.ok) {
        // const pdfBlob = await pdfResponse.blob();
        // setPdfBlob(pdfBlob);
        // setOpenModal(false);
        // handlePdfModalShow();
        const { pdfUrl } = await pdfResponse.json();
        // const pdfurl2 = "https://resumatic-api.viosa.in" + pdfUrl;

        const pdfurl2 = "https://api.viosa.in" + pdfUrl; /*  */
        // const pdfurl2 = "http://localhost:3200" + pdfUrl;

        console.log("PDFURL", pdfUrl);
        console.log("Full PDFURL: ", pdfurl2);
        // saveAs(`https://resumatic-api.viosa.in${pdfUrl}`, "document.pdf");
        saveAs(`https://api.viosa.in${pdfUrl}`, "document.pdf");
        // saveAs(`http://localhost:3200${pdfUrl}`, "document.pdf");
      } else {
        // Handle error
        const errorMessage = await pdfResponse.text();
        console.error("Failed to generate PDF,", errorMessage);
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      setLoading(false);
    }
  };
  const [table1Page, setTable1Page] = useState(1);
  const [searchText1, setSearchText1] = useState("");
  const [table1Data, setTable1Data] = useState([]);
  const [table1TotalPage, setTable1TotalPage] = useState(1);

  const getResumeCreated = async () => {
    try {
      const resume = await getCollegeResumeApi(table1Page, searchText1, token);
      setTable1Data(resume?.data?.data?.data);
      console.log(resume);
      setTable1TotalPage(resume?.data?.pages);
    } catch (error) {
      console.log(error);
    }
  };

  const [loadingStudentBatch, setloadingStudentBatch] = useState(false);
  const handleStudentSubmitToBatch = async () => {
    setloadingStudentBatch(true);
    const obj = {
      id: param.id,
      students: selectedStudent,
    };
    try {
      const response = await AddStudentToBatch(obj);
      fetchingBatchStudent();
      setSelectedStudent([]);
      setloadingStudentBatch(false);
      setstudents([]);
    } catch (error) {
      setloadingStudentBatch(false);
      console.log(error);
    }
  };

  const StudentRemove = async (id) => {
    try {
      const payload = {
        id: param.id,
        studentId: id,
      };
      setbatchStudents(batchStudents?.filter((item, index) => item._id !== id));
      await RemoveStudentFromBatch(payload);
    } catch (error) {}
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="catalogue row px-4 py-5">
          <div className="my-5">
            <h4>
              <strong>Select Student to add</strong>
            </h4>
            {loadingStudentBatch ? (
              <div className="d-flex justify-content-center">
                <div class="spinner-border text-secondary" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="d-flex gap-5 align-items-center">
                <MultipleSelectChip
                  students={students}
                  setstudents={setstudents}
                  setSelectedStudent={setSelectedStudent}
                  studentData={studentData}
                />

                <button
                  onClick={handleStudentSubmitToBatch}
                  className="common-btn rounded-2  p-2 px-4"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
          {loadingStudent ? (
            <>
              <div className="d-flex justify-content-center">
                <div class="spinner-border text-secondary" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </>
          ) : (
            <table className="collegeTable">
              <thead className="collegeTableHead">
                <th className="collegeTableH">Sr. No</th>
                <th className="collegeTableH">Name</th>
                <th className="collegeTableH">Email</th>
                <th className="collegeTableH">Mobile</th>
                <th className="collegeTableH">Actions</th>
              </thead>
              <tbody className="collegeTableBody">
                {batchStudents?.map((item, index, data, student) => {
                  return (
                    <tr className="collegeTableRow">
                      <td className="collegeTableData">{index + 1}</td>
                      <td className="collegeTableData">{item?.name}</td>
                      <td className="collegeTableData">{item?.email}</td>
                      <td className="collegeTableData">{item?.phoneNumber}</td>
                      <td className="collegeTableData">
                        {/* <button onClick={() => viewResumesHandler(item)}>
                                View Resumes
                              </button> */}
                        <button
                          className="common-btn px-2 py-1"
                          onClick={() => viewResumesHandler(item)}
                        >
                          View Resumes
                        </button>

                        <button
                          className="btn-danger px-2 py-1 ms-3"
                          onClick={() => StudentRemove(item._id)}
                        >
                          <Delete fontSize="16" />{" "}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <Dialog open={openModal} onClose={closeViewResumesModal}>
            <DialogTitle>{`Resumes of ${
              selectedStudent?.name || ""
            }`}</DialogTitle>
            <DialogContent>
              <table className="collegeTable">
                <thead className="collegeTableHead">
                  <tr>
                    <th className="collegeTableH">Sr. No</th>
                    <th className="collegeTableH">Resume</th>
                    <th className="collegeTableH">View</th>
                  </tr>
                </thead>
                <tbody>
                  {trailResumes.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="collegeTableData">
                        No resumes
                      </td>
                    </tr>
                  ) : (
                    trailResumes?.map((resume, index, student, item) => (
                      <tr className="collegeTableRow" key={resume._id}>
                        <td className="collegeTableData">{index + 1}</td>
                        <td className="collegeTableData">Resume {index + 1}</td>
                        <td className="collegeTableData">
                          <button
                            onClick={() =>
                              downloadResume(resume._id, student, item)
                            }
                          >
                            Download Resume{" "}
                            {loading && (
                              <div className="loading-spinner-container d-flex mx-2">
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  spin
                                  size="2x"
                                />
                              </div>
                            )}{" "}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeViewResumesModal}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* PDF Modal */}
          <Dialog
            open={showPdfModal}
            onClose={handlePdfModalClose}
            fullWidth={true}
            size="lg"
            className="modal-90w"
          >
            <DialogTitle>Resume PDF Preview</DialogTitle>
            <DialogContent>
              {pdfBlob && (
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                >
                  <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
                </Worker>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="secondary" onClick={handlePdfModalClose}>
                Close
              </Button>
              {pdfBlob && (
                <Button
                  variant="primary"
                  download="resume.pdf"
                  href={URL.createObjectURL(pdfBlob)}
                >
                  Download PDF
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </motion.div>
    </div>
  );
}

export default Batches;
