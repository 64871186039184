import "./skelton.css";
export default function Skeleton({ type }) {
  const COUNTER = 8;
  const CourseSkelton = () => (
    <div className="mb-8 cursor-pointer card-border-skelton mt-24">
      <div className="block mx-auto card-image-skelton" alt="" />
      <div>
        <h1 className="training-skelton pt-3"></h1>
        <p className="card-para-skelton"></p>
        <p className="card-para-skelton"></p>
        <p className="card-para-skelton"></p>
        <div className="px-2 text-end-skelton d-flex justify-content-end">
          <div className="join-session-btn-skelton"></div>
        </div>
      </div>
    </div>
  );
  if (type === "course") return Array(COUNTER).fill(<CourseSkelton />);
}
