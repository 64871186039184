import React from "react";
import home2 from "./../assets/image/Ellipse 318.png";
import home3 from "./../assets/image/Group 33893.png";
import home4 from "./../assets/image/Group 33894.png";
import teaching from "./../assets/image/interview.jpg";
import play from "./../assets/image/play-button.png";
import heading from "./../assets/image/new-home.png";
import threeMinits from "./../assets/image/Group 33931.png";
import inr from "./../assets/icon/inr.svg";
import twelve from "./../assets/icon/1200.svg";
import nine from "./../assets/icon/₹ 999.svg";
import FrequentlyAsked from "./FrequentlyAsked";
import TimelineComp from "../../components/TimelineComp/TimelineComp";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const Interview = () => {
  const timelinedata = [
    "Start Assessment",
    "Set your Goals",
    "Test your Skills",
    "Get Report Analysis of your Performance",
  ];
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <div>
            <img className="home-curve-image" src={home2} alt="" />
            <div>
              <div className="flex items-center home-curve-image">
                <div>
                  <img className="w-96" src={home4} alt="" />
                </div>
                <div className="home-heading">
                  <h1 className="flex justify-center professional-heading">
                    Interview Preparation
                  </h1>
                  <p className="flex justify-center professional-para">
                    Spend 3 minutes here before 30 minutes on a virtual
                    interview
                  </p>
                </div>
                <div className="mt-4 flex-end for-1440">
                  <img className="w-96" src={home3} alt="" />
                </div>
              </div>
              <h1 className="flex justify-center pt-[470px] find-carrer mx-16">
                Using artificial intelligence and natural language processing,
                everyone can interview and get objective analysis. Get your next
                job with VIOSA!Get an assessment of how you perform in
                interviews & check your scores to improve your.
              </h1>
              <p className="flex justify-center find-carrer">performance</p>
            </div>
            <div className="mx-16 mt-8">
              <div className="flex items-center justify-between">
                <img className="first-width" src={heading} alt="" />
                <div className="flex items-center justify-center">
                  <img className="teacher-image" src={teaching} alt="" />
                  <img className="w-16 play-image" src={play} alt="" />
                </div>
              </div>
            </div>

            <div className="mx-16 mt-8 ">
              <div className="flex items-center justify-between">
                <div className="interview-width">
                  <div>
                    <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
                      AI-Based Mock Interview Platform
                    </h1>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      Course duration: 120 minutes (2 sessions) + Mock Interview
                      Tool Access
                    </p>
                  </div>
                  <div className="my-6">
                    <h1
                      className="mb-2"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Resumes are over-rated. Everyone deserves to interview!
                    </h1>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      -Job seekers apply by interviewing immediately.
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      -Recruiters get a shortlist of the best candidates
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      opacity: "0.5",
                    }}
                  >
                    Whether you're leveling up, transitioning your career, or
                    unexpectedly unemployed, this module will help you boost up
                    your interview skills while sharing feedback and areas of
                    improvement.{" "}
                  </p>
                </div>
                <div className="card-background">
                  <div>
                    <div className="flex items-center justify-center mb-4">
                      <img src={inr} alt="" />
                      <img src={twelve} alt="" />
                    </div>

                    <div className="flex items-center justify-center">
                      <img src={nine} alt="" />
                    </div>
                  </div>

                  <div>
                    <button className="my-4 buy-button w-100">Buy Now</button>
                    <button className="add-cart w-100">Add to Cart</button>
                  </div>
                </div>
              </div>
            </div>

            <section className="py-10 row roadmap-section-container">
              <TimelineComp data={timelinedata} />
            </section>

            <div className="flex items-center justify-center">
              <img
                className="flex justify-center mx-16 mb-12 interview-image"
                src={teaching}
                alt=""
              />
              <img className="w-16 play-image" src={play} alt="" />
            </div>

            {/* <img className='flex justify-center mx-16 mb-12 rectangle-image' src={teaching} alt="" /> */}

            <div className="">
              <div className="flex items-center justify-between mx-16">
                <div>
                  <p className="Frequently-heading">
                    Spend{" "}
                    <span style={{ color: "rgba(255, 163, 3, 1)" }}>
                      {" "}
                      3 minutes{" "}
                    </span>{" "}
                    here before 30 minutes on a virtual interview
                  </p>
                </div>
                <div>
                  <img className="" src={threeMinits} alt="" />
                </div>
              </div>
              <p className="flex justify-center Frequently-heading">
                Frequently Asked{" "}
                <span
                  style={{ color: "rgba(255, 163, 3, 1)", marginLeft: "10px" }}
                >
                  {" "}
                  Questions{" "}
                </span>{" "}
              </p>
            </div>
            <FrequentlyAsked></FrequentlyAsked>
            {/* <Footer></Footer> */}
          </div>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default Interview;
