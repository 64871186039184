import React from "react";
import courseimg from "./../assets/image/coursepageimg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const CareerMaping = ({ heading, bannerImg }) => {
    AOS.init({ duration: 2000 });
    return (
        <>
            <div>
                {/* <div style={{ height: "20rem" }} className="flex items-center justify-center py-3 they-said-background ">
                    <div className="lg:ml-8 job-ready-res px-2" >
                        <p className="mb-2 investing-para">#{heading}</p>
                        <h1 className="invest-heading">with VIOSA</h1>
                    </div>
                </div> */}
                <div className="jobPoster lg:mr-24" >
                    <img data-aos="zoom-in" src={bannerImg} alt="" />
                </div>
            </div>
        </>
    )
}

export default CareerMaping