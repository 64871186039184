import React, { useRef, useState } from "react";
import "./testomonial.css";
import Coma from "./../assets/image/coma.png";
import User from "./../assets/image/user-testomonial.png";
import PurpleComa from "./../assets/image/purple-coma.png";
import RightArrow from "./../assets/image/right-arrow.png";
import LeftArrow from "./../assets/image/left-arrow.png";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const Testomonial = ({ setRegister, setLogin }) => {
  const slider1 = useRef(null);

  const [revdata, setrevdata] = useState([
    {
      name: "Dr. Anita Ramnarayan",
      title: "Medical adviser, Healthcare specialist, Pharma Trainer.",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam urna diam integer lacus, id mattis. Tempus ultrices in lorem vitae massa tempor, sollicitudin tempus.",
      url: "https://viosa-storage-2.s3.amazonaws.com/Dr.Anita.mp4",
    },
    {
      name: "Mr. Anuj Daftary",
      title:
        "Founder Daftary and associates, Start-up Consultant, CA, Lawyer, Alternative Finance.",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam urna diam integer lacus, id mattis. Tempus ultrices in lorem vitae massa tempor, sollicitudin tempus.",
      url: "https://viosa-storage-2.s3.amazonaws.com/Anuj+Daftery.mp4",
    },
    {
      name: "Mr. Anuj Parekh",
      title: "Co-Founder & CEO @Healthysure, IIM Bangalore",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam urna diam integer lacus, id mattis. Tempus ultrices in lorem vitae massa tempor, sollicitudin tempus.",
      url: "https://viosa-storage-2.s3.amazonaws.com/Anuj+Parekh.mp4",
    },
    {
      name: "Mr. Russell Kane",
      title: "Hon. Director Bombay Education Society",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam urna diam integer lacus, id mattis. Tempus ultrices in lorem vitae massa tempor, sollicitudin tempus.",
      url: "https://viosa-storage-2.s3.amazonaws.com/Russell+Kane.mp4",
    },
  ]);

  const [isDisabledArrowLeft, setIsDisableArrowLeft] = useState(true);
  const [isDisabledArrowRight, setIsDisableArrowRight] = useState(false);

  const handleBack = () => {
    document.getElementById("list-scroll1").scrollLeft -= 360;
    document.getElementById("list-scroll1").scrollLeft <= 380
      ? setIsDisableArrowLeft(true)
      : setIsDisableArrowRight(false);
    // //console.log("Left :", document.getElementById("list-scroll1").scrollLeft);
  };

  const handleForward = () => {
    document.getElementById("list-scroll1").scrollLeft += 360;
    document.getElementById("list-scroll1").scrollLeft !== 0
      ? setIsDisableArrowLeft(false)
      : setIsDisableArrowLeft(false);
    const listLength = revdata.length - 3;
    const isRightDisabled =
      document.getElementById("list-scroll1").scrollLeft > 300 * listLength;
    isRightDisabled
      ? setIsDisableArrowRight(true)
      : setIsDisableArrowRight(false);
    // //console.log("Right:", document.getElementById("list-scroll1").scrollLeft);
  };
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const handleLogin = () => {
    if (!token) {
      setRegister(true);
    } else if (token) {
      navigate("/profile/catalogue");
    }
  };
  document.addEventListener(
    "play",
    function (e) {
      var videos = document.getElementsByClassName("video-test");
      for (var i = 0, len = videos.length; i < len; i++) {
        if (videos[i] != e.target) {
          videos[i].pause();
        }
      }
    },
    true
  );

  return (
    <>
      <div className="test-container">
        <div className="flex test-sub-container">
          <div className="flex left-container">
            <div className="top-img zero-display-xl">
              <img src={Coma} alt="" />
            </div>
            <div className="head-text mt-12 zero-mt-md">
              <h4>Career Insights From Industry Experts</h4>
              {/* <p>Students, professionals, institutions and organisations drive and inspire us and make up the VIOSA community. Here's what they have to say.</p> */}
              <ul className="list-experts">
                <li>Agri Business Management</li>
                <li>Banking & Insurance</li>
                <li>Education</li>
                <li>Entertainment & Media</li>
                <li>Healthcare Management</li>
                <li>Human Resource</li>
                <li>Information Technology</li>
                <li>Legal & Compliance</li>
                <li>Marketing</li>
                <li>Startup Mentor</li>
              </ul>
            </div>
            {/* <div onClick={handleLogin} className="connect-button">
              <button>Course Preview</button>
            </div> */}
          </div>
          <div className="right-container">
            <div className="testomonial-card">
              <div id="list-scroll1" className="list-scroll">
                {revdata.map((el, index) => (
                  <>
                    <div
                      className={`detail-card ${
                        index === 0 && "margin-left-0"
                      }`}
                    >
                      <div className="person-img">
                        <video
                          className="video-test"
                          controls
                          height="150px"
                          width="100%"
                          controlsList="nodownload"
                        >
                          <source src={el.url} />
                        </video>
                        {/* <ReactPlayer
                          url={el?.url}
                          width="100%"
                          playsinline={true}
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          height="150px"
                          className="video-test"
                          controls
                          controlsList="nodownload"
                        /> */}
                      </div>
                      <div className="small-coma">
                        <img src={PurpleComa} alt="" />
                      </div>
                      {/* <div className="person-text">
                                                    <p>sit consectetur adipiscing elit. Quam urna diamiuygh integer lacus, id mattis Quam urna id mattis Quam urna</p>
                                                </div> */}
                      <div className="person-head">
                        <h4 className="person-name">{el.name}</h4>
                        <h4 className="person-desigination">{el.title}</h4>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="scroll-button">
              <button
                id="arrowBtn"
                onClick={handleBack}
                className={`arrow-left-button ${
                  isDisabledArrowLeft ? "disable-button" : "active-button"
                }`}
              >
                <img src={LeftArrow} alt="" />
              </button>
              <button
                id="arrowBtn"
                onClick={handleForward}
                className={`arrow-right-button ${
                  isDisabledArrowRight ? "disable-button" : "active-button"
                }`}
              >
                <img src={RightArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testomonial;
