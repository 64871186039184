import { useEffect, useState } from "react";
import { getAllUserCourses } from "Axios/apis";
import "./livewebinars.css";
import video from "../../icons/courseplaceholder.png";
import { motion } from "framer-motion";
import HeaderTop from "components/HeaderTop";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import notEnrolled from "../../icons/not-enrolled.png";
import rightArrow from "../../icons/right-arrow.svg";
const LiveWebinar = (props) => {
  const [courses, setCourses] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchCourse = async () => {
      const response = await getAllUserCourses(token);
      setCourses(response?.data?.courses);
      // //console.log(response?.data?.courses)
    };
    fetchCourse();
  }, []);

  const liveSessionItems = courses?.filter(
    (course) => course?.liveSession?.length > 0
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="dashboard">
        <>
          <div className="row catalogue">
            <HeaderTop
              togglesidebar={props.togglesidebar}
              name="Live Sessions"
            />
          </div>
          <div>
            {liveSessionItems ? (
              <div>
                <div className="grid mt-4 mb-8">
                  <p className="dont-have-course">
                    No live Sessions Available for Now.
                  </p>

                  <Link to="/profile/catalogue" className="explore">
                    <div className="flex mt-4">
                      <p className="mr-4 explore">Explore Courses </p>
                      <img src={rightArrow} alt="" />
                    </div>
                  </Link>
                </div>

                <img className="not-enrolled-img" src={notEnrolled} alt="" />
              </div>
            ) : (
              <div className="row mt-12 mx-4 justify-content-start">
                {courses?.map((course) =>
                  course.liveSession.map((item) => (
                    <div className="mb-8 cursor-pointer card-border mt-24">
                      <img
                        alt="..."
                        className="block mx-auto card-image"
                        src={item.image || video}
                      />
                      <div>
                        <OverlayTrigger
                          key={item.name || "Live Session"}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              {item.name || "Live Session"}
                            </Tooltip>
                          }
                        >
                          <h1 className="training pt-3">
                            {item.name || "Live Session"}
                          </h1>
                        </OverlayTrigger>

                        <p className="card-para">
                          {item.Startdate + " " + item.start_time ||
                            new Date().toDateString()}
                        </p>
                        <div
                          style={{ color: "#fea302" }}
                          className="px-2 text-end"
                        >
                          <button className="join-session-btn">
                            <a
                              href={item.join_url}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Join Now
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </>
      </div>
    </motion.div>
  );
};

export default LiveWebinar;
