import React, { useRef, useState } from "react";
import messageing from "./../../assets/icon/contact-icon (2).svg";
import google from "./../../assets/icon/google-login.svg";
import homeLogo from "./../../assets/icon/imageHome.png";
import cookies from "js-cookies";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, Signin, SignInByGoogle } from "../../../Axios/apis";
import {
  UserLogin,
  AddtoCart,
  userDashBoard,
} from "../../../redux/user/userAction";
import SignUp from "../SignUp/SignUp";
import { Show } from "../../Show";
import { auth, googleAuthProvider } from "../../../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const Login = ({ setLogin, setBlur }) => {
  let isDev =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000||http://localhost:3001"
      : "https://viosa.vercel.app";

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const closeRef = useRef(null);
  const [register1, setRegister] = useState(false);
  const { userRequest, userRoadMapCourse } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState(false);

  const formHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onForgot = (data) => {
    // //console.log(data);
    const result = form.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    // //console.log(result);
    if (!result) {
      setShowMessage(true);
    }
    setShowMessage(false);
    setPasswordError(false);
    forgotPasswordHandler();
  };
  const forgotPasswordHandler = async () => {
    setIsLoading(true);
    let formData = { email: form.email };
    try {
      const response = await forgotPassword(formData);
      setIsLoading(true);
      setIsLoading(false);
      if (response && response.data.message) {
        setInterval(() => {
          setSuccessMessage(false);
        }, 5000);
        setSuccessMessage(true);
      }
    } catch {
      setSuccessMessage(false);
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    // //console.log(data);
    const result = form.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    // //console.log(result);
    if (!result) {
      setShowMessage(true);
    }
    const passwordValid = form.password.length > 0;
    if (!passwordValid) {
      setPasswordError(true);
      return;
    }
    setShowMessage(false);
    setPasswordError(false);
    signInHandler();
  };

  const googleLoginApiHandler = async (firebaseID, username) => {
    const firebaseId = {
      firebaseId: firebaseID,
    };
    const response = await SignInByGoogle(firebaseId);
    localStorage.setItem("token", response?.data?.token);
    if (response?.data?.token) {
      localStorage.setItem("userName", username);
      localStorage.setItem("email", response?.data?.result?.email);
      localStorage.setItem("role", response?.data?.user?.role);
      localStorage.setItem(
        "InterviewProRole",
        response?.data?.result?.hireProRole
      );
      localStorage.setItem("profileImg", response?.data?.results?.profileImg);
      localStorage.setItem("userId", response?.data?.result?._id);
      localStorage.setItem(
        "InterviewProRole",
        response?.data?.user?.hireProRole
      );
      dispatch(userDashBoard("profile"));
      isDev = "";
      closeRef.current.click();
      dispatch(
        UserLogin({
          name: username,
          loggedIn: true,
          courseList: response?.data?.course || [],
        })
      );
      if (userRoadMapCourse?.courseId) {
        window.location.replace(
          userRoadMapCourse?.navigateTo + "&" + "loggedIn=true"
        );
      } else if (userRequest) {
        navigate(`/profile/catalogue/${userRequest}`);
      } else {
        navigate(`/profile/catalogue`);
      }
    }
  };

  const googleLogin = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // //console.log(credential);
        const user = result.user;
        // //console.log(user);
        const token = user.stsTokenManager.accessToken;

        googleLoginApiHandler(token, user.displayName);
      })
      .catch((error) => {
        // //console.log(error);
      });
  };
  const signInHandler = async (e) => {
    setIsLoading(true);
    setShowMessage(false);
    let formData = { email: form.email, password: form.password };
    try {
      const response = await Signin(formData);
      if (!response.data.user) {
        setMessage("Invalid credentials");
        setShowMessage(true);
        setIsLoading(false);
      } else {
        if (userRoadMapCourse?.courseId) {
          setForm({ email: "", password: "" });
          navigate(userRoadMapCourse?.navigateTo + "&" + "loggedIn=true");
        } else if (userRequest) {
          navigate(`/profile/catalogue/${userRequest}`);
          setForm({ email: "", password: "" });
        } else {
          if (response?.data?.role === "college") {
            navigate(`/profile/college`);
          } else {
            navigate(`/profile/dashboard`);
          }
          setForm({ email: "", password: "" });
          setMessage("");
        }
        dispatch(userDashBoard("profile"));
        isDev = "";
        closeRef.current.click();
        // //console.log(response?.data.user);
        setIsLoading(false);
        dispatch(
          UserLogin({
            name:
              response?.data?.user?.name || response?.data?.user?.collegename,
            email: response?.data?.user?.email,
            date: response?.data?.user?.date,
            courseList: response?.data?.user?.course,
            loggedIn: true,
            role: response?.data?.role,
          })
        );
        dispatch(AddtoCart(response?.data?.user?.cart));
        cookies.setItem("viosa", response?.data?.data);

        cookies.setItem(
          "userName",
          response?.data?.user?.name || response?.data?.user?.collegename
        );
        localStorage.setItem("email", response?.data?.user?.email);
        localStorage.setItem(
          "phone",
          response?.data?.user?.phone || response?.data?.user?.phoneNumber
        );
        localStorage.setItem(
          "userName",
          response?.data?.user?.name || response?.data?.user?.collegename
        );
        localStorage.setItem("role", response?.data?.role);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("userId", response?.data?.user?._id);
        localStorage.setItem(
          "InterviewProRole",
          response?.data?.user?.hireProRole
        );
        localStorage.setItem("profileImg", response?.data?.user?.profileImg);
      }
    } catch (e) {
      // //console.log(e);
      setShowMessage(true);
      setIsLoading(false);
      setMessage("Please Enter Valid Creaentials");
      setInterval(() => {
        setShowMessage(false);
      }, 3000);
      setIsLoading(false);

      setShowMessage(true);
      setMessage("Invalid credentials");
      // //console.log(e);
    }
  };

  const handleSignUp = () => {
    setRegister(true);
    handleRemoveBlur();
    setShow(false);
    closeRef.current.click();
  };
  const handleRemoveBlur = () => {
    setBlur(false);
  };
  const handlePasswordShowHide = () => {
    if (!password) {
      setPassword(true);
    } else {
      setPassword(false);
    }
  };

  return (
    <div>
      <div>
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
        <div className={`modal stop-scroll modal-dev`}>
          <div className="relative modal-box">
            <label
              onClick={() => {
                handleRemoveBlur();
                setShow(false);
                setPassword(false);
              }}
              setBlur={setBlur}
              htmlFor="my-modal-4"
              className="absolute  btn btn-sm btn-circle right-2 top-2 hover:text-white "
              ref={closeRef}
            >
              ✕
            </label>
            <div>
              <div>
                <img
                  className="block mx-auto mb-4 w-28"
                  src={homeLogo}
                  alt=""
                />
                <p className="mb-3 flex justify-center login-heading">
                  Login to your VIOSA Account
                </p>
              </div>
              <Show condition={!show && !successMessage}>
                <div className="flex justify-center">
                  <div>
                    <div className="mt-1 input-border hover:shadow-2xl">
                      <div className="flex items-center  ">
                        <i className="fa-solid fa-envelope mx-4 for-opacity text-2xl mb-2"></i>
                        {/* <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        /> */}
                        <input
                          style={{ background: "#FFF7E7" }}
                          onChange={(e) => formHandler(e)}
                          type="email"
                          name="email"
                          value={form.email || ""}
                          // {...register("email")}
                          className="pl-2 input-field"
                          // className={`${
                          //   "pl-2 input-field" + errors?.email?.message.length
                          //     ? "error-outline"
                          //     : ""
                          // }`}
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                    <div>
                      <span className="error-message error-message-email">
                        {showMessage && "Please Enter Valid Email Address"}
                      </span>
                    </div>
                    <div className="mb-2 extra-margin input-border hover:shadow-2xl">
                      <div className="flex items-center">
                        <svg
                          style={{ marginTop: 5 }}
                          className="fa-regular fa-lock mx-3 for-opacity text-2xl mb-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                        </svg>
                        {/* <img
                          className="mx-4 for-opacity"
                          src={password1}
                          alt=""
                        /> */}
                        <input
                          style={{ background: "#FFF7E7", paddingLeft: 24 }}
                          className="pl-2 input-field"
                          type={!password ? "password" : "text"}
                          onChange={(e) => formHandler(e)}
                          name="password"
                          autoComplete="off"
                          value={form.password || ""}
                          // {...register("password")}
                          placeholder="Enter the password"
                        ></input>
                        {!password ? (
                          <i
                            style={{ marginTop: 5 }}
                            onClick={handlePasswordShowHide}
                            className="fa-solid fa-eye text-xl ml-5"
                          ></i>
                        ) : (
                          <i
                            style={{ marginTop: 5 }}
                            onClick={handlePasswordShowHide}
                            className="fa-solid text-xl ml-5 fa-eye-slash"
                          ></i>
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="error-message error-message-password">
                        {passwordError && "Please Enter Password"}
                      </span>
                    </div>
                    <div>
                      <span className="error-message error-message-error">
                        {message && message}
                      </span>
                    </div>
                    <span className="flex justify-end">
                      <span
                        onClick={() => setShow(true)}
                        className="forget-password my-1"
                      >
                        Forgot Password ?
                      </span>
                    </span>
                    <div className="flex justify-center">
                      <div className="mt-1 mb-1 lg:ml-2 md:ml-2">
                        {!isLoading ? (
                          <button
                            htmlFor="my-modal-1"
                            type="submit"
                            onClick={onSubmit}
                            className="forHoverContact-button hover:shadow-2xl"
                          >
                            <div className="flex items-center justify-center">
                              <p className="text-xl">Login</p>
                            </div>
                          </button>
                        ) : (
                          <button
                            htmlFor="my-modal-1"
                            type="submit"
                            onClick={onSubmit}
                            className="forHoverContact-button hover:shadow-2xl"
                          >
                            <div className="flex items-center justify-center">
                              <p className="text-xl">Login...</p>
                              <div
                                className="spinner-border text-light ml-4"
                                role="status"
                              ></div>
                            </div>
                          </button>
                        )}
                        {/* <button
                          htmlFor="my-modal-1"
                          type="submit"
                          onClick={onSubmit}
                          className="forHoverContact-button hover:shadow-2xl"
                        >
                          {isLoading ? "Login..." : "Login"}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Show>
              <Show condition={show && !successMessage}>
                <img
                  onClick={() => setShow(false)}
                  className="mx-4 cursor-pointer"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAwklEQVRYhe2Vuw4BQRRAz0w0ErViVWt1/JOGwh+QsNmshp6g8iU+Q7uFglBruSoJsQ/F7Ihkzgfcc5LJvQMOh8PxL5zCoHsetwLTc/V38uZAkO1N33eXqF2zGnAM/b7AAlACq3q0v5oMUEVyUGtACwwbcTI3Kc8NsCHPDLAlTw14lZch9OLkzfkpEaQMcRbpTzDxeyi1ATTCyJsmM6sBNiPy19BCRG6AjYjCAHieYpbAoaKrHdPX8CvK+owcDofj5zwAMNZOj9RwihQAAAAASUVORK5CYII="
                ></img>
                <div className="flex justify-center">
                  <div className="m-3 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={messageing}
                        alt=""
                      />
                      <input
                        style={{ background: "#FFF7E7" }}
                        onChange={(e) => formHandler(e)}
                        type="email"
                        name="email"
                        className="pl-2 input-field"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span className="error-message error-message-email">
                    {showMessage && "Please Enter Valid Email Address"}
                  </span>
                </div>
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="my-4 lg:ml-6 md:ml-6 ">
                      <button
                        htmlFor="my-modal-1"
                        type="submit"
                        onClick={onForgot}
                        className="forHoverContact-button hover:shadow-2xl"
                      >
                        {!isLoading ? (
                          <div className="flex items-center justify-center">
                            <p className="text-xl">Reset</p>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center">
                            <p className="text-xl">Sending...</p>
                            <div
                              className="spinner-border text-light ml-4"
                              role="status"
                            ></div>
                          </div>
                        )}
                        {/* {isLoading ? "Sending..." : "Reset"} */}
                      </button>
                    </div>
                  </div>
                </div>
              </Show>
              <Show condition={successMessage}>
                <div className="px-5 my-5 success-message">
                  <p>
                    {successMessage
                      ? `Email Send to ${form.email}, Please Check Your Email.`
                      : ""}
                  </p>
                </div>
              </Show>
            </div>
            <div className="text-center mt-3" style={{ fontSize: "12px" }}>
              If you are an existing user and unable to login, Please go to
              forgot password
            </div>
            <div className="divider">OR</div>

            <div className="flex items-center justify-center">
              <div
                className="flex items-center alternative-login justify-content-center"
                onClick={googleLogin}
                style={{
                  border: "1px solid gainsboro",
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div>
                  <img className="w-6 mx-2" src={google} alt="" />
                </div>
                <div>
                  <p>Sign Up With Google</p>
                </div>
              </div>
            </div>

            <p className="flex justify-center my-1 text-xl mt-3">
              Create an account{" "}
              <label
                onClick={handleSignUp}
                htmlFor="my-modal-6"
                className="modal-button ml-2"
                style={{ color: "#fea302", cursor: "pointer" }}
              >
                {" "}
                Sign up{" "}
              </label>{" "}
            </p>

            <p className="login-term-contidition text-center">
              *By joining and using VIOSA Learning platform, you agree to our{" "}
              <span
                onClick={() =>
                  window.open("https://viosa.in/privacy-policy", "_blank")
                }
                className="ml-1"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Terms and Policies
              </span>
            </p>
          </div>
        </div>
      </div>
      {register1 && (
        <SignUp
          register1={register1}
          setLogin={setLogin}
          setRegister={setRegister}
          setBlur={setBlur}
        ></SignUp>
      )}
    </div>
  );
};

export default Login;
