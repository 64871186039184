import React from "react";
import home2 from "./../assets/image/Ellipse 318.png";
import home3 from "./../assets/image/resume2.png";
import home4 from "./../assets/image/resume1.png";
import teaching from "./../assets/image/resume-image.jpg";
import play from "./../assets/image/play-button.png";
import heading from "./../assets/image/new-home.png";
import inr from "./../assets/icon/inr.svg";
import twelve from "./../assets/icon/1200.svg";
import nine from "./../assets/icon/₹ 999.svg";
import FrequentlyAsked from "./FrequentlyAsked";
import TimelineComp from "../../components/TimelineComp/TimelineComp";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const Job = () => {
  const timelinedata = [
    "Start Assessment",
    "Set your Goals",
    "Test your Skills",
    "Get Report Analysis of your Performance",
  ];
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <div>
            <img className="home-curve-image" src={home2} alt="" />
            <div>
              <div className="flex items-center home-curve-image">
                <div>
                  <img className="w-96" src={home4} alt="" />
                </div>
                <div className="home-heading">
                  <h1 className="flex justify-center professional-heading">
                    Job Easy
                  </h1>
                  <p className="flex justify-center professional-para">
                    Using text generation AI and billions of data points,{" "}
                  </p>
                  <p className="flex justify-center professional-para">
                    This tool will write the perfect resume for you.
                  </p>
                </div>
                <div className="mt-4 flex-end for-1440">
                  <img className="w-96" src={home3} alt="" />
                </div>
              </div>
              <h1
                className="flex justify-center pt-[500px] mx-16"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                Save time searching endlessly on various job sites.
              </h1>

              <h1
                style={{ fontSize: "18px", fontWeight: "500", opacity: "0.5" }}
                className="flex justify-center mx-16"
              >
                Get your personalised job recommendations & apply automatically.
                Track your
              </h1>

              <h1
                style={{ fontSize: "18px", fontWeight: "500", opacity: "0.5" }}
                className="flex justify-center mx-16"
              >
                applications with a personalised dashboard.
              </h1>
            </div>
            <div className="mx-16 mt-8">
              <div className="flex items-center justify-between">
                <img className="first-width" src={heading} alt="" />
                <div className="flex items-center justify-center">
                  <img className="teacher-image" src={teaching} alt="" />
                  <img className="w-16 play-image" src={play} alt="" />
                </div>
              </div>
            </div>

            <div className="mx-16 mt-8 ">
              <div className="flex items-center justify-between">
                <div className="interview-width">
                  <div>
                    <h1
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginBottom: "7px",
                      }}
                    >
                      This course includes:
                    </h1>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      Course duration: 120 minutes.
                    </p>
                  </div>
                  <div className="my-2">
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      - Demo to create automated job loop{" "}
                    </p>

                    <p
                      className="my-2"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      - Coaching on job application skills
                    </p>

                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      - Automate your emails
                    </p>

                    <p
                      className="my-2"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      - Automate your applications{" "}
                    </p>
                  </div>
                </div>
                <div className="card-background">
                  <div>
                    <div className="flex items-center justify-center mb-4">
                      <img src={inr} alt="" />
                      <img src={twelve} alt="" />
                    </div>

                    <div className="flex items-center justify-center">
                      <img src={nine} alt="" />
                    </div>
                  </div>

                  <div>
                    <button className="my-4 buy-button w-100">Buy Now</button>
                    <button className="add-cart w-100">Add to Cart</button>
                  </div>
                </div>
              </div>
            </div>

            <section className="py-10 row roadmap-section-container">
              <TimelineComp data={timelinedata} />
            </section>

            <div className="flex items-center justify-center">
              <img
                className="flex justify-center mx-16 mb-12 interview-image"
                src={teaching}
                alt=""
              />
              <img className="w-16 play-image" src={play} alt="" />
            </div>

            {/* <img className='flex justify-center mx-16 mb-12 rectangle-image' src={teaching} alt="" /> */}

            <div className="">
              <p className="flex justify-center Frequently-heading">
                Frequently Asked{" "}
                <span
                  style={{ color: "rgba(255, 163, 3, 1)", marginLeft: "10px" }}
                >
                  {" "}
                  Questions{" "}
                </span>{" "}
              </p>
            </div>
            <FrequentlyAsked></FrequentlyAsked>
          </div>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default Job;
