import React, { useEffect, useRef, useState } from "react";
import homeLogo from "./../../assets/icon/imageHome.png";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "components/Modal/Modal";
import { resetPasswordApi, Signin } from "Axios/apis";
import { async } from "@firebase/util";

const ChangePassword = ({ setRegister }) => {
  const closeRef = useRef(null);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const [confirmPassword, setComfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [enterPassword, setEnterPassword] = useState(false);
  const [surePassword, setSurePassword] = useState(false);
  const [passwordChange, setForgetPasswordChanged] = useState(false);
  const [changepasswordform, setchangepasswordform] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const id = useParams();
  const formHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onSubmit = async (data) => {
    // signUpHandler();

    setIsLoading(true);
    let formData = { password: form.password };
    try {
      const response = await resetPasswordApi(formData, id.id);
      setMessage("Password reset successfully");
      if (response.data.result.status) {
        setMessage("Password reset successfully");
        setchangepasswordform(false);
        setForgetPasswordChanged(true);
        alert("Password Reset Successfully");
        setIsLoading(false);
        navigate("/");
      }
    } catch (e) {
      setIsLoading(false);
      setMessage("Password reset failed");
      // //console.log(e);
    }
  };

  const onBlurValidation = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "password":
        if (
          !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
            e.target.value
          )
        ) {
          setPasswordError(true);
          return;
        } else {
          setPasswordError(false);
        }
        break;
      case "confirmPassword":
        if (form.password !== form.confirmPassword) {
          setComfirmPassword(true);
          setPasswordError(false);
          return;
        } else {
          setComfirmPassword(false);
        }
    }
  };

  const handlePasswordShowHide = () => {
    if (!enterPassword) {
      setEnterPassword(true);
    } else {
      setEnterPassword(false);
    }
  };

  const handleConfirmPasswordShowHide = () => {
    if (!surePassword) {
      setSurePassword(true);
    } else {
      setSurePassword(false);
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <div className="signup-modal">
        <Modal onClose={() => setRegister(false)} show={changepasswordform}>
          <div>
            <div className="relative mb-4 pt-3">
              <div>
                <div>
                  <img
                    className="block mx-auto mb-1 w-28"
                    src={homeLogo}
                    alt=""
                  />
                  <p className="flex justify-center mb-2 login-heading-sign-up">
                    Reset Your Password
                  </p>
                </div>
                <div>
                  <div className="">
                    <div className="mt-4 mb-2 input-border hover:shadow-2xl">
                      <div className="flex">
                        <i className="fa-solid fa-lock mx-4 for-opacity text-2xl mb-2"></i>
                        {/* <img className="mx-4 for-opacity" src={password1} alt="" /> */}
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          onBlur={(e) => onBlurValidation(e)}
                          type={!enterPassword ? "password" : "text"}
                          name="password"
                          placeholder="Enter new Password"
                        ></input>
                        {!enterPassword ? (
                          <i
                            onClick={handlePasswordShowHide}
                            className="fa-solid fa-eye text-xl ml-12"
                          ></i>
                        ) : (
                          <i
                            onClick={handlePasswordShowHide}
                            className="fa-solid text-xl ml-12 fa-eye-slash"
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="px-3 mx-5">
                      <span className="flex  error-message w-100 error-message-password">
                        {passwordError &&
                          "Password must contain at least 8 characters, one uppercase and lowercase, one number and one special case character"}
                      </span>
                    </div>
                    <div className={`mt-4 input-border hover:shadow-2xl`}>
                      <div className="flex  mb-2">
                        {/* <img className="mx-4 for-opacity" src={password1} alt="" /> */}
                        <i className="fa-solid fa-lock mx-4 for-opacity text-2xl mb-2"></i>
                        <input
                          style={{ background: "#FFF7E7" }}
                          onChange={(e) => formHandler(e)}
                          className="pl-2 input-field"
                          type={!surePassword ? "password" : "text"}
                          onBlur={(e) => onBlurValidation(e)}
                          name="confirmPassword"
                          placeholder="Confirm your password"
                        ></input>
                        {!surePassword ? (
                          <i
                            onClick={handleConfirmPasswordShowHide}
                            className="fa-solid fa-eye text-xl ml-12"
                          ></i>
                        ) : (
                          <i
                            onClick={handleConfirmPasswordShowHide}
                            className="fa-solid text-xl ml-12 fa-eye-slash"
                          ></i>
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="error-message w-100 error-message-password1 px-3 mx-5">
                        {confirmPassword && "Password does not match"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <div className="mt-4">
                  <button
                    for="my-modal-1"
                    onClick={onSubmit}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {!isLoading ? (
                      <div className="flex items-center justify-center">
                        <p className="mt-[2px] text-xl">Submit</p>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <p className="text-xl">Submitting...</p>
                        <div
                          className="spinner-border text-light ml-4"
                          role="status"
                        ></div>
                      </div>
                    )}
                  </button>
                  <div>
                    <small className="text-danger">{message}</small>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ChangePassword;
