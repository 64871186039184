const initialState = {
  course: "",
  courseLink: {},
  singleCourse: [],
  singleCourse2: [],
  courseBody: {},
  loader: false,
  searchValue: "",
};
export const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COURSE_ID":
      return {
        ...state,
        course: action.payload,
      };
    case "GET_COURSE_LINKS":
      return {
        ...state,
        courseLink: action.payload,
      };
    case "SINGLE_COURSE_DATA":
      return {
        ...state,
        singleCourse: action.payload,
      };
    case "SINGLE_COURSE_DATA_2":
      return {
        ...state,
        singleCourse2: action.payload,
      };
    case "COURSE_BODY":
      return {
        ...state,
        courseBody: action.payload,
      };
    case "SEARCH_COURSE":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "LOADING":
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};
