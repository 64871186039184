import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllCourse,
  getAllCourseWithPrice,
  getAllUserCourses,
} from "../Axios/apis";
import video from "icons/courseplaceholder.png";
import "./Catalogue.css";
import road from "assets/road.png";
import PureModal from "react-pure-modal";
import "./Admin.css";
import { useDispatch, useSelector } from "react-redux";
import { Show } from "./Show";
import { courseId } from "../redux/coures/courseAction";
import { UserLogin } from "../redux/user/userAction";
import "swiper/css";
import "swiper/css/pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FillDetailsModal from "./Dashboard/FillDetailsModal";
import { motion } from "framer-motion";
import HeaderTop from "./HeaderTop";
import Skeleton from "./Skelton/Skelton";
import Spinner from "./Spinner/Spinner";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import axios from "axios";

const Catalogue = (props) => {
  const [courses, setCourses] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState("");
  const [nextid, setNextId] = useState("");
  const [path, setPath] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [purchage, setPurchage] = useState(false);
  const [loadspinner, setloading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTools, setisTools] = useState(false);
  const [tools, setTools] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // //console.log(event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [totalLive, setTotalLive] = useState(0);
  const [totalChapter, SettotalChapter] = useState(0);
  const { courseList } = useSelector((state) => state.user.userData);
  const { searchValue } = useSelector((state) => state.course);
  const navigate = useNavigate();
  useEffect(() => {
    const totalLive1 = courseList?.map((item) =>
      setTotalLive((prev) => prev + item.liveSession.length)
    );
    const totalChapter = courseList?.map((item) =>
      item.section.reduce((total, section) => {
        SettotalChapter((prev) => prev + section.chapter.length);
      }, 0)
    );
  }, []);

  // //console.log({ totalLive });

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsLoading(true);
    const fetchCourse = async () => {
      try {
        const response = await getAllUserCourses(token);
        // //console.log(response.data.courses);
        setIsLoading(false);
        dispatch(
          UserLogin({
            courseList: response?.data?.courses,
            loggedIn: true,
          })
        );
      } catch (err) {
        setIsLoading(false);
        // //console.log(err);
      }
    };
    fetchCourse();
  }, [purchage]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isOpened]);
  const [allCourses, setAllCourses] = useState([]);
  useEffect(() => {
    // //console.log(token);
    // ;
    const fetchCourse = async () => {
      const response = await getAllUserCourses(token);
      // //console.log(response.data.courses);

      dispatch(
        UserLogin({
          courseList: response?.data?.courses,
          loggedIn: true,
        })
      );
    };
    fetchCourse();
  }, [purchage]);

  var course;
  // //console.log(course)

  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);
      const result = await axios.get("https://ipapi.co/json");
      // const response = await getAllCourseWithPrice({
      //   country: result?.data?.country,
      //   country_calling_code: result?.data?.country_calling_code,
      // });
      const response = await getAllCourse();

      const data3 = response?.data?.courses?.sort((a, b) => a.order - b.order);
      const filtered = data3?.filter(
        (el) =>
          el.isCounselling === false &&
          el?.name?.toLowerCase()?.includes("dashboard") === false
      );
      setCourses(filtered || []);
      setAllCourses(response?.data?.courses);
      const alltools = response?.data?.courses?.filter(
        (tool) => tool?.isTool === true
      );
      setTools(alltools);
      console.log(alltools);
      // debugger
      setloading(false);
    };
    fetchCourse();
  }, []);

  // useEffect(() => {
  //   const result = allCourses?.filter((el) => {
  //     return el.name.toLowerCase().includes(searchValue);
  //   });
  //   setCourses(result);
  // }, [searchValue]);
  // //console.log(searchValue)

  useEffect(() => {
    handleAll();
  });

  let specificCourse = courses
    ?.sort((a, b) => a.order - b.order)
    .filter((data) => data?.isCounselling === false);

  console.log(allCourses);

  const handleAll = () => {
    setisTools(false);
    setCourses(allCourses);
    handleClose();
  };
  const handleTools = () => {
    setisTools(true);
    setCourses(tools);
    handleClose();
  };

  const handlePaid = () => {
    // //console.log("paid")
    const paidCourse = allCourses.filter((data) => data.price !== 0);
    setCourses(paidCourse);
    handleClose();
    // //console.log(paidCourse)
  };

  const handleFree = () => {
    const freeCourse = allCourses.filter((data) => data.price === 0);
    setCourses(freeCourse);
    handleClose();
    // //console.log(freeCourse)
  };
  const handleNavigate = (course_Id, category, isCounselling, name, order) => {
    console.log(course_Id, category);
    if (
      !isCounselling &&
      (name?.toLowerCase().includes("roadmap") ||
        name?.toLowerCase().includes("8th") ||
        name?.toLowerCase().includes("11th"))
    ) {
      setPath(
        name?.toLowerCase().includes("8th") === true ||
          name?.toLowerCase().includes("11th") === true
          ? `road/`
          : ``
      );
      setCourseId(course_Id);
      const nextId = allCourses?.filter((data) => data?.order === order + 1);
      // setNextId(nextId[0]?.course_id);
      // setShow(true);
      navigate(`${nextId[0]?.course_id}`);
    } else {
      if (category.toLowerCase().includes("8-12 student")) {
        navigate(`road/${course_Id}`);
      } else {
        navigate(`${course_Id}`);
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PureModal
        isOpen={show}
        style={{ borderRadius: "10px" }}
        closeButton="X"
        width={900}
        closeButtonPosition="header"
        onClose={() => {
          setShow(false);
          return true;
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mt-2 mb-4">
            <img src={road} className="counseling-popup-img"></img>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ justifyContent: "space-around", width: "100%" }}
          >
            <button
              className="btn btn-warning new-button-1 counseling-popup-button"
              style={{
                backgroundColor: "#FEA302",
                color: "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate(path + nextid);
              }}
            >
              With Counseling
            </button>
            <button
              className="btn btn-outline-warning new-button-2 counseling-popup-button"
              style={{
                border: "2px solid #FEA302",
                color: "#000",
                backgroundColor: "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate(path + courseid);
              }}
            >
              Without Counseling
            </button>
          </div>
          <div className="my-2">
            <h1 style={{ fontSize: "35px", color: "#eb6a3f" }}>
              {/* {props.paymentsuccess ? "All the Best!" : "Try Again"} */}
            </h1>
          </div>
        </div>
      </PureModal>
      <FillDetailsModal />
      {/* <ChangePassword/> */}
      <div>
        <Show condition={!isOpened}>
          <div className="catalogue row">
            <HeaderTop
              courses={courses}
              setCourses={setCourses}
              togglesidebar={props.togglesidebar}
              name="All Courses"
            />
            {loadspinner ? (
              <Spinner />
            ) : (
              <>
                <div className="recommended">
                  <div className="my-4 flex items-center justify-between align-items-center">
                    <div className="flex items-center mb-3">
                      <h1
                        onClick={handleAll}
                        className={`c-pointer mr-2 text-xl ${
                          !isTools ? "update-heading text-xl" : ""
                        }`}
                      >
                        Courses
                      </h1>
                      /
                      <h1
                        onClick={handleTools}
                        className={`c-pointer px-2 text-xl ${
                          isTools ? "update-heading text-xl" : ""
                        }`}
                      >
                        {" "}
                        Tools
                      </h1>
                    </div>
                    {/* <div className="setting-icon-pos">
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        className="linkT NavICon"
                      >
                        <i class="setting-icon fa-solid fa-gear"></i>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleAll();
                          }}
                          style={{
                            width: "150px",
                            justifyContent: "center",
                            borderBottom: "2px solid gainsboro",
                          }}
                        >
                          <i class="fa-brands fa-microsoft"></i>
                          <span className="ml-2">All</span>
                        </MenuItem>
                        <MenuItem
                          style={{
                            width: "150px",
                            justifyContent: "center",
                            borderBottom: "2px solid gainsboro",
                          }}
                          onClick={() => handlePaid()}
                        >
                          <i class="fas fa-dollar"></i>
                          <span className="ml-2">Paid</span>
                        </MenuItem>
                        <MenuItem
                          style={{ width: "150px", justifyContent: "center" }}
                          onClick={() => handleFree()}
                        >
                          <i class="fas fa-bolt"></i>
                          <span className="ml-2">Free</span>
                        </MenuItem>
                      </Menu>
                    </div> */}
                  </div>
                  {loading ? (
                    <div className="row" key={Math.random(100000)}>
                      <Skeleton type="course" />
                    </div>
                  ) : (
                    <div className="">
                      <div className="row mt-12 mx-4 justify-content-start">
                        {courses?.map((course, idx) => (
                          <>
                            <div
                              key={idx}
                              className="mb-8 cursor-pointer card-border mt-24"
                            >
                              <a
                                // to={`${
                                //   course.order < 5
                                //     ? "road/" + course?.course_id
                                //     : course?.course_id
                                // }`}
                                onClick={() =>
                                  handleNavigate(
                                    course?.course_id,
                                    course?.category,
                                    course?.isCounselling,
                                    course?.name,
                                    course?.order
                                  )
                                }
                              >
                                <img
                                  className="block mx-auto card-image"
                                  src={course.thumbnail || video}
                                  alt=""
                                />
                                <div>
                                  <div className="heading-card-div">
                                    <OverlayTrigger
                                      key={course?.name}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {" "}
                                          {course?.name?.split("(")[0]}
                                        </Tooltip>
                                      }
                                    >
                                      <h1 className="training pt-3">
                                        {course?.name?.split("(")[0]}
                                      </h1>
                                    </OverlayTrigger>
                                    {/* {course?.description?.length ? (
                                        <OverlayTrigger
                                          key={course?.description}
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id={`tooltip-bottom`}>
                                              {" "}
                                              {course?.description}
                                            </Tooltip>
                                          }
                                        >
                                          <p
                                            className="card-para"
                                            style={{ paddingLeft: "10px" }}
                                          >
                                            {course?.description
                                              ? course.description.slice(
                                                  0,
                                                  84
                                                ) + "..."
                                              : ""}
                                          </p>
                                        </OverlayTrigger>
                                      ) : null} */}
                                  </div>
                                  <div
                                    style={{
                                      color: "#fea302",
                                      marginBottom: "10px",
                                    }}
                                    className="px-2 text-end mt-2"
                                  >
                                    <button
                                      onClick={() =>
                                        handleNavigate(
                                          course?.course_id,
                                          course?.category,
                                          course?.isCounselling,
                                          course?.name,
                                          course?.order
                                        )
                                      }
                                      className="join-session-btn"
                                    >
                                      View Course
                                    </button>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        ))}
                      </div>

                      <div
                        className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1"
                        style={{ position: "" }}
                      >
                        {/* <ArrowButton slider={slider1} />
                <ArrowNext slider={slider1} /> */}
                      </div>
                    </div>
                  )}

                  {/* Recomendations */}
                  {/* <div className="flex items-center justify-between">
                <strong style={{ fontSize: "25px" }} className="mt-4">
                  Recomendations
                </strong>
              </div>

              <div className="">
                <div className="row mt-12 mx-4">
                  {specificCourse.map((course, idx) => (
                    <div
                      key={idx}
                      className="mb-8 cursor-pointer card-border mt-24"
                    >
                      <img
                        className="block mx-auto card-image"
                        src={course?.thumbnail || video}
                        alt=""
                      />
                      <div>
                        <OverlayTrigger
                          key={course.name ? course.name : "Course Name"}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              {course.name ? course.name : "Course Name"}
                            </Tooltip>
                          }
                        >
                          <h1 className="training pt-3">
                            {course.name ? course.name : "Course Name"}
                          </h1>
                        </OverlayTrigger>

                        <p className="card-para">
                          {course?.description
                            ? course.description.slice(0, 84)
                            : "This combo package contains 5 courses in....."}
                          ...
                        </p>
                        <div
                          style={{ color: "#fea302" }}
                          className="px-2 text-end"
                        >
                          <button
                            onClick={() => {
                              setIsOpened(true);
                              dispatch(courseId(course.course_id));
                            }}
                            className="join-session-btn"
                          >
                            Read More
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1"
                  style={{ position: "" }}
                >

                </div>
              </div> */}
                </div>
              </>
            )}
          </div>
        </Show>
      </div>
    </motion.div>
  );
};

export default Catalogue;
