import React, { useEffect, useState } from "react";
import AvailableCourse from "./AvailableCourse";
import JobReady from "./JobReady";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";
import Spinner from "components/Spinner/Spinner";
import { getAllCourse } from "Axios/apis";
import CareerMaping from "./CareerMaping";
import AllCourses from "./../assets/image/coursepageimg.png";
import HighCourses from "./../assets/image/course-8-12.jpg";
import UnderCourses from "./../assets/image/course-under.jpg";
import PostCourses from "./../assets/image/course-proff.jpg";
import ProffCourses from "./../assets/image/course-post.jpg";

const StudentCourse = ({ course, setCourse }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [coursedata, setcoursedata] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    const getalldata = async () => {
      try {
        setloading(true);
        const data = await getAllCourse();
        setcoursedata(data?.data?.courses);
        setloading(false);
      } catch (error) {
        console.error(error);
      }
      setloading(false);
    };
    getalldata();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="container-fluid">
              {
                course === "8-12 Student" ? <CareerMaping heading="CareerMapping" bannerImg={HighCourses} /> : course === "Undergraduate/Graduate" ? <CareerMaping heading="BanoJobReady" bannerImg={UnderCourses} /> : course === "Post Graduate" ? <CareerMaping heading="BanoJobReady" bannerImg={PostCourses} /> : <CareerMaping heading="BanoJobReady" bannerImg={ProffCourses} />
              }
              {/* {
                course === "8-12 Student" ? <CareerMaping /> : <JobReady />
              } */}
              <AvailableCourse
                course={course}
                setCourse={setCourse}
                coursedata={coursedata}
              ></AvailableCourse>
            </div>
          </motion.div>
          {/* <FooterPurple /> */}
        </>
      )
      }
    </>
  );
};

export default StudentCourse;
