import React, { useEffect, useState } from "react";
import Classes from "./modal.module.css";

const Modal = ({ show, onClose, children, title, subtitle }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  return show ? (
    <div className={Classes.ModalOverlay}>
      <div className={Classes.StyledModal}>
        {title && <h4 className="px-3">{title}</h4>}
        {subtitle && <p className="px-3 py-2">{subtitle}</p>}
        <div className={Classes.StyledModalBody}>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
