import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const Protected = () => {
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);
  const isLoggedIn = userData.loggedIn;
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to="/profile/catalogue"
      redirect="/profile/catalogue"
      replace
      state={{ from: location }}
    />
  );
};

export default Protected;
