import React, { useState } from "react";
import career from "./../assets/image/career (3).png";
import career2 from "./../assets/image/career (2).png";
import downArrow from "./../assets/icon/down-arrow.svg";
import upArrow from "./../assets/icon/up-arrow.svg";
import human from "./../assets/icon/human.svg";
import careerHome from "./../assets/image/career-picture.png";
import ourLickage from "./../assets/image/our-lickage (1).png";
import ourLickage2 from "./../assets/image/our-lickage (2).png";
import ourLickage3 from "./../assets/image/our-lickage (3).png";
import ourLickage4 from "./../assets/image/our-lickage (4).png";
import ourLickage5 from "./../assets/image/our-lickage (5).png";
import FooterPurple from "components/Footer/FooterPurple";
import { motion } from "framer-motion";

const CertifiedCareer = () => {
  const [card, setCard] = useState(false);
  const [blur, setBlur] = useState(false);
  const handleCardToggle = () => {
    if (!card) {
      setCard(true);
    } else {
      setCard(false);
    }
  };
  const handleBlur = (event) => {
    const data = event.target.value;
    if (data === "show") {
      setBlur(true);
    } else {
      setBlur(false);
    }
    // //console.log(data);
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="mb-8">
          <div className="flex items-center justify-between mx-12">
            <div>
              <h1 className="certified-career">
                Become{" "}
                <span style={{ color: "#fea302" }}>
                  a Certified Career Coach
                </span>{" "}
                with us.
              </h1>
              <button className="sign-up-career">Sign Up</button>
            </div>
            <div>
              <img src={careerHome} alt="" />
            </div>
          </div>

          <div className="mt-16">
            <p className="ml-12 our-corporate">
              Our{" "}
              <span className="mx-2" style={{ color: "#fea302" }}>
                {" "}
                Corporate{" "}
              </span>{" "}
              Linkages
            </p>
            <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-4">
              <div className="flex items-center justify-center">
                <img src={ourLickage2} alt="" />
              </div>

              <div className="flex items-center justify-center">
                <img src={ourLickage5} alt="" />
              </div>

              <div className="flex items-center justify-center">
                <img src={ourLickage4} alt="" />
              </div>

              <div className="flex items-center justify-center">
                <img src={ourLickage3} alt="" />
              </div>

              <div className="flex items-center justify-center">
                <img src={ourLickage} alt="" />
              </div>
            </div>
            <button className="mt-12 ml-12 New-Requirement">
              New Requirement
            </button>
          </div>

          <div>
            <div className="flex m-12">
              <div>
                <div className="select-dropdown">
                  <div className="px-4 py-6">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Job Role Type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <select
                      className="form-select my-4"
                      aria-label="Default select example"
                    >
                      <option selected>Location</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Skills</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <select
                      className="form-select my-4"
                      aria-label="Default select example"
                    >
                      <option selected>Qualification</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Experience</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-center my-12 access-code-card">
                  <div>
                    <input
                      onChange={handleBlur}
                      className="flex justify-center pl-2 access-code-input"
                      type="text"
                      placeholder="Have any access code?"
                    />
                    <p>
                      Don’t have any access Code?{" "}
                      <span
                        style={{
                          color: "rgba(82, 25, 134, 1)",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        Get It Now{" "}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className={`pt-6 mx-10 ${
                    card ? "identification" : "less-identification"
                  }`}
                >
                  <div className="flex items-center less-identification-res justify-between">
                    <img className="w-20 ml-6" src={human} alt="" />
                    <div>
                      <p className="font-bold d-none-768">Name</p>
                      <p className="my-4 font-bold d-none-768">Skills</p>
                      {card && (
                        <p className="font-bold d-none-768">Experience</p>
                      )}

                      {card && (
                        <p className="my-4 font-bold d-none-768">
                          Qualification
                        </p>
                      )}
                    </div>
                    <div className="lg:mr-6">
                      <p>Rohit Pal</p>
                      <p className="my-4">Wireframes, Prototyping, App UI</p>
                      {card && (
                        <p className={`${!blur ? "make-blur" : ""}`}>
                          1 year of experiance
                        </p>
                      )}

                      {card && (
                        <p className={`my-4 ${!blur ? "make-blur" : ""}`}>
                          Graduation
                        </p>
                      )}
                    </div>
                  </div>
                  {card && (
                    <span
                      className="flex justify-center my-4"
                      style={{
                        color: "rgba(82, 25, 134, 1)",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Get In Touch{" "}
                    </span>
                  )}
                  {!card ? (
                    <img
                      onClick={handleCardToggle}
                      className="block w-6 mx-auto"
                      src={downArrow}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={handleCardToggle}
                      className="block w-6 mx-auto"
                      src={upArrow}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>{/* <FooterPurple></FooterPurple> */}</div>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default CertifiedCareer;
