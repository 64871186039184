export const careerAssessmentCD = [
  {
    id: "6343bf2ae33839d145810ec6",
    value: 89,
    optionSelect: false,
    courseName: "Career Map for 9th & 10th with counselling",
  },
  {
    id: "6343bbfee33839d145810ea0",
    value: 89,
    optionSelect: false,
    courseName: "Career Map for 9th & 10th with counselling",
  },
  // {
  //   id: "637239518381b37e31dbd273",
  //   value: 0,
  //   link: "https://pca.viosa.in/student-dashboard/?channel_id=MTY0NQ==",
  //   optionSelect: false,
  //   courseName: "Student Dashboard",
  // },
  // {
  //   id: "637239518381b37e31dbd276",
  //   value: 0,
  //   link: "https://pca.viosa.in/student-dashboard/?channel_id=MTY0NQ==",
  //   optionSelect: false,
  //   courseName: "Student Dashboard",
  // },
  {
    id: "6343bfe5e33839d145810ede",
    value: 90,
    optionSelect: false,
    courseName: "Career Map for 11th & 12th with counselling",
  },
  {
    id: "6343bda5e33839d145810eba",
    value: 90,
    optionSelect: false,
    courseName: "Career Map for 11th & 12th with counselling",
  },
  {
    id: "6377550b5487bee9bd93580f",
    optionSelect: true,
    value: 96,
    courseName:
      "Professional Roadmap For Graduates/Under-Graduates with Counseling",
  },
  {
    id: "6343c02de33839d145810eeb",
    optionSelect: true,
    value: 96,
    courseName:
      "Professional Roadmap For Graduates/Under-Graduates with Counseling",
  },
  {
    id: "634fbdd75321de9b7048f588",
    value: 83,
    optionSelect: false,
    courseName: "Professional Roadmap for Engineers with Counseling",
  },
  {
    id: "637755b15487bee9bd93584d",
    value: 83,
    optionSelect: false,
    courseName: "Professional Roadmap for Engineers with Counseling",
  },
  {
    id: "6343c079e33839d145810efb",
    value: 83,
    optionSelect: false,
    courseName: "Professional Roadmap for Engineers with Counseling",
  },
  {
    id: "6343c1e6e33839d145810f52",
    value: 96,
    optionSelect: false,
    courseName:
      "Professional Roadmap for working professionals With Counseling",
  },
];
