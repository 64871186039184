import React, { useEffect, useRef, useState } from "react";
import { ChapterItem } from "./ChapterItem";
import inactiveimg from "../../icons/inactive.svg";
import "./course.css";
import { FetchSingleCourse } from "Axios/apis";

export const Accordion = ({
  courseID,
  item,
  live,
  singleCourse,
  progressCircle,
}) => {
  // //console.log(item);
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const [allChapter, setAllChapter] = useState([]);
  console.log(content);
  console.log("item", item);
  console.log(content.current);
  // function toggleAccordion() {
  //   setActive(!active);
  //   console.log(content.current.scrollHeight);
  //   console.log(item);
  //   console.log(courseID);
  //   if (item?.sectionName.toLowerCase().includes("Access Tool")) {
  //     setHeight(active ? "0px" : `57px`);
  //     console.log("access");
  //   } else {
  //     setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  //   }
  // }
  // async function toggleAccordion() {
  //   setActive(!active);
  //   console.log(content.current.scrollHeight);
  //   console.log(item);
  //   console.log(courseID);
  //   console.log(localStorage.userId);
  //   const response = await FetchSingleCourse(courseID, localStorage.userId);
  //   console.log(response.data.courses.name);
  //   // if (response?.data.name.includes("Access Tool")) {
  //   //   setHeight(active ? "0px" : `57px`);
  //   //   console.log("access");
  //   // } else {
  //   setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  //   // }
  // }
  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  Array.prototype.resize = function (newSize, defaultValue) {
    while (newSize > this.length) this.push(defaultValue);
    this.length = newSize;
  };

  function roundUp(num, precision) {
    return Math.ceil(num * precision) / precision;
  }

  var vid = document.getElementById("video");
  var duration = 0;
  var watched = new Array(0);
  let currentTime = new Date();
  var reported80percent = false;
  // const courseID = singleCourse[0].courseID;
  const section_id = item._id;
  //   vid.addEventListener("loadedmetadata", getDuration, false);
  //   vid.addEventListener("timeupdate", timeupdate, false);

  // //console.log(item);

  function timeupdate() {
    currentTime = parseInt(vid.currentTime);
    watched[currentTime] = 1;
    // //console.log(watched);
    var sum = watched.reduce(function (acc, val) {
      return acc + val;
    }, 0);
    if (sum >= duration * 0.1 && !reported80percent) {
      reported80percent = true;
      // //console.log("80% watched...");
      // call api when over...
    }
  }

  function getDuration() {
    // //console.log("duration:" + vid.duration);
    duration = parseInt(roundUp(vid.duration, 1));
    // //console.log("resizing arrary to " + duration + " seconds.");
    watched.resize(duration, 0);
  }

  useEffect(() => {
    setAllChapter(
      item?.chapter?.filter(
        (data) => data?.chapterName !== "Start Your Career Assessment"
      )
    );
    console.log("items:", item);
  }, [item?.chapter]);

  return (
    <div className="accordion__section ">
      <div
        className={`accordion-main ${active ? "accordion-active" : ""}`}
        onClick={toggleAccordion}
      >
        <p className="accordion__title">{item?.sectionName || item?.name}</p>
        <span className={`arrow ${active ? "arrow-active" : ""}`}>
          <img className="w-2" src={inactiveimg} alt="" />
        </span>
      </div>
      <div className={active ? "accordion-content-container" : ""}>
        {allChapter?.map((chapter) => (
          <ChapterItem
            {...{
              chapter,
              content,
              height,
              courseID,
              section_id,
              live,
              progressCircle,
            }}
          />
        ))}
      </div>
    </div>
  );
};
