import React, { useState } from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AddEducation } from "../../../Axios/apis";
import { toast } from "react-toastify";

function EducationForm({
  setEditModalOpen,
  detailsupdated,
  setdetailsupdated,
  userDetails,
}) {
  const [formValues, setFormValues] = useState(
    userDetails?.result[0]?.education
  );
  const [loading, setLoading] = useState(false);
  const handleChange = (i, e) => {
    // //console.log(e.target.date)
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleStartingDate = (i, date) => {
    let newFormValues = [...formValues];
    // //console.log(date)
    newFormValues[i].startingYear = formatDate(new Date(date.$d));
    setFormValues(newFormValues);
  };
  const handlePassingDate = (i, date) => {
    let newFormValues = [...formValues];
    newFormValues[i].passingYear = formatDate(new Date(date.$d));
    setFormValues(newFormValues);
  };

  const addFormFields = (e) => {
    e.preventDefault();
    setFormValues([
      ...formValues,
      {
        course: "",
        stream: "",
        college: "",
        passingYear: "",
        startingYear: "",
      },
    ]);
  };

  const removeField = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  }

  // //console.log(formValues)

  const submit = async (e) => {
    e.preventDefault();
    const formData = {
      userId: localStorage.getItem("userId"),
      educationBody: formValues,
    };
    setLoading(true);

    // //console.log(formData);
    try {
      const coupon = await AddEducation(formData);
      // //console.log(coupon);
      setLoading(false);
      setdetailsupdated(!detailsupdated);
    } catch (error) {
      // //console.log(error);
      setLoading(false);
      alert("Failed to edit");
      toast.warning("Failed to edit");
    }
    setEditModalOpen(false);
  };

  return (
    <>
      <form>
        {formValues.map((e, i) => (
          <>
            <div onClick={() => removeField(i)} className="cancel-icon">
              <i className="fa-solid fa-xmark"></i>
            </div>

            <TextField
              name="course"
              className="edit-profile-text-field"
              label="Course Name"
              value={e.course}
              onChange={(e) => handleChange(i, e)}
              variant="standard"
            />
            <TextField
              name="stream"
              className="edit-profile-text-field"
              label="Stream"
              value={e.stream}
              onChange={(e) => handleChange(i, e)}
              variant="standard"
            />
            <TextField
              name="college"
              className="edit-profile-text-field"
              label="Institute Name"
              value={e.college}
              onChange={(e) => handleChange(i, e)}
              variant="standard"
            />

            <div className="d-flex flex-row justify-content-between">
              <div className="col-5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    name="startingYear"
                    value={e.startingYear}
                    className="edit-profile-date-field"
                    onChange={(date) => handleStartingDate(i, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="edit-profile-date-field"
                    label="Till Date"
                    name="passingYear"
                    value={e.passingYear}
                    onChange={(date) => handlePassingDate(i, date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <input className="form-check-input mr-2" type="radio" />
              Present
            </div>
          </>
        ))}

        <div className="d-flex flex-row flex-wrap justify-content-around mt-5">
          <button onClick={addFormFields} className="edit-profile-btn-addMore">
            + Add More
          </button>
          <button
            // onClick={() => removeField(i)}
            //   disabled={loading}
            className="edit-profile-btn edit-profile-btn-white"
            // onClick={submit}
          >
            Discard
          </button>
          <button
            disabled={loading}
            className="edit-profile-btn edit-profile-btn-orange"
            onClick={submit}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
}

export default EducationForm;
