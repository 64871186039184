import React, { useState } from "react";
import people from "./../assets/icon/contact-icon (1).svg";
import messageing from "./../assets/icon/contact-icon (2).svg";
import phoneCall from "./../assets/icon/contact-icon (3).svg";
import file from "./../assets/icon/contact-icon (4).svg";
import collabaration from "./../assets/icon/collabaration.svg";
import facebook from "./../assets/icon/facebook.svg";
import linkedin from "./../assets/icon/linkedin.svg";
import twiter from "./../assets/icon/twiter.svg";
import instagram from "./../assets/icon/Instagram.png";
import youtube from "./../assets/icon/Youtube.png";
import callico from "./../assets/icon/call.svg";
import locationico from "./../assets/icon/location.svg";
import textico from "./../assets/icon/text.svg";
import whatsappico from "./../assets/icon/whatsapp.svg";
import { CareerCounselorForm, CollabForm, SupportForm } from "Axios/apis";
import ReCAPTCHA from "react-google-recaptcha";

const ContactTop = () => {
  const [call, setCall] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [text, setText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading state
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // State to track captcha verification

  const [counselordata, setcounselordata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    course: "",
  });
  const [supportdata, setsupportdata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [collaborationdata, setcollaborationdata] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    schoolOrCollege: "",
  });
  //changes

  const handleChangecounselor = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setcounselordata({ ...counselordata, [e.target.name]: e.target.value });
  };
  const handleChangesupport = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setsupportdata({ ...supportdata, [e.target.name]: e.target.value });
  };
  const handleChangecollaboration = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setcollaborationdata({
      ...collaborationdata,
      [e.target.name]: e.target.value,
    });
  };

  const submitcounselorform = async (e) => {
    e.preventDefault();
    // //console.log(counselordata);
    const captchaResponse = await verifyCaptcha(captchaValue);
    // console.log(captchaResponse);
    if (!captchaResponse.success) {
      // Display error message for CAPTCHA verification failure
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (
      counselordata.name === "" ||
      counselordata.email === "" ||
      counselordata.phone === "" ||
      counselordata.message === "" ||
      counselordata.course === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (counselordata.phone.toString().length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const res = await CareerCounselorForm(counselordata);
      console.log(res);
      setLoading(false);
      setCall(false);
      setcounselordata({
        name: "",
        email: "",
        phone: "",
        message: "",
        schoolOrCollege: "",
      });
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      // //console.log(error);
      setLoading(false);
    }
  };
  const submitsupportform = async (e) => {
    e.preventDefault();
    // //console.log(supportdata);
    const captchaResponse = await verifyCaptcha(captchaValue);
    // console.log(captchaResponse);
    if (!captchaResponse.success) {
      // Display error message for CAPTCHA verification failure
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (
      supportdata.name === "" ||
      supportdata.email === "" ||
      supportdata.message === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (supportdata.phone.toString().length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const res = await SupportForm(supportdata);
      console.log(res);
      setLoading(false);
      setWhatsApp(false);
      setsupportdata({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      setLoading(false);

      // //console.log(error);
    }
  };
  const submitcollaborationform = async (e) => {
    e.preventDefault();
    const captchaResponse = await verifyCaptcha(captchaValue);
    // console.log(captchaResponse);
    if (!captchaResponse.success) {
      // Display error message for CAPTCHA verification failure
      setIsLoading(false); // Set loading state to false
      return;
    }
    if (collaborationdata.schoolOrCollege === "") {
      alert("Please choose school or college");
      return;
    }
    if (collaborationdata?.phone.toString().length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }
    setLoading(true);
    console.log(collaborationdata);
    try {
      const res = await CollabForm(collaborationdata);
      console.log(res);
      setLoading(false);

      setText(false);
      setcollaborationdata({
        name: "",
        email: "",
        phone: "",
        message: "",
        schoolOrCollege: "",
      });
      setTimeout(() => {
        alert("Thank you for your interest. We will get back to you soon.");
      }, 500);
    } catch (error) {
      setLoading(false);

      // //console.log(error);
    }
  };

  const isDev =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://viosa.vercel.app";
  const handleCall = () => {
    setCall(true);
    setWhatsApp(false);
    setText(false);
  };

  const handleWhatsApp = () => {
    setCall(false);
    setWhatsApp(true);
    setText(false);
  };

  const handleText = () => {
    setCall(false);
    setWhatsApp(false);
    setText(true);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(true); // Set captcha verification state to true when user interacts with captcha
  };
  const verifyCaptcha = async (token) => {
    try {
      // const response = await axios.post("/verify-captcha", { token });
      // const response = await fetch("https://api.viosa.in/verify-captcha", {
      const response = await fetch("https://api.viosa.in/verify-captcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Parse response body as JSON
      // console.log(responseData); // Log the parsed response data
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  return (
    <div className="">
      <div className="pb-12">
        <div className=" justify-center ">
          <div
            className="hero-content flex-col lg:flex-row-reverse"
            style={{ maxWidth: "1680px" }}
          >
            <div className="col-6 contact-width contact-box-res w-100-768 pl-16">
              <div className="p-4 justify-content-end d-flex flex-column float-box float-end">
                <label for="my-modal-1" className="modal-button">
                  <div onClick={handleCall}>
                    <p
                      className={`contact flex justify-center  items-center ${
                        call ? "forHover" : false
                      }`}
                      style={{ textAlign: "center" }}
                    >
                      Contact Career Counsellor
                    </p>
                  </div>
                </label>

                <label for="my-modal-2" className="modal-button">
                  <div onClick={handleWhatsApp}>
                    <p
                      className={`my-4 contact flex justify-center  items-center ${
                        whatsApp ? "forHover" : false
                      }`}
                    >
                      Get Support
                    </p>
                  </div>
                </label>

                <label for="my-modal-3" className="modal-button">
                  <div onClick={handleText}>
                    <p
                      className={`contact flex justify-center  items-center ${
                        text ? "forHover" : false
                      }`}
                    >
                      Collaboration Enquiry
                    </p>
                  </div>
                </label>
              </div>
            </div>

            <div className="flex contact-width flex-column py-4 px-2 w-100-768 col-6 mt-40-md">
              <h1
                className="mb-2 schools-mainheading  text-start"
                style={{ color: "#521986", fontWeight: "700" }}
              >
                We'd Love to <span style={{ color: "#FFA303" }}>hear</span> from
                you
              </h1>

              <p
                className="my-2 schools-subheading text-start schools-text-purple"
                style={{
                  fontSize: "22px",
                  color: "#3F3D56",
                }}
              >
                Contact us for a quote, help or to join the team
              </p>
              <div className="px-10-md">
                <a
                  href="https://goo.gl/maps/2YoHrrX9Nv4qy9ak8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p
                    className="d-flex my-2 schools-paragraph"
                    style={{ color: "#3F3D56", textAlign: "left" }}
                  >
                    <img
                      src={locationico}
                      style={{ width: "15px", marginRight: "15px" }}
                      alt=""
                    />
                    Corporate Office : 104, Abhishek Premises, Off New Link Rd,
                    Veera Desai Industrial Estate, Andheri West, Mumbai,
                    Maharashtra 400053
                  </p>
                </a>
                <a
                  href="tel:022-4011-5448"
                  className="d-flex my-2 schools-paragraph"
                >
                  <img
                    src={callico}
                    style={{ width: "15px", marginRight: "15px" }}
                    alt=""
                  />
                  022-4011-5448
                </a>
                <a
                  href="mailto:mail@viosa.in"
                  className="d-flex my-2 schools-paragraph"
                >
                  <img
                    src={textico}
                    style={{ width: "15px", marginRight: "15px" }}
                    alt=""
                  />
                  mail@viosa.in
                </a>
                <a
                  href="https://wa.me/918655803488"
                  target="_blank"
                  className="d-flex my-2 schools-paragraph"
                >
                  <img
                    src={whatsappico}
                    style={{ width: "15px", marginRight: "15px" }}
                    alt=""
                  />
                  +91-8655803488
                </a>

                <div className="d-flex my-4" style={{ marginRight: "15px" }}>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/viosa.learning"
                  >
                    <img
                      style={{ width: "30px", height: "30px" }}
                      className="mr-3 social-icon"
                      src={facebook}
                      alt=""
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/viosa-learning/"
                  >
                    <img
                      style={{ width: "30px", height: "30px" }}
                      className="mr-3 social-icon"
                      src={linkedin}
                      alt=""
                    />
                  </a>
                  <a target="_blank" href="https://twitter.com/viosa_learning">
                    <img
                      style={{ width: "30px", height: "30px" }}
                      className="mr-3 social-icon"
                      src={twiter}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              {/* <img className='w-[1800px]' src={contactInformation} alt="" /> */}
            </div>
          </div>
          {/* <div className="d-flex my-2" style={{ marginRight: "15px" }}>
            <a
              target="_blank"
              href="https://www.facebook.com/viosa.learning"
              style={{ marginLeft: "43px" }}
            >
              <img
                style={{ width: "30px", height: "30px" }}
                className="mr-3 social-icon"
                src={facebook}
                alt=""
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/viosa-learning/"
            >
              <img
                style={{ width: "30px", height: "30px" }}
                className="mr-3 social-icon"
                src={linkedin}
                alt=""
              />
            </a>
            <a target="_blank" href="https://twitter.com/viosa_learning">
              <img
                style={{ width: "30px", height: "30px" }}
                className="mr-3 social-icon"
                src={twiter}
                alt=""
              />
            </a>
          </div> */}
        </div>
      </div>

      {/* Modal cards */}

      {call && (
        <div>
          <input type="checkbox" id="my-modal-1" className="modal-toggle" />
          <div className={`modal stop-scroll modal-dev`}>
            <div className="modal-box relative">
              <label
                for="my-modal-1"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <form onSubmit={(e) => submitcounselorform(e)}>
                <div className="flex justify-center">
                  <div>
                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6">
                        <img className="mx-4 for-opacity" src={people} alt="" />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="text"
                          name="name"
                          required
                          onChange={handleChangecounselor}
                          value={counselordata?.name}
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border  hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="email"
                          name="email"
                          required
                          onChange={handleChangecounselor}
                          value={counselordata?.email}
                          placeholder="Enter your email"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={phoneCall}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="tel"
                          required
                          placeholder="Enter your phone"
                          name="phone"
                          pattern="[6-9]{1}[0-9]{9}"
                          title="Please enter a valid phone number"
                          onChange={handleChangecounselor}
                          value={counselordata?.phone}
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 input-main-wraper">
                        <img className="mx-3 for-opacity" src={file} alt="" />

                        <select
                          required
                          style={{
                            background: "#FFF7E7",
                            opacity: "0.5",
                            margin: "0 !important",
                          }}
                          className="pl-2 input-field"
                          name="course"
                          onChange={handleChangecounselor}
                        >
                          <option disabled selected>
                            Choose your Course
                          </option>
                          <option value="8th-12th Standard">
                            Class 8th to 12th
                          </option>
                          <option value="Undergraduates/ Graduates">
                            Undergraduates/ Graduates
                          </option>
                          <option value="Post Graduates">Post Graduates</option>
                          <option value="Working Professionals">
                            Working Professionals
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <input
                          required
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field text-center"
                          type="text"
                          placeholder="Your Message"
                          name="message"
                          onChange={handleChangecounselor}
                          value={counselordata?.message}
                        ></input>
                      </div>
                    </div>
                    <ReCAPTCHA
                      sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
                      onChange={handleCaptchaChange}
                      style={{
                        margin: "0px 0px 10px 0px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </div>
                <div className="my-2 d-flex align-center justify-content-center">
                  <button
                    type="submit"
                    for="my-modal-1"
                    disabled={loading}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {whatsApp && (
        <div>
          <input type="checkbox" id="my-modal-2" className="modal-toggle" />
          <div className={`modal stop-scroll modal-dev`}>
            <div className="modal-box relative">
              <label
                for="my-modal-2"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <div className="flex justify-center">
                <form onSubmit={(e) => submitsupportform(e)}>
                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6">
                      <img className="mx-4 for-opacity" src={people} alt="" />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="text"
                        name="name"
                        required
                        value={supportdata.name}
                        onChange={handleChangesupport}
                        placeholder="Enter your name"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border  hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={messageing}
                        alt=""
                      />
                      <input
                        required
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="email"
                        name="email"
                        value={supportdata.email}
                        onChange={handleChangesupport}
                        placeholder="Enter your email"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={phoneCall}
                        alt=""
                      />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="tel"
                        name="phone"
                        pattern="[6-9]{1}[0-9]{9}"
                        title="Please enter a valid phone number"
                        required
                        value={supportdata.phone}
                        onChange={handleChangesupport}
                        placeholder="Enter your phone"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border-message hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field text-center"
                        type="text"
                        required
                        name="message"
                        value={supportdata.message}
                        onChange={handleChangesupport}
                        placeholder="Your Message"
                      ></input>
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
                    onChange={handleCaptchaChange}
                    style={{
                      margin: "0px 0px 10px 0px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />

                  <div className="my-2 d-flex align-center justify-content-center">
                    <button
                      type="submit"
                      for="my-modal-1"
                      disabled={loading}
                      className="forHoverContact-button hover:shadow-2xl"
                    >
                      {loading ? "Sending..." : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {text && (
        <div>
          <input type="checkbox" id="my-modal-3" className="modal-toggle" />
          <div className={`modal stop-scroll modal-dev`}>
            <div className="modal-box relative">
              <label
                for="my-modal-3"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <form onSubmit={(e) => submitcollaborationform(e)}>
                <div className="flex justify-center">
                  <div>
                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6">
                        <img className="mx-4 for-opacity" src={people} alt="" />
                        <input
                          required
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="text"
                          name="name"
                          value={collaborationdata.name}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your name"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border  hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={messageing}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="email"
                          name="email"
                          required
                          value={collaborationdata.email}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your email"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={phoneCall}
                          alt=""
                        />
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field"
                          type="tel"
                          name="phone"
                          required
                          pattern="[6-9]{1}[0-9]{9}"
                          title="Please enter a valid phone number"
                          value={collaborationdata.phone}
                          onChange={handleChangecollaboration}
                          placeholder="Enter your phone"
                        ></input>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <img
                          className="mx-4 for-opacity"
                          src={collabaration}
                          alt=""
                        />

                        <select
                          required
                          style={{ background: "#FFF7E7", opacity: "0.5" }}
                          className="pl-2 input-field"
                          name="schoolOrCollege"
                          onChange={handleChangecollaboration}
                        >
                          <option disabled selected>
                            Choose Collaboration
                          </option>
                          <option value="School">School</option>
                          <option value="College">College</option>
                        </select>
                      </div>
                    </div>

                    <div className="my-2 input-border hover:shadow-2xl">
                      <div className="flex items-center justify-center mb-6 ">
                        <input
                          style={{ background: "#FFF7E7" }}
                          className="pl-2 input-field text-center"
                          type="text"
                          name="message"
                          required
                          value={collaborationdata.message}
                          onChange={handleChangecollaboration}
                          placeholder="Your Message"
                        ></input>
                      </div>
                    </div>
                    <ReCAPTCHA
                      sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
                      onChange={handleCaptchaChange}
                      style={{
                        margin: "0px 0px 10px 0px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </div>
                <div className="my-2 d-flex align-center justify-content-center">
                  <button
                    for="my-modal-1"
                    type="submit"
                    disabled={loading}
                    className="forHoverContact-button hover:shadow-2xl"
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactTop;
