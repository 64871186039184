import { useEffect } from "react";
import { useDispatch } from "react-redux";
import viosalogo from "../icons/viosa-logo.png";
// import catalogueicon from "../icons/catalogue-icon.png";
// import sidebarLogo1 from "../icons/sidebar-logo (2).svg";
// import sidebarLogo2 from "../icons/sidebar-logo (3).svg";
// import sidebarLogo3 from "../icons/sidebar-logo (4).svg";
// import sidebarLogo4 from "../icons/sidebar-logo (5).svg";
// import sidebarLogo5 from "../icons/sidebar-logo (6).svg";
// import sidebarLogo6 from "../icons/sidebar-logo (7).svg";
// import search from "../icons/search.png";
// import profilephoto from "../icons/profilephoto.png";
// import timer from "../icons/timer.png";
// import bell from "../icons/bell.png";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import CatalogueModal from "./CatalogueModal";
import { Cart } from "./Cart";
import Dashboard from "./Dashboard/Dashboard";
import Catalogue from "./Catalogue";
import { Logout, userDashBoard } from "redux/user/userAction";
import UserProfile from "./UserProfile";
import {
  collegeProfile,
  corporateProfile,
  professionProfile,
  StudentProfile,
} from "./Contansts";
import LiveWebinar from "./LiveWebinar/LiveWebinar";
import { CourseBuy } from "./CourseBuy";
import Achievements from "./Achievements";
import SingleCourse from "./Dashboard/SingleMyCourse";
import SingleMyCourse from "./Dashboard/SingleMyCourse";
import { CourseBuyLogin } from "./CourseBuyLogin";
import Roadmap from "Pages/Roadmap/Roadmap";
import RoadMapLogin from "Pages/Roadmap/RoadMapLogin";
import { Curricullam } from "./Dashboard/Curricullam";
import { CareerAssessment } from "./Section/CoursePlay/CareerAssessment";
import { CareerAssessmentTest } from "./Section/CoursePlay/CareerAssessmentTest";
import aiIcon from "../../src/assets/aiicon.png";
import PureModal from "react-pure-modal";
import { Button } from "react-bootstrap";
import CollegeDashboard from "./CollegeDashboard";
import cookies from "js-cookies";
import { getAllUserCourses } from "Axios/apis";
import Batches from "Pages/CollegeDashboard/Batches";

const Profile = () => {
  const navigate = useNavigate();

  const [modalCatalouge, setModalCatalouge] = useState(false);
  const [profile, setProfile] = useState([]);
  const [dropdown, setDropdown] = useState(false);

  const handleCatalogue = () => {
    setModalCatalouge(true);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    cookies.removeItem("userName");
    cookies.removeItem("zc_consent");
    cookies.removeItem("viosa");
    cookies.removeItem("zc_show");
    cookies.removeItem("skydbi-_zldt");
    cookies.removeItem("token");
    dispatch(userDashBoard("home"));
    dispatch(
      Logout({
        isLoggedIn: false,
      })
    );
    navigate("/");
  };

  const activeItem = window.location.pathname.split("/")[2];
  const isLoggedIn = localStorage.getItem("token");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  useEffect(() => {
    console.log(role);
    if (isLoggedIn) {
      switch (role) {
        case "USER":
          setProfile(StudentProfile);
          break;
        case "PROFESSIONAL":
          setProfile(professionProfile);
          break;
        case "CORPORATE":
          setProfile(corporateProfile);
          break;
        case "college":
          setProfile(collegeProfile);
          break;
        default:
          setProfile(StudentProfile);
      }
    }
  }, [isLoggedIn]);

  console.log(profile);

  const [show, setShow] = useState(false);

  const [signoutModalOpen, setSignoutModalOpen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const backHome = () => {
    dispatch(userDashBoard("home"));
    navigate("/", { state: { from: "profile" } });
  };
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await getAllUserCourses(token);
        const arr = [];
        response?.data?.courses?.map((m) => {
          arr.push(m.courseID);
        });

        setCourses(arr);
        // setCourses(filteredCourses || response?.data?.courses);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourse();
    // fetchProgress();
  }, []);

  console.log(courses);

  return (
    <div className="App">
      <div className="signout-modal">
        <PureModal
          isOpen={signoutModalOpen}
          closeButton="X"
          width={500}
          closeButtonPosition="header"
          onClose={() => {
            setSignoutModalOpen(false);
            return true;
          }}
        >
          <h2 className="mt-6 text-center" style={{ wordBreak: "break-word" }}>
            Are you sure you really want to sign out?
          </h2>
          <div className="my-4 d-flex justify-content-center">
            <button
              className="edit-profile-btn mx-2"
              onClick={() => {
                setSignoutModalOpen(false);
              }}
              style={{ color: "#fea302" }}
            >
              Cancel
            </button>
            <button
              className="edit-profile-btn edit-profile-btn-orange"
              onClick={handleLogout}
            >
              Sign Out
            </button>
          </div>
        </PureModal>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="navpanel sidebar-toggle">
            <div className="header"></div>
            <img src={viosalogo}></img>
            <div className="menu-height d-flex align-items-center">
              <div className="menuItems">
                <button
                  onClick={() => backHome()}
                  className="text-decoration-none primary-color-text dashboard-topic"
                >
                  <div
                    className={`menuItem for-margin`}
                    style={{ paddingLeft: "30px" }}
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      class="home-icon fa-sharp fa-solid fa-house margin-right-10"
                    ></i>
                    Home
                  </div>
                </button>
                {profile.map((item) => (
                  <>
                    <Link
                      className="ml-4 text-decoration-none primary-color-text dashboard-topic"
                      to={`/profile/${item.link}`}
                      onClick={handleClose}
                    >
                      <button
                        key={item.id}
                        className={`menuItem for-margin ${
                          activeItem === `${item.link}` ? "active-item" : ""
                        }`}
                      >
                        <img
                          src={item.icon}
                          className="margin-right-10 mr-3"
                        ></img>
                        <span>{item.name || "Dashboard"}</span>
                        {/* <button>
                        {item.name || "Dashboard"}
                      </button> */}
                      </button>
                    </Link>
                  </>
                ))}

                {/* <div
                  className="text-decoration-none primary-color-text dashboard-topic"
                >
                  <div
                    onClick={() => setDropdown(!dropdown)}
                    className={`menuItem for-margin`}
                    style={{ marginBottom: 0 }}
                  >
                    <img style={{ marginRight: "10px" }} src={aiIcon} width="25px" height="25px"></img>
                    <button>AI TOOLS</button>
                  </div>
                </div>
                {dropdown === true ? (
                  <div className="mt-2 d-flex flex-column">
                    {role !== "college" ? (<a style={{ padding: "10px" }} href={`https://interviewpro.viosa.in/?token=${localStorage.getItem("token")}&role=${localStorage.getItem("role")}&hireProRole=${localStorage.getItem("InterviewProRole") || localStorage.getItem("role")}`} target="_blank">
                      <span>InterviewPro</span>
                    </a>) : (<>
                      <div onClick={() => { console.log("pro"); window.location.reload(`https://interviewpro.viosa.in/?token=${localStorage.getItem("token")}&role=${localStorage.getItem("role")}&hireProRole=${localStorage.getItem("InterviewProRole") || localStorage.getItem("role")}`) }} style={{ padding: "10px" }} >
                        <span>InterviewPro</span>
                      </div>
                    </>)}
                    {
                      role !== "college" && <a style={{ padding: "10px" }} href={`https://resumatic.viosa.in/auth?token=${localStorage.getItem("token")}&id=${localStorage.getItem("userId")}`} target="_blank">
                        <span>Resumatic</span>
                      </a>
                    }

                  </div>
                ) : (
                  <></>
                )} */}

                <div
                  onClick={() => setSignoutModalOpen(true)}
                  className="cursor-pointer mt-2 sign-out-margin logo d-flex justify-content-center align-items-center"
                >
                  <span style={{ fontSize: "18px" }} className="mr-4">
                    Sign Out
                  </span>{" "}
                  <AiOutlineLogout size={30} />
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="navpanel">
        <div className="header"></div>
        <img src={viosalogo}></img>
        <div className="d-flex align-items-center">
          <div className="menuItems">
            <button
              onClick={() => backHome()}
              className="text-decoration-none primary-color-text dashboard-topic"
            >
              <div className={`menuItem for-margin`}>
                <i
                  style={{ marginRight: "10px" }}
                  class="home-icon fa-solid fa-house"
                ></i>
                Home
              </div>
            </button>
            {profile.map((item) => (
              <>
                <Link
                  className="text-decoration-none primary-color-text dashboard-topic"
                  to={`/profile/${item.link}`}
                >
                  <div
                    key={item.id}
                    className={`menuItem for-margin ${
                      activeItem === `${item.link}` ? "active-item" : ""
                    }`}
                  >
                    <img style={{ marginRight: "10px" }} src={item.icon}></img>
                    <button>{item.name || "Dashboard"}</button>
                  </div>
                </Link>
              </>
            ))}
            {role === "college" && (
              <Link className="text-decoration-none primary-color-text dashboard-topic">
                <div
                  onClick={() => setDropdown(!dropdown)}
                  className={`menuItem for-margin`}
                  style={{ marginBottom: 0 }}
                >
                  <img
                    style={{ marginRight: "10px" }}
                    src={aiIcon}
                    width="25px"
                    height="25px"
                  ></img>
                  <button>AI TOOLS</button>
                </div>
              </Link>
            )}
            {dropdown === true ? (
              <div className="mt-2 d-flex flex-column">
                {courses.includes("6343c15fe33839d145810f31") ? (
                  <>
                    <a
                      style={{ padding: "10px" }}
                      href={`https://interviewpro.viosa.in/?token=${localStorage.getItem(
                        "token"
                      )}&role=${localStorage.getItem(
                        "role"
                      )}&hireProRole=${localStorage.getItem(
                        "InterviewProRole"
                      )}`}
                      target="_blank"
                    >
                      <span>InterviewPro</span>
                    </a>
                  </>
                ) : (
                  <>
                    {role === "college" ? (
                      <>
                        {" "}
                        <a
                          style={{ padding: "10px" }}
                          href={`https://interviewpro.viosa.in/?token=${localStorage.getItem(
                            "token"
                          )}&role=${localStorage.getItem("role")}&hireProRole=${
                            localStorage.getItem("InterviewProRole") ||
                            localStorage.getItem("role")
                          }`}
                          target="_blank"
                        >
                          <span>InterviewPro</span>
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          style={{ padding: "10px" }}
                          // to={`/profile/catalogue/6343c15fe33839d145810f31`}
                          to={`/profile/catalogue/interviewpro`}
                        >
                          <span>InterviewPro</span>
                        </Link>
                      </>
                    )}
                  </>
                )}

                {courses.includes("6343c119e33839d145810f19") ? (
                  <>
                    {role !== "college" && (
                      <a
                        style={{ padding: "10px" }}
                        href={`https://resumatic.viosa.in/auth?token=${localStorage.getItem(
                          "token"
                        )}&id=${localStorage.getItem("userId")}`}
                        target="_blank"
                      >
                        <span>Resumatic</span>
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    {role !== "college" && (
                      <Link
                        style={{ padding: "10px" }}
                        to={`/profile/catalogue/resumatic-ai`}
                      >
                        <span>Resumatic</span>
                      </Link>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="signout-button">
              <div
                onClick={() => setSignoutModalOpen(true)}
                className="cursor-pointer sign-out-margin logo d-flex justify-content-center align-items-center"
              >
                <span style={{ fontSize: "18px" }} className="mr-4">
                  Sign Out
                </span>{" "}
                <AiOutlineLogout size={30} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pageContent">
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route
            path="/catalogue"
            element={<Catalogue togglesidebar={handleShow} />}
          />
          <Route
            path="/college"
            element={<CollegeDashboard togglesidebar={handleShow} />}
          />
          <Route
            path="/catalogue/:id"
            element={<CourseBuyLogin togglesidebar={handleShow} />}
          />
          <Route
            path="/catalogue/road/:id"
            element={<RoadMapLogin togglesidebar={handleShow} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard togglesidebar={handleShow} />}
          />
          <Route
            path="/dashboard/:id"
            element={
              <SingleCourse togglesidebar={handleShow} progress={true} />
            }
          />
          <Route
            path="/college/batch/:id"
            element={<Batches togglesidebar={handleShow} progress={true} />}
          />
          <Route
            path="/dashboard/:id/curriculum"
            element={<Curricullam togglesidebar={handleShow} />}
          />
          <Route
            path="/webinars"
            element={<LiveWebinar togglesidebar={handleShow} />}
          />
          <Route
            path="/career-assessment"
            element={
              <CareerAssessment headerTop={true} togglesidebar={handleShow} />
            }
          />
          <Route
            path="/career-assessment-test"
            element={
              <CareerAssessmentTest
                headerTop={true}
                togglesidebar={handleShow}
              />
            }
          />
          <Route
            path="/user"
            element={<UserProfile togglesidebar={handleShow} />}
          />
          <Route
            path="/achievements"
            element={<Achievements togglesidebar={handleShow} />}
          />
          <Route path="/cart" element={<Cart togglesidebar={handleShow} />} />
        </Routes>
      </div>

      {modalCatalouge && <CatalogueModal></CatalogueModal>}
    </div>
  );
};

export default Profile;
