import React, { useEffect, useState } from "react";
import downArrow from "./../assets/icon/down-arrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { courseId } from "redux/coures/courseAction";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import dummyimg from "../../../src/icons/courseplaceholder.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import road from "assets/road.png";
import PureModal from "react-pure-modal";
import { ConstructionOutlined } from "@mui/icons-material";
import { getCourseDetailsbyId } from "Axios/apis";

const AvailableCourse = ({ course, setCourse, coursedata }) => {
  const [subcourse, setSubcourse] = useState("Course");
  const [courses, setCourses] = useState(false);
  const [courseid, setCourseId] = useState("");
  const [nextid, setNextId] = useState("");
  const [path, setPath] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const handleCourse = (data) => {
    // //console.log(data)
    // //console.log(`/course/${data?.course_id}`);
    navigate(`/course/${data?.course_id}`);
    setCourses(true);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setCourse(e.target.value);
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // setSubcourse("Course")
    } else {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setCourse("All Courses");
    }
  };

  const handleSubChange = (e) => {
    if (e.target.value !== "Course") {
      setSubcourse(e.target.value);
    } else {
      setSubcourse("All Courses");
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("Params", params);
  }, [course]);

  let filtercourse = [];
  if (course != "All Courses") {
    console.log(coursedata);
    console.log(course);
    filtercourse = coursedata
      ?.filter(
        (data) => data?.category.includes(course) && data.isCounselling === true
      )
      .sort((a, b) =>
        course.toLowerCase().includes("Working Professional".toLowerCase())
          ? a?.order - b?.order
          : a?.order - b?.order
      );
    const filterCourseOrder = coursedata?.sort((p1, p2) =>
      p1.order < p2.order ? 1 : p1.price > p2.price ? -1 : 0
    );
    // //console.log(filtercourse);
    // //console.log(coursedata);
    // Add "Interview Pro" and "Resumatic AI" if not in "8-12 Student"
    if (course !== "8-12 Student") {
      const additionalCourses = coursedata.filter(
        (data) =>
          ["Interview Pro", "Resumatic AI"].includes(data.name) &&
          data.category.includes(course)
      );
      filtercourse = [...filtercourse, ...additionalCourses];
    }

    filtercourse.sort((a, b) => a?.order - b?.order);
  } else {
    filtercourse = coursedata.sort((a, b) => a?.order - b?.order);
  }
  const handleNavigate = async (
    course_Id,
    category,
    isCounselling,
    name,
    order
  ) => {
    console.log(course_Id, category, isCounselling, name, order);
    // Convert course name to URL-friendly format
    const urlFriendlyName = name.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    console.log(urlFriendlyName);
    const userId = localStorage.getItem("userId");

    try {
      const response = await getCourseDetailsbyId(course_Id, userId);
      console.log("response:", response);
      const url = response.data.courses.slug;
      console.log("url", url);
      let navigatePath = "";

      if (
        !isCounselling &&
        (name?.toLowerCase().includes("roadmap") ||
          name?.toLowerCase().includes("8th") ||
          name?.toLowerCase().includes("11th"))
      ) {
        // setPath(
        navigatePath =
          name?.toLowerCase().includes("8th") === true ||
          name?.toLowerCase().includes("11th") === true
            ? `/course/roadmap/`
            : `/course/`;
        // );
        // setCourseId(course_Id);
        // setCourseId(urlFriendlyName);
        setCourseId(url);
        const nextId = coursedata?.filter((data) => data?.order === order + 1);
        // setNextId(nextId[0]?.course_id);
        console.log(nextId);
        const namewith = nextId[0].name;
        console.log(namewith);
        const urlFriendlyName2 = namewith
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-");
        navigatePath += urlFriendlyName2;
        setNextId(urlFriendlyName2);
        console.log(urlFriendlyName2);
        // setShow(true);
      } else {
        if (category.toLowerCase().includes("8-12 student")) {
          // navigate(`/course/roadmap/${course_Id}`);
          // navigate(`/course/roadmap/${urlFriendlyName}`);
          // navigatePath = `/course/roadmap/${urlFriendlyName}`;
          navigatePath = `/course/roadmap/${url}`;
          console.log("navigating to 8th:", url);
        } else {
          // navigate(`/course/${course_Id}`);
          // navigate(`/course/${urlFriendlyName}`);
          // navigatePath = `/course/${urlFriendlyName}`;
          navigatePath = `/course/${url}`;
          console.log("navigating to:", url);
        }
      }
      console.log(`Navigating to: ${navigatePath}`);
      navigate(navigatePath);
      dispatch(courseId(course_Id));
    } catch (error) {
      console.error("Failed to get course details by slug:", error);
    }
  };
  //console.log(filtercourse);
  console.log(path);
  return (
    <div className="px-3 pt-8 pb-12 res-px-4">
      {/* <PureModal
        isOpen={show}
        style={{ borderRadius: "10px" }}
        closeButton="X"
        width={900}
        closeButtonPosition="header"
        onClose={() => {
          setShow(false);
          return true;
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div className="mt-2 mb-4">
            <img src={road} className="counseling-popup-img"></img>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ justifyContent: "space-around", width: "100%" }}
          >
            <button
              className="btn btn-warning new-button-1 counseling-popup-button"
              style={{
                backgroundColor: "#FEA302",
                color: "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate(path + nextid);
              }}
            >
              With Counseling
            </button>
            <button
              className="btn btn-outline-warning new-button-2 counseling-popup-button"
              style={{
                border: "2px solid #FEA302",
                color: "#000",
                backgroundColor: "#fff",
                textTransform: "capitalize",
              }}
              onClick={() => {
                navigate(path + courseid);
              }}
            >
              Without Counseling
            </button>
          </div>
          <div className="my-2">
            <h1 style={{ fontSize: "35px", color: "#eb6a3f" }}>
            </h1>
          </div>
        </div>
      </PureModal> */}
      <div className="margin-course row  mx-4 justify-content-start">
        {filtercourse.map((data, idx) => (
          <div
            key={idx}
            className="mb-8 cursor-pointer card-border mt-24"
            onClick={() =>
              handleNavigate(
                data?.course_id,
                data?.category,
                data?.isCounselling,
                data?.name,
                data?.order,
                data.slug
              )
            }
          >
            {console.log(data)}
            <img
              className="block mx-auto card-image"
              src={data?.thumbnail || dummyimg}
              alt=""
            />
            <div>
              <div className="heading-card-div">
                <OverlayTrigger
                  key={data?.course_id}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      {data?.name?.split("(")[0]}
                    </Tooltip>
                  }
                >
                  <h1 className="training pt-3 mb-1">
                    {data?.name?.split("(")[0]}
                  </h1>
                </OverlayTrigger>
                {/* {data?.description?.length ? (
                  <OverlayTrigger
                    key={data?.description}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        {data?.description}
                      </Tooltip>
                    }
                  >
                    <p className="card-para" style={{ paddingLeft: "10px" }}>
                      {data?.description}
                    </p>
                  </OverlayTrigger>
                ) : null} */}
              </div>

              <div
                style={{ color: "#fea302", marginBottom: "10px" }}
                className="px-2 text-end"
              >
                <button className="join-session-btn">View Course</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {course == "All Courses" ||
      course == "8-12 Student" ||
      course == "Undergraduate/Graduate" ||
      course == "Post Graduate" ||
      course == "Working Professional" ? (
        <div className="my-1 mx-2 menu-count-card">
          <ul
            style={{ marginTop: "10px" }}
            className="menu menu-horizontal p-0 "
          >
            {/* <Select
              className="ml-4 select-course"
              value={course}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="All Courses">All Courses</MenuItem>
              <MenuItem value="8-12 Student">8th to 12th</MenuItem>
              <MenuItem value="Undergraduate/Graduate">
                Undergrad/Grad
              </MenuItem>
              <MenuItem value="Post Graduate">Post Grad</MenuItem>
              <MenuItem value="Working Professional">
                Working Professional
              </MenuItem>
            </Select> */}

            {course === "8-12 Student" ? (
              <>
                {/* <Select className="ml-4 select-course"
                  value={subcourse}
                  onChange={handleSubChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    subcourse === "Course" && <MenuItem value="Course">Course</MenuItem>
                  }
                  <MenuItem value="8/10 th standard Career Map">-8/10 th standard Career Map</MenuItem>
                  <MenuItem value="-11/12 standard Career Map">-11/12 standard Career Map</MenuItem>

                </Select> */}
                {/* <li
                  className="ml-4 text-black text-sige for-hover box-course-type"
                  tabindex="0"
                >
                  {subcourse}
                  <img className="mb-0 header-dropdown" src={downArrow} alt="" />

                  <ul className="bg-white forZindex mb-6 course-topic mt-3 pt-2 ">
                    <p
                      onClick={() => {
                        setSubcourse("8/10 th standard Career Map");
                      }}
                      className={`my-2  pr-4 different-color`}
                    >
                      -8/10 th standard Career Map
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("-11/12 standard Career Map");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      -11/12 standard Career Map
                    </p>
                  </ul>
                </li> */}
              </>
            ) : course === "Undergraduate/Graduate" ? (
              <>
                {/* <Select className="ml-4 select-course"
                  value={subcourse}
                  onChange={handleSubChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    subcourse === "Course" && <MenuItem value="Course">Course</MenuItem>
                  }
                  <MenuItem value="Undergraduates Professional RoadMap">Undergraduates Professional RoadMap</MenuItem>
                  <MenuItem value="Engineers Professional RoadMap"> Engineers Professional RoadMap</MenuItem>
                  <MenuItem value="Corporate learning"> Corporate learning</MenuItem>
                  <MenuItem value="Competency Coaching">Competency Coaching</MenuItem>
                  <MenuItem value="Resumatic">Resumatic</MenuItem>
                  <MenuItem value="Interview Pro">Interview Pro</MenuItem>
                  <MenuItem value="VDO Resume">VDO Resume</MenuItem>
                  <MenuItem value="Job Specific all courses">Job Specific all courses</MenuItem>
                </Select> */}
                {/* <li
                  className="ml-4 text-black text-sige for-hover box-course-type"
                  tabindex="0"
                >
                  {subcourse}
                  <img className="mb-0 header-dropdown" src={downArrow} alt="" />

                  <ul className="bg-white forZindex mb-6 course-topic mt-3 pt-2 ">
                    <p
                      onClick={() => {
                        setSubcourse("Undergraduates Professional RoadMap");
                      }}
                      className={`my-2 pr-4  different-color`}
                    >
                      Undergraduates Professional RoadMap
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Engineers Professional RoadMap");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Engineers Professional RoadMap
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Corporate learning");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Corporate learning
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Competency Coaching");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Competency Coaching
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Resumatic");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Resumatic
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Interview Pro");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Interview Pro
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("VDO Resume");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      VDO Resume
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Pro Connect");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Pro Connect
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Job Specific all courses");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Job Specific all courses
                    </p>
                  </ul>
                </li> */}
              </>
            ) : course === "Post Graduate" ? (
              <>
                {/* <Select className="ml-4 select-course"
                  value={subcourse}
                  onChange={handleSubChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    subcourse === "Course" && <MenuItem value="Course">Course</MenuItem>
                  }
                  <MenuItem value="Undergraduates Professional RoadMap">Undergraduates Professional RoadMap</MenuItem>
                  <MenuItem value="Engineers Professional RoadMap"> Engineers Professional RoadMap</MenuItem>
                  <MenuItem value="Corporate learning"> Corporate learning</MenuItem>
                  <MenuItem value="Competency Coaching">Competency Coaching</MenuItem>
                  <MenuItem value="Resumatic">Resumatic</MenuItem>
                  <MenuItem value="Interview Pro">Interview Pro</MenuItem>
                  <MenuItem value="VDO Resume">VDO Resume</MenuItem>
                  <MenuItem value="Job Specific all courses">Job Specific all courses</MenuItem>
                </Select> */}
                {/* <li
                  className="ml-4 text-black text-sige for-hover box-course-type"
                  tabindex="0"
                >
                  {subcourse}
                  <img className="mb-0 header-dropdown" src={downArrow} alt="" />

                  <ul className="bg-white forZindex mb-6 course-topic mt-3 pt-2 ">
                    <p
                      onClick={() => {
                        setSubcourse("Engineers Professional RoadMap");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Engineers Professional RoadMap
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Graduates Professional RoadMap");
                      }}
                      className={`my-2 pr-4 different-color`}
                    >
                      Graduates Professional RoadMap
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Corporate learning");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Corporate learning
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Competency Coaching");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Competency Coaching
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Resumatic");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Resumatic
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Interview Pro");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Interview Pro
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("VDO Resume");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      VDO Resume
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Pro Connect");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Pro Connect
                    </p>
                    <p
                      onClick={() => {
                        setSubcourse("Job Specific all courses");
                      }}
                      className={`my-2  different-color pl-2`}
                    >
                      Job Specific all courses
                    </p>
                  </ul>
                </li> */}
              </>
            ) : course === "Working Professional" ? (
              <>
                {/* <Select className="ml-4 select-course"
                  value={subcourse}
                  onChange={handleSubChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    subcourse === "Course" && <MenuItem value="Course">Course</MenuItem>
                  }
                  <MenuItem value="Undergraduates Professional RoadMap">Undergraduates Professional RoadMap</MenuItem>
                  <MenuItem value="Engineers Professional RoadMap"> Engineers Professional RoadMap</MenuItem>
                  <MenuItem value="Corporate learning"> Corporate learning</MenuItem>
                  <MenuItem value="Competency Coaching">Competency Coaching</MenuItem>
                  <MenuItem value="Resumatic">Resumatic</MenuItem>
                  <MenuItem value="Interview Pro">Interview Pro</MenuItem>
                  <MenuItem value="VDO Resume">VDO Resume</MenuItem>
                  <MenuItem value="Job Specific all courses">Job Specific all courses</MenuItem>
                </Select> */}
                {/* <li
                className="ml-4 text-black text-sige for-hover box-course-type"
                tabindex="0"
              >
                {subcourse}
                <img className="mb-0 header-dropdown" src={downArrow} alt="" />

                <ul className="bg-white forZindex mb-6 course-topic mt-3 pt-2 ">
                  <p
                    onClick={() => {
                      setSubcourse("Professional");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Professional
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Professional & Postgraduate RoadMap");
                    }}
                    className={`my-2 pr-4 different-color`}
                  >
                    Professional & Postgraduate RoadMap
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Engineers Professional RoadMap");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Engineers Professional RoadMap
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Post graduates Professional RoadMap");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Post graduates Professional RoadMap
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Corporate learning");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Corporate learning
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Competency Coaching");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Competency Coaching
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Resumatic");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Resumatic
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Interview Pro");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Interview Pro
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("VDO Resume");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    VDO Resume
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Pro Connect");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Pro Connect
                  </p>
                  <p
                    onClick={() => {
                      setSubcourse("Job Specific all courses");
                    }}
                    className={`my-2  different-color pl-2`}
                  >
                    Job Specific all courses
                  </p>
                </ul>
              </li> */}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AvailableCourse;
