import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import gradute from "../icons/gra.svg";

const ProgressTracker = ({ setProgressTracker }) => {
  const data = [
    {
      name: "Monday",

      pv: 2400,
    },
    {
      name: "Tuesday",

      pv: 1398,
    },
    {
      name: "Wednesday",

      pv: 9800,
    },
    {
      name: "Thursday",

      pv: 3908,
    },
    {
      name: "Friday",

      pv: 4800,
    },
    {
      name: "Saturday",

      pv: 3800,
    },
    {
      name: "Sunday",

      pv: 4300,
    },
  ];

  return (
    <div>
      <div>
        <div className="flex gap-x-4">
          <p className="UIUXdesign">UI & UX Designing</p>
          <img src={gradute} alt="" />
        </div>

        <div className="flex justify-betweeen">
          <div className="mt-10 mr-12">
            <p className="mb-6 bar-heading">Basic Concepts</p>
            <p className="mb-6 bar-heading">Design Approach</p>
            <p className="mb-6 bar-heading">Selecting your Tool</p>
            <p className="mb-6 bar-heading">Making Prototype</p>
            <p className="mb-6 bar-heading">Designing and its types</p>
            <p className="mb-6 bar-heading">Making Protfolio</p>
          </div>

          <div
            style={{ width: 800, height: 600 }}
            className="flex justify-end pl-8 mt-12"
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={600}
                height={400}
                data={data}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                barSize={60}
              >
                <XAxis
                  dataKey="name"
                  scale="point"
                  padding={{ left: 50, right: 10 }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar
                  dataKey="pv"
                  fill="#FFA303"
                  background={{ fill: "#FFFF" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressTracker;
