import React from "react";
import "./Featurecard.css";
export const Featurecard = ({ icon, heading, subheading }) => {
  return (
    <div className=" feature-card ">
      <div className="feature-card-circle">
        <img src={icon} alt="" />
      </div>
      <h1 className="feature-card-heading text-dark">{heading}</h1>
      <p className="feature-card-paragraph">{subheading}</p>
    </div>
  );
};
