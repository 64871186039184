import React from "react";
import "./Roadmap.css";

const CareerRoadMapCard = ({ data, handleBuy }) => {
  return (
    <div className="col-4-1 card-container px-2 py-4 my-2">
      <div>
        <div className="card-icon d-flex justify-center mb-2">
          <img src={data?.thumbnail} alt="roadmap" className="img-circle" />
        </div>
        <div className="card-content">
          <div className="px-4" style={{ height: "30px" }}>
            <h3>
              {data?.name
                ?.split("(")[0]
                .replace(
                  "Career Map for 8th, 9th & 10th - With Counseling",
                  "Career Map for 8th, 9th & 10th"
                )}
            </h3>
          </div>
          <div className="d-flex justify-center px-5 pt-4">
            <p className="card-description fw-700">{data?.description}</p>
          </div>
        </div>
        <div className="">
          <div className="row gx-2 mb-2">
            <div className="col-12">
              <div className="card-content-1">
                <div className="d-flex justify-content-center align-items-center">
                  <h3>With Counselling</h3>
                  <strong className="text-left ms-3">₹ 2499 /-</strong>
                </div>
                <small>+ GST applicable</small>

                {/* <h3>With Counselling</h3>
                <strong className="text-left">₹ 2499 /-</strong>
                <br />
                (courseId, name, image, price)
                <small>+ GST applicable</small> */}

                <div className="d-flex justify-center pt-3">
                  <button
                    className="button-with"
                    onClick={() =>
                      handleBuy(
                        data?.idWith,
                        data?.name,
                        data?.thumbnail,
                        data?.withPrice
                      )
                    }
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col-6">
              <div className="card-content-1">
                <h3>Without Counselling</h3>
                <strong className="text-left">₹ 1499 /-</strong>
                <br />
                <small>+ GST applicable</small>
                <div className="d-flex justify-center pt-3">
                  <button
                    className="button-without"
                    onClick={() =>
                      handleBuy(
                        data?.idWithout,
                        data?.withoutName,
                        data?.thumbnail,
                        data?.withoutPrice
                      )
                    }
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerRoadMapCard;
