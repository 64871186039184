import React, { useEffect, useState } from "react";
import cartCourse from "../icons/milestone (1).png";
import cartCourse2 from "../icons/milestone (2).png";
import cartCourse3 from "../icons/milestone (3).png";
import cartCourse4 from "../icons/milestone (4).png";
import achievement1 from "../icons/achievement (1).png";
import achievement2 from "../icons/achievement (1).png";
import achievement3 from "../icons/achievement (1).png";
import lock from "../icons/lock1.png";
import Lock from "../icons/Lock2.png";
import Lock3 from "../icons/Lock3.png";
import Lock4 from "../icons/Lock4.png";
import Lock5 from "../icons/Lock5.png";
import { getAllUserCourses, getProgress, getProgressById } from "Axios/apis";
import Spinner from "./Spinner/Spinner";
import NotEnrolled from "./NotEnrolled";
import HeaderTop from "./HeaderTop";
import "./achiev-certificate.css";
import html2canvas from "html2canvas";
import AchievementCertificates from "../components/assets/image/Achievement-Certificates.jpg";

const Achievements = (props) => {
  const [courses, setCourses] = useState([]);
  const [progressbar, setProgressBar] = useState();
  const [loadspinner, setloading] = useState(true);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [top, setTop] = useState(44.1);
  const [lastid, setLastid] = useState("");
  const downloadCert = (e) => {
    setTop(44);
    var node = document.getElementById("certificate");
    html2canvas(node).then((canvas) => {
      var img = canvas.toDataURL(`image/png`);
      var link = document.createElement("a");
      link.download = "certificate." + "png";
      link.href = img;
      link.click();
    });
    // setTimeout(() => {
    //   setTop(47.1);
    // }, [1000]);
    // setModalShow(true);
  };
  // useEffect(() => {
  //   const fetchCourse = async () => {
  //     setloading(true);
  //     // const result = await getProgress({
  //     //   userId: userId,
  //     // }, token)
  //     // //console.log(result.data.result)
  //     // setProgressBar(result.data.result)
  //     const response = await getAllUserCourses(token);
  //     setCourses(response?.data?.courses);
  //     setloading(false);
  //   };

  //   fetchCourse();
  //   // fetchProgress();
  // }, []);
  useEffect(() => {
    const fetchCourse = async () => {
      setloading(true);

      try {
        const response = await getAllUserCourses(token);
        const updatedCourses = response?.data?.courses.map(async (item) => {
          const totalChapters = item?.section?.reduce((total, section) => {
            return total + section.chapter.length;
          }, 0);
          const progressResult = await getProgressById(
            {
              course_id: item?.courseID,
            },
            token
          );

          const isCompleted =
            progressResult?.data?.result?.completedChapters === totalChapters;

          return {
            ...item,
            totalChapters,
            isCompleted,
          };
        });

        // Wait for all the course data to be updated
        const coursesWithData = await Promise.all(updatedCourses);

        setCourses(coursesWithData);
        setloading(false);
      } catch (error) {
        // Handle error
        console.error(error);
        setloading(false);
      }
    };

    fetchCourse();
  }, [token]);

  const certificate = [
    {
      certificateName: "UI & UX Bootcamp",
      certificateImg: achievement1,
    },
    {
      certificateName: "Web 3.0: Introduction",
      certificateImg: achievement1,
    },
    {
      certificateName: "Thinking behind the design",
      certificateImg: achievement1,
    },
  ];

  const [showCertitifcate, setShowCertificate] = useState({
    coursename: "",
    username: "",
    value: false,
  });
  const [isCom, setIsCom] = useState({});
  const showCert = async (item) => {
    setLastid(item?.courseID);
    setLoading(true);
    let result; // Declare result outside the try block
    try {
      const totalChapters =
        item &&
        item?.section?.reduce((total, section) => {
          return total + section.chapter.length;
        }, 0);
      result = await getProgressById(
        {
          course_id: item?.courseID,
        },
        token
      );
      setLoading(false);
      result?.data?.result?.completedChapters === totalChapters
        ? setShowCertificate({
            coursename: item?.name,
            username: localStorage.getItem("userName"),
            value: true,
          })
        : alert("You have to complete this course to get this certificate");
    } catch (err) {
      //console.log(err);
      setLoading(false);
    }
  };

  // //console.log(isCom);
  return (
    <>
      {loadspinner ? (
        <Spinner />
      ) : (
        <>
          <div>
            <HeaderTop
              togglesidebar={props.togglesidebar}
              name="Certificates"
            />

            {courses?.length === 0 && <NotEnrolled></NotEnrolled>}
            <div>
              <div className="flex flex-wrap">
                {courses?.map((item, index) => (
                  <>
                    <div className="achivement-container" key={index}>
                      <img
                        className="ach-img"
                        style={{ width: "100%" }}
                        src={item?.thumbnail}
                        alt=""
                      />
                      <div className="ach-middle">
                        {/* <img
                          src={Lock5}
                          alt=""
                        // onMouseEnter={() =>
                        //   alert(
                        //     "You have to complete this course to get this certificate"
                        //   )
                        // }
                        /> */}
                        {item.isCompleted ? null : <img src={Lock5} alt="" />}
                        {/* <div className="ach-text">John Doe</div> */}
                      </div>
                      <p
                        style={{ textAlign: "center", height: "74px" }}
                        className="flex justify-center"
                      >
                        {item.name}
                      </p>
                      <div className="d-flex justify-content-end">
                        <button
                          className="join-session-btn"
                          onClick={() => showCert(item)}
                        >
                          {loading && lastid === item.courseID ? (
                            <p className="text-center">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </p>
                          ) : (
                            "Certificate"
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          {showCertitifcate.value && (
            <>
              <div className="boq-service">
                <div className="card-achv box-shadow justify-center box-shadow shadow-2xl bg-white">
                  <div
                    onClick={() =>
                      setShowCertificate({
                        coursename: "",
                        username: "",
                        value: false,
                      })
                    }
                    id="close"
                    className="cancel-button"
                  >
                    <h4>
                      <i className="fa-regular fa-circle-xmark"></i>
                    </h4>
                  </div>
                  <div className="achi-cerf" id="certificate">
                    <img src={AchievementCertificates} alt="" />
                    <h3 className="content-head" style={{ top: `${top}%` }}>
                      {showCertitifcate?.username}
                    </h3>
                    <h3
                      className="content-course"
                      style={{
                        left:
                          showCertitifcate?.coursename.length > 50
                            ? "33%"
                            : "40%",
                      }}
                    >
                      {showCertitifcate?.coursename}
                    </h3>
                  </div>
                  <div
                    className="download-button"
                    onClick={() => downloadCert()}
                  >
                    <button>
                      Download
                      <i className="fa-solid fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Achievements;
