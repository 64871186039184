import React, { useState, useEffect } from "react";
import edit from "../components/assets/icon/edit.svg";
import cirlcleHeading from "../components/assets/icon/circle-heading.svg";
// import star from "../components/assets/icon/star.svg";
// import stardark from "../icons/stardark.svg";
import plus from "../components/assets/icon/plus.svg";
import EditProfileModal from "./EditProfileModal/EditProfileModal";
import {
  GetUserDetails,
  getBarGraph,
  getIntervieweeData,
  getStudentData,
} from "Axios/apis";
import moment from "moment";
import PureModal from "react-pure-modal";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import "./userprofile.css";
import { useRef } from "react";
import courseLeft from "../components/assets/icon/course-left-right (1).svg";
import courseRight from "../components/assets/icon/course-left-right (2).svg";
// import Avatar from "./Avatar/Avatar.png";
import Avatar2 from "./Avatar/Avatar-2.png";
// import Avatar4 from "./Avatar/Avatar-4.png";
import Avatar6 from "./Avatar/Avatar-6.png";
import Avatar8 from "./Avatar/Avatar-8.png";
import Avatar10 from "./Avatar/Avatar-10.png";
import Avatar12 from "./Avatar/Avatar-12.png";
import Avatar15 from "./Avatar/Avatar-15.png";
import Avatar17 from "./Avatar/Avatar-17.png";
import Avatar19 from "./Avatar/Avatar-19.png";
import Avatar20 from "assets/av2.png";
import Avatar21 from "assets/av3.png";
import Avatar22 from "assets/av4.png";
import Avatar23 from "assets/av5.png";
import Avatar25 from "assets/av6.png";
import Avatar24 from "assets/av7.png";
import Avatar26 from "assets/av9.png";
import Avatar27 from "assets/av10.png";
import avatar from "../components/assets/icon/profile-avatar3.png";
import { motion } from "framer-motion";
import HeaderTop from "./HeaderTop";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   PointElement,
//   LineElement,
// } from 'chart.js';
// import { Bar, Chart } from 'react-chartjs-2';
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

const role = localStorage.getItem("InterviewProRole");

const UserProfile = (props) => {
  // const [userProfile, setUserProfile] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [changeavatarmodal, setChangeavatarmodal] = useState(false);
  const userID = localStorage.getItem("userId");
  const [detailsupdated, setdetailsupdated] = useState(true);

  const [activeModal, setActiveModal] = useState("");

  // user avatar
  const [userAvatar, setUserAvatar] = useState();
  useEffect(() => {
    const getUserData = async () => {
      //
      const data = await GetUserDetails(userID);
      setUserDetails(data?.data);
      console.log(data?.data);
      // //console.log("hello");
    };
    getUserData();
    fetchHireProData();
    fetchBarGraph();
  }, [detailsupdated]);
  const [slidersetting, setslidersetting] = useState();

  var setting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  var setting1 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const updatesetting = () => {
      let screenwidth = window.innerWidth;
      if (screenwidth <= 910) {
        setslidersetting(setting1);
      } else {
        setslidersetting(setting);
      }
    };
    window.addEventListener("resize", updatesetting);
    updatesetting();
  }, []);
  const slider1 = useRef(null);

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const [weekPractice, setWeekPractice] = useState([]);
  const [lastWeekPractice, setLastWeekPractice] = useState([]);
  const [weekScore, setWeekScore] = useState([]);
  const [lastWeekScore, setLastWeekScore] = useState([]);

  const fetchBarGraph = async () => {
    try {
      const response = await getBarGraph();
      const arr1 = [];
      const arr2 = [];
      const arr3 = [];
      const arr4 = [];
      const arr5 = [];
      const arr6 = [];
      response?.data?.data?.weeklyData?.map((data, i) => {
        if (i < 7) {
          arr1.push(data?.attempt);
          arr2.push(data?.accuracy || 0);
        } else {
          arr3.push(data?.attempt);
          arr4.push(data?.accuracy || 0);
        }
      });
      response?.data?.data?.topicwise?.slice(0, 6).map((data) => {
        arr5.push(data?.stream_id?.name);
        arr6.push(data?.studentMarks);
      });
      setWeekPractice(arr3);
      setLastWeekPractice(arr1);
      setWeekScore(arr4);
      setLastWeekScore(arr2);
    } catch (error) {
      console.log(error);
    }
  };

  // const labels = ['w1', 'w2', 'w3', 'w4', 'w5', 'w6', 'w7'];

  // const data2 = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       type: "line",
  //       label: 'Last Week ',
  //       data: lastWeekPractice,
  //       backgroundColor: '#ffa303',
  //     },
  //     {
  //       type: "bar",
  //       label: 'This Week',
  //       data: weekPractice,
  //       backgroundColor: '#FFC156',
  //     }
  //   ],
  // };
  // const data3 = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       type: "line",
  //       label: 'Last Week ',
  //       data: lastWeekScore,
  //       backgroundColor: '#ffa303',
  //     },
  //     {
  //       type: "bar",
  //       label: 'This Week',
  //       data: weekScore,
  //       backgroundColor: '#FFC156',
  //     }
  //   ],
  // };

  const [hireproData, setHireproData] = useState({});

  const fetchHireProData = async () => {
    try {
      if (role === "interviewee") {
        const res = await getIntervieweeData();
        setHireproData(res?.data?.data);
      } else {
        const res = await getStudentData();
        setHireproData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        {editModalOpen && (
          <EditProfileModal
            detailsupdated={detailsupdated}
            setdetailsupdated={setdetailsupdated}
            editModalOpen={editModalOpen}
            setEditModalOpen={setEditModalOpen}
            userDetails={userDetails}
            openModal={activeModal}
          />
        )}
        <HeaderTop togglesidebar={props.togglesidebar} name="Profile" />

        <div className="flex row gap-x-6  mt-4 mx-3">
          <div className="col-3 w-100-768 d-flex-col-res">
            <div>
              <div
                onClick={() => {
                  setChangeavatarmodal(true);
                }}
                className="flex items-center add-avater "
              >
                <img className="m-2" src={plus} alt="" />
                <p style={{ color: "#FFA303" }}>Add Avatar</p>
              </div>
              <div className="user-photo d-flex justify-content-center align-items-center">
                <img
                  height={
                    localStorage.getItem("userimageavatar") ? "170" : "120"
                  }
                  width={
                    localStorage.getItem("userimageavatar") ? "170" : "120"
                  }
                  src={localStorage.getItem("userimageavatar") || avatar}
                  alt=""
                />
              </div>
            </div>

            <div className="w-100-md">
              <div className="flex items-center justify-between mt-5">
                <p
                  style={{ width: "225px", fontSize: "22px" }}
                  className="userName"
                >
                  {userDetails?.role}
                </p>
                <img
                  onClick={() => {
                    setActiveModal("Personal Details");
                    setEditModalOpen(true);
                  }}
                  src={edit}
                  className="mx-2 cursor-pointer mr-zero-md"
                  alt=""
                />
              </div>

              <p className="my-4 ">
                {userDetails?.result?.name || "Anonymous"}
              </p>
              <p className="my-4 ">{userDetails?.result?.email}</p>
              <p className="mb-4">{userDetails?.result?.phoneNumber}</p>
            </div>
          </div>

          <div className="user-course col-8 w-100-768">
            <div>
              <p className="mb-2 coursePurchased" style={{ fontSize: "22px" }}>
                Courses Purchased
              </p>
              <img src="" alt="" />
            </div>
            {userDetails?.result?.course.length > 0 ? (
              <div className="mt-2 mx-2">
                <div
                  className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 course-view-slider"
                  style={{ position: "relative" }}
                >
                  {userDetails?.result?.course.length > 2 && (
                    <div className="flex items-center justify-end mb-2 gap-x-6 mx-2">
                      <img
                        className="course-left-right"
                        onClick={() => slider1.current.slickPrev()}
                        src={courseLeft}
                        alt=""
                      />
                      <img
                        onClick={() => slider1.current.slickNext()}
                        className="course-left-right"
                        src={courseRight}
                        alt=""
                      />
                    </div>
                  )}
                  {userDetails?.result?.course.length > 1 && (
                    <Slider ref={slider1} {...slidersetting}>
                      {userDetails?.result?.course.map((data, index) => (
                        <div
                          className="flex holeCourse mb-3 me-2 d-flex mx-2"
                          key={index}
                          style={{ border: "2px solid #FEA302" }}
                        >
                          <img
                            className="mr-4 courseImage"
                            src={data?.thumbnail}
                            style={{ borderRadius: "20px" }}
                            alt=""
                          />
                          <div>
                            <p
                              className="uiux-design"
                              style={{ fontSize: "14px" }}
                            >
                              {data?.name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  )}
                  {userDetails?.result?.course.length === 1 &&
                    userDetails?.result?.course.map((data, index) => (
                      <div
                        className="flex holeCourse mb-3 me-2 d-flex mx-2"
                        key={index}
                        style={{ border: "2px solid #FEA302" }}
                      >
                        <img
                          className="mr-4 courseImage"
                          src={data?.thumbnail}
                          style={{ borderRadius: "20px" }}
                          alt=""
                        />
                        <div>
                          <p
                            className="uiux-design"
                            style={{ fontSize: "14px" }}
                          >
                            {data?.name}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="my-4">
                <h1 style={{ color: "#521986" }}>
                  You have not Purchased any course Yet
                </h1>
              </div>
            )}

            <div className="course-container-pur">
              <div className="me-5 w-100-md mr-zero-md">
                <div className=" mb-4 d-flex align-items-center justify-content-between-md ">
                  <p className=" skill-heading">Education</p>
                  <img
                    onClick={() => {
                      setActiveModal("Education");
                      setEditModalOpen(true);
                    }}
                    src={edit}
                    className="mx-2 cursor-pointer"
                    alt=""
                  />
                </div>
                {userDetails?.result?.education.map((data, index) => (
                  <div className="mb-4" key={index}>
                    <div className="flex">
                      <img src={cirlcleHeading} alt="" />
                      <p className="ml-2 skill-sub-heading">{data?.college}</p>
                    </div>
                    <p className="ml-[27px] skill-details mt-2">
                      {data?.stream}, {data?.course} |{" "}
                      {moment(data?.startingYear).format("MM/YYYY")} -{" "}
                      {moment(data?.passingYear).format("MM/YYYY")}
                    </p>
                  </div>
                ))}
              </div>
              <div className="w-100-md">
                <div className="mb-4 d-flex align-items-center justify-content-between-md ">
                  <p className=" skill-heading">Work Experience</p>
                  <img
                    onClick={() => {
                      setActiveModal("Work Ex");
                      setEditModalOpen(true);
                    }}
                    src={edit}
                    className="mx-2 cursor-pointer"
                    alt=""
                  />
                </div>
                {userDetails?.result?.workExperience.map((data, index) => (
                  <div key={index} className="mb-4">
                    <div className="flex">
                      <img src={cirlcleHeading} alt="" />
                      <p className="ml-2 skill-sub-heading">{data?.company}</p>
                    </div>
                    <p className="ml-[27px] skill-details mt-2">
                      {data?.designation}, {data?.location} |{" "}
                      {moment(data?.startingYear).format("MM/YYYY")} -{" "}
                      {moment(data?.endingYear).format("MM/YYYY")}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-content-center">
          <p className="skill-heading">Aptitude Analysis</p>
        </div>
        <div className="flex mb-3">
          <div className=' col-12 col-sm-6 '>
            <h6 className='text-center my-2'>WEEKLY PRACTICE</h6>

            <Chart options={options2} data={data2} />
          </div>
          <div className='col-12 col-sm-6 '>
            <h6 className='text-center my-2' >WEEKLY SCORE </h6>
            <Chart options={options2} data={data3} />
          </div>
        </div> */}
      </div>

      <div className="flex justify-content-center">
        <p className="skill-heading">Interview Pro Analysis</p>
      </div>
      <div>
        <div className="card1-container my-3">
          {role === "interviewee" ? (
            <div className="dashboard-card1 ">
              <div className="col-12">
                <h6 className="card1-head">Total Mock Interviews</h6>
                <h6>{hireproData?.totalInterviews}</h6>
              </div>
            </div>
          ) : (
            <div className="dashboard-card1 ">
              <div className="col-12">
                <h6 className="card1-head">Total Interviews</h6>
                <h6>{hireproData?.totalInterviews}</h6>
              </div>
            </div>
          )}

          <div className="dashboard-card1 ">
            <div className="col-12">
              <h6 className="card1-head">Total interview attempts</h6>
              <h6>{hireproData?.attemptedInterviews}</h6>
            </div>
          </div>

          <div className="dashboard-card1 ">
            <div className="col-12">
              <h6 className="card1-head">Total Time Spent</h6>
              <h6>{Math.round(hireproData?.totalMinutes)} Minutes</h6>
            </div>
          </div>
          <div className="dashboard-card1 ">
            <div className="col-12">
              <h6 className="card1-head">Average Interview Score</h6>
              <h6>{Math.round(hireproData?.averageScore)}/100</h6>
            </div>
          </div>
        </div>
      </div>
      {/* // change avatar modal */}
      <div className="overflow-auto">
        <PureModal
          header="Change Avatar"
          isOpen={changeavatarmodal}
          closeButton="X"
          width={500}
          closeButtonPosition="header"
          onClose={() => {
            setChangeavatarmodal(false);
            return true;
          }}
          className="overflow-auto"
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="image-container-1">
              {/* <div
              className={` ${userAvatar === Avatar ? "active" : null} avatar `}
            >
              <img
                onClick={() => {
                  setUserAvatar(Avatar);
                }}
                src={Avatar}
                alt=""
              />
            </div> */}
              {/* <div className={` ${
                userAvatar === Avatar1 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar1)}} src={Avatar1} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar2 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar2);
                  }}
                  src={Avatar2}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar3 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar3)}} src={Avatar3} alt="" />
        </div> */}
              {/* <div
              className={` ${userAvatar === Avatar4 ? "active" : null} avatar `}
            >
              <img
                onClick={() => {
                  setUserAvatar(Avatar4);
                }}
                src={Avatar4}
                alt=""
              />
            </div> */}
              <div
                className={` ${
                  userAvatar === Avatar23 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar23);
                  }}
                  src={Avatar23}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar27 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar27);
                  }}
                  src={Avatar27}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar26 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar26);
                  }}
                  src={Avatar26}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar5 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar5)}} src={Avatar5} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar6 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar6);
                  }}
                  src={Avatar6}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar7 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar7)}} src={Avatar7} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar8 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar8);
                  }}
                  src={Avatar8}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar9 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar9)}} src={Avatar9} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar10 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar10);
                  }}
                  src={Avatar10}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar11 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar11)}} src={Avatar11} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar12 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar12);
                  }}
                  src={Avatar12}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar13 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar13)}} src={Avatar13} alt="" />
        </div> */}
              {/* <div className={` ${
                userAvatar === Avatar14 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar14)}} src={Avatar14} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar15 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar15);
                  }}
                  src={Avatar15}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar16 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar16)}} src={Avatar16} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar17 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar17);
                  }}
                  src={Avatar17}
                  alt=""
                />
              </div>
              {/* <div className={` ${
                userAvatar === Avatar18 ? "active" : null
              } avatar `}>
          <img onClick={()=>{setUserAvatar(Avatar18)}} src={Avatar18} alt="" />
        </div> */}
              <div
                className={` ${
                  userAvatar === Avatar19 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar19);
                  }}
                  src={Avatar19}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar20 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar20);
                  }}
                  src={Avatar20}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar21 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar21);
                  }}
                  src={Avatar21}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar22 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar22);
                  }}
                  src={Avatar22}
                  alt=""
                />
              </div>

              <div
                className={` ${
                  userAvatar === Avatar24 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar24);
                  }}
                  src={Avatar24}
                  alt=""
                />
              </div>
              <div
                className={` ${
                  userAvatar === Avatar25 ? "active" : null
                } avatar `}
              >
                <img
                  onClick={() => {
                    setUserAvatar(Avatar25);
                  }}
                  src={Avatar25}
                  alt=""
                />
              </div>
            </div>
            <div className="my-4 ">
              <Button
                className="mx-2"
                style={{
                  borderRadius: "1px solid #fea302",
                  background: "#fff",
                  color: "#fea302",
                }}
                onClick={() => {
                  setChangeavatarmodal(false);
                }}
              >
                Close
              </Button>
              <Button
                className="mx-2"
                onClick={() => {
                  localStorage.setItem("userimageavatar", userAvatar);
                  setChangeavatarmodal(false);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </PureModal>
      </div>
    </motion.div>
  );
};

export default UserProfile;
