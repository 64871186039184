import React from "react";
import { useState } from "react";

const AnswerPart = () => {
  const [call, setCall] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [text, setText] = useState(false);

  const handleCall = () => {
    setCall(true);
    setWhatsApp(false);
    setText(false);
  };

  const handleWhatsApp = () => {
    setCall(false);
    setWhatsApp(true);
    setText(false);
  };

  const handleText = () => {
    setCall(false);
    setWhatsApp(false);
    setText(true);
  };
  return (
    <div className="mx-16 mb-12">
      <div className="flex justify-between contact-info-border">
        <div className="flex items-center p-8 mx-12">
          <p className="answer">
            Here is your answer to <br></br>{" "}
            <span className="my-6" style={{ color: "#fea302" }}>
              "100% Placement."
            </span>{" "}
            <br></br>
            Now and always.
          </p>
        </div>

        <div className="p-8 mx-12">
          <div onClick={handleCall}>
            <p
              className={`contact flex justify-center items-center ${
                call ? "forHover" : false
              }`}
            >
              022-4011-5448
            </p>
          </div>
          <div onClick={handleWhatsApp}>
            <p
              className={`my-4 contact flex justify-center items-center ${
                whatsApp ? "forHover" : false
              }`}
            >
              +91-8877554411
            </p>
          </div>
          <div onClick={handleText}>
            <p
              className={`contact flex justify-center items-center ${
                text ? "forHover" : false
              }`}
            >
              mail@viosa.in
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerPart;
