import React, { useState, useEffect } from "react";
import "./Assignment.css";
import { useSelector, useDispatch } from "react-redux";
import { Show } from "components/Show";
import AssignmentQuiz from "./AssignmentQuiz";
import AssignmentSubmit from "./AssignmentSubmit";
import { motion } from "framer-motion";
import { updateAttempts } from "../../redux/user/userAction"; // Assuming you have a redux action for updating attempts

const Assignment = () => {
  const [questionScreen, setQuestionScreen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // in seconds
  const [showResults, setShowResults] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const dispatch = useDispatch();
  const { attempts } = useSelector((state) => state.user); // Assuming attempts are stored in user state
  const { courseLink } = useSelector((state) => state.course);
  const duration = courseLink?.link6[0]?.duration || 30; // default to 30 minutes
  // const question = courseLink?.link6?.length;
  const questionList = courseLink?.link6 || [];
  const attempt = courseLink?.link6[0]?.numberOfAttemp || 5;
  const totalMarks = courseLink.link6[0].passingMarks;
  console.log(duration, attempt, totalMarks);
  console.log("courseLink", courseLink);
  const maxAttempts = attempt; // Example limit

  // function handleClick() {
  //   setQuestionScreen(true);
  // }
  useEffect(() => {
    if (questionScreen) {
      setTimeLeft(duration * 60); // Convert minutes to seconds
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setShowResults(true);
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [questionScreen, duration]);

  const handleClick = () => {
    if (attempts < maxAttempts) {
      setQuestionScreen(true);
      dispatch(updateAttempts(attempts + 1)); // Increment attempt count
    } else {
      alert("You have reached the maximum number of attempts");
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Show condition={!questionScreen && !showResults}>
        <div className="main-container">
          <div className="outer-container">
            <div className="container">
              <div className="container-fluid layout-container">
                <div className="preview-txt">
                  <p>Preview Assessment</p>
                </div>
                <div className="inner-container container-fluid">
                  {/* <div className="inner-container-heading">
                    Untitled Chapter
                  </div> */}
                  <div className="inner-container-subheading"></div>
                  <div className="container-fluid no-padding ">
                    <table className="table ">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Duration</th>
                          <th scope="col">Attempt</th>
                          <th scope="col">Questions</th>
                          <th scope="col">Total Marks</th>
                          <th scope="col">Pass Marks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td title="Duration (minutes) :">{duration} m</td>
                          <td title="Attempt :">{attempt || `0/${attempt}`}</td>
                          {/* <td title="Questions :">{question || "2"}</td> */}
                          <td title="Questions :">
                            {questionList.length || "2"}
                          </td>
                          <td title="Total Marks :">{totalMarks || "10"}</td>
                          <td title="Pass Marks :">{totalMarks || "5"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="exm-thead"></div>
                    <div className="exm-tbody"></div>
                  </div>
                  <div className="inner-container-footer">
                    <p>
                      By clicking on the start button your assessment timer will
                      start. Do not reload or navigate on other page when timer
                      starts. Best of Luck!
                    </p>
                    <button
                      // className="inner-container-btn"
                      className={`inner-container-btn ${
                        attempts >= maxAttempts ? "disabled-btn" : ""
                      }`}
                      onClick={handleClick}
                      disabled={attempts >= maxAttempts}
                    >
                      Start Assessment
                    </button>
                    {attempts >= maxAttempts && (
                      <p className="error-msg">
                        You have reached the maximum number of attempts.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Show>
      <Show condition={questionScreen}>
        <AssignmentQuiz
          // question={courseLink.link6}
          questionList={questionList}
          timeLeft={timeLeft}
          setShowResults={setShowResults}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      </Show>
      {/* <Show condition={showResults}>
        <AssignmentSubmit questionList={questionList} />
      </Show> */}
    </motion.div>
  );
};

export default Assignment;
