import React from "react";
import { Link } from "react-router-dom";
import notEnrolled from "../icons/not-enrolled.png";
import rightArrow from "../icons/right-arrow.svg";

const NotEnrolled = () => {
  return (
    <div>
      <div>
        <div className="grid mt-4 mb-8">
          <p className="dont-have-course">
            Currently you don’t have any active course
          </p>

          <div className="flex mt-4">
            <p className="mr-4 explore">
              <Link to="/profile/catalogue" className="explore">
                Explore Courses{" "}
              </Link>
            </p>
            <img src={rightArrow} alt="" />
          </div>
        </div>

        <img className="not-enrolled-img" src={notEnrolled} alt="" />
      </div>
    </div>
  );
};

export default NotEnrolled;
