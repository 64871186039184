import clock from "../icons/clock.svg";
import star from "../icons/star.svg";
import stardark from "../icons/stardark.svg";
import carrer2 from "../icons/carrer (2).png";
import carrer5 from "../icons/career5.png";
import carrer6 from "../icons/career6.png";
import "./Catalogue.css";
import "./Admin.css";
import "swiper/css";
import why from "assets/why.png";
import people from "../components/assets/icon/contact-icon (1).svg";
import messageing from "../components/assets/icon/contact-icon (2).svg";
import phoneCall from "../components/assets/icon/contact-icon (3).svg";
import {
  CareerCounselorForm,
  CollabForm,
  SupportForm2,
  getCourseDetailsbySlug,
  AddToCart,
  ApplyCoupon,
  FetchSingleCourse,
  getAllUserCourses,
} from "Axios/apis";

// import LargeVideo from "components/assets/icon/large-video.png";
import ug from "assets/gu.png";
import pg from "assets/pg.png";
import interview from "assets/ip.png";
import resume from "assets/resume.png";
import je from "assets/je.png";
import "swiper/css/pagination";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  AddtoCart,
  UserLogin,
  userRequest,
  userRoadMapCourse,
} from "redux/user/userAction";
import Cartmodal from "./Cartmodal/Cartmodal";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CourseData from "./CourseData";
import { toast, ToastContainer } from "react-toastify";
import ReactPlayer from "react-player";
import SignUp from "./Authentication/SignUp/SignUp";
import Spinner from "./Spinner/Spinner";
import FooterPurple from "./Footer/FooterPurple";
import axios from "axios";
import TimelineComp from "./TimelineComp/TimelineComp";
import FrequentlyAsked from "./Home/FrequentlyAsked";
import { Featurecard } from "./Featurecard/Featurecard";
import FiveD from "../components/assets/icon/5D.png";
import ACCURACY from "../components/assets/icon/ACCURACY.png";
import REPORT from "../components/assets/icon/REPORT-1.png";
import COUNSELLING from "../components/assets/icon/COUNSELLING.png";

const CourseNotLogin = ({
  setBlur,
  register1,
  setRegister,
  login,
  setLogin,
}) => {
  const [loading, setloading] = useState(false);
  const [call, setCall] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [text, setText] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [supportdata, setsupportdata] = useState({
    course: "",
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  // console.log(supportdata);
  // console.log(params.id);
  const submitsupportform = async (e) => {
    e.preventDefault();
    // console.log(courseid);
    supportdata.course = params.id;
    console.log(supportdata);
    if (
      supportdata.name === "" ||
      supportdata.email === "" ||
      supportdata.message === ""
    ) {
      alert("Please fill all the fields");
      return;
    }
    if (supportdata.phone.toString().length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }
    setloading(true);
    try {
      const res = await SupportForm2(supportdata);
      // //console.log(res);

      setloading(false);
      setWhatsApp(false);
      setsupportdata({
        Course: "",
        name: "",
        email: "",
        phone: "",
        message: "",
      });

      alert("Thank you for your interest. We will get back to you soon.");
    } catch (error) {
      setloading(false);

      // //console.log(error);
    }
  };
  const handleChangesupport = (e) => {
    if (e.target.name === "phone") {
      if (e.target.validity.patternMismatch) {
        e.target.setCustomValidity("Please enter a valid phone number");
      } else {
        e.target.setCustomValidity("");
      }
    }
    setsupportdata({ ...supportdata, [e.target.name]: e.target.value });
  };
  const [courseData, setCourseData] = useState();
  const dispatch = useDispatch();
  const [largeVideo, setVideo] = useState();
  const { cart } = useSelector((state) => state.user);
  const [courses, setCourses] = useState([]);

  const [accessCode, setAccessCode] = useState("");
  const { course } = useSelector((state) => state.course);
  const { userData } = useSelector((state) => state.user);
  const [status, setStatus] = useState(false);
  const [videoLink, setVideoLink] = useState({
    videoLink1: "",
    videoLink2: "",
  });
  const location = useLocation();
  const loginToken = localStorage.getItem("token");
  const [couponloading, setcouponloading] = useState(false);
  const lastCourse = courses.filter((item) => item?.course_id === course);
  const [courseid, setCourseid] = useState("");

  const [singleCourse, setSingleCourse] = useState([]);
  const params = useParams();
  console.log("Params", params);
  const [addedtocart, setaddedtocart] = useState("");
  const [loadingcart, setloadingcart] = useState(false);
  const [isPurchased1, setIsPurchased] = useState(false);
  const [paymentsuccess, setPaymentSuccess] = useState(false);

  const [counselling, setCounselling] = useState("");
  const [activecourse, setActiveCourse] = useState(false);
  // //console.log("activecourse", activecourse);
  const [firstHead, setFirstHeaad] = useState("");
  const [secondHead, setSecondHead] = useState("");

  const [proffcontent, setProffContent] = useState("");
  const userid = localStorage.getItem("userId");
  // //console.log("courseData", courseData);
  const [spin, setSpin] = useState(false);
  let [searchParams] = useSearchParams();

  const handleLogin = () => {
    console.log(params.id);
    dispatch(userRequest(params.id));
    setRegister(true);
  };
  const handleWhatsApp = () => {
    setCall(false);
    setWhatsApp(true);
    setText(false);
    console.log(whatsApp);
  };
  const isCourseExists = cart?.find((item) => item.name === singleCourse?.name);

  const addToCart = async () => {
    if (!loginToken) {
      setRegister(true);
    } else {
      setloadingcart(true);
      try {
        const cartitem = {
          cartBody: {
            courseID: singleCourse?._id,
            name: singleCourse?.name,
            thumbnail: singleCourse?.thumbnail,
            price: singleCourse?.price,
            course_id: singleCourse?.course_id,
          },
          userId: localStorage.getItem("userId"),
        };
        const res = await AddToCart(cartitem);
        dispatch(AddtoCart(res?.data?.result?.cart));
        setaddedtocart(lastCourse[0]?.course_id);
        setloadingcart(false);
      } catch (error) {
        // //console.log(error);
        // toast.warning("Failed to Add to Cart, please try again");
        setloadingcart(false);

        alert("Failed to Add to Cart, please try again");
      }
    }
  };

  const handleBuy = async () => {
    if (loginToken) {
      if (isCourseExists?.name === singleCourse?.name) {
        navigate("/profile/cart");
      } else {
        const cartitem = {
          cartBody: {
            // courseID: params.id,
            courseID: singleCourse?._id,
            name: singleCourse?.name,
            thumbnail: singleCourse?.thumbnail,
            price: singleCourse?.price,
            course_id: singleCourse?.course_id,
          },
          userId: localStorage.getItem("userId"),
        };
        const res = await AddToCart(cartitem);
        dispatch(AddtoCart(res?.data?.result?.cart));
        setSpin(false);
        if (res?.data?.result?.cart) {
          navigate("/profile/cart");
        }
      }
    } else {
      setRegister(true);
      setSpin(false);
      dispatch(
        userRoadMapCourse({
          courseId: params.id,
          navigateTo: `/course/${params.id}?couselling=false`,
        })
      );
    }
  };

  const isPurchased =
    userData?.courseList?.filter((item) => item?.courseID === course) || [];

  const removeUselessWords = (txt) => {
    var uselessWordsArray = [
      "&",
      "with",
      "counselling",
      "without",
      "counseling",
    ];

    var expStr = uselessWordsArray.join("|");
    return txt
      .replace(new RegExp("\\b(" + expStr + ")\\b", "gi"), " ")
      .replace(/\s{2,}/g, " ");
  };
  console.log("singleCourse?.price", singleCourse?.price);
  console.log("singleCourse", singleCourse);
  console.log("params: ", params.id);

  const navigate = useNavigate();
  console.log(whatsApp);

  // //console.log(params);
  // useEffect(() => {
  //   setloading(true);
  //   if (params.id) {
  //     const fetchCourse = async () => {
  //       const response = await FetchSingleCourse(params.id, userid);
  //       setSpin(false);
  //       setVideoLink({
  //         videoLink1: response?.data?.courses?.videoLink1,
  //         videoLink2: response?.data?.courses?.videoLink2,
  //       });
  //       if (
  //         response?.data?.courses?.name?.includes("Under-Grad/Graduates") ||
  //         response?.data?.courses?.name?.includes(
  //           "Graduates/Under-Graduates"
  //         ) ||
  //         params.id === "6343c079e33839d145810efb"
  //       ) {
  //         setVideo(ug);
  //       }
  //       if (
  //         response?.data?.courses?.name?.includes("Post-Graduates") ||
  //         response?.data?.courses?.name?.includes("Post Graduates")
  //       ) {
  //         setVideo(pg);
  //       }
  //       if (params.id === "6343c119e33839d145810f19") {
  //         setVideo(resume);
  //       }
  //       if (params.id === "6343c15fe33839d145810f31") {
  //         debugger;
  //         setVideo(interview);
  //       }
  //       if (params.id === "6343c195e33839d145810f48") {
  //         setVideo(je);
  //       }
  //       if (
  //         !response?.data?.courses?.name?.includes("Roadmap") &&
  //         params.id !== "6343c195e33839d145810f48" &&
  //         params.id !== "6343c119e33839d145810f19" &&
  //         params.id === "6343c15fe33839d145810f31"
  //       ) {
  //         setVideo("");
  //       }
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //         left: 0,
  //       });
  //       console.log(response?.data?.courses);
  //       if (
  //         response?.data?.courses?.name
  //           .toLowerCase()
  //           .includes("with counseling") ||
  //         response?.data?.courses?.name
  //           .toLowerCase()
  //           .includes("with counseling") ||
  //         response?.data?.course?.name
  //           .toLowerCase()
  //           .includes("(with counseling)")
  //       ) {
  //         setCounselling("With Counseling");
  //       } else if (
  //         response?.data?.courses?.name
  //           .toLowerCase()
  //           .includes("without counseling") ||
  //         response?.data?.courses?.name
  //           .toLowerCase()
  //           .includes("without counseling") ||
  //         response?.data?.courses?.name
  //           .toLowerCase()
  //           .includes("(without counseling)")
  //       ) {
  //         //console.log("No");
  //         setCounselling("Without Counseling");
  //       }
  //       setSingleCourse(response?.data?.courses);
  //       if (
  //         response?.data?.courses?.description == "Corporate Sector Learning"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.description
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.description == "Automated Resume Builder"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.description
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.name == "Corporate Sector Learning"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.name
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.name == "Functional skill competency"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == "Softskill Competency Coaching"
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.description == "Interview Skill Training"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.description
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.name == "Elevator Pitch & Group Discussions"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.name
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.name ==
  //         "Digital Profiling & Professional Networking"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.name
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else if (
  //         response?.data?.courses?.name == "Job Identification & Application"
  //       ) {
  //         let newcoursedata = CourseData.filter(
  //           (course) => course.name == response?.data?.courses?.name
  //         );
  //         console.log("All Course Data:", CourseData);

  //         console.log("Response Course Name:", response?.data?.courses?.name);
  //         console.log("Filtered Course Data:", newcoursedata);

  //         setCourseData(newcoursedata[0]);
  //         const fullstring = removeUselessWords(newcoursedata[0].name);
  //         const splitfullstring = fullstring.split(" ");
  //         if (splitfullstring.length === 3) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[2]);
  //         } else if (splitfullstring.length === 5) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
  //         } else if (splitfullstring.length === 4) {
  //           setActiveCourse(true);
  //           setloading(false);
  //           setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
  //           setSecondHead(splitfullstring[3]);
  //         }
  //         setProffContent([
  //           {
  //             heading: "Benefits of Resumatic AI",
  //             para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
  //             para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
  //           },
  //           {
  //             heading: "Why AI-Powered Career Assessment & Planning?",
  //             para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
  //             para2:
  //               "Personalized career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //           },
  //         ]);
  //       } else {
  //         setActiveCourse(false);
  //         setCourseData({
  //           isCounselling: response?.data?.courses?.isCounselling,
  //           name: removeUselessWords(response?.data?.courses?.name),
  //           desc:
  //             response?.data?.courses?.description ||
  //             response?.data?.courses?.name,
  //           // "The difference between User Interface (UI) and User Experience (UX) is that UI refers to the aesthetic elements by which people interact with a product, while UX is about the experience a user has with a product or service",
  //           time: "60 min",
  //           price: response?.data?.courses?.price || "999",
  //           text1: "You Will Learn & Gain Expertise on Various Sectors",
  //           text2: "Identify the Industry & Sector Of Your Interest",
  //           text3: "Course duration: 240 minutes (2 sessions)",
  //           videoLink1: response?.data?.courses?.videoLink1,
  //           videoLink2: response?.data?.courses?.videoLink2,
  //           text4: [
  //             "- Recorded Sessions",
  //             "- Learn Sector-Based Competencies",
  //             "- 240 min modules ",
  //             "- 30 days access for a revision session",
  //           ],
  //           text5: [
  //             {
  //               head: "Benefits Of AI-Powered Viosa Career Roadmap",
  //               para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding.",
  //               para2:
  //                 "Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-Powered professional career assessment and a professional counselor.",
  //             },
  //             {
  //               head: "Why AI-Powered Career Assessment & Planning?",
  //               para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality. ",
  //               para2:
  //                 "Personalized career guidance from certified counselors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
  //             },
  //           ],
  //           featurecarddata: [
  //             {
  //               icon: FiveD,
  //               heading: "5-Dimensional Professional Personality Analysis",
  //               subheading:
  //                 "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
  //             },
  //             {
  //               icon: ACCURACY,
  //               heading: "98.6 % Accuracy",
  //               subheading:
  //                 "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
  //             },
  //             {
  //               icon: REPORT,
  //               heading: "24+ Page Detailed Professional Career Plan ",
  //               subheading:
  //                 "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
  //             },
  //             {
  //               icon: COUNSELLING,
  //               heading:
  //                 "Career Assessment & Counseling In the Language Of Your Choice",
  //               subheading:
  //                 "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
  //             },
  //           ],
  //           timelinedata: [
  //             "Start Career Assessment",
  //             "Get a 24 + Page Report",
  //             "Analyse Your Career Paths",
  //             "Get Personalized Career Counseling",
  //           ],
  //           text6: `How will ${response?.data?.courses?.name} help you?`,
  //           text7: [
  //             {
  //               iconImg: carrer2,
  //               head: "Sector Knowledge",
  //               para: "Learn about sector and respective competencies to achieve your aspirational professional roles",
  //             },
  //             {
  //               iconImg: carrer5,
  //               head: "Functional Understanding",
  //               para: "Employees who possess frontend/backend skills tend to be more successful in professional careers at an early stage",
  //             },
  //             {
  //               iconImg: carrer6,
  //               head: "Industry Trends",
  //               para: "Learn about latest trends in your choice of industry and government initiative for top 5 growing sectors",
  //             },
  //           ],
  //           faq: [
  //             {
  //               headQuestion: "What is five dimensional career assessment?",
  //               paraAnswer: `Our career assessment explores the 5-dimensions of the candidate. It
  //               includes Personality analysis, Career Interest analysis, Career
  //               motivators analysis, learning style analysis and Skills & Abilities
  //               analysis.`,
  //             },
  //             {
  //               headQuestion:
  //                 "What is the difference between Free Suitability and Comprehensive Analysis?",
  //               paraAnswer: `Both are 5-Dimensional career assessment. In Free Suitability,
  //               candidate can get analysis of one career path of their choice with
  //               free 3 pages basic report. In Comprehensive plan, career analysis will
  //               be done across 160+ career paths and 2000+ Occupations to find out the
  //               best career path for you. Comprehensive 28+ pages career report with
  //               execution plan will help you to achieve career goals.`,
  //             },
  //             {
  //               headQuestion:
  //                 "Once Comprehensive report is activated, can I see analysis other career paths as well?",
  //               paraAnswer: `Yes, very much. This is the biggest advantage every student is having.
  //               Once assessment is done student can generate unlimited Comprehensive
  //               28+ pages Comprehensive reports of different career paths. It is
  //               important to know you most preferred career paths but equally it is
  //               important to know about career paths that you should avoid or develop.`,
  //             },
  //             {
  //               headQuestion:
  //                 "Do I need to give assessment once again for Comprehensive analysis?",
  //               paraAnswer: `No, you do not need to give assessment again. Once assessment is done,
  //               you can upgrade any time. System will automatically analyze all career
  //               paths and generate Comprehensive report. Our powerful Artificial
  //               intelligence engine will unlock all the career path analysis.`,
  //             },
  //           ],
  //         });
  //         setloading(false);
  //       }

  //       // dispatch(SingleCourse(response?.data?.courses));
  //     };
  //     const setSpecificCourses = () => {
  //       setCourseid(params.id);
  //       fetchCourse();
  //     };
  //     setSpecificCourses();
  //   }
  // }, [params.id, location.pathname]);
  // console.log(params.id);
  useEffect(() => {
    setloading(true);
    console.log("hi");
    if (params.id) {
      const fetchCourse = async () => {
        console.log("Response", params.id);
        const response = await getCourseDetailsbySlug(params.id, userid);
        console.log("Response", response);
        setSpin(false);
        setVideoLink({
          videoLink1: response?.data?.courses?.videoLink1,
          videoLink2: response?.data?.courses?.videoLink2,
        });
        if (
          response?.data?.courses?.name?.includes("Under-Grad/Graduates") ||
          response?.data?.courses?.name?.includes(
            "Graduates/Under-Graduates"
          ) ||
          params.id ===
            "professional-roadmap-for-post-graduates-without-counselling"
        ) {
          setVideo(ug);
        }
        if (
          response?.data?.courses?.name?.includes("Post-Graduates") ||
          response?.data?.courses?.name?.includes("Post Graduates")
        ) {
          setVideo(pg);
        }
        if (params.id === "resumatic-ai") {
          setVideo(resume);
        }
        if (params.id === "interview-pro") {
          setVideo(interview);
        }
        if (params.id === "job-identification-application") {
          setVideo(je);
        }
        if (
          !response?.data?.courses?.name?.includes("Roadmap") &&
          params.id !== "job-identification-application" &&
          params.id !== "resumatic-ai" &&
          params.id === "interview-pro"
        ) {
          setVideo("");
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
          left: 0,
        });
        console.log(response?.data?.courses);
        if (
          response?.data?.courses?.name
            .toLowerCase()
            .includes("with counseling") ||
          response?.data?.courses?.name
            .toLowerCase()
            .includes("with counseling") ||
          response?.data?.course?.name
            .toLowerCase()
            .includes("(with counseling)")
        ) {
          setCounselling("With Counseling");
        } else if (
          response?.data?.courses?.name
            .toLowerCase()
            .includes("without counseling") ||
          response?.data?.courses?.name
            .toLowerCase()
            .includes("without counseling") ||
          response?.data?.courses?.name
            .toLowerCase()
            .includes("(without counseling)")
        ) {
          //console.log("No");
          setCounselling("Without Counseling");
        }
        setSingleCourse(response?.data?.courses);
        if (
          response?.data?.courses?.description == "Corporate Sector Learning"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.description
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.description == "Automated Resume Builder"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.description
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.name == "Corporate Sector Learning"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.name
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.name == "Functional skill competency"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == "Softskill Competency Coaching"
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.description == "Interview Skill Training"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.description
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.name == "Elevator Pitch & Group Discussions"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.name
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.name ==
          "Digital Profiling & Professional Networking"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.name
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else if (
          response?.data?.courses?.name == "Job Identification & Application"
        ) {
          let newcoursedata = CourseData.filter(
            (course) => course.name == response?.data?.courses?.name
          );
          console.log("All Course Data:", CourseData);

          console.log("Response Course Name:", response?.data?.courses?.name);
          console.log("Filtered Course Data:", newcoursedata);

          setCourseData(newcoursedata[0]);
          const fullstring = removeUselessWords(newcoursedata[0].name);
          const splitfullstring = fullstring.split(" ");
          if (splitfullstring.length === 3) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[2]);
          } else if (splitfullstring.length === 5) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3] + " " + splitfullstring[4]);
          } else if (splitfullstring.length === 4) {
            setActiveCourse(true);
            setloading(false);
            setFirstHeaad(splitfullstring[0] + " " + splitfullstring[1]);
            setSecondHead(splitfullstring[3]);
          }
          setProffContent([
            {
              heading: "Benefits of Resumatic AI",
              para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding. ",
              para2: `Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-powered professional career assessment and a professional counsellor.`,
            },
            {
              heading: "Why AI-Powered Career Assessment & Planning?",
              para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality.",
              para2:
                "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
            },
          ]);
        } else {
          setActiveCourse(false);
          setCourseData({
            isCounselling: response?.data?.courses?.isCounselling,
            name: removeUselessWords(response?.data?.courses?.name),
            desc:
              response?.data?.courses?.description ||
              response?.data?.courses?.name,
            // "The difference between User Interface (UI) and User Experience (UX) is that UI refers to the aesthetic elements by which people interact with a product, while UX is about the experience a user has with a product or service",
            time: "60 min",
            price: response?.data?.courses?.price || "999",
            text1: "You Will Learn & Gain Expertise on Various Sectors",
            text2: "Identify the Industry & Sector Of Your Interest",
            text3: "Course duration: 240 minutes (2 sessions)",
            videoLink1: response?.data?.courses?.videoLink1,
            videoLink2: response?.data?.courses?.videoLink2,
            text4: [
              "- Recorded Sessions",
              "- Learn Sector-Based Competencies",
              "- 240 min modules ",
              "- 30 days access for a revision session",
            ],
            text5: [
              {
                head: "Benefits Of AI-Powered Viosa Career Roadmap",
                para: "Understanding your professional personality, career interest & motivators play an important role to identify the right nature of your job and succeeding.",
                para2:
                  "Choosing the right professional career is one of the most important decisions in everyone’s life. It is imperative to know what kind of work environment suits you the best. All these important parameters can be identified through AI-Powered professional career assessment and a professional counsellors.",
              },
              {
                head: "Why AI-Powered Career Assessment & Planning?",
                para: "VIOSA AI-powered career assessment provides a very high level of accuracy in identifying the 5-Dimensions of your professional personality. ",
                para2:
                  "Personalised career guidance from certified counsellors will help you in selecting the right Job which is mapped with your skill and abilities. This career planning will empower you to succeed in your professional career.",
              },
            ],
            featurecarddata: [
              {
                icon: FiveD,
                heading: "5-Dimensional Professional Personality Analysis",
                subheading:
                  "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
              },
              {
                icon: ACCURACY,
                heading: "98.6 % Accuracy",
                subheading:
                  "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
              },
              {
                icon: REPORT,
                heading: "24+ Page Detailed Professional Career Plan ",
                subheading:
                  "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
              },
              {
                icon: COUNSELLING,
                heading:
                  "Career Assessment & Counseling In the Language Of Your Choice",
                subheading:
                  "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
              },
            ],
            timelinedata: [
              "Start Career Assessment",
              "Get a 24 + Page Report",
              "Analyse Your Career Paths",
              "Get Personalised Career Counseling",
            ],
            text6: `How will ${response?.data?.courses?.name} help you?`,
            text7: [
              {
                iconImg: carrer2,
                head: "Sector Knowledge",
                para: "Learn about sector and respective competencies to achieve your aspirational professional roles",
              },
              {
                iconImg: carrer5,
                head: "Functional Understanding",
                para: "Employees who possess frontend/backend skills tend to be more successful in professional careers at an early stage",
              },
              {
                iconImg: carrer6,
                head: "Industry Trends",
                para: "Learn about latest trends in your choice of industry and government initiative for top 5 growing sectors",
              },
            ],
            faq: [
              {
                headQuestion: "What is five dimensional career assessment?",
                paraAnswer: `Our career assessment explores the 5-dimensions of the candidate. It
                includes Personality analysis, Career Interest analysis, Career
                motivators analysis, learning style analysis and Skills & Abilities
                analysis.`,
              },
              {
                headQuestion:
                  "What is the difference between Free Suitability and Comprehensive Analysis?",
                paraAnswer: `Both are 5-Dimensional career assessment. In Free Suitability,
                candidate can get analysis of one career path of their choice with
                free 3 pages basic report. In Comprehensive plan, career analysis will
                be done across 160+ career paths and 2000+ Occupations to find out the
                best career path for you. Comprehensive 28+ pages career report with
                execution plan will help you to achieve career goals.`,
              },
              {
                headQuestion:
                  "Once Comprehensive report is activated, can I see analysis other career paths as well?",
                paraAnswer: `Yes, very much. This is the biggest advantage every student is having.
                Once assessment is done student can generate unlimited Comprehensive
                28+ pages Comprehensive reports of different career paths. It is
                important to know you most preferred career paths but equally it is
                important to know about career paths that you should avoid or develop.`,
              },
              {
                headQuestion:
                  "Do I need to give assessment once again for Comprehensive analysis?",
                paraAnswer: `No, you do not need to give assessment again. Once assessment is done,
                you can upgrade any time. System will automatically analyze all career
                paths and generate Comprehensive report. Our powerful Artificial
                intelligence engine will unlock all the career path analysis.`,
              },
            ],
          });
          setloading(false);
        }

        // dispatch(SingleCourse(response?.data?.courses));
      };
      const setSpecificCourses = () => {
        setCourseid(params.id);
        fetchCourse();
      };
      setSpecificCourses();
    }
  }, [params.id, location.pathname]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token && params.id) {
      const fetchCourse = async () => {
        try {
          const response = await getAllUserCourses(token);
          console.log("Respose:", response);
          const isAlreadyPurchased = response?.data?.courses.filter(
            (item) => item.slug === params.id
          );
          console.log("ispurchased", isAlreadyPurchased);
          setIsPurchased(isAlreadyPurchased.length > 0 ? true : false);
          dispatch(
            UserLogin({
              courseList: response?.data?.courses,
              loggedIn: true,
            })
          );
        } catch (e) {
          console.log(e);
        }
      };
      fetchCourse();
    }
  }, [params.id]);

  const isLogin = searchParams.get("loggedIn");

  useEffect(() => {
    if (isLogin === "true") {
      setLoading1(true);

      if (loginToken) {
        if (singleCourse) {
          setTimeout(() => {
            handleBuy();
          }, 1000);
          setLoading1(false);
        }
      }
    }
  }, [isLogin, loginToken, singleCourse, isCourseExists]);
  // //console.log(CourseData);

  // const timelinedata = [
  //   "Start Assessment",
  //   "Set your Goals",
  //   "Test your Skills",
  //   "Get Report Analysis of your Performance",
  // ];
  const timelinedata = [
    "Start Career Assessment",
    "Get a 24 + Page Report",
    "Analyse Your Career Paths",
    "Get Personalised Career Counseling",
  ];

  return (
    <div className="" style={{ position: "relative" }}>
      {whatsApp && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 999,
            top: "15%",
          }}
        >
          {/* {console.log("abhi")} */}
          <input type="checkbox" id="my-modal-2" className="modal-toggle" />
          <div className="">
            {/* className={`modal stop-scroll modal-dev`} */}
            <div className="modal-box relative">
              <label
                for="my-modal-2"
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={() => {
                  setWhatsApp(false);
                }}
              >
                ✕
              </label>
              <div className="flex justify-center">
                <form onSubmit={(e) => submitsupportform(e)}>
                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6">
                      <img className="mx-4 for-opacity" src={people} alt="" />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="text"
                        name="name"
                        required
                        value={supportdata.name}
                        onChange={handleChangesupport}
                        placeholder="Enter your name"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border  hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={messageing}
                        alt=""
                      />
                      <input
                        required
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="email"
                        name="email"
                        value={supportdata.email}
                        onChange={handleChangesupport}
                        placeholder="Enter your email"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={phoneCall}
                        alt=""
                      />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="tel"
                        name="phone"
                        pattern="[6-9]{1}[0-9]{9}"
                        title="Please enter a valid phone number"
                        required
                        value={supportdata.phone}
                        onChange={handleChangesupport}
                        placeholder="Enter your phone"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border-message hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field text-center"
                        type="text"
                        required
                        name="message"
                        value={supportdata.message}
                        onChange={handleChangesupport}
                        placeholder="Your Message"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 d-flex align-center justify-content-center">
                    <button
                      type="submit"
                      for="my-modal-1"
                      disabled={loading}
                      className="forHoverContact-button hover:shadow-2xl"
                    >
                      {loading ? "Sending..." : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SignUp
              setBlur={setBlur}
              register1={register1}
              setRegister={setRegister}
              setLogin={setLogin}
              login={login}
            />

            <div className="bg-white container-fluid aboutus-main-container">
              <ToastContainer />
              <div className="align-responsive">
                <div className="items-center onetp-res justify-between">
                  <div
                    style={{ maxWidth: "1680px" }}
                    className="hero-content direction-cont flex-col lg:flex-row-reverse my-5 "
                  >
                    <div className="col-4 w-100-768">
                      {status && (
                        <Cartmodal
                          accessCode={accessCode}
                          status={status}
                          paymentsuccess={paymentsuccess}
                          setStatus={setStatus}
                        />
                      )}
                      <div className="d-flex  price-box-res">
                        <div className="mt-3 box d-flex">
                          {/* <h1 className="one-time-pay">One Time Pay Only</h1> */}

                          {isPurchased1 === false ? (
                            <>
                              <>
                                <div>
                                  <h2
                                    style={{
                                      fontSize: "34px",
                                      display: "inline",
                                      color: "#521986",
                                    }}
                                  >
                                    {singleCourse?.price
                                      ? "₹" + singleCourse?.price
                                      : ""}
                                  </h2>
                                  <span
                                    style={{
                                      colour: "gray",
                                      marginLeft: "5px",
                                      fontSize: "11px",
                                      color: "#FF0000",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {singleCourse?.price ? (
                                      <>
                                        <i class="fa-solid fa-plus"></i> GST
                                        (18%)
                                      </>
                                    ) : (
                                      <>
                                        <p className="text-center">
                                          <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </p>
                                      </>
                                    )}
                                  </span>
                                </div>
                              </>
                              <>
                                <div
                                  disabled={singleCourse?.price > 0}
                                  style={{ fontSize: "15px" }}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleLogin();
                                  }}
                                >
                                  {singleCourse?.price ? (
                                    <>
                                      {/* <div className="coupon__box ">
                                  <p className="coupon__name">Coupon Applied</p>{" "}
                                  <img alt="..." className="coupon__img" src={check} />
                                </div> */}
                                    </>
                                  ) : couponloading ? (
                                    <div className="apply__coupon cursor-pointer d-flex align-center justify-center">
                                      <p className="apply__text ">Loading...</p>
                                      <div
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        className="spinner-border text-light ml-2 mt-1"
                                        role="status"
                                      ></div>
                                    </div>
                                  ) : null}
                                </div>

                                {singleCourse?.price ? (
                                  <>
                                    {isPurchased1 === true ? (
                                      <button
                                        className="cursor-pointer btn-primary-1"
                                        // onClick={() => buyNow()}
                                        // onClick={() => setCouponInput(true)}
                                        // onClick={() => handleLogin()}
                                        onClick={() =>
                                          navigate("/profile/dashboard")
                                        }
                                      >
                                        Go to Dashboard
                                      </button>
                                    ) : (
                                      singleCourse?.price && (
                                        <div
                                          className="cursor-pointer apply__coupon m-2"
                                          onClick={handleWhatsApp}
                                          disabled={spin}
                                        >
                                          {spin ? (
                                            <p className="text-center">
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </p>
                                          ) : (
                                            <p className="buy__text">
                                              Send Request
                                            </p>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {loadingcart ? (
                                      <p className="text-center">
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </p>
                                    ) : isCourseExists?.name ? (
                                      <h4 style={{ fontSize: "15px" }}>
                                        Added to Cart
                                      </h4>
                                    ) : (
                                      <h4
                                        onClick={() => {
                                          addToCart();
                                        }}
                                        style={{ fontSize: "15px" }}
                                        className="cursor-pointer"
                                      >
                                        Add to Cart
                                      </h4>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => navigate("/profile/dashboard")}
                                className="btn-primary-1"
                              >
                                Go to Dashboard
                              </button>
                              Already Purchased
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-8 w-100-768">
                      <div className="">
                        <div className="flex course-head-sec items-center flex-direction-column align-item-start gap-x-4">
                          {!activecourse ? (
                            <h1
                              style={{ color: "#521986 !important" }}
                              className="course-mainheading text-start "
                            >
                              {courseData?.name.replace("( )", "")}
                              <span
                                style={{
                                  color: "#fea302",
                                  display: "block",
                                  textTransform: "capitalize",
                                }}
                              >
                                {counselling}
                              </span>
                            </h1>
                          ) : (
                            <>
                              {(courseData?.name
                                .toLowerCase()
                                .includes("resume") ||
                                courseData?.name
                                  .toLowerCase()
                                  .includes("interview") ||
                                courseData?.name
                                  .toLowerCase()
                                  .includes("identification")) && (
                                <p
                                  style={{
                                    display: "block",
                                    color: "black",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    fontFamily: "'Inter', sans-serif",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {" " + "AI-Powered"}
                                </p>
                              )}
                              <h1
                                style={{ color: "#521986 !important" }}
                                className="course-mainheading"
                              >
                                {courseData?.name.toLowerCase().includes("&")
                                  ? firstHead.toUpperCase() + " & "
                                  : firstHead.toUpperCase()}
                                <span style={{ color: "#fea302" }}>
                                  {firstHead[1] !== "AI-Powered" &&
                                    " " + secondHead.toUpperCase()}
                                </span>
                              </h1>
                            </>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center mb-2 mt-2 gap-x-2">
                            <p className="text-xl" style={{ color: "#FFA200" }}>
                              4.0
                            </p>
                            <img src={star} style={{ width: "15px" }} alt="" />
                            <img src={star} style={{ width: "15px" }} alt="" />
                            <img src={star} style={{ width: "15px" }} alt="" />
                            <img src={star} style={{ width: "15px" }} alt="" />
                            <img
                              src={stardark}
                              style={{ width: "15px" }}
                              alt=""
                            />
                          </div>
                          <p
                            style={{ width: "90%" }}
                            className="course-paragraph"
                          >
                            {courseData?.desc}
                          </p>
                        </div>

                        <button
                          className="flex items-center
                        cursor-none
                        justify-center mt-4 minit120-button gap-x-2"
                          style={{ cursor: "default" }}
                        >
                          <img src={clock} alt="" />
                          <p className="text-white">{courseData?.time} </p>
                        </button>
                        <p className="course-month-head">
                          {courseData?.timeMonth}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ maxWidth: "1680px", position: "relative" }}
                    className="py-10 row roadmap-section-container"
                  >
                    <div className="col-7 w-100-768 roadmap-card-container">
                      {courseData?.featurecarddata.map((carddata, idx) => {
                        return (
                          <Featurecard
                            key={idx}
                            heading={carddata?.heading}
                            // subheading={carddata.subheading}
                            icon={carddata?.icon}
                          />
                        );
                      })}
                    </div>
                    <div
                      className="col-5 w-100-768 "
                      style={{ position: "relative" }}
                    >
                      <div className="d-flex justify-end price-box-res">
                        <img
                          src={videoLink?.videoLink1}
                          width="100%"
                          lazy="loading"
                          height="300px"
                          alt="produt-demo"
                          style={{
                            position: "absolute",
                            top: "-119px",
                            left: "63px",
                            width: "414px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="col-6 w-100-768">
                      <h3 className="color-black course-sub-main-heading text-start">
                        {courseData?.text1}
                      </h3>
                      <p className="course-paragraph mt-1 mb-5">
                        {courseData?.text2}
                      </p>
                      <p className="mt-4 color-black course-sub-main-heading text-start">
                        About the Course
                      </p>
                      <p className="text-start schools-subheading">
                        {courseData?.text3}
                      </p>
                      {courseData?.text4.map((data, idx) => {
                        return (
                          <p
                            style={{ opacity: "1" }}
                            key={idx}
                            className="color-black my-1 course-paragraph"
                          >
                            {data}
                          </p>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
                <div
                  style={{ maxWidth: "1680px" }}
                  className="hero-content flex-col lg:flex-row-reverse m-auto"
                >
                  <div className="col-12 ">
                    {courseData?.text5.map((data, idx) => {
                      return (
                        <>
                          <br />
                          {data.head && (
                            <p className="schools-subheading  text-start ">
                              {data.head}
                            </p>
                          )}
                          {data.para && (
                            <>
                              <p
                                key={idx}
                                className="course-paragraph text-justify"
                              >
                                {data.para}{" "}
                              </p>{" "}
                              <br />
                            </>
                          )}
                          {data.para2 && (
                            <p
                              key={idx}
                              className="course-paragraph text-justify"
                            >
                              {data.para2}
                            </p>
                          )}
                          {data?.list?.length !== 0 && (
                            <>
                              <ul className="list-experts">
                                {data?.list?.map((e) => (
                                  <li className="course-paragraph text-justify">
                                    {e}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>

                <div>
                  {courseData?.name === "Softskill Competency Coaching" ? (
                    <>
                      <section className="py-5 row roadmap-section-container">
                        <TimelineComp data={timelinedata} />
                      </section>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {/* <section className="py-5 row roadmap-section-container">
                  <TimelineComp data={timelinedata} />
                </section> */}

                {/* <section className="py-5 row roadmap-section-container">
                  <div className="col-12 roadmap-feature-containerss course-video-bottom">
                    <video
                      className="roadmap-feature-video-1"
                      loop
                      poster={largeVideo}
                      controls
                      playsInline
                      src={videoLink?.videoLink2}
                      muted
                      controlsList="nodownload"
                    ></video>
                  </div>
                </section> */}
                <div
                  style={{ maxWidth: "1680px" }}
                  className="mb-12 hero-content flex-col lg:flex-row-reverse"
                >
                  {/* <div className="col-12">
                    <p className="color-black mb-8 course-mainheading text-center">
                      {courseData?.text6}
                    </p>
                    <div>
                      <div className="flex course-not-conatiner item-align-column grid-cols-1 lg:grid-cols-3 md:grid-cols-3">
                        {courseData?.text7.map((data, idx) => {
                          return (
                            <div
                              key={idx}
                              className=" shadow-xl carrer-box career-box-white "
                            >
                              <figure className="pb-0 card-body items-center text-center">
                                <img
                                  // className="ml-24"
                                  src={data.iconImg}
                                  alt="Shoes"
                                />
                              </figure>
                              <div className="card-body-career items-center text-center">
                                <h2 className=" schools-subheading ">
                                  {data?.head}
                                </h2>
                                <p className="course-paragraph">{data?.para}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {!firstHead.includes("Corporate") &&
              !firstHead.includes("Competency") &&
              !firstHead.includes("Elevator") &&
              !firstHead.includes("Digital") ? (
                <section className="py-10 row roadmap-section-container">
                  <FrequentlyAsked faq={courseData?.faq} />
                </section>
              ) : (
                <></>
              )}
            </div>
          </motion.div>
          {/* <FooterPurple /> */}
        </>
      )}
      {whatsApp && (
        <div>
          <input type="checkbox" id="my-modal-2" className="modal-toggle" />
          <div className={`modal stop-scroll modal-dev`}>
            <div className="modal-box relative">
              <label
                for="my-modal-2"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <div className="flex justify-center">
                <form onSubmit={(e) => submitsupportform(e)}>
                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6">
                      <img className="mx-4 for-opacity" src={people} alt="" />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="text"
                        name="name"
                        required
                        value={supportdata.name}
                        onChange={handleChangesupport}
                        placeholder="Enter your name"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border  hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={messageing}
                        alt=""
                      />
                      <input
                        required
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="email"
                        name="email"
                        value={supportdata.email}
                        onChange={handleChangesupport}
                        placeholder="Enter your email"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <img
                        className="mx-4 for-opacity"
                        src={phoneCall}
                        alt=""
                      />
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field"
                        type="tel"
                        name="phone"
                        pattern="[6-9]{1}[0-9]{9}"
                        title="Please enter a valid phone number"
                        required
                        value={supportdata.phone}
                        onChange={handleChangesupport}
                        placeholder="Enter your phone"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 input-border-message hover:shadow-2xl">
                    <div className="flex items-center justify-center mb-6 ">
                      <input
                        style={{ background: "#FFF7E7" }}
                        className="pl-2 input-field text-center"
                        type="text"
                        required
                        name="message"
                        value={supportdata.message}
                        onChange={handleChangesupport}
                        placeholder="Your Message"
                      ></input>
                    </div>
                  </div>

                  <div className="my-2 d-flex align-center justify-content-center">
                    <button
                      type="submit"
                      for="my-modal-1"
                      disabled={loading}
                      className="forHoverContact-button hover:shadow-2xl"
                    >
                      {loading ? "Sending..." : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseNotLogin;
