import React, { useEffect, useState } from "react";
import Roadmapheading1 from "../../assets/image/roadmap-heading-1.png";
import Roadmapheading2 from "../../assets/image/roadmap-heading-2.png";
import IMG1 from "../../assets/image/8-10-with.jpg";
import arrow from "icons/arrow.png";
import IMG2 from "../../assets/image/8-10-without.jpg";
import IMG3 from "../../assets/image/11-12-without.jpg";
import IMG4 from "../../assets/image/11-12-with.jpg";
import spaceRover from "../../components/assets/icon/Space Rover.svg";
import FiveD from "../../components/assets/icon/5D.png";
import ACCURACY from "../../components/assets/icon/ACCURACY.png";
import REPORT from "../../components/assets/icon/REPORT-1.png";
import COUNSELLING from "../../components/assets/icon/COUNSELLING.png";
import PREDICTOR from "../../components/assets/icon/PREDICTOR.png";
import EXAM_CALANDER from "../../components/assets/icon/EXAM_CALANDER.png";
import LANGUAGE from "../../components/assets/icon/LANGUAGE.png";
import why from "assets/why.png";
import REVISION from "../../components/assets/icon/REVISION.png";
import largeImage from "components/assets/icon/large-video.png";
import smallImage from "components/assets/icon/small-video.png";
import library2 from "../../components/assets/icon/library2.png";
import class8 from "assets/8.png";
import class11 from "assets/11.png";
import FrequentlyAsked from "../../components/Home/FrequentlyAsked";
import "./Roadmap.css";
import { Featurecard } from "../../components/Featurecard/Featurecard";
import TimelineComp from "../../components/TimelineComp/TimelineComp";
import { motion } from "framer-motion";
import PureModal from "react-pure-modal";
import road from "assets/road.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddToCart,
  FetchSingleCourse,
  getAllCourseWithPrice,
  getAllUserCourses,
  getCourseDetailsbyId,
} from "Axios/apis";
import SignUp from "components/Authentication/SignUp/SignUp";
import {
  AddtoCart,
  UserLogin,
  userRequest,
  userRoadMapCourse,
} from "redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import FooterPurple from "components/Footer/FooterPurple";
import Spinner from "components/Spinner/Spinner";
import axios from "axios";
import Cartmodal from "components/Cartmodal/Cartmodal";
import { async } from "@firebase/util";
import HeaderTop from "components/HeaderTop";
import prettyName from "pretty-name";

const RoadMapLogin = ({
  setBlur,
  togglesidebar,
  register1,
  setRegister,
  login,
  setLogin,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading1, setLoading1] = useState(false);
  const loginToken = localStorage.getItem("token");
  const [accessCode, setAccessCode] = useState("");
  const [largeVideo, setVideo] = useState();

  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [spin, setSpin] = useState(false);
  const [courseData, setCourseData] = useState();
  const [status, setStatus] = useState(false);
  const [loadingcart, setloadingcart] = useState(false);
  const [isPurchased, setIsPurchased] = useState();
  const [purchage, setPurchage] = useState(false);
  const [paymentsuccess, setPaymentSuccess] = useState(false);
  const [courseid, setCourseid] = useState("");
  const [show, setShow] = useState(false);
  const [bgimage, setBgImage] = useState();

  const [singleCourse, setSingleCourse] = useState([]);
  const [revealPrice, setRevealPrice] = useState("");

  const { cart } = useSelector((state) => state.user);
  const isCourseExists = cart?.find((item) => item.name === singleCourse?.name);
  const AddthisToCart = async () => {
    setloadingcart(true);
    try {
      const cartitem = {
        cartBody: {
          courseID: params.id,
          name: singleCourse?.name,
          thumbnail: singleCourse?.thumbnail,
          price: revealPrice,
          course_id: singleCourse?.course_id,
        },
        userId: localStorage.getItem("userId"),
      };
      const res = await AddToCart(cartitem);
      dispatch(AddtoCart(res?.data?.result?.cart));
      setloadingcart(false);
    } catch (error) {
      // //console.log(error);
      // toast.warning("Failed to Add to Cart, please try again");
      setloadingcart(false);

      alert("Failed to Add to Cart, please try again");
    }
  };
  useEffect(() => {
    const fetchCourse = async () => {
      const userid = localStorage.getItem("userId");
      const response = await FetchSingleCourse(params.id, userid);
      console.log("response", response);
      if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 8th, 9th, 10th")
      ) {
        setBgImage(IMG2);
      } else if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 11th & 12th")
      ) {
        setBgImage(IMG3);
      } else if (params.id === "6343bf2ae33839d145810ec6") {
        setBgImage(IMG1);
      } else if (
        response?.data?.courses?.name
          .toLowerCase()
          .includes("career map for 11th & 12th with counseling")
      ) {
        setBgImage(IMG4);
      }

      setRevealPrice(response?.data?.courses?.price);
      setSingleCourse(response?.data?.courses);
      if (response?.data?.courses?.name?.includes("8th")) {
        setVideo(class8);
      } else {
        setVideo(class11);
      }
      setCourseData({
        isCounselling: response?.data?.courses?.isCounselling,
        name: response?.data?.courses?.name,
        videoLink1: response?.data?.courses?.videoLink1,
        videoLink2: response?.data?.courses?.videoLink2,
        desc:
          response?.data?.courses?.description || response?.data?.courses?.name,
      });

      // dispatch(SingleCourse(response?.data?.courses));
    };
    const setSpecificCourses = () => {
      setCourseid(params.id);
      fetchCourse();
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSpecificCourses();
  }, [params.id]);

  useEffect(() => {
    setLoading1(true);
    const fetchCourse = async () => {
      setloading(true);
      try {
        setloading(false);
        setLoading1(false);
      } catch {
        setIsPurchased(false);
        setloading(false);
        setLoading1(false);
      }
    };
    fetchCourse();
  }, [loginToken, params.id, location.search]);

  useEffect(() => {
    if (loginToken && params.id) {
      setLoading1(true);
      const fetchCourse = async () => {
        try {
          const response = await getAllUserCourses(loginToken);
          const isAlreadyPurchased = response?.data?.courses.filter(
            (item) => item.courseID === params.id
          );
          setIsPurchased(isAlreadyPurchased.length > 0 ? true : false);
          setLoading1(false);
          dispatch(
            UserLogin({
              courseList: response?.data?.courses,
              loggedIn: true,
            })
          );
        } catch (e) {
          setLoading1(false);
          console.log(e);
        }
      };
      fetchCourse();
    }
  }, [params.id]);

  //console.log("purchaged", isPurchased);
  const handleLogin = () => {
    // //console.log(params.id);
    dispatch(userRequest(params.id));
    setRegister(true);
  };
  const featurecarddata = [
    {
      icon: FiveD,
      heading: "5-Dimensional Career Analysis",
      subheading:
        "Practice in real time with questions directly taken from their official questions banks. Get questions from relevant industries like consulting or marketing as well!",
    },
    {
      icon: ACCURACY,
      heading: "98.6 % Accuracy",
      subheading:
        "Our machine-learning algorithm analyses interview answers and provides feedback based on your performance.",
    },
    {
      icon: REPORT,
      heading: "24+ Page Detailed Career Plan Report",
      subheading:
        "Job seekers can use e to learn and improve their responses to more than 800 interview questions. ",
    },
    {
      icon: library2,
      heading: "Career Library with 150+ Career Paths & 3000+ Occupations",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: COUNSELLING,
      heading: "Personalised Career Counseling by Certified Counsellors",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: PREDICTOR,
      heading: "College Admission Predictor",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: EXAM_CALANDER,
      heading: "Competitive Exam Calendar",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
    {
      icon: LANGUAGE,
      heading: "Career Assessment & Counseling in Language of your Choice",
      subheading:
        "Candidates can get immediate feedback on performance, complete with specific advice and examples of best answers.",
    },
  ];

  const timelinedata = [
    "Start Career Assessment",
    "Get a 24+ Page Report",
    "Analyse Your Career Paths",
    "Get Personalised Career Counseling",
  ];

  const faq = [
    {
      headQuestion: "What is five dimensional career assessment?",
      paraAnswer: `Our career assessment explores the 5-Dimensions of the candidate. It
      includes Personality analysis, Career Interest analysis, Career
      motivators analysis, learning style analysis and Skills & Abilities
      analysis.`,
    },
    {
      headQuestion:
        "What is the difference between Free Suitability and Comprehensive Analysis?",
      paraAnswer: `Both are 5-dimensional career assessment. In Free Suitability,
      candidate can get analysis of one career path of their choice with
      free 3 pages basic report. In Comprehensive plan, career analysis will
      be done across 160+ career paths and 2000+ Occupations to find out the
      best career path for you. Comprehensive 28+ pages career report with
      execution plan will help you to achieve career goals.`,
    },
    {
      headQuestion:
        "Once Comprehensive report is activated, can I see analysis other career paths as well?",
      paraAnswer: `Yes, very much. This is the biggest advantage every student is having.
      Once assessment is done student can generate unlimited Comprehensive
      28+ pages Comprehensive reports of different career paths. It is
      important to know you most preferred career paths but equally it is
      important to know about career paths that you should avoid or develop.`,
    },
    {
      headQuestion:
        "Do I need to give assessment once again for Comprehensive analysis?",
      paraAnswer: `No, you do not need to give assessment again. Once assessment is done,
      you can upgrade any time. System will automatically analyze all career
      paths and generate Comprehensive report. Our powerful Artificial
      intelligence engine will unlock all the career path analysis.`,
    },
  ];
  // const timelinedata = [
  //   "Start Assessment",
  //   "Set your Goals",
  //   "Test your Skills",
  //   "Get Report Analysis of your Performance",
  // ];
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleBuy = async () => {
    if (loginToken) {
      if (isCourseExists?.name === singleCourse?.name) {
        navigate("/profile/cart");
      } else {
        const cartitem = {
          cartBody: {
            courseID: params.id,
            name: singleCourse?.name,
            thumbnail: singleCourse?.thumbnail,
            price: singleCourse?.price,
            course_id: singleCourse?.course_id,
          },
          userId: localStorage.getItem("userId"),
        };
        const res = await AddToCart(cartitem);
        dispatch(AddtoCart(res?.data?.result?.cart));
        setSpin(false);
        if (res?.data?.result?.cart) {
          navigate("/profile/cart");
        }
      }
    } else {
      setRegister(true);
      setSpin(false);
      dispatch(
        userRoadMapCourse({
          courseId: params.id,
          navigateTo: `/course/roadmap/${params.id}?couselling=false`,
        })
      );
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <HeaderTop togglesidebar={togglesidebar} name="Course" />
          <div className="flex items-center mb-3">
            <h1 className="mr-2 text-xl update-heading">
              <Link style={{ color: "#535353" }} to="/profile/catalogue">
                All Courses
              </Link>
            </h1>
            <img className="img-small" src={arrow} height="16" alt="img" />
            <h1 className="px-1 update-heading">
              {prettyName(singleCourse?.name)}
            </h1>
          </div>
          {status && (
            <Cartmodal
              accessCode={accessCode}
              status={status}
              paymentsuccess={paymentsuccess}
              setStatus={setStatus}
            />
          )}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="container-fluid">
              <div className="bg-white container-fluid roadmap-main-container">
                <section>
                  <div className="row roadmap-heading-bg">
                    <div className="roadmap-professional-mainheader-bg ">
                      <div className="w-100 ">
                        <img className="" src={bgimage} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className=" row mt-4">
                    <h1
                      style={{
                        color: "#521986",
                        fontWeight: "700",
                        fontSize: "40px",
                        lineHeight: "50px",
                      }}
                      className="py-5 text-center course-one-line-head   "
                    >
                      {courseData?.isCounselling
                        ? "Unlock Your Potential with AI-powered Career Guidance and Counselling"
                        : "Unlock Your Potential with AI-powered Career Guidance"}
                      {/* Find out your suitable career plan with our 5-Dimensional
                      career assessment and build your dream career */}
                    </h1>
                  </div>
                  {/* <div className="roadmap-professional-mainheader-bg"></div> */}
                  <div className="p-0 d-flex-768 d-none-big col-12 d-flex justify-content-center ">
                    <img
                      className="roadmap-professional-img"
                      src={Roadmapheading1}
                      alt=""
                    />
                  </div>
                </section>

                <section className="py-10 row roadmap-section-container">
                  <div className="col-7 w-100-768 roadmap-card-container">
                    {featurecarddata.map((carddata, idx) => {
                      return (
                        <Featurecard
                          key={idx}
                          heading={carddata.heading}
                          // subheading={carddata.subheading}
                          icon={carddata?.icon}
                        />
                      );
                    })}
                  </div>
                  <div className="col-5 w-100-768 roadmap-feature-containerss road-login-mid">
                    <video
                      className="roadmap-feature-video"
                      loop
                      controls
                      poster={why}
                      style={{ height: 200 }}
                      height="200px"
                      src={courseData?.videoLink1}
                      controlsList="nodownload"
                    ></video>
                    <div className="col-12 mt-4  w-100-768">
                      <div className="d-flex  price-box-res">
                        <div className="mt-3 mx-auto box d-flex gap-y-4">
                          <div></div>
                          {isPurchased ? (
                            <>
                              <button
                                className="cursor-pointer btn-primary-1"
                                // onClick={() => buyNow()}
                                // onClick={() => setCouponInput(true)}
                                // onClick={() => handleLogin()}
                                onClick={() => navigate("/profile/dashboard")}
                              >
                                Go to Dashboard
                              </button>
                              Already Purchased
                            </>
                          ) : (
                            <>
                              {revealPrice !== "Contact Us" ? (
                                loading1 ? (
                                  <p className="text-center">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </p>
                                ) : (
                                  <div className="d-flex align-items-end">
                                    <h1
                                      style={{
                                        display: "inline",
                                        color: "#521986",
                                        fontWeight: "700",
                                        fontSize: "34px",
                                      }}
                                      className="one-time-pay"
                                    >
                                      {"₹" + revealPrice}
                                    </h1>
                                    <span
                                      style={{
                                        colour: "gray",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                        color: "#FF0000",
                                      }}
                                    >
                                      <i className="fa-solid fa-plus"></i> GST
                                      (18%)
                                    </span>
                                  </div>
                                )
                              ) : (
                                <h1
                                  style={{ display: "inline" }}
                                  className="one-time-pay"
                                >
                                  {revealPrice}
                                </h1>
                              )}
                              {loading1 ? null : (
                                <button
                                  className="cursor-pointer btn-primary-1"
                                  disabled={spin}
                                  onClick={() => handleBuy()}
                                >
                                  {spin ? (
                                    <p className="text-center">
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </p>
                                  ) : (
                                    <p className="buy__text">Buy Now</p>
                                  )}
                                </button>
                              )}
                              {loadingcart ? (
                                <p className="text-center">
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </p>
                              ) : isCourseExists?.name ? (
                                <h4 style={{ fontSize: "15px" }}>
                                  Added to Cart
                                </h4>
                              ) : (
                                <h4
                                  onClick={() => {
                                    AddthisToCart();
                                  }}
                                  style={{ fontSize: "15px" }}
                                  className="cursor-pointer"
                                >
                                  {loading1 ? null : "Add to Cart"}
                                </h4>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="py-10 row roadmap-section-container align-items-center">
                  <div className="col-12 p-0 w-100-768 d-flex flex-column ">
                    <p className="roadmap-paragraph">
                      Choosing the right career path is one of the most
                      important decisions in everyone’s life. It is important to
                      pick the right one since it determines the course of your
                      professional career.
                    </p>
                  </div>
                </section>

                <section className="py-10 roadmap-margin roadmap-section-container">
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "40px",
                      fontWeight: "700",
                      color: "rgb(82, 25, 134)",
                    }}
                    className="why-ai-head"
                  >
                    Why AI-Powered Career Assessment & Planning?
                  </h1>
                  <p className="roadmap-paragraph mt-2">
                    VIOSA AI-powered career assessment provides a very high
                    level of accuracy in identifying the 5-Dimensions of your
                    professional personality. Personalised career guidance from
                    certified counsellors will help you in understanding the
                    right choice for your career progression which is aligned
                    with your personality, skills and abilities, career
                    interests and motivators.
                  </p>
                </section>
                <section className="py-5 row roadmap-section-container">
                  <TimelineComp data={timelinedata} />
                </section>
                {/* <section className="py-5 row roadmap-section-container">
                  <div className="col-12 roadmap-feature-containerss course-video-bottom">
                    <video
                      className="roadmap-feature-video"
                      loop
                      controls
                      poster={largeVideo}
                    >
                      <source src={courseData?.videoLink2} />
                    </video>
                  </div>
                </section> */}
                {/* <section className="cell-background">
                  <div className="flex items-center justify-between mx-16">
                    <div>
                      <p className="ai-heading">
                        Why is{' '}
                        <span style={{ color: 'rgba(255, 163, 3, 1)' }}> AI </span>{' '}
                        Important
                      </p>
                    </div>
                    <div>
                      <img className="ai-image" src={RoadmapWhyus} alt="" />
                    </div>
                  </div>
                  <p className="flex justify-center Frequently-heading">
                    Frequently Asked{' '}
                    <span style={{ color: 'rgba(255, 163, 3, 1)', marginLeft: '10px' }}>
                      {' '}
                      Questions{' '}
                    </span>{' '}
                  </p>
                </section> */}
                <section className="py-10 row roadmap-section-container">
                  <FrequentlyAsked faq={faq}></FrequentlyAsked>
                </section>
              </div>
            </div>
          </motion.div>
          {/* <FooterPurple /> */}
        </>
      )}
    </>
  );
};

export default RoadMapLogin;
