import React, { useEffect } from "react";
import comingsoon from "assets/comingsoon.jpg";
import { useLocation } from "react-router-dom";

export const ComingSoon = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <div className="d-flex justify-content-center">
      <img src={comingsoon} width="100%" height="560" />
    </div>
  );
};
