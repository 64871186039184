import React from "react";
import home2 from "./../assets/image/Ellipse 318.png";
import home3 from "./../assets/image/resume2.png";
import home4 from "./../assets/image/resume1.png";
import teaching from "./../assets/image/resume-image.jpg";
import play from "./../assets/image/play-button.png";
import heading from "./../assets/image/new-home.png";
import threeMinits from "./../assets/image/Group 33931.png";
import inr from "./../assets/icon/inr.svg";
import twelve from "./../assets/icon/1200.svg";
import nine from "./../assets/icon/₹ 999.svg";
import FrequentlyAsked from "./FrequentlyAsked";
import TimelineComp from "../../components/TimelineComp/TimelineComp";
import { motion } from "framer-motion";
import FooterPurple from "components/Footer/FooterPurple";

const Resume = () => {
  const timelinedata = [
    "Start Assessment",
    "Set your Goals",
    "Test your Skills",
    "Get Report Analysis of your Performance",
  ];
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <div>
            <img className="home-curve-image" src={home2} alt="" />
            <div>
              <div className="flex items-center home-curve-image">
                <div>
                  <img className="w-96" src={home4} alt="" />
                </div>
                <div className="home-heading">
                  <h1 className="flex justify-center professional-heading">
                    Resume Builder
                  </h1>
                  <p className="flex justify-center professional-para">
                    You spend days to write a resume but can’t{" "}
                  </p>
                  <p className="flex justify-center professional-para">
                    find the correct words{" "}
                  </p>
                </div>
                <div className="mt-4 flex-end for-1440">
                  <img className="w-96" src={home3} alt="" />
                </div>
              </div>
              <h1
                className="flex justify-center pt-[470px] mx-16"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                Want to create a resume that stands out?
              </h1>

              <h1
                style={{ fontSize: "18px", fontWeight: "500", opacity: "0.5" }}
                className="flex justify-center mx-16"
              >
                Our AI-Powered resume builder will help you create a
                job-specific impactful resume and
              </h1>

              <h1
                style={{ fontSize: "18px", fontWeight: "500", opacity: "0.5" }}
                className="flex justify-center mx-16"
              >
                a cover letter to match your job profile!
              </h1>
            </div>
            <div className="mx-16 mt-8">
              <div className="flex items-center justify-between">
                <img className="first-width" src={heading} alt="" />
                <div className="flex items-center justify-center">
                  <img className="teacher-image" src={teaching} alt="" />
                  <img className="w-16 play-image" src={play} alt="" />
                </div>
              </div>
            </div>

            <div className="mx-16 mt-8 ">
              <div className="flex items-center justify-between">
                <div className="interview-width">
                  <div>
                    <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
                      ABOUT THIS COURSE{" "}
                    </h1>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      Course duration: 120 minutes (3 sessions) + Resume Builder
                      Access
                    </p>
                  </div>
                  <div className="my-2">
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        opacity: "0.5",
                      }}
                    >
                      As job hunting has transitioned to an online experience,
                      we need to make sure that your documents are formatted and
                      written to get past Applicant Tracking Systems and into
                      the hands of employers. Understanding keyword
                      optimization, adaptability, and text-based formatting are
                      all vital in maximizing your success in this new
                      landscape.
                    </p>
                  </div>
                  <p style={{ fontSize: "20px", fontWeight: "600" }}>
                    Whether you're leveling up, transitioning your career, or
                    unexpectedly unemployed, this module will help you craft a
                    resume & cover letter which is job-specific and AI-enabled.
                  </p>
                </div>
                <div className="card-background">
                  <div>
                    <div className="flex items-center justify-center mb-4">
                      <img src={inr} alt="" />
                      <img src={twelve} alt="" />
                    </div>

                    <div className="flex items-center justify-center">
                      <img src={nine} alt="" />
                    </div>
                  </div>

                  <div>
                    <button className="my-4 buy-button w-100">Buy Now</button>
                    <button className="add-cart w-100">Add to Cart</button>
                  </div>
                </div>
              </div>
            </div>

            <section className="py-10 row roadmap-section-container">
              <TimelineComp data={timelinedata} />
            </section>

            <div className="flex items-center justify-center">
              <img
                className="flex justify-center mx-16 mb-12 interview-image"
                src={teaching}
                alt=""
              />
              <img className="w-16 play-image" src={play} alt="" />
            </div>

            {/* <img className='flex justify-center mx-16 mb-12 rectangle-image' src={teaching} alt="" /> */}

            <div className="">
              <div className="flex items-center justify-between mx-16">
                <div>
                  <p className="Frequently-heading">
                    <span style={{ color: "rgba(255, 163, 3, 1)" }}>
                      {" "}
                      Automated <br></br>{" "}
                    </span>
                    Job Identification and Application
                  </p>
                </div>
                <div>
                  <img className="" src={threeMinits} alt="" />
                </div>
              </div>
              <p className="flex justify-center Frequently-heading">
                Frequently Asked{" "}
                <span
                  style={{ color: "rgba(255, 163, 3, 1)", marginLeft: "10px" }}
                >
                  {" "}
                  Questions{" "}
                </span>{" "}
              </p>
            </div>
            <FrequentlyAsked></FrequentlyAsked>
            {/* <Footer></Footer> */}
          </div>
        </div>
      </motion.div>
      {/* <FooterPurple /> */}
    </>
  );
};

export default Resume;
