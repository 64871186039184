import React, { useState } from 'react'
import selected from "./../assets/icon/Polygon 7.svg";
import notSelected from "./../assets/icon/Polygon 6.svg";
import curve from "./../assets/icon/Rectangle 154.svg";

const MyAsked = ({ headQuestion, paraAnswer }) => {

    const [question, setQuestion] = useState(false)

    const handleQuestion = () => {
        setQuestion(!question)
    }

    return (
        <>
            <div className="w-full question-background">
                <div onClick={handleQuestion} className="flex items-center ques-res h-16">
                    {question && <img className="h-16" src={curve} alt="" />}
                    {question ? (
                        <img className="w-4 mr-4 ml-4" src={notSelected} alt="" />
                    ) : (
                        <img className="w-4 mr-4" src={selected} alt="" />
                    )}
                    <h1 className="question">{headQuestion}</h1>
                </div>
                <p className={`${question ? "blocked" : "hide"}`}>
                    {paraAnswer}
                </p>
            </div>
        </>
    )
}

export default MyAsked